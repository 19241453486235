import { ApiEntity } from 'app/api/models/api-entity.model';
import { LookupList } from 'app/shared/models/lookup-list.model';

export class CaseActivityEmailModel extends ApiEntity {
  constructor(
    id: number = null,
    public subject: string = '',
    public body: string = '',
    public recipientId: number = null,
    public recipientLookupData: LookupList = null,
    public emailTemplateId: number = 0,
    public emailTemplateLookupData: LookupList = null,
    public isReplyTo: boolean = false,
    public recipientEmailAddress: string = '',
    public isReadOnly: boolean = false,
    public originalEmailBody: string = '',
    public fileStoreReferenceId: number = null,
    public documentPackId: number = null,
    public isRecipientLookupDataGrouped: boolean = false,

  ) {
    super(id);
  }
}
