import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { CaseActivityTimeSearchListListItemModel } from './case-activity-time-search-list-list-item.model';

export class CaseActivityTimeSearchListListItemViewModel extends ActionableItemState {

  constructor(dataModel: CaseActivityTimeSearchListListItemModel) {
    super();

    this.dataModel = dataModel;
    
  }
}

