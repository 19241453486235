import { Component, Input, Output, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TimerControlStatus, TimerControlEvents } from 'app/shared/enumeration/timer-control-status';

@Component({
  selector: 'timer-control',
  templateUrl: './timer-control.component.html',
  styles: [
    `
      .recording {
        color: #f00;
      }
    `
  ]
})
export class TimerControlComponent implements OnChanges {
  @Input() controlStatus = TimerControlStatus.Paused;
  @Output() timerClicked = new EventEmitter<TimerControlEvents>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {

  }

  get isRecording(): boolean {
    return this.controlStatus === TimerControlStatus.Recording;
  }

  get showPause(): boolean {
    return this.controlStatus === TimerControlStatus.Recording;
  }
  get showStop(): boolean {
    return this.controlStatus === TimerControlStatus.Paused;
  }

  recordClicked($event) {
    $event.stopPropagation();
    if (this.controlStatus === TimerControlStatus.Paused) {
      // this.controlStatus = TimerControlStatus.Recording;
      this.timerClicked.emit(TimerControlEvents.Record);
    }
  }
  pauseClicked($event) {
    $event.stopPropagation();
    if (this.controlStatus === TimerControlStatus.Recording) {
        // this.controlStatus = TimerControlStatus.Paused;
        this.timerClicked.emit(TimerControlEvents.Pause);
    }
  }
  stopClicked($event) {
    $event.stopPropagation();
    if (this.controlStatus === TimerControlStatus.Paused) {
      this.timerClicked.emit(TimerControlEvents.Stop);
    }
  }
}
