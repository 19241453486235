import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { ActivityListItemModel } from './activity-list-item.model';
import { CaseActivityListMode } from './CaseActivityListMode';
import { ListItemViewBaseModel } from 'app/shared/models/list-item-view-base.model';
import { CaseActivityPriorityNoteListItemModel } from './case-activity-priority-note-list-item.model';

export class CaseActivityPriorityNoteListItemViewModel extends ListItemViewBaseModel<CaseActivityPriorityNoteListItemModel> {
  expanded = false;
  editButton: IIconActionButton;

  constructor(dataModel: CaseActivityPriorityNoteListItemModel, mode: CaseActivityListMode) {
    super();

    this.dataModel = dataModel;

    const canEdit = mode === CaseActivityListMode.History; 

    if (canEdit) {
      this.editButton = {
        visible: true,
        enabled: true,
        actionEnabled: this.dataModel.canEdit,
        actionDisabledMessage: this.dataModel.cannotEditReason,
        iconName: 'edit',
        actionName: 'edit',
        hoverText: 'Edit the item',
        class: '',
        buttonText: '',
      };
      this.processActions.push(this.editButton);
    }
  }
}
