import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ContentChild } from '@angular/core';
import { Filter } from 'app/shared/models/filter.model';
import { FilterFieldsBaseComponent } from 'app/shared/components/list-filter/filter-fields-base.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'list-filter',
  templateUrl: './list-filter.component.html',
})
export class ListFilterComponent implements OnInit {
  @Input() showFilters = false;
  @Input() title = '';
  @ContentChild(FilterFieldsBaseComponent, {static: true}) filterFields: FilterFieldsBaseComponent;
  @Output() filterChanged = new EventEmitter<Array<Filter>>();
  @Output() showFiltersChanged = new EventEmitter<boolean>();

  private ngUnsubscribe: Subject<boolean> = new Subject();


  constructor() {

   }

  ngOnInit() {
    this.filterFields.filterChanged.subscribe(params => {
      this.applyFilter();
      
    });        
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();  
  }

  toggleDisplay() {
    this.showFilters = !this.showFilters;
    this.showFiltersChanged.emit(this.showFilters)
  }

  applyFilter() {
    const filters = this.filterFields.getFilters();
    this.filterChanged.emit(filters);
    this.filterFields.markPristine();
  }

  resetFilter() {
    this.filterFields.resetToDefault();
    this.applyFilter();
  }

  hasFilters() {
    if (this.filterFields === null || this.filterFields === undefined) {
      return false;
    }
    return this.filterFields.hasFilters();
  }
}
