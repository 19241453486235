import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Constants } from 'app/core/models/constants';
import { StringUtilities } from 'app/shared/helpers/string-helpers';

@Injectable()
export class CookieService {
  public get(name: string): string {
    return Cookie.get(name);
  }

  public isOffline(): boolean {
    const v = Cookie.get(Constants.Offline.KeyOfflineStatus);
    if (!v) {
      return false;
    }

    if (v === Constants.Offline.IsOffline) {
      return true;
    }

    return false;
  }

  public isOnline(): boolean {
    return !this.isOffline();
  }

  public goOffline(): void {
    Cookie.set(Constants.Offline.KeyOfflineStatus, Constants.Offline.IsOffline, 365, '/');
  }

  public goOnline(): void {
    Cookie.set(Constants.Offline.KeyOfflineStatus, Constants.Offline.IsOnline, 365, '/');
  }

  public delete(key: string): void {
    Cookie.delete(key);
  }

  public getLoggedInUserId(): number {
    const v = Cookie.get(Constants.Cookies.KeyLoggedInUserId);
    if (!v) {
      return -1;
    }

    return +v;
  }

  public getLoggedInUserName(): string {
    const v = Cookie.get(Constants.Cookies.KeyLoggedInUserName);
    if (!v) {
      return '';
    }

    return v;
  }

  public getLoggedInFullName(): string {
    const v = Cookie.get(Constants.Cookies.KeyLoggedInFullName);
    if (!v) {
      return '';
    }

    return v;
  }

  public getLoggedInExtension(): string {
    const v = Cookie.get(Constants.Cookies.KeyLoggedInUserExtension);
    if (!v) {
      return '';
    }

    return v;
  }

  public getLoggedInMitelUserName(): string {
    const v = Cookie.get(Constants.Cookies.KeyLoggedInMitelUserName);
    if (!v) {
      return '';
    }

    return v;
  }

  public getLoggedInMitelPassword(): string {
    const v = Cookie.get(Constants.Cookies.KeyLoggedInMitelPassword);
    if (!v) {
      return '';
    }

    return v;
  }


  public setLoggedInUserId(userId: number): void {
    Cookie.set(Constants.Cookies.KeyLoggedInUserId, userId.toString(), null, '/');
  }

  public setLoggedInUserName(userName: string): void {
    Cookie.set(Constants.Cookies.KeyLoggedInUserName, userName, null, '/');
  }

  public setLoggedInFullName(fullName: string): void {
    Cookie.set(Constants.Cookies.KeyLoggedInFullName, fullName, null, '/');
  }

  public setTheme(themeId: number): void {
    const theme = themeId ? themeId.toString() : null;
    Cookie.set(Constants.Cookies.KeyDivisionTheme, theme, null, '/');

  }

  public setLoggedInUserIsInternal(isInternal: string): void {
    Cookie.set(Constants.Cookies.KeyLoggedInUserIsInternal, String(isInternal), null, '/');
  }

  public getLoggedInUserIsInternal(): boolean {
    const v = Cookie.get(Constants.Cookies.KeyLoggedInUserIsInternal);
    if (!v) {
      return false;
    }
    const value = v.toLowerCase() === 'true' ? true : false;
    return value;
  }

  public getTheme(): number {
    const v = Cookie.get(Constants.Cookies.KeyDivisionTheme);
    if (!v) {
      return null;
    }

    const parsed = parseInt(v, 0);
    if (isNaN(parsed)) {
      return 0;
    }

    return parsed;
  }

  public getDeviceId(): string {
    const v = Cookie.get(Constants.Cookies.KeyDeviceId);
    if (v) {
      return v;
    }

    const guid = StringUtilities.generateNewGuid();
    Cookie.set(Constants.Cookies.KeyDeviceId, guid, 365, '/');

    return guid;
  }

  constructor() {}

  //#region UI State - for returning the user where thye were last time they visited the page

  private getUiState(): any[] {
    const v = Cookie.get(Constants.Cookies.KeyUiState);

    if (!v) {
      return [];
    }

    return JSON.parse(v);
  }

  public getUiStateForKey(key: string): any {
    const stateCookie = Cookie.get(Constants.Cookies.KeyUiState);

    if (!stateCookie) {
      return null;
    }

    const array = JSON.parse(stateCookie);

    const foundObject = array.find(f => f.key === key);
    return foundObject == null ? null : foundObject.value;
  }

  public setUiState(key: string, state: any): void {
    let stateKvPairs = this.getUiState();

    let i = stateKvPairs.findIndex(x => x.key === key);
    if (i < 0) {
      stateKvPairs.push({ key: key, value: state });
    } else {
      stateKvPairs[i].value = state;
    }

    Cookie.set(Constants.Cookies.KeyUiState, JSON.stringify(stateKvPairs), null, '/');
  }

  public setLoggedInMitelUserName(username: string): void {
    Cookie.set(Constants.Cookies.KeyLoggedInMitelUserName, username, null, '/');
  }

  public setLoggedInMitelPassword(password: string): void {
    Cookie.set(Constants.Cookies.KeyLoggedInMitelPassword, password, null, '/');
  }



  public setLoggedInExtension(extension: string): void {
    Cookie.set(Constants.Cookies.KeyLoggedInUserExtension, extension, null, '/');
  }

  //#endregion
}
