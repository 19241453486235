import { ApiEntity } from 'app/api/models/api-entity.model';
import { LookupList } from 'app/shared/models/lookup-list.model';

export class ActivityDocumentPackCreateFormModel extends ApiEntity {
    constructor(id: number = null,
        public documentPackDefinitionId: number = null,
        public documentPackDefinitionLookupData: LookupList = null,
        public levelParentId: number = null,
        public activityLevel: number = null
      ) {
        super(id);
      }
}


