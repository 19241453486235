// Wraps the Kaleida.Telephony web components into an Angular service
// Work-in-progress and at the time of writing doesn't seem to be getting instantiated.

import { Injectable } from '@angular/core';
import { Subject, TimeoutError } from 'rxjs';

import { TelephonySession } from '../kaleida.telephony/TelephonySession';
import { CallDetails, CallStates, CallDirections, CallEvent, DndInfo, CallRecordingDetails } from '../kaleida.telephony/Contract_Types';
import { AnnouncedTransferArgs, TransferCallArgs, MakeCallArgs, QueryRecordingDetailsArgs, LoginExtensionArgs } from '../kaleida.telephony/Contract_InboundMessageParams';
import { CookieService } from './cookie.service';
import { LiteEvent } from '../kaleida.telephony/LiteEvent';

// experimental workaround for D13963
import { timeout } from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { SnackBarService } from './snack-bar.service';
import { environment } from 'environments/environment';
import { loadUrlToNewTargetViaLinkClick } from '../helpers/file-download-helpers';

/**
 * A service wrapper for TelephonySession that adds DriveFurther specific functionality and exposes events in a way more consistent with the rest of the DriveFurther application.
 * */
@Injectable()
export class TelephonyService {
    private static readonly websockUrl = 'ws://localhost:8090/kaleidaTelephony/';

    /** If we can't connect or lose the connection, this will attempt to reconnect periodically so long as another tab isn't logged in with the extension */
    private static readonly autoReconnect = environment.telephonyAutoReconnect;

    /**
     * The underlying TelephonySession object. Various events are exposed on this that the application can listen out for.
     * For any of TelephonySession to work, a compatible companion app (such as Kaleida.Telephony.WinUiHost.exe) will need to be running
     * on the endpoint that we reference in the service constructor.
     * */
    private _session: TelephonySession = null;

    private _lastInboundCallEvent: CallEvent;
    private _lastInboundCallState: CallStates = CallStates.NoConnection;
    private _lastOutboundCallEvent: CallEvent;
    private _lastOutboundCallState: CallStates = CallStates.NoConnection;
    private _lastConnectionError: string;

    /** connected to websock server */
    private _isConnected = false;
    /** used for auto login when connection is lost */
    private _phoneExtension: string = null;

    private _loggedIn = new LiteEvent<string>();
    private _loggedOut = new LiteEvent<string>();


    constructor(private cookieService: CookieService, private snackBar: SnackBarService) {
        console.debug('TelephonyService is being constructed.');

        this._session = new TelephonySession(TelephonyService.websockUrl);

        // Set up diagnostic logging for a few events and some state tracking
        this._session.ForceLogout.on(reason => {
            console.warn('Forced logout - Reason: ' + reason);
        });

        this._session.CallEvent.on(callEvent => {
            console.debug('Call event: ' + JSON.stringify(callEvent));
            if (callEvent.CallDetails.CallDirection === CallDirections.Inbound) {
                this._lastInboundCallEvent = callEvent;
                this._lastInboundCallState = callEvent.CallDetails.CallState;
            } else if (callEvent.CallDetails.CallDirection === CallDirections.Outbound) {
                this._lastOutboundCallEvent = callEvent;
                this._lastOutboundCallState = callEvent.CallDetails.CallState;
            }
        });

        // Clear call state if we lose the connection to companion app
        this._session.OnDisconnected.on(e => {
            console.error('Disconnected from phone system.');
            this._lastOutboundCallState = CallStates.NoConnection;
            this._lastInboundCallState = CallStates.NoConnection;
            this._isConnected = false;
            if(this._lastConnectionError == null) this._lastConnectionError = "Received OnDisconnected event."; // set a default error reason if there isn't one already stored
            this._loggedOut.trigger('Disconnected from phone system, client console logs may contain more details.');

            this.activateAutoReconnect();
        });

        this._session.ForceLogout.on(e => {
            // in the event that we're forced out of the extension, just disconnect from the websock server to avoid dealing with the intermediate state
            // of connected but not logged in
            this._lastConnectionError = "ForceLogout";
            this._session.disconnect();
        });

        // Attempt to log in. Note that although we do this explicitly from the auth service login, that doesn't re-run if the user refreshes the page so we need to do it here as well.
        
        this._phoneExtension = cookieService.getLoggedInExtension();
        if (this._phoneExtension !== '') {
            const logonExtensionArgs = new LoginExtensionArgs();
            logonExtensionArgs.Extension = this._phoneExtension;
            logonExtensionArgs.Username = cookieService.getLoggedInMitelUserName();
            logonExtensionArgs.Password = cookieService.getLoggedInMitelPassword();
            logonExtensionArgs.KickExistingUser = true;
            this.logon(logonExtensionArgs);
        }
    }

    private activateAutoReconnect() {
        if (TelephonyService.autoReconnect && this._phoneExtension !== null) {
            // Warning: Ensure that the polling frequency here exceeds the max time that the connection is expected to take.
            // Despite not requeuing activateAutoReconnect until the disconnection event at the end, we seem to end up with
            // all sorts of weirdness when the polling frequency is too short (such as 5 seconds) and the companion app gets flooded with requests
            // in a seemingly infinite (actually probably just very big) loop once it's started up.
            console.info("TelephonyService.activateAutoReconnect() will try to reconnect to phone system in 10 seconds.");
            setTimeout(() => this.activateAutoReconnect_poll(), 10000);
        }
    }

    private activateAutoReconnect_poll() {
        if (this.isLoggedIn) {
            console.warn("TelephonyService.activateAutoReconnect_poll() aborted as already logged on.");
            return;
        }
        if (this._phoneExtension === null) {
            console.warn("TelephonyService.activateAutoReconnect_poll() aborted as phoneExtension was null.");
            return;
        }

        console.debug("TelephonyService.activateAutoReconnect_poll() is about to poll.");
        var logonArgs = new LoginExtensionArgs();
        logonArgs.Extension = this._phoneExtension;
        logonArgs.KickExistingUser = false;
        this.logon(logonArgs)
            .then(result => {
                console.info("TelephonyService.activateAutoReconnect_poll() resulted in a successful logon.");
            })
            .catch(err => {
                console.warn("TelephonyService.activateAutoReconnect_poll() failed. Error: " + err);
            });
    }


    // Misc properties

    /**
     * Provides direct access to the underlying TelephonySession object.
     * In general, angular applications should use the functionality on this service rather than the session object, but this can be used to provide
     * access to functionality that has not yet been implemented within the angular service.
     * */
    public get session(): TelephonySession {
        return this._session;
    }

    public get lastInboundCallEvent(): CallEvent {
        return this._lastInboundCallEvent;
    }

    public get lastConnectionError(): string{
        return this._lastConnectionError;
    }

    public get isLoggedIn(): boolean {
        return this._isConnected && this._session.LoggedIn;
    }

    // Button availability properties
    public get isAnswerButtonAvailable(): boolean {
        return this._lastInboundCallState === CallStates.Ringing;
    }

    public get isEndCallButtonAvailable(): boolean {
        return !(this._lastInboundCallState === CallStates.NoConnection && this._lastOutboundCallState === CallStates.NoConnection)
    }

    public get isHoldButtonAvailable(): boolean {
        return (this._lastInboundCallState === CallStates.InProgress || this._lastOutboundCallState === CallStates.InProgress);
    }

    public get isRetrieveButtonAvailable(): boolean {
        return (this._lastInboundCallState === CallStates.OnHold || this._lastOutboundCallState === CallStates.OnHold);
    }

    public get isDialOutButtonAvailable(): boolean {
        return this.isLoggedIn && this._lastInboundCallState === CallStates.NoConnection && this._lastOutboundCallState === CallStates.NoConnection;
    }

    public get isTransferOutButtonAvailable(): boolean {
        return this._lastInboundCallState === CallStates.InProgress && this._lastOutboundCallState == CallStates.NoConnection;
    }

    public get isCompleteTransferButtonAvailable(): boolean {
        return this._lastInboundCallState === CallStates.OnHold && this._lastOutboundCallState == CallStates.InProgress;
    }

    public get isCancelTransferButtonAvailable(): boolean {
        return this._lastInboundCallState === CallStates.OnHold && (this._lastOutboundCallState == CallStates.InProgress || this._lastOutboundCallState == CallStates.Ringing);
    }


    // Observable events
    public get onLoggedIn$(): Subject<String> {
        return this._loggedIn.asObservable();
    }
    public get onLoggedOut$(): Subject<String> {
        return this._loggedOut.asObservable();
    }

    /**
     * Gets an event that is fired when a phone is ringing. Note that this may be raised both when our phone is ringing and when a recipient's phone
     * is ringing as a result of us dialling out. Examine the {@link CallDetails} object for information relating to the call direction, etc.
     * Please also be aware that call ringing events may fire whilst already in a call due to transfer requests, etc.
     */
    public get onPhoneRinging$(): Subject<CallDetails> {
        return this.session.CallRinging.asObservable();
    }

    /**
     * Fired when a call is answered. This could be us answering the call, or a recipient answering.
     * Examine the {@link CallDetails} object for information relating to the call direction, etc.
     * Please also be aware that call answered events may fire whilst already in a call due to transfer requests, etc.
     */
    public get onCallAnswered$(): Subject<CallDetails> {
        return this.session.CallAnswered.asObservable();
    }

    /** Fired when a call is ended, usually as a result of one or the other parties hanging up. */
    public get onCallCleared$(): Subject<CallDetails> {
        return this.session.CallCleared.asObservable();
    }


    // Public methods
    public logon(args: LoginExtensionArgs): Promise<string> {
        // Note: The extension we have for dev/testing purposes is "1221"

        if (this.isLoggedIn && this.session.LoggedInExtension === args.Extension) { return new Promise<string>(() => 'Already logged in with the specified extension'); }
        this._phoneExtension = args.Extension;

        console.debug('TelephonyService is attempting to log in with extension ' + args.Extension + '...');

        const promise = this._session.OnConnected.asObservable()                        // first wait for OnConnected to fire (with timeout)
            .pipe(timeout(5000), first()).toPromise()
            .catch((err: string) => {
                //this.activateAutoReconnect(); // no need to do this, we still get a disconnect event which enqueues the reconnect and leaving this in will potentially cause chaos with concurrent reconnects!
                throw 'Connection to companion app via WebSockets failed. Reason: ' + err.toString();
            })
            .then(() => this._session.GetCompanionVersion())                                // then wait for version response
            .then((version) => {                                                            // then perform version check
                const minimumRequiredRevision = 58220;
                console.info('Connected to telephony companion version: ' + version.Major + '.' + version.Minor + '.' + version.Build + '.' + version.Revision);
                // 0 indicates a local developer build and will skip the version check
                if (version.Revision > 0 && version.Revision < minimumRequiredRevision) {
                    throw 'Connection to companion app aborted - version check failed. Required rev ' + minimumRequiredRevision + ' but companion app was rev ' + version.Revision + '.';
                }
            })
            .then(() => this._session.LoginExtension(args))                                 // then log in using our extension
            .catch((err: string) => {
                throw 'Login to phone system failed. Reason: ' + err.toString();
            })
            .then((status) => {                                                             // trigger loggedIn event, etc
                console.info('Login to phone system completed. Status: ' + status);
                this._lastConnectionError = null;
                this._isConnected = true;
                this._loggedIn.trigger(status);
                return status;
            })
            .catch((err: string) => {
                // Catch all block intended to run for all errors, even the ones we rethrow above
                console.error('Connection to phone system failed. Reason: ' + err.toString());
                this._lastConnectionError = err;
                this._isConnected = false;
                this._session.disconnect();
                throw new Error(err);
            });

        // Initiate connection to companion app and return our promise chain
        this._session.connect();

        return promise;
    }

    public logoutAndDisconnect() {
        // Note: There is actually a Logout method on the session which does a proper logout and resolves a promise upon completion, but simply terminating the connection should be sufficient and keeps things simpler
        // Otherwise we might need to either slow down the logout or deal with situations during login where a logout is still in progress etc.
        // The companion app will already perform a logout without response upon the connection being lost between web app and companion anyweay.
        this._phoneExtension = null;
        if (this.isLoggedIn) { this.session.disconnect(); }
    }

    public answerCall(): Promise<string> {
        return this.session.AnswerCall(null);
    }

    public makeOutboundCall(phoneNumber: string) {
        const args = new MakeCallArgs();
        args.DestinationPhoneNumber = phoneNumber;
        return this.session.MakeOutboundCall(args);
    }

    public endCall(): Promise<string> {
        return this.session.EndCall(null);
    }

    /**
     * Sets whether the phone should be in do-not-disturb mode or not, and optionally sets a message.
     * @param {boolean} state - True if DND mode should be on, false if it should be off.
     * @param {string} [message=Unavailable] - The reason that DND mode is being turned on.
     * @returns {Promise<string>} Promise object represents the status of the request.
     */
    public setDnd(state: boolean, message: string = 'Unavailable'): Promise<string> {
        const args = new DndInfo();
        args.State = state;
        args.Message = message;
        return this.session.SetDnd(args);
    }

    /**
     * Gets the current do-not-disturb state information.
     * @returns {Promise<DndInfo>} Promise object that resolves to the do not disturb information.
     */
    public getDnd(): Promise<DndInfo> {
        return this.session.GetDnd();
    }

    /**
     * Fetches the recording details, including the RecID for the current call.
     * */
    public getRecordingDetails(): Promise<CallRecordingDetails> {
        const args = new QueryRecordingDetailsArgs();
        args.MaxRetriesIfRecordingUnavailable = 15;
        args.DelayBetweenPollsMs = 3000;
        return this.session.QueryCallRecordingDetails(args);
    }

    /**
     * Gets the URL of a page containing a recording player to play back the specified RecID.
     * @param {number} recId - The unique ID of the recording that we want to play.
     * */
    public getRecordingPlayerUrl(recId: number): Promise<string> {
        return this.session.GetRecordingPlayerUrl(recId);
    }

    /**
     * Opens the call recording player to play the specified recording. This is asynchronous and the function will return before the recording has opened.
     * @param {number} recId - The unique ID of the recording that we want to play.
     * */
    public openRecordingPlayer(recId: number) {
        if (recId !== null) {
            this.getRecordingPlayerUrl(recId)
            .then((url)=>{
                loadUrlToNewTargetViaLinkClick(url, "Recording_" + recId);
            })
            .catch((err)=>{
                this.snackBar.error('Unable to play recording. Note that the companion app must be running.');  
                throw err;
            });

        }
        else {
            this.snackBar.error('No recording available for this call.');
        }
    }

    /**
     * Puts the current call on hold and begins calling out to a new number, in preparation for a transfer.
     * @param {string} destinationPhoneNumber - The new phone number to dial out to.
     * @returns {Promise<string>} Promise object represents the status of the request.
     * @see completeTransferOut
     */
    public startTransferOutAnnounced(destinationPhoneNumber: string): Promise<string> {
        if (!this.isTransferOutButtonAvailable) { throw new Error('TransferOut not currently available.'); }
        const args = new AnnouncedTransferArgs();
        args.DestinationPhoneNumber = destinationPhoneNumber;
        args.CallIndexToTransfer = this._lastInboundCallEvent.CallDetails.CallIndex;
        return this._session.AnnouncedTransfer(args);
    }

    /**
     * Completes a transfer previously started using {@link startTransferOutAnnounced}.
     * @returns {Promise<string>} Promise object represents the status of the request.
     * @see startTransferOutAnnounced
     */
    public completeTransferOut(): Promise<string> {
        if (!this.isCompleteTransferButtonAvailable) { throw new Error('CompleteTransfer not currently available.'); }
        const args = new TransferCallArgs();
        args.TransferToHold = false;
        return this._session.TransferCall(args);
    }

    public cancelTransfer(): Promise<string> {
        if (!this.isCancelTransferButtonAvailable) { throw new Error('CancelTransfer not currently available.'); }
        return this._session.EndCall(this._lastOutboundCallEvent.CallDetails.CallIndex);
    }



}
