import { Component, OnInit, OnDestroy } from '@angular/core';
import { SagePayService } from './services/sage-pay.service';
import { MerchantSessionKeyModel } from './models/merchant-session-key.model';
import { SnackBarService } from 'app/shared/services/snack-bar.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreatePaymentRequest } from './models/create-payment-request.model';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
declare let sagepayCheckout: any;

@Component({
    selector: 'sage-pay',
    templateUrl: './sage-pay.component.html'
  })

  export class SagePayComponent implements OnInit, OnDestroy {

    checkout: any;
    dataForm: FormGroup;
    merchantSessionKey: MerchantSessionKeyModel;
    checkoutInitialised = false;
    transactionResponse: string;

    constructor(private fb: FormBuilder, private service: SagePayService, private snackBar: SnackBarService, private formHelperService: ReactiveFormHelperService) {     
      
      this.dataForm = fb.group({
        'description': ['', [Validators.required ]],
        'reference': ['', [Validators.required, Validators.maxLength(40) ]],
        'amount': ['', [Validators.required]],
        'firstName': ['', [Validators.required]],
        'lastName': ['', [Validators.required ]],
        'addressLine1': ['', [Validators.required ]],
        'addressCity': ['', [Validators.required]],
        'addressPostCode': ['', [Validators.required]],
      });
      
    }

      // shortcuts to controls for readability
  get description(): AbstractControl { return this.dataForm.controls['description']; }
  get reference(): AbstractControl { return this.dataForm.controls['reference']; }
  get amount(): AbstractControl { return this.dataForm.controls['amount']; }
  get firstName(): AbstractControl { return this.dataForm.controls['firstName']; }
  get lastName(): AbstractControl { return this.dataForm.controls['lastName']; }
  get addressLine1(): AbstractControl { return this.dataForm.controls['addressLine1']; }
  get addressCity(): AbstractControl { return this.dataForm.controls['addressCity']; }
  get addressPostCode(): AbstractControl { return this.dataForm.controls['addressPostCode']; }

    ngOnInit() {
      this.initialiseSagePayIFrame();
    }

    ngOnDestroy() {
      this.destroyCheckout();
    }

    private initialiseSagePayIFrame() {
      this.service.getNewMerchantSessionKeyResponse().then((response: MerchantSessionKeyModel) => {
        this.merchantSessionKey = response;
        this.checkout = sagepayCheckout({
          merchantSessionKey: response.merchantSessionKey,
          onTokenise: (tokenisationResult) => {
            if (tokenisationResult.success) {
              this.mySubmit(tokenisationResult.cardIdentifier);
            } else {
              this.snackBar.error('The following error occurred during the processing of the card details: ' + tokenisationResult.error.errorMessage);
            }
          }
        });
        this.checkoutInitialised = true;
      });
    }

    private destroyCheckout() {
      if (this.checkoutInitialised) {
        this.checkout.destroy();
        this.checkoutInitialised = false;
      }
    }

    private createModel(cardIdentifier: string): CreatePaymentRequest {
       let model: CreatePaymentRequest = {
        merchantSessionKey: this.merchantSessionKey.merchantSessionKey,
        cardIdentifier: cardIdentifier,
        amount: this.amount.value,
        description: this.description.value,
        firstName: this.firstName.value,
        lastName: this.lastName.value,
        reference: this.reference.value,
        address: {
          line1: this.addressLine1.value,
          city: this.addressCity.value,
          postCode: this.addressPostCode.value
        }
       };

      return model;
    }

    mySubmit(cardIdentifier) {
      const model = this.createModel(cardIdentifier);
      this.service.createPayment(model).then((response: any) => {
        this.transactionResponse = response;
      });
    }

    sendPaymentDetailsToSagePay(e) {
      
      if (this.formHelperService.isFormValid(this.dataForm)) {

        this.service.getNewMerchantSessionKeyResponse().then((response: MerchantSessionKeyModel) => {
          this.merchantSessionKey = response;
          this.checkout.tokenise({ newMerchantSessionKey: response.merchantSessionKey});
        });

    }

    }

  }
