import { Component, Output, EventEmitter, OnInit, Inject, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UsersService } from 'app/modules/user-management/services/users.service';
import { CookieService } from 'app/shared/services/cookie.service';
import { Division } from 'app/shared/enumeration/division';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { KeepAliveService } from 'app/shared/services/keep-alive.service';
@Component({
  selector: 'welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, OnDestroy {
  themeName: Observable<number>;
  loggedInFullName: string;
  divisions = Division;

  permissionsLoadedSubscription: Subscription;

  supportisDivisionAccess: boolean = false;
  financeAndLegalDivisionAccess: boolean = false;
  dualDriveDivisionAccess: boolean = false;
  permissionsLoaded: boolean = false;

  constructor(protected usersService: UsersService, private router: Router, private cookieService: CookieService, private location: Location, public keepAliveService: KeepAliveService) {}

  ngOnInit(): void {

    this.keepAliveService.checkAuthentication()
    this.usersService.setTheme(null);

    this.processUserPermissions();

    this.permissionsLoadedSubscription = this.usersService.permissionsLoaded$.subscribe(() => this.processUserPermissions());
  }

  ngOnDestroy() {
    this.permissionsLoadedSubscription.unsubscribe();
  }

  private processUserPermissions() {
    this.themeName = this.usersService.themeName;
    this.loggedInFullName = this.cookieService.getLoggedInFullName();

    this.supportisDivisionAccess = this.usersService.userDivisions.includes(Division.Supportis);
    this.financeAndLegalDivisionAccess = this.usersService.userDivisions.includes(Division.FinanceAndLegal);
    this.dualDriveDivisionAccess = this.usersService.userDivisions.includes(Division.DualDrive);

    if (this.usersService.userHasSingleDivision) {
      this.selectDivision(this.usersService.userDivisions[0]);
    }

    this.permissionsLoaded = true;
  }

  selectDivision(division: Division) {
    this.usersService.setTheme(division);
    this.router.navigate(['/divisions', division, 'tasks']);
  }
}
