import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { CaseClaimEmailReplyFormModel } from './models/CaseClaimEmailReplyFormModel';
import { ActivityTaskListService } from '../case-claim-detail/case-claim-detail-tasks/services/case-claim-task-list.service';

@Component({
  selector: 'case-claim-email-reply',
  templateUrl: './case-claim-email-reply.component.html'
})
export class CaseClaimEmailReplyComponent implements OnInit {
  public data: any;
  public editMode: boolean;
  dataForm: FormGroup;
  id: number;
  viewModel = new CaseClaimEmailReplyFormModel();

  constructor(
    private fb: FormBuilder,
    route: ActivatedRoute,
    private asideService: AsideService,
    private formHelperService: ReactiveFormHelperService,
    private dialog: MatDialog,
    private service: ActivityTaskListService
  ) {
    this.dataForm = fb.group({
      // TODO: [Technical Debt][DPB][2020-04-06] Why have we got an untestable validator like this?!!
      body: ['', [Validators.required, Validators.maxLength(1000000000)]]
    });
  }

  ngOnInit(): void {
    // Get either the latest draft email or the to email address

    this.loadFormDetails();
  }

  private loadFormDetails() {
    this.id = this.data.itemId;

    if (this.id) {
      this.service.getReplyToEmail(this.id).then((response: CaseClaimEmailReplyFormModel) => {
        this.viewModel = response;
        if (this.viewModel) {
          this.editMode = this.viewModel.outboundEmailId != null ? true : false;
          this.initialiseForm();
        }
      });
    }
  }
  initialiseForm() {
    this.body.setValue(this.viewModel.body);
  }

  get body(): AbstractControl {
    return this.dataForm.controls['body'];
  }

  private createUpdateModel() {
    return {
      body: this.body.value,
      activityLevel: this.data.activityLevel,
      levelParentId: this.data.levelParentId
    };
  }

  send() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();
      this.service.sendEmail(this.data.itemId, updateModel).then(() => {
        this.asideService.close('update');
      });
    }
  }

  save() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();

      if (this.editMode) {
        this.service.updateEmailItem(this.data.itemId, updateModel).then(() => {
          this.asideService.close('update');
        });
      } else {
        this.service.addNewEmailItem(this.data.itemId, updateModel).then(() => {
          this.asideService.close('update');
        });
      }
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?'
        }
      });

      dialogRef.componentInstance.onClose.subscribe(result => {
        if (result.result) {
          this.asideService.close('cancel');
        }
      });
    } else {
      this.asideService.close('cancel');
    }
  }
}
