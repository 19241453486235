import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  //Template from http://www.nextbootstrap.com/2016/12/404-error-page-code-example-for.html

  constructor() { }

  ngOnInit() {
  }

}
