import { ApiEntity } from 'app/api/models/api-entity.model';

export class CaseClaimEmailReplyFormModel extends ApiEntity {
  constructor(
    id: number = null,
    public toEmailAddress: string = null,
    public subject: string = '',
    public body: string = '',
    public outboundEmailId: number = null,
    public originalEmailBody: string = ''
  ) {
    super(id);
  }
}
