import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ActivityDocumentCreateModel } from 'app/modules/solution-shared/case-activity/models/case-activity-document-create.model';
import { ActivityDocumentCreateService } from 'app/modules/solution-shared/case-activity/services/case-activity-document-create.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { formatAsString, getCurrentDate, toFormattedDateString } from 'app/shared/helpers/date-helpers';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { StringUtilities } from 'app/shared/helpers/string-helpers';
import { ActivityTimerHubService } from 'app/shared/services/signalr.service';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';


@Component({
  selector: 'case-activity-document-create-form',
  templateUrl: './case-activity-document-create-form.component.html',
  styles: [
    `
      .form-field {
        margin-top: 12px;
        margin-bottom: 12px;
      }

      .onedrive-error-container {
        margin-bottom: 5px;
      }
      .onedrive-message {
        background-color: #c00;
        color: #fff;
        font-size: 0.9em;
        padding: 5px 10px;
        margin-bottom: 3px;
      }
    `
  ]
})
export class ActivityDocumentCreateFormComponent implements OnInit {
  public data: any;
  saveText: string;
  editMode: boolean;
  isInDocumentPack: boolean;

  documentTemplateLookupData: any[];
  paragraphLookupData: any[];
  viewModel: ActivityDocumentCreateModel;
  concurrencyErrorMessage = '';
  oneDriveErrorMessage = '';
  dataForm: FormGroup;
  combinedParagraphEditMode = false;

  startTime: Date;
  stopTime: Date;

  constructor(
    private fb: FormBuilder,
    private service: ActivityDocumentCreateService,
    private asideService: AsideService,
    private activityHubService: ActivityTimerHubService,
    private formHelperService: ReactiveFormHelperService,
    private dialog: MatDialog
  ) {
    this.startTime = new Date();

    this.dataForm = fb.group({
      documentTemplateId: ['', [Validators.required, this.validDocumentTemplate().bind(this)]],
      description: ['', [Validators.required, Validators.maxLength(200)]],
      documentCreationDate: ['', [Validators.required]],
      publishToPortal: ['', [Validators.required]],
      combinedAllegation: ['', []],
      selectedAllegationParagraph: ['', []]
    });
  }

  // shortcuts to controls for readability
  get documentTemplateId(): AbstractControl {
    return this.dataForm.controls['documentTemplateId'];
  }
  get description(): AbstractControl {
    return this.dataForm.controls['description'];
  }
  get documentCreationDate(): AbstractControl {
    return this.dataForm.controls['documentCreationDate'];
  }
  get publishToPortal(): AbstractControl {
    return this.dataForm.controls['publishToPortal'];
  }
  get combinedAllegation(): AbstractControl {
    return this.dataForm.controls['combinedAllegation'];
  }
  get selectedAllegationParagraph(): AbstractControl {
    return this.dataForm.controls['selectedAllegationParagraph'];
  }
  get allegationParagraphSelected() {
    return this.selectedAllegationParagraph.value.text !== undefined && this.selectedAllegationParagraph.value.text !== null;
  }

  ngOnInit() {
    this.editMode = this.data.itemId > 0;
    this.isInDocumentPack = this.data.isInDocumentPack === true;
    this.saveText = this.editMode ? 'Update' : 'Create';
    this.combinedParagraphEditMode = this.editMode ? false : true;

    if (this.editMode) {
      this.activityHubService.startRecording(this.data.activityId);
    }

    this.fixupServiceIds();
    this.loadFormDetails();
  }

  /**
    * D14085 + D14876 - fix up IDs that haven't been set correctly
    * In some places, component IDs are set but not the service IDs, in others it's the other way around.
    * This is more of a workaround, however don't want to fix it in the components consumer and risk leaving it broken in other places that use the component
    * Let's attempt to fix it and log an error to the console if the IDs still cannot be obtained.
   */
  private fixupServiceIds(){

    if(this.data.divisionId !== undefined){
      this.fixupServiceIdsAddWarningIfDifferent(this.service.divisionId, this.data.divisionId, "divisionId");
      this.service.divisionId = this.data.divisionId;
    }
    else{
      console.warn("fixupServiceIds() observed that data.divisionId was not set.");
    }
    if(this.data.activityLevel !== undefined){
      this.fixupServiceIdsAddWarningIfDifferent(this.service.activityLevel, this.data.activityLevel, "activityLevel");
      this.service.activityLevel = this.data.activityLevel;
    }
    else{
      console.warn("fixupServiceIds() observed that data.activityLevel was not set.");
    }
    if(this.data.levelParentId !== undefined){
      this.fixupServiceIdsAddWarningIfDifferent(this.service.levelParentId, this.data.levelParentId, "levelParentId");
      this.service.levelParentId = this.data.levelParentId;
    }
    else{
      console.warn("fixupServiceIds() observed that data.levelParentId was not set.");
    }

    if(this.data.divisionId===undefined || this.data.activityLevel===undefined || this.data.levelParentId===undefined)
    {
      console.error("Unable to fix service IDs in document create aside. IDs were not set on either the service or data object.");
      debugger;
    }
  }

  private fixupServiceIdsAddWarningIfDifferent(serviceId:any, dataId:any, fieldName:string){
    if(serviceId != dataId)
    {
      console.warn("fixupServiceIds() is replacing service " + fieldName + " of '" + this.service.divisionId + "' with data ID '" + this.data.divisionId + "'");
    }
  }


  private loadFormDetails() {
    const id: number = this.editMode ? this.data.itemId : null;

    this.service.getEditableItemDetail(id).then((response: ActivityDocumentCreateModel) => {
      this.viewModel = response;
      if (this.viewModel) {
        this.concurrencyErrorMessage = '';
        this.oneDriveErrorMessage = '';
        this.initialiseForm();
      }
    });
  }

  initialiseForm() {
    this.documentTemplateLookupData = this.formHelperService.createLookupData(this.viewModel.documentTemplateLookupData);
    this.paragraphLookupData = this.service.createParagraphLookupData(this.viewModel.allegationParagraphLookupData);
    const currentDocumentTemplte = this.documentTemplateLookupData.find(f => f.id === this.viewModel.documentTemplateId);
    this.documentTemplateId.setValue(currentDocumentTemplte);
    this.description.setValue(this.viewModel.description);
    this.documentCreationDate.setValue(
      this.viewModel.documentCreationDate !== undefined ? this.viewModel.documentCreationDate : getCurrentDate().toJSON()
    );
    this.publishToPortal.setValue(this.viewModel.publishToPortal !== undefined ? this.viewModel.publishToPortal : false);

    this.combinedAllegation.setValue(this.viewModel.combinedAllegation);

    this.viewModel.activityLevel = this.data.activityLevel;
    this.viewModel.levelParentId = this.data.levelParentId;
    this.getDocumentTemplateAutoCompleteLookupList();

    this.dataForm.markAsPristine();
  }

  getDocumentTemplateAutoCompleteLookupList() {
    this.service.documentTemplateLookupData = this.viewModel.documentTemplateLookupData;

    this.dataForm.controls['documentTemplateId'].valueChanges
    .pipe(
      debounceTime(500), // waits 500ms before calling
      startWith(''),
      switchMap(value => this.service.getDocumentTemplateAutoCompleteLookupList(value)) // pass the control value to the service method
    )
    .subscribe(results => {
      (this.documentTemplateLookupData = results.items.map(item => {
        return {
          id: item.id,
          text: item.name,
        }}));
    }); // create lookup data from the api response
  }


  private createUpdateModel() {
    this.stopTime = new Date();
    return {
      documentTemplateId: this.documentTemplateId.value.id,
      description: this.description.value,
      publishToPortal: this.publishToPortal.value,
      documentCreationDate: formatAsString(this.documentCreationDate.value),
      combinedAllegation: this.combinedAllegation.value,
      startTime: toFormattedDateString(this.startTime, 'YYYY-MM-DD HH:mm:ss'),
      stopTime: toFormattedDateString(this.stopTime, 'YYYY-MM-DD HH:mm:ss'),
      timestamp: this.viewModel.timestamp,
      activityLevel: this.viewModel.activityLevel,
      levelParentId: this.viewModel.levelParentId
    };
  }

  allowEditParagraph() {
    this.combinedParagraphEditMode = true;
  }

  clearParagraph() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '450px',
      data: {
        title: 'Confirm Clear Changes?',
        message: 'Are you sure you want to clear this allegation paragraph?'
      }
    });

    dialogRef.componentInstance.onClose.subscribe(result => {
      if (result.result) {
        this.combinedAllegation.setValue('');
        this.combinedAllegation.markAsDirty();
        this.combinedAllegation.markAsTouched();
      }
    });
  }

  appendParagraph() {
    const currentCombinedParagraph =
      this.combinedAllegation.value !== undefined && this.combinedAllegation.value !== null ? this.combinedAllegation.value : '';
    const selectedParagraph = this.allegationParagraphSelected ? this.selectedAllegationParagraph.value.text : '';

    if (selectedParagraph !== '') {
      const updatedCombinedAllegationParagraph = currentCombinedParagraph.concat('\n', selectedParagraph);
      this.combinedAllegation.setValue(updatedCombinedAllegationParagraph);
    }
  }

  save() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();
      if (this.editMode) {
        this.service.updateDocument(this.data.itemId, updateModel).then(response => {
          if (response.success === true) {
            this.closeForm('update');
          } else {
            if (response.data[0].status === 507) {
              this.oneDriveErrorMessage = response.message;
            } else {
            this.concurrencyErrorMessage = response.message;
            }
          }
        });
      } else {
        this.service.addDocument(updateModel).then(newRecordId => {
          this.activityHubService.requestActivityTimer(newRecordId, 'Create Document');
          this.closeForm('update');
        });
      }
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?'
        }
      });

      dialogRef.componentInstance.onClose.subscribe(result => {
        if (result.result) {
          this.closeForm('cancel');
        }
      });
    } else {
      this.closeForm('cancel');
    }
  }

  closeForm(message: string) {
    if (this.editMode) {
      this.activityHubService.pauseRecording(this.data.activityId);
    }
    this.asideService.close(message);
  }

  combinedAllegationHtml(): string {
    return this.combinedAllegation.value ? StringUtilities.translateCR(this.combinedAllegation.value) : '';
  }

  // for autocomplete so that it knows which property ro display
  displaySelectedItem(option?: any) {
    return option ? option.text : undefined;
  }


  validDocumentTemplate(): ValidatorFn {
    return (dataForm: AbstractControl): { [key: string]: any } => {
      return !dataForm || !dataForm.value || !dataForm.value.id ? { notValid: true } : null;
    };
  }
}
