import { ApiDependenciesService } from './../../../api/services/api-dependencies.service';
import { UserProfile } from './../models/user-profile.model';
import { DataService } from 'app/shared/services/data.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ProfileService extends DataService<UserProfile> {

  private profileBase: string = '/user-profile';

  constructor(protected dependencies: ApiDependenciesService) {
    super(dependencies);
    this.endpoint = this.profileBase + '/profile-detail'
  }

  resetPassword(resetPasswordModel: any, userId: number = null) {
    if (userId !== null) {
      resetPasswordModel.userId = userId;
    }
    return super
      .setEndpoint(this.profileBase + '/reset-password')
      .update(resetPasswordModel);
  }
}
