import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import {
  AssigneeLookupList, CaseClaimTaskFormModel,

  GroupedAssigneeList
} from 'app/modules/solution-shared/case-claim/case-claim-detail/case-claim-detail-tasks/models/case-claim-task-form.model';
import { AssignedByLookupList } from 'app/modules/solution-shared/case-claim/case-claim-detail/case-claim-detail-tasks/models/case-claim-task-list-filter.model';
import { CaseClaimTaskListItemViewModel } from 'app/modules/solution-shared/case-claim/case-claim-detail/case-claim-detail-tasks/models/case-claim-task-list-item-view.model';
import { CaseClaimTaskListItemModel } from 'app/modules/solution-shared/case-claim/case-claim-detail/case-claim-detail-tasks/models/case-claim-task-list-item.model';
import { downloadFileFromExtendedBlob } from 'app/shared/helpers/file-download-helpers';
import { ApiResponseSummary } from 'app/shared/models/api-response-summary.model';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { CaseClaimEmailReplyFormModel } from '../../../case-claim-email-reply/models/CaseClaimEmailReplyFormModel';


@Injectable()
export class ActivityTaskListService extends ListServiceBase {
  divisionId: number;
  caseClaimId: number;
  activityLevel: number;
  constructor(protected dependencies: ApiDependenciesService, protected dialog: MatDialog) {
    super(dependencies, dialog);

    this.initialiseFiltersSortAndPaging();
  }

  // populate initial filter properties
  private initialiseFiltersSortAndPaging() {
    // Add intialisation here
  }

  //#region Get Endpoints

  get baseEndpoint(): string {
    return '/divisions/' + this.divisionId + '/case-claims/' + this.caseClaimId + '/tasks';
  }

  baseEndpointWithId(id: number): string {
    return this.baseEndpoint + '/' + id;
  }

  // a number of endpoint getters are defined in ListServiceBase
  // not all endpoints will be required for all implementations

  // OVERRIDDEN from ListServiceBase
  protected getDeleteEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }
  // OVERRIDDEN from ListServiceBase
  protected getCommandStubEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }

  // OVERRIDDEN from ListServiceBase
  protected getListEndpoint(): string {
    return this.baseEndpoint + '/list';
  }

  protected get filterLookupEndpoint(): string {
    return `/divisions/${this.divisionId}/task-filter-lookup-data`;
  }

  protected get movetoRoleEndpoint(): string {
    return this.baseEndpoint + '/move-to-role';
  }
  protected get moveToRoleLookupEndpoint(): string {
    return this.baseEndpoint + '/move-to-role-lookup-data';
  }

  //#region list loading

  // OVERRIDDEN from ListServiceBase
  // Create a new ViewModel specific to this list
  // Not necessary to set the viewmodel itemId as it is set in the viewModel constructor
  createListViewModel(dataModel: ApiEntity) {
    return new CaseClaimTaskListItemViewModel(dataModel as CaseClaimTaskListItemModel);
  }

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for each list row item
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedListItemDataModel(responseItem: any): ApiEntity {
    const model: CaseClaimTaskListItemModel = responseItem;
    return model;
  }

  //#region get details

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for the detail item (create/update)
  // The model must be derived from ApiEntity
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedEditableItemDataModel(response: any): ApiEntity {
    const model: CaseClaimTaskFormModel = response;
    return model;
  }

  //#endregion

  protected getCreateEndpoint(): string {
    return this.baseEndpoint;
  }

  protected getUpdateEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }

  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  async viewNotes(itemId: number) {
    await this.sendCommand(itemId, 'view-notes');
  }

  // OVERRIDDEN from ListServiceBase
  protected getGetEditableItemEndpoint(itemId: number) {
    return itemId ? this.baseEndpointWithId(itemId) : this.baseEndpoint + '/blank';
  }

  // OVERRIDDEN to exclude completed items when selecting
  // selectAll(selectValue: boolean) {
  //   for (let i = 0, len = this.itemList.length; i < len; i++) {
  //     if(!selectValue || !this.itemList[i].dataModel.isComplete) {
  //       this.itemList[i].itemSelected = selectValue;
  //     }
  //   }
  // }

  protected isItemSelectable(datamodel: any): boolean {
    return !datamodel.isComplete;
  }

  async exportToExcel() {
    await this.setEndpoint(this.baseEndpoint + '/export')
      .getFileAsBlobWithPost(this.listRequest)
      .then(response => {
        downloadFileFromExtendedBlob(response);
      });
  }

  async getFilterLookupData(): Promise<any> {
    let model: any;
    await this.setEndpoint(this.filterLookupEndpoint)
      .getSingle()
      .then(response => {
        model = response;
      });
    return Promise.resolve(model);
  }

  createGroupedLookupDataForAssignee(list: AssigneeLookupList): any[] {
    let model: GroupedAssigneeList[] = [];

    let users = {} as GroupedAssigneeList;

    users.name = 'Users';
    users.items = list.items
      .filter(item => item.isUser === true)
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      .map(item => {
        return {
          id: item.id,
          text: item.name,
          isUser: item.isUser
        };
      });

    let roles = {} as GroupedAssigneeList;
    roles.name = 'Roles';
    roles.items = list.items
      .filter(item => item.isUser === false)
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      .map(item => {
        return {
          id: item.id,
          text: item.name,
          isUser: item.isUser
        };
      });

    model.push(users);
    model.push(roles);

    return model;
  }

  createGroupedLookupDataForAssignedBy(list: AssignedByLookupList): any[] {
    let model: GroupedAssigneeList[] = [];

    let users = {} as GroupedAssigneeList;

    users.name = 'Users';
    users.items = list.items
      .filter(item => item.isUser === true)
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      .map(item => {
        return {
          id: item.id,
          text: item.name,
          isUser: item.isUser
        };
      });

    let workflow = {} as GroupedAssigneeList;
    workflow.name = 'Workflow';
    workflow.items = list.items
      .filter(item => item.isUser === false)
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      .map(item => {
        return {
          id: item.id,
          text: item.name,
          isUser: item.isUser
        };
      });

    model.push(users);
    model.push(workflow);

    return model;
  }

  async getTargetRoles(): Promise<any> {
    let model: any;

    await this.setEndpoint(this.moveToRoleLookupEndpoint)
      .getSingle()
      .then(response => {
        model = response;
      });

    return Promise.resolve(model);
  }

  async moveToRole(dataToUpdate: any) {
    this.blockUI.start();

    await this.setEndpoint(this.movetoRoleEndpoint)
      .update(dataToUpdate)
      .then(() => {
        this.selectAll(false);
        this.reload();
      });

    this.blockUI.stop();
  }

  // end of class

  async getReplyToEmail(itemId: number): Promise<ApiEntity> {
    this.blockUI.start();
    let model: CaseClaimEmailReplyFormModel;

    const endpoint = '/divisions/' + this.divisionId + '/group-inbox-items/' + itemId + '/reply-to';
    await this.setEndpoint(endpoint)
      .getSingle()
      .then(response => {
        model = response;
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });

    return Promise.resolve(model);
  }

  async sendEmail(itemId: number, dataToUpdate: any) {
    const endpoint = '/divisions/' + this.divisionId + '/group-inbox-items/' + itemId + '/reply-to/send';
    await this.saveItemNoReload(0, dataToUpdate, endpoint);
  }

  async updateEmailItem(itemId: number, dataToUpdate: any): Promise<ApiResponseSummary<any>> {
    let result: ApiResponseSummary<any>;

    const endpoint = '/divisions/' + this.divisionId + '/group-inbox-items/' + itemId + '/reply-to';

    await this.updateItemNoReload(itemId, dataToUpdate, endpoint).then(response => {
      result = response;
    });

    return Promise.resolve(result);
  }

  async addNewEmailItem(itemId: number, item: any): Promise<number> {
    let newRecordId: number;
    const endpoint = '/divisions/' + this.divisionId + '/group-inbox-items/' + itemId + '/reply-to';
    this.blockUI.start();
    await this.setEndpoint(endpoint)
      .save(item)
      .then(response => {
        newRecordId = response.newRecordId;
        this.itemAdded.next(response.newRecordId);

        this.blockUI.stop();
        this.reload();
      })
      .catch(() => {
        this.blockUI.stop();
      });

    return Promise.resolve(newRecordId);
  }

  async updateTask(itemId: number, dataToUpdate: any): Promise<ApiResponseSummary<any>> {
    let result: ApiResponseSummary<any>;

    const endpoint = '/divisions/' + this.divisionId + '/activities/tasks/' + itemId;
    await this.setEndpoint(endpoint)
      .update(dataToUpdate)
      .then(response => {
        result = response;
        if (result.success === true) {
          this.reload();
        }
      });

    return Promise.resolve(result);
  }

  async addNewTask(item: any): Promise<number> {
    
    let newRecordId: number;
    const endpoint = '/divisions/' + this.divisionId + '/activities/tasks';

    await this.setEndpoint(endpoint)
      .save(item)
      .then(response => {
        newRecordId = response.newRecordId;
        this.itemAdded.next(response.newRecordId);
        this.reload();
      });

    return Promise.resolve(newRecordId);
  }
}
