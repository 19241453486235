import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { ActivityRecordedTimeListItemModel } from './activity-recorded-time-list-item.model';
import { RequestStatus } from 'app/shared/enumeration/request-status';

export class ActivityRecordedTimeListItemViewModel extends ActionableItemState {
  requestAmendButton: IIconActionButton;

  constructor(dataModel: ActivityRecordedTimeListItemModel) {
    super();

    this.dataModel = dataModel;

    const canAmend = this.dataModel.stopTime;
    const cannotAmendMessage = 'Only recorded time with a stop time can be amended';

    if (dataModel.approvedForInvoice === false) {
      const amendClass = this.classFromStatus(dataModel.amendStatus);
      this.requestAmendButton = {
        visible: true,
        enabled: dataModel.amendStatus <= 1,
        actionEnabled: canAmend,
        actionDisabledMessage: cannotAmendMessage,
        iconName: 'live_help',
        actionName: 'requestAmend',
        hoverText: 'Request an amend to the Recorded Time',
        class: amendClass,
        buttonText: ''
      };

      this.processActions.push(this.requestAmendButton);
    }
  }

  classFromStatus(amendStatus: RequestStatus) {
    switch (amendStatus) {
      case RequestStatus.Accepted:
        return 'amend-status-accepted';
      case RequestStatus.Declined:
        return 'amend-status-declined';
      case RequestStatus.Requested:
        return 'amend-status-requested';
      default:
        return '';
    }
  }
}
