import { ActivatedRoute } from "@angular/router";
import { Component, EventEmitter, Output, Input } from "@angular/core";
import { IIconActionButton } from "app/shared/interfaces/IIconActionButton.interface";
import { IconActionButtonEventMessage } from "app/shared/components/icon-action-button-list/icon-action-button-list-message.model";

@Component({
  selector: "icon-action-button-list",
  templateUrl: "./icon-action-button-list.component.html",
})

// Collection of IconActionButton components
export class IconActionButtonListComponent {
  @Input() actionItems: IIconActionButton[]; // the individual buttons to be renderd in the list
  @Output() onInvokeAction = new EventEmitter<IconActionButtonEventMessage>(); // emitted when child button is clicked and emits an event
  @Input() data: any;

  constructor() {}

  // bound to the onInvokeAction action of each child button
  processItemAction(message: string) {
    this.onInvokeAction.emit(new IconActionButtonEventMessage(message, this.data));
  }
}
