import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import { environment } from 'environments/environment';

// TODO refactor to use local storage?
// Cookies are sent with every request, more secure to only send in the way intended, i.e as bearer token
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { AuthResponse } from './auth-response.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { map, share } from 'rxjs/operators';
import { Constants } from 'app/core/models/constants';
import { Router } from '@angular/router';

// TODO rename this service to be password credential flow specific
// TODO implement interface with auth and deauth methods for app auth service to consume
@Injectable()
export class ApiAuthService {
  protected clientSecret: string = environment.clientSecret;
  protected readonly apiPrefix: string = environment.apiPrefix;
  protected readonly baseApiUrl: string = environment.baseUrl;
  protected apiUrl: string = this.baseApiUrl + this.apiPrefix;
  accessToken = '';
  refreshToken = '';

  constructor(private http: HttpClient, private router: Router) {}

  public async authenticate(username: string, password: string): Promise<AuthResponse> {
    const result: AuthResponse = new AuthResponse();

    await this.requestNewAccessToken(username, password)
      .then(res => {
        result.data = res;
        this.accessToken = result.data.access_token;
        this.refreshToken = result.data.refresh_token;
        localStorage.setItem(Constants.LocalStorage.KeyAccessToken, this.accessToken);
        localStorage.setItem(Constants.LocalStorage.KeyRefreshToken, this.refreshToken);
      })
      .catch(error => {
        result.isError = true;

        // TODO If response is not JSON, return some error general information manually based on http status code
        if (error.status === 404) {
          result.errorType = 'Not Found';
          result.errorMessage = 'HTTP Error 404. The requested resource is not found.';
        } else if (error.status === 400) {
          result.errorType = 'Bad Request';
          result.errorMessage = error.error.error_description;
        } else if (error.status === 0) {
          result.errorType = 'Unknown Error';
          result.errorMessage = error.message;
        } else {
          console.log(error);
          const body = JSON.parse(error._body);

          result.errorType = body.error;
          result.errorMessage = body.error_description;
        }
      });

    return Promise.resolve(result);
  }

  public deauthenticate(): Promise<boolean> {
    this.accessToken = '';
    this.refreshToken = '';
    return Promise.resolve(true);
  }

  public isAuthenticated(): boolean {
    if (this.accessToken.length > 0) {
      return true;
    }
    return this.checkCookie();
  }

  private checkCookie(): boolean {
    const accessTokenFromCookie = localStorage.getItem(Constants.LocalStorage.KeyAccessToken);

    if (accessTokenFromCookie !== null && accessTokenFromCookie !== undefined && accessTokenFromCookie.length > 0) {
      this.accessToken = accessTokenFromCookie;
      return true;
    }
    return false;
  }

  private requestNewAccessToken(username: string, password: string): Promise<Object> {
    const url = this.apiUrl + '/token';

    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('client_secret', this.clientSecret)
      .set('username', username)
      .set('password', password);

    return this.http.post(url, body).toPromise(); //async&await only works with promises
  }

  refreshAccessToken(): Observable<any> {
    const url = this.apiUrl + '/token';
    const refreshToken = localStorage.getItem(Constants.LocalStorage.KeyRefreshToken);
    // TODO: [Technical Debt][DPB][2020-05-06] Need to understand why the refresh token is being called for login
    // expect the login screen to not need authenticated API requests
    // and so not need to make this check
    if (this.router.url !== '/login?r=1') {
      localStorage.setItem(Constants.LocalStorage.RedirectUrl, this.router.url);
    }

    if (refreshToken === null) {
      return Observable.empty();
    }

    const body = new HttpParams().set('grant_type', 'refresh_token').set('refresh_token', refreshToken);
    const result: AuthResponse = new AuthResponse();

    return this.http.post(url, body).pipe(
      share(),
      map(res => {
        result.data = res;
        this.accessToken = result.data.access_token;
        this.refreshToken = result.data.refresh_token;
        localStorage.setItem(Constants.LocalStorage.KeyAccessToken, this.accessToken);
        localStorage.setItem(Constants.LocalStorage.KeyRefreshToken, this.refreshToken);
        localStorage.setItem(Constants.LocalStorage.RedirectUrl, '');
        return this.accessToken;
      })
    );
  }

  getAccessToken(): string {
    const accessTokenFromCookie = localStorage.getItem(Constants.LocalStorage.KeyAccessToken);
    return accessTokenFromCookie;
  }
}
