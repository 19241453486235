import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { BordereauxImportListService } from 'app/modules/contacts/company-detail/services/bordereaux-import-list.service';
import { ActivityListItemViewModel } from 'app/modules/solution-shared/case-activity/models/activity-list-item-view.model';
import { ActivityListItemModel } from 'app/modules/solution-shared/case-activity/models/activity-list-item.model';
import { CaseActivityModel } from 'app/modules/solution-shared/case-activity/models/case-activity.model';
import { ActivityLevel } from 'app/shared/enumeration/activity-level';
import { downloadFileFromExtendedBlob, loadUrlToNewTargetViaLinkClick } from 'app/shared/helpers/file-download-helpers';
import { ApiResponseSummary } from 'app/shared/models/api-response-summary.model';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { SnackBarService } from 'app/shared/services/snack-bar.service';
import { CaseActivityListMode } from '../models/CaseActivityListMode';

@Injectable()
export class ActivityListService extends ListServiceBase {
  listMode = CaseActivityListMode.History;

  divisionId: number;
  levelParentId: number;
  activityLevel: number;

  constructor(protected dependencies: ApiDependenciesService, protected dialog: MatDialog, private snackBar: SnackBarService, private bordereauxImportListService: BordereauxImportListService) {
    super(dependencies, dialog);

    this.initialiseFiltersSortAndPaging();
  }

  // populate initial filter properties
  private initialiseFiltersSortAndPaging() {
    // Add intialisation here
  }

  //#region Get Endpoints

  get baseEndpoint(): string {
    if (this.divisionId === undefined) debugger;
    if (this.levelParentId === undefined) debugger;
    return '/divisions/' + this.divisionId + '/case-claims/' + this.levelParentId + '/case-activities';
  }
  baseEndpointWithId(id: number): string {
    if (id === undefined) debugger;
    return this.baseEndpoint + '/' + id;
  }
  get baseEndpointForTribunalPack(): string {
    if (this.divisionId === undefined) debugger;
    if (this.levelParentId === undefined) debugger;
    return '/divisions/' + this.divisionId + '/case-claims/' + this.levelParentId + '/tribunal-activities';
  }

  get baseActivityEndpoint(): string {
    if (this.divisionId === undefined) debugger;
    return '/divisions/' + this.divisionId + '/activities/custom-activities';
  }

  baseActivityEndpointWithId(id: number): string {
    if (this.divisionId === undefined) debugger;
    return '/divisions/' + this.divisionId + '/activities/custom-activities/' + id;
  }

  // a number of endpoint getters are defined in ListServiceBase
  // not all endpoints will be required for all implementations

  // OVERRIDDEN from ListServiceBase
  protected getCommandStubEndpoint(itemId: number): string {
    if (itemId === undefined) debugger;
    return this.baseEndpointWithId(itemId);
  }

  // OVERRIDDEN from ListServiceBase
  protected getListEndpoint(): string {
    if (this.listMode === CaseActivityListMode.TribunalPackContents) {
      return this.baseEndpointForTribunalPack + '/included';
    } else if (this.listMode === CaseActivityListMode.TribunalPackSelection) {
      return this.baseEndpointForTribunalPack + '/excluded';
    } else if (this.activityLevel === ActivityLevel.Product) {
      if (this.divisionId === undefined) debugger;
      if (this.levelParentId === undefined) debugger;
      return '/divisions/' + this.divisionId + '/products/' + this.levelParentId + '/activities';
    } else if (this.activityLevel === ActivityLevel.Client) {
      if (this.divisionId === undefined) debugger;
      if (this.levelParentId === undefined) debugger;
      return '/divisions/' + this.divisionId + '/companies/' + this.levelParentId + '/activities';
    } else {
      return this.baseEndpoint + '/case-activity-list';
    }
  }

  //#region list loading

  // OVERRIDDEN from ListServiceBase
  // Create a new ViewModel specific to this list
  // Not necessary to set the viewmodel itemId as it is set in the viewModel constructor
  createListViewModel(dataModel: ApiEntity) {
    return new ActivityListItemViewModel(dataModel as ActivityListItemModel, this.listMode);
  }

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for each list row item
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedListItemDataModel(responseItem: any): ApiEntity {
    const model: ActivityListItemModel = responseItem;
    return model;
  }

  //#region get details

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for the detail item (create/update)
  // The model must be derived from ApiEntity
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedEditableItemDataModel(response: any): ApiEntity {
    const model: CaseActivityModel = response;
    return model;
  }

  //#endregion

  protected getCreateEndpoint(): string {
    return this.baseEndpoint;
  }

  protected getUpdateEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }

  protected getCreateActivityEndPoint(): string {
    if (this.divisionId === undefined) debugger;
    return '/divisions/' + this.divisionId + '/activities/custom-activities';
  }

  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  async download(itemId: number) {
    await this.sendCommand(itemId, 'download');
  }
  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  async notes(itemId: number) {
    await this.sendCommand(itemId, 'notes');
  }
  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  async reassociate(itemId: number) {
    await this.sendCommand(itemId, 'reassociate');
  }

  // OVERRIDDEN from ListServiceBase
  protected getGetEditableItemEndpoint(itemId: number) {
    return itemId ? this.baseEndpointWithId(itemId) : this.baseEndpoint + '/blank';
  }

  protected get filterLookupDataEndpoint(): string {
    return this.baseEndpoint + '/filter-lookup-data';
  }

  protected get tribunalFilterLookupDataEndpoint(): string {
    return this.baseEndpointForTribunalPack + '/filter-lookup-data';
  }

  async getFilterLookupData(): Promise<any> {
    let model: any;
    await this.setEndpoint(this.filterLookupDataEndpoint)
      .getSingle()
      .then((response: any) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  async getTribunalFilterLookupData(): Promise<any> {
    let model: any;
    await this.setEndpoint(this.tribunalFilterLookupDataEndpoint)
      .getSingle()
      .then((response: any) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  async updatePackContents(include: boolean, selectedItemIds: number[]) {
    const model = { include: include, caseActivityIds: selectedItemIds };
    await this.setEndpoint(this.baseEndpointForTribunalPack + '/update-contents')
      .update(model)
      .then(() => {
        if (include === false) {
          this.reload();
        }
      });
  }

  async downloadPack() {
    if (this.divisionId === undefined) debugger;
    if (this.levelParentId === undefined) debugger;
    this.blockUI.start();
    await this.setEndpoint(`/divisions/${this.divisionId}/case-claims/${this.levelParentId}/download-tribunal-pack`)
      .getFileAsBlobWithPost()
      .then((response) => {
        downloadFileFromExtendedBlob(response);
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });
  }

  async downloadFile(uniqueIdentifier: string) {
    await this.setEndpoint('/file-store/' + uniqueIdentifier + '/download')
      .getFileAsBlobWithPost()
      .then((response) => {
        downloadFileFromExtendedBlob(response);
      });
  }

  async regenerate(itemId: number) {
    if (this.divisionId === undefined) debugger;
    if (this.activityLevel === undefined) debugger;
    if (this.levelParentId === undefined) debugger;
    if (itemId === undefined) debugger;
    this.blockUI.start();
    await this.setEndpoint('/divisions/' + this.divisionId + '/activities/' + this.activityLevel + '/' + this.levelParentId + '/generated-documents/' + itemId + '/regenerate')
      .command()
      .then(() => {
        this.snackBar.info('Document regenerated.');
        this.reload();
      })
      .catch(() => {
        this.snackBar.error('Unable to regenerate the document due to a server error.');
      })
      .finally(() => {
        this.blockUI.stop();
      });
  }


  async uploadBordereauxFromEmailAttachment(attachmentId: number, dataToUpdate: any) {
    debugger;
    if (this.divisionId === undefined) debugger;
    if (attachmentId === undefined) debugger;
    this.blockUI.start();
    await this.setEndpoint('/divisions/' + this.divisionId + '/bordereaux-imports/upload-from-email-attachment/' + attachmentId)
      .save(dataToUpdate)
      .then(() => {
        this.snackBar.info('Uploaded into Bordereaux.');
        //this.reload();  // should actually reload bordereaux grid really, if we can. Nothing to reload on our attachment list as it hasn't changed
        this.bordereauxImportListService.reload();
      })
      .catch(() => {
        this.snackBar.error('Unable to upload into Bordereaux due to a server error.');
      })
      .finally(() => {
        this.blockUI.stop();
      });
  }

  async previewEmailAttachment(itemId: any, divisionId: number) {
    if (divisionId !== undefined) {
      this.divisionId = divisionId;
    } 

    if (this.divisionId === undefined) debugger;
    const endpoint = `/divisions/${this.divisionId}/group-inbox-items/${itemId}/email-attachment-url`;
    await this.setEndpoint(endpoint)
      .getSingle()
      .then((url) => {
        loadUrlToNewTargetViaLinkClick(url, 'oneDriveFiles');
      });
  }

  async downloadEmailAttachment(itemId: any) {
    if (this.divisionId === undefined) debugger;
    if (itemId === undefined) debugger;
    await this.setEndpoint(`/divisions/${this.divisionId}/group-inbox-items/${itemId}/email-attachment`)
      .getFileAsBlobWithPost()
      .then((response) => {
        downloadFileFromExtendedBlob(response);
      });
  }

  async getWebUrl(uniqueIdentifier: string): Promise<string> {
    let model: string;
    const endpoint = '/file-store/' + uniqueIdentifier + '/weburl';
    await this.setEndpoint(endpoint)
      .getSingle()
      .then((response: string) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  async getInlinePreviewUrls(uniqueIdentifier: string): Promise<OfficeInlinePreviewDetailsModel> {
    let model: OfficeInlinePreviewDetailsModel;
    const endpoint = '/file-store/' + uniqueIdentifier + '/preview-inline-urls';
    await this.setEndpoint(endpoint)
      .getSingle()
      .then((response: OfficeInlinePreviewDetailsModel) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  async addNewActivity(item: any): Promise<number> {
    let newRecordId: number;
    this.blockUI.start();
    await this.setEndpoint(this.baseActivityEndpoint)
      .save(item)
      .then((response) => {
        newRecordId = response.newRecordId;
        this.itemAdded.next(response.newRecordId);

        this.blockUI.stop();
        this.reload();
      })
      .catch(() => {
        this.blockUI.stop();
      });

    return Promise.resolve(newRecordId);
  }

  async updateActivityItem(itemId: number, dataToUpdate: any, endpoint: string = null): Promise<ApiResponseSummary<any>> {
    let result: ApiResponseSummary<any>;
    if (itemId === undefined) debugger;

    this.blockUI.start();

    const endpointToUse = endpoint == null ? this.baseActivityEndpointWithId(itemId) : endpoint;
    await this.setEndpoint(endpointToUse)
      .update(dataToUpdate)
      .then((response) => {
        result = response;
        if (result.success === true) {
          this.reload();
        }
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });
    return Promise.resolve(result);
  }

  async togglePublishToPortal(itemId: number) {
    await this.sendCommand(itemId, 'toggle-publish-to-portal');
  }
}
