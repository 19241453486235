import { Component, Input, OnChanges, ViewChild, Injector } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ActivityRecordedTimeListService } from 'app/modules/solution-shared/case-activity-time-search/services/activity-recorded-time-list.service';
import { ComponentItem } from 'app/shared/base/aside/component-item';
import { AddListComponent } from 'app/shared/components/add-list/add-list.component';
import { IconActionButtonEventMessage } from 'app/shared/components/icon-action-button-list/icon-action-button-list-message.model';
import { SystemActivityType } from 'app/shared/enumeration/system-activity-types';
import { IAddListService } from 'app/shared/interfaces/IAddListService.interface';
import { ActivityTimerHubService } from 'app/shared/services/signalr.service';
import CaseActivityHelper from '../../case-activity/helpers/case-activity-helper';
import { TimeRecorderListMode } from '../models/time-recorder-mode';
import { RecordedTimeAmendFormComponent } from './recorded-time-amend-form/recorded-time-amend-form.component';

@Component({
  selector: 'activity-recorded-time-list',
  templateUrl: './activity-recorded-time-list.component.html',
  styleUrls: ['./activity-recorded-time-list.component.scss']
})
export class ActivityRecordedTimeListComponent extends AddListComponent implements OnChanges {
  divisionId: number;
  @Input() caseActivityId: number;
  @Input() showUserName = true;
  @Input() listMode: TimeRecorderListMode;

  private sort: MatSort;
  private paginator: MatPaginator;
  matSortSubscribed;
  matPaginatorSubscribed;

  constructor(
    private route: ActivatedRoute,
    private listService: ActivityRecordedTimeListService,
    private asideService: AsideService,
    private activityTimerHubService: ActivityTimerHubService,
    private injector: Injector
  ) {
    super();

    this.loadAfterInit = false;

    /**get parent id form the route */
    route.params.subscribe(params => {
      this.divisionId = params['divisionId'];
      this.listService.divisionId = this.divisionId;

      if (this.caseActivityId) {
        this.listService.caseActivityId = this.caseActivityId;
      }
    });
  }

  get displayedColumns(): string[] {
    const columns = ['id', 'startTime', 'stopTime', 'durationSort', 'actions'];

    if (this.showUserName) {
      columns.splice(4, 0, 'userName');
    }
    if (this.listMode === TimeRecorderListMode.CaseSearch) {
      columns.splice(1, 0, 'activitySummary');
      columns.splice(1, 0, 'activityType');
    }

    return columns;
  }

  iconFromActivityType(systemActivityType: SystemActivityType): string {
    return CaseActivityHelper.iconFromActivityType(systemActivityType);
  }

  protected get usePaginator(): boolean {
    return this.listMode === TimeRecorderListMode.CaseSearch;
  }

  protected get service(): IAddListService {
    return this.listService;
  }

  @ViewChild(MatSort, { static: false })
  set matSort(ms: MatSort) {
    this.sort = ms;
    if (this.sort) {
      if (this.matSortSubscribed) {
        this.matSortSubscribed.unsubscribe();
      }
      this.matSortSubscribed = this.sort.sortChange.subscribe(e => {
        this.originalSort(e.active, e.direction);
      });
    }
  }

  @ViewChild(MatPaginator, { static: false })
  set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    if (this.paginator) {
      if (this.matPaginatorSubscribed) {
        this.matPaginatorSubscribed.unsubscribe();
      }

      this.matPaginatorSubscribed = this.paginator.page.subscribe(e => {
        this.service.ListRequest.pageNumber = e.pageIndex + 1;
        this.service.ListRequest.pageSize = e.pageSize;
        this.pageChanged();
      });
    }
  }

  get activityRecordedTimeListService() {
    return this.service as ActivityRecordedTimeListService;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnInit() {
    super.ngOnInit();
    this.listService.caseActivityId = this.caseActivityId;
  }

  ngOnChanges(): void {
    this.listService.caseActivityId = this.caseActivityId;
    this.service.reload();
  }

  pageChanged() {
    this.service.reload();
  }

  originalSort(column: string, direction: string) {
    this.service.setSort(column, direction);
  }

  doIconButtonAction(message: IconActionButtonEventMessage) {
    switch (message.action) {
      case 'requestAmend': {
        const rowModel = this.listService.findViewModelFromItemId(message.data);
        const amendId = rowModel.dataModel.amendId;
        this.asideService.setAndOpen(
          new ComponentItem(RecordedTimeAmendFormComponent, {
            itemId: message.data,
            amendId: amendId,
            divisionId: this.divisionId,
            caseActivityId: this.caseActivityId
          }),
          this.injector
        );
        break;
      }
      default: {
        console.log('Unsupported action: ' + message.action);
        break;
      }
    }
  }
}
