import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { InternalFileUploaderComponent } from 'app/shared/components/file-uploader/file-uploader.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'simple-file-upload',
  templateUrl: './simple-file-upload.component.html'
})

/**
 * Provides an easy way of allowing a file upload.
 * 
 * @example
 * // Displays an aside that will upload a file the specified endpoint and allows the selection of Excel and CSV files.
 * this.asideService.setAndOpen(
 *    new ComponentItem(SimpleFileUploadComponent, {
 *      allowedTypes: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
 *      uploadEndpoint: uploadUrl,
 *      jsonHeader: {},
 *      formTitle: 'Select File to Upload',
 *    }),
 *    this.injector
 *  );
 * 
 */
export class SimpleFileUploadComponent implements OnInit {
  public data: any;

  allowedTypes = []; // bound to uploader. Set by data passed in to component
  uploadEndpoint: string; // bound to uploader. Set by data passed in to component. Can only be set in ngOnInit
  fileUploadMethod = 'POST'; // bound to uploader. Set by data passed in to component
  formTitle = 'Title Not Set'; // Set by data passed in to component

  dataForm: FormGroup;

  @ViewChild(InternalFileUploaderComponent, { static: false }) fileUploaderComponent: InternalFileUploaderComponent;

  constructor(
    private fb: FormBuilder,
    private asideService: AsideService,
    private formHelperService: ReactiveFormHelperService,
    private dialog: MatDialog
  ) {
    this.dataForm = fb.group({});
  }

  ngOnInit() {
    this.formTitle = this.data.formTitle;
    this.uploadEndpoint = this.data.uploadEndpoint;
    if (this.data.allowedTypes) {
      this.allowedTypes = this.data.allowedTypes;
    }
    if (this.data.fileUploadMethod) {
      this.fileUploadMethod = this.data.fileUploadMethod;
    }
  }

  save() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      if (this.data.jsonHeader) {
        this.fileUploaderComponent.setXJsonHeader(this.data.jsonHeader);
      }

      this.fileUploaderComponent.onApply();
    }
  }

  cancel() {
    const isFormDirty = this.formHelperService.isFormDirty(this.dataForm);

    // the data form isn't maked as dirty when the forma array is dirty so need to add additional check
    if (isFormDirty) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?'
        }
      });

      dialogRef.componentInstance.onClose.subscribe(result => {
        if (result.result) {
          this.asideService.close('cancel');
        }
      });
    } else {
      this.asideService.close('cancel');
    }
  }

  //#endregion

  fileUploadComplete(): void {
    this.fileUploaderComponent.clearQueue();
    this.asideService.close('update');
  }
}
