// Provides web access to the Kaleida Telephony server.
// Note that although it is a "server", it is expected this server will be installed on each client machine and ran locally there.

import { LiteEvent } from '../kaleida.telephony/LiteEvent';
import { InboundMessageType, OutboundMessageType, InboundMessage, InboundMessageResponse, OutboundMessage, DotNetVersionInfo} from '../kaleida.telephony/Contract_Core';
import { CallEventType, CallEvent, CallDirections, CallDetails, DndInfo, CallRecordingDetails } from './Contract_Types';
import { createVerify } from 'crypto';
import { MakeCallArgs, ModifyCallDisplayArgs, AnnouncedTransferArgs, TransferCallArgs, QueryRecordingDetailsArgs, LoginExtensionArgs } from './Contract_InboundMessageParams';

interface SendMessageCallback { (response: InboundMessageResponse): void }

class PendingMessage {
    MessageId: number;
    Callback: SendMessageCallback;
    CreatedAt: number;
}

export class TelephonySession {
    readonly serviceUri : string;
    readonly wsTimeoutMs = 10000;       // number of ms to wait before failing a websock request that's still pending
    readonly wsTimeoutPollMs = 5000;    // number of ms between polling for timed-out requests

    private connection: WebSocket = null;
    private pendingMessages: Array<PendingMessage> = new Array<PendingMessage>();   // tracks the inbound messages we have sent that we are awaiting a response for

    private loggedInExtension: string = null;

    // Note: At present construction will fail (or hang, not sure what the timeout is?) if we can't connect to the local service
    // Maybe we need better error handling here or maybe the host app should catch and handle it appropriately?
    constructor(serviceUri: string) {
        this.serviceUri = serviceUri;
    }

    public connect() {
        if(this.connection!==null) this.disconnect();
        console.debug("TelephonySession.connect() is using service URI: " + this.serviceUri);
        this.connection = new WebSocket(this.serviceUri, ["json"]);
        console.debug("TelephonySession.connect() has constructed WebSocket");
        this.connection.onmessage = (e) => this.connection_onMessage(e);
        this.connection.onopen = (e) => this.connection_onOpen(e);
        this.connection.onclose = (e) => this.connection_onClose(e);
        this.connection.onerror = (e) => this.connection_onError(e);
        setInterval(() => { this.failExpiredPendingMessages(); }, this.wsTimeoutPollMs);
    }

    public disconnect(){
        console.debug("TelephonySession.disconnect() is disconnecting existing WebSocket");
        this.connection.close();
        this.connection = null;
    }

    private failExpiredPendingMessages() {
        var requestsKilled = 0;
        try {
            var oldestToSurvive = Date.now() - this.wsTimeoutMs;
            var killList = this.pendingMessages.filter((value, index, arr) => { return value.CreatedAt < oldestToSurvive });

            if (killList.length === 0) return;  // usually, there won't even be anything to kill, so we can skip the rest of this method

            var survivors = this.pendingMessages.filter((value, index, arr) => { return !(value.CreatedAt < oldestToSurvive) });

            var timeoutIbmTemplate = new InboundMessageResponse();
            timeoutIbmTemplate.Successful = false;
            timeoutIbmTemplate.AdditionalInformation = "The request timed out. The timeout can be configured in TelephonySession.wsTimeoutMs.";

            killList.forEach((killTarget) => {
                var timeoutIbm = Object.assign({}, timeoutIbmTemplate);
                timeoutIbm.RequestId = killTarget.MessageId;
                killTarget.Callback(timeoutIbm);
                requestsKilled++;
            });

            this.pendingMessages = survivors;

            console.warn(requestsKilled + " pending TelephonySession WebSock requests were killed due to timeout.");
        } catch (err) {
            console.error("Error in TelephonySession.failExpiredPendingMessages(): " + err);
            if (requestsKilled > 0) console.error(requestsKilled + " requests were partially killed prior to the previous error.");
        }
    }

    private sendMessageInternal(message: InboundMessage): Promise<InboundMessageResponse> {
        return new Promise((resolve, reject) => {
            message.RequestId = TelephonySession.random(10000000, 99999999);
            var json = TelephonySession.serialize(message);

            var callback = (response: InboundMessageResponse) => {
                if (response.Successful)
                    resolve(response);
                else
                    reject(response.AdditionalInformation);
            }

            var pm = new PendingMessage();
            pm.MessageId = message.RequestId;
            pm.Callback = callback;
            pm.CreatedAt = Date.now();
            this.pendingMessages.push(pm);

            this.connection.send(json);
        });
    }

    /**
     * Sends a message to the server (companion app).
     * This version takes care of the usual assembling of the InboundMessage object and unwrapping the AdditionalData in the response so the caller doesn't have to, and
     * so is preferred over sendMessageInternal().
     * */
    private sendMessage(type: InboundMessageType, data: any, dataRequiresSerialization: boolean) : Promise<string>{
        var ibm = new InboundMessage();
        ibm.Type = type;
        ibm.Data = (data===null) ? null : (dataRequiresSerialization? TelephonySession.serialize(data) : data);
        return this.sendMessageInternal(ibm).then(ibmr=>ibmr.AdditionalInformation);
    }

    private connection_onOpen(e: Event){
        console.debug("connection_onOpen event detected.");
        this.OnConnected.trigger();
    }
    private connection_onClose(e: CloseEvent){
        console.warn("connection_onClose event detected.");
        this.OnDisconnected.trigger();
    }
    private connection_onError(e: Event){
        console.error("connection_onError event detected.");
        // It shouldn't be necessary to trigger an OnDisconnected here? I've read that onClose is always fired after onError anyawy.
    }

    private connection_onMessage(e: MessageEvent) {
        // This will be called when we receive an OutboundMessage. This could be an event, such as a phone ring event, or it could be the response for a previous InboundMessage we've sent.
        try {
            var outboundMessage = TelephonySession.deserialize<OutboundMessage>(e.data);
            if (outboundMessage.Type === OutboundMessageType.InboundInstructionResponse) {
                var inboundMessageResponse = TelephonySession.deserialize<InboundMessageResponse>(outboundMessage.Data);
                var requestId = inboundMessageResponse.RequestId;

                // Tally this up to a pending request and trigger the associated callback
                var matchIndex = this.pendingMessages.findIndex(pm => pm.MessageId === requestId);
                if (matchIndex >= 0) {
                    var match = this.pendingMessages.splice(matchIndex, 1);
                    match[0].Callback(inboundMessageResponse);
                }
                else {
                    console.warn("Disregarding inbound telephony response for request " + requestId + " as client doesn't have a record of the request being sent.");   // might happen if page is refreshed etc and then a response comes in for an earlier message
                }
            }
            else {
                // The message is unrelated to a previous request we've sent, it's an ad-hoc event.
                this.handleEvent(outboundMessage);
            }
        }
        catch(exception){
            console.error("connection_onMessage() unhandled exception: " + exception);
        }
    }

    private handleEvent(message: OutboundMessage) {
        // Doesn't seem to be a built-in way of doing events in TypeScript. We could use callbacks but multicast events probably make more sense.
        // Rather than writing custom event handling from scratch there are a few examples here that could be ripped off: https://stackoverflow.com/questions/12881212/does-typescript-support-events-on-classes

        switch (message.Type) {
            case OutboundMessageType.DoNothing:
                console.info("DoNothing debugging/testing event received. Data string: " + message.Data);
                break;
            case OutboundMessageType.ForceLogout:
                this.loggedInExtension = null;
                this.ForceLogout.trigger(message.Data);
                break;
            case OutboundMessageType.CallEvent:
                return this.handleCallEvent(TelephonySession.deserialize<CallEvent>(message.Data));
            default:
                console.error("Unsupported outbound message type: " + message.Type);
        }
    }

    private handleCallEvent(message: CallEvent) {
        this.CallEvent.trigger(message);

        switch (message.EventType) {
            case CallEventType.CallAnswered:
                return this.CallAnswered.trigger(message.CallDetails);
            case CallEventType.CallCleared:
                return this.CallCleared.trigger(message.CallDetails);
            case CallEventType.CallHeld:
                return this.CallHeld.trigger(message.CallDetails);
            case CallEventType.CallRetrieved:
                return this.CallRetrieved.trigger(message.CallDetails);
            case CallEventType.CallRinging:
                return this.CallRinging.trigger(message.CallDetails);
            case CallEventType.CallTransferred:
                return this.CallTransferred.trigger(message.CallDetails);

            default:
                console.warn("Unsupported call event type (will still be raised as a generic CallEvent): " + message.EventType);
        }
    }

    private static random(inclusiveMinimum: number, exclusiveMaximum: number): number {
        // Quick & dirty & haven't tested this! But it's only to generate a unique number so should be fine.
        return Math.floor(Math.random() * (exclusiveMaximum - inclusiveMinimum)) + inclusiveMinimum;
    }

    private static serialize(object): string {
        return JSON.stringify(object);
    }

    private static deserialize<T>(jsonString): T {
        return JSON.parse(jsonString);
    }





    // * Public properties

    public get LoggedIn(): boolean {
        return this.loggedInExtension != null;
    }
    public get LoggedInExtension(): string {
        return this.loggedInExtension;
    }


    // * Public methods
    //   In most cases these will be processed asynchronously and a Promise will be returned allowing further action to be taken upon success or failure.

    /**
     * Log-in with a specific phone extension.
     * */
    public LoginExtension(args: LoginExtensionArgs): Promise<string> {
        args.Extension = args.Extension.trim();
        console.debug("LoginExtension: " + args.Extension + ", kick: " + args.KickExistingUser);

        return this.sendMessage(InboundMessageType.LoginExtension, args, true).then(strResponse => {
            this.loggedInExtension = args.Extension;
            console.debug("LoginExtension: " + args.Extension + " successful.");
            return strResponse;
        });

        /* The below could be used to handle and rethrow the error. But I doubt there's much of any use we could do in here anyway really.
        .catch(errMessage => {
            // handle here
            throw errMessage;
        });*/
    }

    /**
     * Logout from using a phone extension.
     * */
    public Logout(): Promise<string> {
        this.loggedInExtension = null;
        return this.sendMessage(InboundMessageType.Logout, null, false);
    }

    public GetCompanionVersion(): Promise<DotNetVersionInfo> {
        return this.sendMessage(InboundMessageType.GetCompanionVersion, null, false)
        .then(json=>TelephonySession.deserialize<DotNetVersionInfo>(json));
    }

    /**
     * Sets the DND state of the phone.
     * */
    public SetDnd(args: DndInfo) {
        return this.sendMessage(InboundMessageType.SetDnd, args, true);
    }

    public GetDnd(): Promise<DndInfo>{
        return this.sendMessage(InboundMessageType.GetDnd, null, false)
        .then(json=>TelephonySession.deserialize<DndInfo>(json));
    }

    /**
     * Attempts to get details of the recording for the current segment of a call.
     * Xarios advise that this may not be available immediately upon getting the call event and we may need to call it after some kind of delay.
     * */
    public QueryCallRecordingDetails(args: QueryRecordingDetailsArgs): Promise<CallRecordingDetails>{
        return this.sendMessage(InboundMessageType.QueryRecordingDetails, args, true)
        .then(json=>TelephonySession.deserialize<CallRecordingDetails>(json));
    }

    public GetRecordingPlayerUrl(recId: number): Promise<string>{
        return this.sendMessage(InboundMessageType.GetRecordingPlayerUrl, recId, false);
    }

    public AnswerCall(optionalCallIndex: number) {
        return this.sendMessage(InboundMessageType.AnswerCall, optionalCallIndex, true);
    }

    public HoldCall(optionalCallIndex: number) {
        return this.sendMessage(InboundMessageType.HoldCall, optionalCallIndex, true);
    }

    public RetrieveCallFromHold(optionalCallIndex: number) {
        return this.sendMessage(InboundMessageType.RetrieveCall, optionalCallIndex, true);
    }

    public MakeOutboundCall(args: MakeCallArgs) {
        return this.sendMessage(InboundMessageType.MakeCall, args, true);
    }

    public EndCall(optionalCallIndex: number) {
        return this.sendMessage(InboundMessageType.ClearCall, optionalCallIndex, true);
    }

    public AnnouncedTransfer(args: AnnouncedTransferArgs){
        return this.sendMessage(InboundMessageType.AnnouncedTransfer, args, true);
    }

    public TransferCall(args: TransferCallArgs){
        return this.sendMessage(InboundMessageType.TransferCall, args, true);
    }

    public ModifyCallDisplay(args: ModifyCallDisplayArgs) {
        return this.sendMessage(InboundMessageType.ModifyCallDisplay, args, true);
    }


    // * Public events
    //   These can be subscribed to by calling .on() on the relevant event.

    /**
     * Fired when we are connected to the server. We can't log into an extension or send any messages to the phone system until this event has been fired.
     * */
    public OnConnected = new LiteEvent();
    
    public OnDisconnected = new LiteEvent();

    /**
     * Fired when the app has been kicked out from using a phone extension. This could be because another instance of the app has logged in with it, the connection to the Mitel system has been lost, etc.
     * */
    public ForceLogout = new LiteEvent<string>();

    // Call events

    /**
     * General event fired for many types of call events. The consumer can either listen for this event or handle the individual more specific event types.
     * */
    public CallEvent = new LiteEvent<CallEvent>();

    public CallAnswered = new LiteEvent<CallDetails>();
    public CallCleared = new LiteEvent<CallDetails>();
    public CallHeld = new LiteEvent<CallDetails>();
    public CallRetrieved = new LiteEvent<CallDetails>();
    public CallRinging = new LiteEvent<CallDetails>();
    public CallTransferred = new LiteEvent<CallDetails>();

}