exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "table {\n  width: 100%;\n  table-layout: fixed; }\n\n.table-container {\n  overflow: auto;\n  width: 100%;\n  height: calc(100% - 125px); }\n\nth.mat-header-cell,\ntd.mat-cell {\n  padding-left: 10px; }\n\ntr.mat-row:nth-child(odd) {\n  background-color: #efefef; }\n\n.mat-column-actions {\n  width: 160px;\n  display: table-cell; }\n\n.mat-column-select {\n  width: 24px; }\n\n.mat-column-timerControls {\n  width: 27px; }\n\n.mat-column-activityTypeName {\n  width: 12px; }\n\n.mat-column-caseReference {\n  width: 70px; }\n\n.mat-column-caseType {\n  width: 50px; }\n\n.mat-column-productOption {\n  width: 70px; }\n\n.mat-column-summary {\n  width: 150px; }\n\n.mat-column-activityDate {\n  width: 50px; }\n\n.mat-column-totalTimeSort {\n  width: 22px; }\n\n/* override the height of the detail row so that it can be zero height */\ntr.detail-row {\n  height: 0 !important; }\n\n.expanded-detail {\n  overflow: hidden; }\n\n/* remove the border from the bottom of the main data row  */\n.table-data-row td {\n  border-bottom-width: 0; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();