import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { CompanyLookupResponseItemModel, CompanyLookupResponseModel } from './models/company-lookup.model';
import { CompanyLookupService } from './services/company-lookup.service';

@Component({
  selector: 'company-lookup',
  templateUrl: './company-lookup.component.html'
})
export class CompanyLookupComponent implements OnInit {
  dataForm: FormGroup;
  responseModel: CompanyLookupResponseModel = null;
  companyLookupData: any[];
  result: string;
  showResults = false;

  @Input() companyName = '';
  @Input() regNumber = '';
  @Input() postcode = '';
  @Output() selectedCompanyChange = new EventEmitter<CompanyLookupResponseItemModel>();
  @Output() hasFoundResults = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private service: CompanyLookupService) {}

  ngOnInit() {
    this.dataForm = this.fb.group({
      selectCompany: ['']
    });

    this.selectCompany.valueChanges.subscribe(() => this.companySelected());
  }

  get selectCompany(): AbstractControl {
    return this.dataForm.controls['selectCompany'];
  }

  enableLookupButton = () => {
    return (this.companyName && this.companyName !== '') || (this.regNumber && this.regNumber !== '') || (this.postcode && this.postcode !== '');
  };

  lookup() {
    this.service.getCompanyLookupResponse(this.companyName ? this.companyName : '', this.regNumber ? this.regNumber : '', this.postcode ? this.postcode : '').then((response: CompanyLookupResponseModel) => {
      this.responseModel = response;

      if (response.companies === null || response.companies.length === 0) {
        this.result = 'Company Not Found';
        this.hasFoundResults.emit(false);
        this.showResults = false;
        return;
      }

      this.companyLookupData = this.createCompanyLookupData(response.companies);
      this.hasFoundResults.emit(true);
      this.result = '';
      this.showResults = true;
    });
  }

  createCompanyLookupData(list: any[]): any[] {
    return list.map(item => {
      return {
        id: item.id,
        text: [item.name, item.address.simpleValue].filter(Boolean).join(', '),
        address1: item.address.street,
        town: item.address.city,
        postcode: item.address.postcode,
        regNo: item.regNo
      };
    });
  }

  public companySelected(): void {
    const data = this.responseModel.companies.find(c => c.id === this.selectCompany.value);
    this.selectedCompanyChange.emit(data);
  }
}
