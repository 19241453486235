import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ComponentItem } from 'app/shared/base/aside/component-item';
import { AddListComponent } from 'app/shared/components/add-list/add-list.component';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { IconActionButtonEventMessage } from 'app/shared/components/icon-action-button-list/icon-action-button-list-message.model';
import { ActivityLevel } from 'app/shared/enumeration/activity-level';
import { SnackBarService } from 'app/shared/services/snack-bar.service';
import { ActivityEmailAttachmentListItemViewModel } from '../models/activity-email-attachment-list-item-view.model';
import { ActivityEmailAttachmentListItemModel } from '../models/activity-email-attachment-list-item.model';
import { ActivityListService } from '../services/activity-list.service';
import { UploadBordereauxFromEmailAttatchmentFormComponent } from '../upload-bordereaux-from-email-attachment-form/upload-bordereaux-from-email-attachment-form.component';

@Component({
  selector: 'activity-email-attachment-list',
  templateUrl: './activity-email-attachment-list.component.html',
  styleUrls: ['./activity-email-attachment-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ActivityEmailAttachmentComponent extends AddListComponent implements OnInit {
  @Input() listData: ActivityEmailAttachmentListItemModel[];
  @Input() levelParentId: number;
  @Input() activityLevel: ActivityLevel;
  @Input() divisionId: number;

  itemList: ActivityEmailAttachmentListItemViewModel[];

  get displayedColumns(): string[] {
    return ['name', 'actions'];
  }

  constructor(
    public snackBar: SnackBarService,
    private activityListService: ActivityListService,
    private asideService: AsideService,
    protected dialog: MatDialog,
    private injector: Injector) {
    super();
    this.loadAfterInit = false;
  }

  ngOnInit() {
    this.itemList = [];
    this.listData.forEach((dataModel: ApiEntity) => {
      const model = new ActivityEmailAttachmentListItemViewModel(dataModel as ActivityEmailAttachmentListItemModel);
      model.itemId = dataModel.id;
      this.itemList.push(model);
    });
  }

  doIconButtonAction(message: IconActionButtonEventMessage) {
    switch (message.action) {
      case 'download': {
        this.activityListService.downloadEmailAttachment(message.data.itemId);
        break;
      }
      case 'viewFile': {
        this.activityListService.previewEmailAttachment(message.data.itemId, this?.divisionId).then((response) => {});
        break;
      }
      case 'bordereauxImport': {
        this.asideService.setAndOpen(
          new ComponentItem(UploadBordereauxFromEmailAttatchmentFormComponent, {
            attatchmentId: message.data.itemId,
          }),
          this.injector
        );
      }
    }
  }

  confirmAction(title: string, message: string, action: ()=>void){
    this.confirm(title, message).then(result=>{
      if(result) action();
    })
  }

  /**
   * Asks the user for confirmation and then resolves a promise with the user's selection.
   * */
  confirm(title: string, message: string) : Promise<boolean> {
    return new Promise((resolve, reject) => {

      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: title,
          message: message
        }
      });

      dialogRef.componentInstance.onClose.subscribe(result => {
        resolve(!!result.result);
      });
    })
  }

  toggleExpanded(row: ActivityEmailAttachmentListItemViewModel) {
    row.expanded = !row.expanded;
    console.debug("Email attachment item toggleExpanded() - set to: " + row.expanded);
  }

}
