import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { formatAsString } from 'app/shared/helpers/date-helpers';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { UploadBordereauxFromEmailAttachmentModel } from '../models/upload-bordereaux-from-email-attachment.model';
import { ActivityListService } from '../services/activity-list.service';


@Component({
  selector: 'upload-bordereaux-from-email-attachment-form',
  templateUrl: './upload-bordereaux-from-email-attachment-form.component.html',
  styles: [
    `
    `
  ]
})
export class UploadBordereauxFromEmailAttatchmentFormComponent implements OnInit {
  public data: any;
  viewModel: UploadBordereauxFromEmailAttachmentModel;
  concurrencyErrorMessage = '';
  dataForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private service: ActivityListService,
    private asideService: AsideService,
    private formHelperService: ReactiveFormHelperService,
    private dialog: MatDialog
  ) {

    this.dataForm = fb.group(
      {
        postingDate: ['', [Validators.required]],
      }
    );
  }

  get showDateWarning(): boolean{
    if(!this.postingDate.touched) return false;
    return this.isPostingDateInFuture || this.isPostingDateMoreThan30DaysAgo;
  }

  get isPostingDateInFuture(): boolean{
    var selectedDate = this.postingDate.value;
    var currentDate = new Date();
    return selectedDate > currentDate;
  }

  get isPostingDateMoreThan30DaysAgo(): boolean{
    var selectedDate = this.postingDate.value;
    var date = new Date();
    date.setDate(date.getDate()-30);
    date.setHours(0,0,0,0);
    return selectedDate<date;
  }

  // shortcuts to controls for readability
  get postingDate(): AbstractControl {
    return this.dataForm.controls['postingDate'];
  }

  ngOnInit() {
    this.loadFormDetails();
  }

  private loadFormDetails() {
    const id: number = null;

    this.viewModel = new UploadBordereauxFromEmailAttachmentModel();
    this.concurrencyErrorMessage = '';
    this.initialiseForm();
  }


  initialiseForm() {
    this.postingDate.setValue(this.viewModel.postingDate.toJSON());
    this.dataForm.markAsPristine();
  }

  private createUpdateModel() {
    return {
      postingDate: formatAsString(this.postingDate.value),
    };
  }

  save() {
    this.dataForm.updateValueAndValidity();

    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();

      this.service.uploadBordereauxFromEmailAttachment(this.data.attatchmentId, updateModel).then(() => {
        this.closeForm('update');
      });

    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?'
        }
      });

      dialogRef.componentInstance.onClose.subscribe(result => {
        if (result.result) {
          this.closeForm('cancel');
        }
      });
    } else {
      this.closeForm('cancel');
    }
  }

  closeForm(message: string) {
    this.asideService.close(message);
  }
}
