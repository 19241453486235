import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { Filter, FilterOperatorType } from 'app/shared/models/filter.model';
import { CheckListItem } from 'app/shared/models/check-list-item.model';
import { SortOrder } from 'app/shared/models/sort-order.model';
import { ApiEntity } from 'app/api/models/api-entity.model';

import { NotesFormModel } from 'app/modules/solution-shared/note/models/notes-form.model';
import { NotesListItemModel } from 'app/modules/solution-shared/note/models/notes-list-item.model';
import { NotesListItemViewModel } from 'app/modules/solution-shared/note/models/notes-list-item-view.model';
import { downloadFileFromExtendedBlob } from 'app/shared/helpers/file-download-helpers';
import { ApiResponseSummary } from 'app/shared/models/api-response-summary.model';
import { ActivityLevel } from 'app/shared/enumeration/activity-level';
import { SystemActivityType } from 'app/shared/enumeration/system-activity-types';
import { IDocumentTemplateFilterLookupModel } from 'app/modules/configuration/document-templates/models/IDocumentTemplateFilterLookupModel';

@Injectable()
export class NotesListService extends ListServiceBase {

  // TODO: [Refactor][DPB][2020-03-19] Consider whether levelParentId this should be renamed workTaskId
  private _taskParentId: number; // task id
  caseClaimId: number;
  divisionId: number;

  constructor(protected dependencies: ApiDependenciesService, protected dialog: MatDialog) {
    super(dependencies, dialog);

    this.initialiseFiltersSortAndPaging();
    console.debug("A new instance of NotesListService has been constructed.");
  }

  // populate initial filter properties
  private initialiseFiltersSortAndPaging() {
    // Add intialisation here
  }

  //#region Get Endpoints

  get baseEndpoint(): string {
    console.debug("notes-list.service.ts is assembling a URL base endpoint with task parent ID " + this.taskParentId);
    return '/work-tasks/' + this.taskParentId + '/notes';
  }
  baseEndpointWithId(id: number): string {
    return this.baseEndpoint + '/' + id;
  }

  get taskParentId(): number{
    console.debug("Task parent ID being retrieved: " + this._taskParentId);
    return this._taskParentId;
  }
  set taskParentId(value: number){
    console.debug("NotesListService task parent ID: " + this._taskParentId + " being set to: " + value);
    if(value===undefined || value===null){
      // D14846
      throw "Attempt to set taskParentId to a null or undefined value.";
    }
    this._taskParentId=value;
  }


  public getCreateWithAttachmentEndpoint(): string {
    return `${this.baseEndpoint}/note-with-attachment`;
  }

  protected getDeleteEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }

  // a number of endpoint getters are defined in ListServiceBase
  // not all endpoints will be required for all implementations

  // OVERRIDDEN from ListServiceBase
  protected getCommandStubEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }

  // OVERRIDDEN from ListServiceBase
  protected getListEndpoint(): string {
    return this.baseEndpoint + '/task-notes-list';
  }

  //#region list loading

  // OVERRIDDEN from ListServiceBase
  // Create a new ViewModel specific to this list
  // Not necessary to set the viewmodel itemId as it is set in the viewModel constructor 
  createListViewModel(dataModel: ApiEntity) {
    return new NotesListItemViewModel(dataModel as NotesListItemModel);
  }

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for each list row item
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedListItemDataModel(responseItem: any): ApiEntity {
    const model: NotesListItemModel = responseItem;
    return model;
  }

  //#region get details

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for the detail item (create/update)
  // The model must be derived from ApiEntity
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedEditableItemDataModel(response: any): ApiEntity {
    const model: NotesFormModel = response;
    return model;
  }

  //#endregion

  protected getCreateEndpoint(): string {
    return this.baseEndpoint;
  }

  protected getUpdateEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }

  // OVERRIDDEN from ListServiceBase
  protected getGetEditableItemEndpoint(itemId: number) {
    return itemId ? this.baseEndpointWithId(itemId) : this.baseEndpoint + '/blank';
  }

  async downloadNoteAttachment(itemId: number) {
    await this.setEndpoint(`/notes/${itemId}/download-attachment`)
      .getFileAsBlobWithPost()
      .then((response) => {
        downloadFileFromExtendedBlob(response);
      });
  }

  async getActivityIdFromEntityId(entityId: number): Promise<number> {
    let model: number;

    const endpoint = `/divisions/${this.divisionId}/activities-by-type/${SystemActivityType.Task}/find-by-entity/${entityId}`
    await this.setEndpoint(endpoint)
      .getSingle()
      .then((response: number) => {
        model = response;
      });
    return Promise.resolve(model);
  }


  async createActivityNote(dataToUpdate: any): Promise<number> {
    let result: number;
    const endpoint = '/divisions/' + this.divisionId + '/activities/notes';
    await this.saveItemNoReload(0, dataToUpdate, endpoint).then((newRecordId) => {
      result = newRecordId;
    });
    return Promise.resolve(result);
  }

  async updateActivityNote(itemId: number, dataToUpdate: any): Promise<ApiResponseSummary<any>> {
    let result: ApiResponseSummary<any>;

    const endpoint = '/divisions/' + this.divisionId + '/activities/notes/' + itemId;

    await this.updateItemNoReload(itemId, dataToUpdate, endpoint).then((response) => {
      result = response;
    });

    return Promise.resolve(result);
  }
}
