import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { UserListContainerService } from 'app/modules/configuration/user-list-page/user-list-container/user-list-container.service';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { passwordsMustMatch } from 'app/shared/validation/custom-validators';

@Component({
  selector: 'user-reset-password',
  templateUrl: './user-reset-password.component.html'
})
export class UserResetPasswordComponent {

  public data: any;

  dataForm: FormGroup;
  password: AbstractControl;
  confirmPassword: AbstractControl;

  constructor(fb: FormBuilder, private service: UserListContainerService, private asideService: AsideService, 
    private formHelperService: ReactiveFormHelperService, private dialog: MatDialog) {
    
    this.dataForm = fb.group({
      'password': ["", [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$')]],
      'confirmPassword': ["", [Validators.required, passwordsMustMatch()]],
    });

    this.password = this.dataForm.controls['password'];
    this.confirmPassword = this.dataForm.controls['confirmPassword'];
  }

  save() {

    // specific call to check this one field
    this.confirmPassword.updateValueAndValidity();

    if (this.formHelperService.isFormValid(this.dataForm)) {
      if (this.data.resetOwnPassword)
      {
        this.service.processChangeOwnPassword(this.data.itemId, this.password.value).then(() => {
          this.asideService.close();
        });
      }
      else{
        this.service.processChangePassword(this.data.itemId, this.password.value).then(() => {
          this.asideService.close();
         });
      }
     }
  }

  cancel() {

    let isFormDirty = this.formHelperService.isFormDirty(this.dataForm);

    if(isFormDirty){
      
      let dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width:'450px',
        data:{
          title: "Confirm Lost Changes",
          message: "Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?"
        }
      });
      dialogRef.componentInstance.onClose.subscribe(result => {
        if (result.result) {
          this.asideService.close("cancel");
        }
      });  
    }
    else{
      this.asideService.close("cancel");

    }
  
  }
}
