import { Component, OnInit, Input, Output, EventEmitter, OnChanges, Inject, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup, FormBuilder } from '@angular/forms';
import { Moment } from 'moment';

// REf: http://anasfirdousi.com/share-controlvalueaccessor-provider-creation-with-abstract-controlvalueaccessor-across-custom-form-enabled-angular-components.html
// UI need adapting for Angular Material (replace select with menu)

const customValueProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateTimeComponent),
  multi: true
};

@Component({
  selector: 'df-date-time',
  templateUrl: './df-date-time.component.html',
  styleUrls: ['./df-date-time.component.scss'],
  providers: [customValueProvider]
})
export class DateTimeComponent implements ControlValueAccessor, OnInit {
  parts: FormGroup;
  @Input() placeholder: string;
  current: Date;

  @Input()
  get value(): Date | null {
    // let n = this.parts.value;
    // if (n.area.length == 3 && n.exchange.length == 3 && n.subscriber.length == 4) {
    //   return new MyTel(n.area, n.exchange, n.subscriber);
    // }
    return new Date(2011, 11, 11);
  }
  set value(tel: Date | null) {
    // tel = tel || new MyTel('', '', '');
    // this.parts.setValue({area: tel.area, exchange: tel.exchange, subscriber: tel.subscriber});
  }

  constructor(fb: FormBuilder) {
    this.parts = fb.group({
      'date': '',
      'hour': '',
      'minute': '',
    });

    this.parts.controls['date'].valueChanges.subscribe(() => this.onDateChanged());
    this.parts.controls['hour'].valueChanges.subscribe(() => this.onDateChanged());
    this.parts.controls['minute'].valueChanges.subscribe(() => this.onDateChanged());
  }
  ngOnInit(): void {
    //this.current = { value: '', value2: '', rule: this.rule };
  }

  onDateChanged() {
    const m: Moment = this.parts.controls['date'].value;
    const newDate: Date = m.toDate();
    const hh = +this.parts.controls['hour'].value;
    const mm = +this.parts.controls['minute'].value;
    if (hh !== NaN) {
      newDate.setHours(hh);
    }
    if (mm !== NaN) {
      newDate.setMinutes(mm);
    }

    this.propagateChange(newDate);
  }

  propagateChange: any = () => { };

  writeValue(value: any): void {
    if (value) {
      this.current = value;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void { }

  setDisabledState?(isDisabled: boolean): void {

  }

  keyUp(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.keyCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
}
