import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { BordereauxImportListItemModel } from './bordereaux-import-list-item.model';
import { ListItemViewBaseModel } from 'app/shared/models/list-item-view-base.model';
import { BordereauxImportListGroupItemModel } from './bordereaux-import-list-groupitem.model';
import { RagStatus } from './rag-status';

export class BordereauxImportListGroupItemViewModel extends ListItemViewBaseModel<BordereauxImportListGroupItemModel> {
  expanded: boolean = false;
  
  constructor(dataModel: BordereauxImportListGroupItemModel) {
    super(dataModel);  
  
  }

  get ragIconClass(): string{
    switch(this.dataModel.ragIndicator)
    {
      case RagStatus.Red:
        return "icon-red";
      case RagStatus.Amber:
        return "icon-amber";
      case RagStatus.Green:
        return "icon-green";
      default:
          console.warn("Unrecognized RAG Status: " + this.dataModel.ragIndicator)
    }
  }

  get ragTipClass(): string{
    switch(this.dataModel.ragIndicator)
    {
      case RagStatus.Red:
        return "rag-popover-content tip-red";
      case RagStatus.Amber:
        return "rag-popover-content tip-amber";
      case RagStatus.Green:
        return "rag-popover-content tip-green";
      default:
          console.warn("Unrecognized RAG Status: " + this.dataModel.ragIndicator)
    }
  }
}

