import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators, FormArray, ValidatorFn } from '@angular/forms';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivityDocumentPackService } from '../services/activity-document-pack.service';

import { ActivityTimerHubService } from 'app/shared/services/signalr.service';
import { ActivityDocumentPackCreateFormModel } from '../models/activity-document-pack-create-form.model';
import { SystemActivityType } from 'app/shared/enumeration/system-activity-types';

@Component({
  selector: 'activity-document-pack-create-form',
  templateUrl: './activity-document-pack-create-form.component.html'
})

export class ActivityDocumentPackCreateFormComponent implements OnInit {
  public data: any;
  saveText: string;
  closeText: string;

  documentPackDefinitionLookupData: any[];
  viewModel = new ActivityDocumentPackCreateFormModel();
  dataForm: FormGroup;

  startTime: Date;
  stopTime: Date;

  constructor(
    private fb: FormBuilder,
    private service: ActivityDocumentPackService,
    private asideService: AsideService,
    private activityHubService: ActivityTimerHubService,
    private formHelperService: ReactiveFormHelperService,
    private dialog: MatDialog
  ) {
    this.startTime = new Date();

    this.dataForm = fb.group({
        documentPackDefinitionId: ['', [Validators.required]]
    });
  }

  // short cuts to controls for readability
  get documentPackDefinitionId(): AbstractControl {
    return this.dataForm.controls['documentPackDefinitionId'];
  }

  ngOnInit() {
    this.service.activityType = this.data.activityType;

    this.loadFormDetails();
  }

  private loadFormDetails() {
    this.saveText = 'Create';

    this.service.getEditableItemDetail(this.data.itemId).then((response: ActivityDocumentPackCreateFormModel) => {
      this.viewModel = response;
      if (this.viewModel) {
        this.closeText = 'Cancel';
        this.viewModel.levelParentId = this.data.levelParentId;
        this.viewModel.activityLevel = this.data.activityLevel;
        this.initialiseForm();
      }
    });
  }

  initialiseForm() {
    if (this.viewModel.documentPackDefinitionLookupData) {
      this.documentPackDefinitionLookupData = this.formHelperService.createLookupData(this.viewModel.documentPackDefinitionLookupData);
    }
    
    this.dataForm.markAsPristine();
  }

  private createUpdateModel() {
    this.stopTime = new Date();

    return {
      id: this.data.itemId,
      documentPackDefinitionId: this.documentPackDefinitionId.value,
      activityLevel: this.data.activityLevel,
      levelParentId: this.data.levelParentId
    };
  }

  save() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();

    this.service.addNew(updateModel).then(newRecordId => {
        this.activityHubService.requestActivityTimer(newRecordId, 'Document Pack Create');
        this.asideService.close('update');
    });
      
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?'
        }
      });

      dialogRef.componentInstance.onClose.subscribe(result => {
        if (result.result) {
            this.asideService.close('cancel');
        }
      });
    } else {
        this.asideService.close('cancel');
    }
  }
}
