import { ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import { AddListComponent } from 'app/shared/components/add-list/add-list.component';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ComponentItem } from 'app/shared/base/aside/component-item';
import { IconActionButtonEventMessage } from 'app/shared/components/icon-action-button-list/icon-action-button-list-message.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { IAddListService } from 'app/shared/interfaces/IAddListService.interface';

import { CaseActivityTimeSearchListListService } from 'app/modules/solution-shared/case-activity-time-search/services/case-activity-time-search-list-list.service';
import { ActivityTimerHubService } from 'app/shared/services/signalr.service';
import { MessageFromServer } from 'app/modules/sandbox/signalr-winservice/services/signalr-winservice.service';
import { SystemActivityType } from 'app/shared/enumeration/system-activity-types';
import CaseActivityHelper from '../../case-activity/helpers/case-activity-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'case-activity-time-search-list-list',
  templateUrl: './case-activity-time-search-list-list.component.html',
  styleUrls: ['./case-activity-time-search-list-list.component.scss']
})
export class CaseActivityTimeSearchListListComponent extends AddListComponent implements OnDestroy {
  divisionId: number;

  private sort: MatSort;
  private paginator: MatPaginator;
  matSortSubscribed;
  matPaginatorSubscribed;

  @Output() approvalChanged = new EventEmitter<void>();
  displayedColumns: string[] = [
    'select',
    'timerControls',
    'totalTimeSort',
    'activityTypeName',
    'summary',
    'caseReference',
    'caseType',
    'productOption',
    'activityDate'
  ];


  activityHubMessageRecievedSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    public listService: CaseActivityTimeSearchListListService,
    private activityTimerHubService: ActivityTimerHubService,
    private asideService: AsideService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {
    super();

    this.loadAfterInit = false;
    this.isRowSelectable = true;

    /**get parent id form the route */
    route.params.subscribe(params => {
      this.divisionId = params['divisionId'];
      this.listService.divisionId = this.divisionId;
      if(this.divisionId===undefined) debugger;
    });

    this.activityHubMessageRecievedSubscription = activityTimerHubService.onMessageReceivedFromServer$.subscribe((message: MessageFromServer) => {
      this.processActivityHubMessage(message);
    });
  }

  deselectActiveItemAndSelectAll() {
    this.selectedRowId = null;
    //this.selectAll = false;
  }

  listReloadedHandler(listItemCount) {
    super.listReloadedHandler(listItemCount);

    if (!this.changeDetectorRefs['destroyed']) {
      this.changeDetectorRefs.detectChanges();
    }
  }

  processActivityHubMessage(message: MessageFromServer) {
    switch (message.method) {
      case 'ActivityTimerStatusUpdate':
        this.listService.updateTimer(message.data);

        break;
      case 'UserActivityStatusUpdate':
        message.data.forEach(activityUpdate => {
          this.listService.updateTimer(activityUpdate);
        });
        break;

      default:
        break;
    }
  }

  iconFromActivityType(systemActivityType: SystemActivityType): string {
    return CaseActivityHelper.iconFromActivityType(systemActivityType);
  }

  allowControl(systemActivityType: SystemActivityType): boolean {
    return CaseActivityHelper.canControlTimer(systemActivityType);
  }

  protected get service(): IAddListService {
    return this.listService;
  }

  @ViewChild(MatSort, { static: false })
  set matSort(ms: MatSort) {
    this.sort = ms;
    if (this.sort) {
      if (this.matSortSubscribed) this.matSortSubscribed.unsubscribe();
      this.matSortSubscribed = this.sort.sortChange.subscribe(e => {
        this.originalSort(e.active, e.direction);
      });
    }
  }

  @ViewChild(MatPaginator, { static: false })
  set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    if (this.paginator) {
      if (this.matPaginatorSubscribed) this.matPaginatorSubscribed.unsubscribe();

      this.matPaginatorSubscribed = this.paginator.page.subscribe(e => {
        this.service.ListRequest.pageNumber = e.pageIndex + 1;
        this.service.ListRequest.pageSize = e.pageSize;
        this.pageChanged();
      });
    }
  }

  timerClicked($event, id: number) {
    this.activityTimerHubService.timerChangeEvent({ userId: null, caseActivityId: id, event: $event });
  }

  get caseActivityTimeSearchListListService() {
    return this.service as CaseActivityTimeSearchListListService;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnInit() {
    super.ngOnInit();
  }
  ngOnDestroy() {
    this.changeDetectorRefs.detach();
    this.activityHubMessageRecievedSubscription.unsubscribe();
  }

  pageChanged() {
    this.service.reload();
  }

  originalSort(column: string, direction: string) {
    this.service.setSort(column, direction);
  }

  removeApproval() {
    this.setApproval(false);
  }

  approve() {
    this.setApproval(true);
  }

  private setApproval(approve: boolean) {
    this.listService.approveForInvoice(approve, this.listService.getSelectedItemIds()).then(() => {
      
      this.approvalChanged.emit();
    });
  }

  get noItemsSelected(): boolean {
    return this.listService.getSelectedItemIds().length === 0;
  }

  addNew() { }

  doIconButtonAction(message: IconActionButtonEventMessage) {
    switch (message.action) {
      case 'play': {
        this.caseActivityTimeSearchListListService.play(message.data).then(() => { });

        break;
      }
      default: {
        console.log('Unsupported action: ' + message.action);
        break;
      }
    }
  }
}
