import { Injectable, ViewContainerRef } from "@angular/core";
import { DataService } from "./data.service";
import { ApiInfo } from "../models/api-info.model";
import { ApiDependenciesService } from "app/api/services/api-dependencies.service";

@Injectable()
export class ApplicationService extends DataService<any> {
  vcr: ViewContainerRef;
  constructor(dependencies: ApiDependenciesService) {
    super(dependencies);
  }
  async getBuildVersion(): Promise<ApiInfo> {
    let model: ApiInfo;
    await this.setEndpoint("/application/version")
      .getSingle()
      .then(response => {
        model = new ApiInfo(this.baseApiUrl, response.major + "." + response.minor + "." + response.build + "." + response.revision);
      });
    return Promise.resolve(model);
  }
}
