import { ChangeDetectorRef, Component, Injector, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { CaseActivityFormComponent } from 'app/modules/solution-shared/case-activity/case-activity-form/case-activity-form.component';
import { ActivityTaskListService } from 'app/modules/solution-shared/case-claim/case-claim-detail/case-claim-detail-tasks/services/case-claim-task-list.service';
import { ActivityTaskFormComponent } from 'app/modules/solution-shared/case-claim/case-claim-detail/case-claim-detail-tasks/task-form/case-claim-task-form.component';
import { ComponentItem } from 'app/shared/base/aside/component-item';
import { AddListComponent } from 'app/shared/components/add-list/add-list.component';
import { IconActionButtonEventMessage } from 'app/shared/components/icon-action-button-list/icon-action-button-list-message.model';
import { SystemActivityType } from 'app/shared/enumeration/system-activity-types';
import { IAddListService } from 'app/shared/interfaces/IAddListService.interface';
import { ActivityTimerHubService, MessageFromServer } from 'app/shared/services/signalr.service';
import { SnackBarService } from 'app/shared/services/snack-bar.service';
import { TelephonyService } from 'app/shared/services/telephony.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivityNoteFormComponent } from '../../note/case-note-form/case-note-form.component';
import { ActivityEmailComponent } from '../activity-email/activity-email.component';
import { ActivityDocumentCreateFormComponent } from '../case-activity-document-create-form/case-activity-document-create-form.component';
import { ActivityDocumentUploadFormComponent } from '../case-activity-document-upload-form/case-activity-document-upload-form.component';
import CaseActivityHelper from '../helpers/case-activity-helper';
import { TimerListService } from '../services/timer-list.service';

@Component({
  selector: 'case-activity-timer-list',
  templateUrl: './case-activity-timer-list.component.html',
  styleUrls: ['./case-activity-timer-list.component.scss']
})
export class CaseActivityTimerListComponent extends AddListComponent implements OnChanges, OnDestroy {
  @Input() divisionId: number;

  private sort: MatSort;
  private paginator: MatPaginator;
  matSortSubscribed;
  matPaginatorSubscribed;

  displayedColumns = ['timerControls', 'elapsedTime', 'activityTypeName', 'caseReference', 'summary', 'activityDate', 'actions'];

  private ngUnsubscribe: Subject<boolean> = new Subject();

  activityHubMessageRecievedSubscription: Subscription;
  constructor(
    private listService: TimerListService,
    private asideService: AsideService,
    private taskListService: ActivityTaskListService,
    private activityTimerHubService: ActivityTimerHubService,
    public telephonyService: TelephonyService,
    public snackBar: SnackBarService,
    private changeDetectorRefs: ChangeDetectorRef,
    private injector: Injector
  ) {
    super();

    this.loadAfterInit = false;
    this.activityHubMessageRecievedSubscription = activityTimerHubService.onMessageReceivedFromServer$.subscribe(
      (message: MessageFromServer) => {
        this.processActivityHubMessage(message);
      }
    );
  }

  listReloadedHandler(listItemCount) {
    super.listReloadedHandler(listItemCount);

    this.activityTimerHubService.requestActivityStatus();
  }

  itemChangedHandler(item: number) {
    this.itemChanged.emit(item);

    if (!this.changeDetectorRefs['destroyed']) {
      this.changeDetectorRefs.detectChanges();
    }
  }

  reloadTimerList() {
    this.listService.reload();
  }
  processActivityHubMessage(message: MessageFromServer) {
    switch (message.method) {
      case 'ActivityTimerStatusUpdate': // periodic update of timers currently recording
        console.log('update timer status = ' + message.data.Status + ', activityId = ' + message.data.CaseActivityId);
        this.listService.updateTimer(message.data);
        break;

      case 'UserActivityStatusUpdate': // response following a requets after a reload of the whole list
        // const rec = message.data.filter(f => f.status === TimerControlStatus.Recording).map(m => m.id);
        // console.log(rec);
        message.data.forEach(activityUpdate => {
          this.listService.updateTimer(activityUpdate);
        });
        break;
      case 'ActivityListChanged': // notification that the list has chnaged and should be reloaded e.g. after a "stop"
        this.listService.reload();
        break;
      case 'PauseAllComplete': // notification when pause all has completed so that dnd can be set if required
        if (message.data.RequiresSignOut === false) {
          if (this.telephonyService.isLoggedIn) {
            this.telephonyService.setDnd(true);
          } else {
            // this.snackBar.info('Telephony: Set DND On');
          }
        }
        break;

      default:
        break;
    }
  }

  iconFromActivityType(systemActivityType: SystemActivityType): string {
    return CaseActivityHelper.iconFromActivityType(systemActivityType);
  }

  allowControl(systemActivityType: SystemActivityType): boolean {
    return CaseActivityHelper.canControlTimer(systemActivityType);
  }

  protected get service(): IAddListService {
    return this.listService;
  }

  @ViewChild(MatSort, { static: false })
  set matSort(ms: MatSort) {
    this.sort = ms;
    if (this.sort) {
      if (this.matSortSubscribed) {
        this.matSortSubscribed.unsubscribe();
      }
      this.matSortSubscribed = this.sort.sortChange.subscribe(e => {
        this.originalSort(e.active, e.direction);
      });
    }
  }

  @ViewChild(MatPaginator, { static: false })
  set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    if (this.paginator) {
      if (this.matPaginatorSubscribed) {
        this.matPaginatorSubscribed.unsubscribe();
      }

      this.matPaginatorSubscribed = this.paginator.page.subscribe(e => {
        this.service.ListRequest.pageNumber = e.pageIndex + 1;
        this.service.ListRequest.pageSize = e.pageSize;
        this.pageChanged();
      });
    }
  }

  timerClicked($event, id: number) {
    this.activityTimerHubService.timerChangeEvent({ userId: null, caseActivityId: id, event: $event });
  }

  ngOnChanges(): void {
    if (this.divisionId) {
      this.listService.divisionId = +this.divisionId;
      this.listService.reload();
    }
    // if (this.caseClaimId) {
    //   this.listService.caseClaimId = this.caseClaimId;
    //   this.listService.divisionId = +this.divisionId;
    //   this.listService.reload();

    //   this.taskListService.caseClaimId = this.caseClaimId;
    //   this.noteListService.caseClaimId = this.caseClaimId;
    //   this.taskListService.divisionId = +this.divisionId;
    // }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.asideService.asideClose.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data === 'update') {
        this.listService.reload();
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.changeDetectorRefs.detach();
    this.activityHubMessageRecievedSubscription.unsubscribe();
  }

  ngOnInit() {
    this.loadAfterInit = this.divisionId !== undefined;

    super.ngOnInit();
    this.listService.divisionId = +this.divisionId;
    this.taskListService.divisionId = +this.divisionId;
    //    this.noteListService.caseClaimId = this.caseClaimId;
  }

  pageChanged() {
    this.service.reload();
  }

  originalSort(column: string, direction: string) {
    this.service.setSort(column, direction);
  }

  editItem(message: IconActionButtonEventMessage) {
    const item = this.listService.findViewModelFromItemId(message.data);

    switch (item.dataModel.systemActivityType) {
      case SystemActivityType.Task:
        this.asideService.setAndOpen(
          new ComponentItem(ActivityTaskFormComponent, { itemId: item.dataModel.activityEntityId, activityId: message.data }),
          this.injector
        );
        break;
      case SystemActivityType.Note:
        this.asideService.setAndOpen(
          new ComponentItem(ActivityNoteFormComponent, { itemId: item.dataModel.activityEntityId, activityId: message.data }),
          this.injector
        );
        break;
      case SystemActivityType.DocumentUpload:
        this.asideService.setAndOpen(
          new ComponentItem(ActivityDocumentUploadFormComponent, {
            itemId: item.dataModel.activityEntityId,
            activityId: message.data
          }),
          this.injector
        );
        break;
      case SystemActivityType.EmailInbound:
        this.snackBar.info('Inbound emails cannot be edited');
        break;
      case SystemActivityType.EmailOutbound:
        this.asideService.setAndOpen(
          new ComponentItem(ActivityEmailComponent, { itemId: item.dataModel.activityEntityId, activityId: message.data }),
          this.injector
        );
        break;
      case SystemActivityType.DocumentCreate:
        this.asideService.setAndOpen(
          new ComponentItem(ActivityDocumentCreateFormComponent, {
            itemId: item.dataModel.activityEntityId,
            activityId: message.data,
            divisionId: this.divisionId,
          }),
          this.injector
        );
        break;
      case SystemActivityType.PhoneCallInbound:
      case SystemActivityType.PhoneCallOutbound:
        this.snackBar.info('Phone calls cannot be edited');
        break;
      default:
        this.asideService.setAndOpen(
          new ComponentItem(CaseActivityFormComponent, { itemId: message.data, activityTypeName: item.dataModel.activityTypeName }),
          this.injector
        );
        break;
    }
  }

  doIconButtonAction(message: IconActionButtonEventMessage) {
    switch (message.action) {
      case 'download': {
        // this.caseActivityListService.download(message.data).then(() => {});
        this.snackBar.info('Not implemented yet');

        break;
      }
      case 'edit': {
        this.editItem(message);
        break;
      }
      case 'notes': {
        // this.caseActivityListService.notes(message.data).then(() => {});
        this.snackBar.info('Not implemented yet');

        break;
      }
      case 'reassociate': {
        this.snackBar.info('Not implemented yet');
        break;
      }
      default: {
        console.log('Unsupported action: ' + message.action);
        break;
      }
    }
  }
}
