// Copied (with slight C# to TS conversions) from ServiceLibrary ClientContract/Types

export enum CallEventType {
    CallAnswered = 1,
    CallCleared = 2,
    CallHeld = 3,
    CallRetrieved = 4,
    CallRinging = 5,
    CallTransferred = 6,

    /// The transferred call events match up to the standard call events plus this value.
    TransferredCallOffset = 16,
    TransferredCallCleared = TransferredCallOffset + CallCleared,
    TransferredCallHeld = TransferredCallOffset + CallHeld,
    TranferredCallRetrieved = TransferredCallOffset + CallRetrieved,
    TransferedCallRinging = TransferredCallOffset + CallRinging,
    TransferedCallTransferred = TransferredCallOffset + CallTransferred,
}

export class CallEvent {
    EventType: CallEventType;
    CallDetails: CallDetails;
}

export enum CallDirections {
    Inbound = 0,
    Outbound = 1,
    Both = 2,
    None = 3,
    External = 4
}

export enum CallStates
{
    NoConnection = 0,
    OffHook = 1,
    Ringing = 2,
    InProgress = 3,
    OnHold = 4,
    Engaged = 5,
    Queued = 6,
    Failed = 7,
    ConferenceCall = 8,
    Offered = 9
}

/**
 * Maps to the Xarios CallDetails object. This is a subset though, as there are a lot of properties and we will likely only need a few of them.
 * This is a work in progress and new properties will be added over time.
 * */
export class CallDetails {
    AccountCode: string;
    ActualCLI: string;    // not sure how this differs from the CLI property
    CallDirection: CallDirections;
    CallId: string;
    CallIndex: number;
    CallState: CallStates;
    CalledNumber: string;
    CallHeld: boolean;
    CLI: string;
    CurrentHoldTimeMs: number;

    StartedAt: Date;
    EndedAt: Date;
    AnsweredAt: Date;
    TotalHoldTimeSeconds: number;
    TalkTimeSeconds: number;
}

export class CallRecordingDetails
{
    AccountCode: string;
    CallId: string;
    RecorderServer: string;
    RecId: number;
}

export class DndInfo
{
    /**
     * True if do-not-disturb is turned on, otherwise false.
     */
    State: boolean;
    /**
     * The do-not-disturb status message / reason.
     */
    Message: string;
}