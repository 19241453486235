import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'snack-bar',
  templateUrl: './snack-bar.component.html',
  styles: [
    `
      .snack-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 300px;
      }
      .title {
        font-weight: bold;
      }
      .body {
        padding-left: 15px;
      }
    `
  ]
})
export class SnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snackBar: MatSnackBar) {}
  public dismissSnackbar(): void {
    this.snackBar.dismiss();
  }
}
