import { FormGroup } from "@angular/forms";
import { Filter } from "app/shared/models/filter.model";
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'filter-fields-base',
    template: '', // no template as provided in derived component
})

export class FilterFieldsBaseComponent {
    dataForm: FormGroup; // the fields defining the filter to apply

    @Output() filterChanged = new EventEmitter<void>();

    constructor() {

    }

    //OVERRIDE in derived class
    getFilters(): Array<Filter>{
        return new Array<Filter>();
    }

    markPristine(){
        this.dataForm.markAsPristine();
    }

    hasFilters(): boolean{
        return this.dataForm.dirty;
    }

    //OVERRIDE in derived class
    resetToDefault(){

    }

    notifyFilterChanged(){
        this.filterChanged.emit();
    }
}
