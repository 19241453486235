import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ActivityTimerHubService } from 'app/shared/services/signalr.service';
import { TimerListService } from '../services/timer-list.service';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';

@Component({
  selector: 'case-activity-pause-all',
  templateUrl: './case-activity-pause-all.component.html',
  styles: [
    `
      .form-container {
        padding: 10px 15px;
        background-color: #fff;
      }
    `
  ]
})
export class CaseActivityPauseAllComponent implements OnChanges {
  @Input() divisionId: number;

  model: any; //CaseActivityListFilterModel = new CaseActivityListFilterModel();
  showDndTimes = false;
  reasonLookupData: any[] = [];
  periodLookupData: any[] = [];

  dataForm: FormGroup;
  get reason(): AbstractControl {
    return this.dataForm.controls['reason'];
  }
  get period(): AbstractControl {
    return this.dataForm.controls['period'];
  }

  constructor(
    private fb: FormBuilder,
    private activityListService: TimerListService,
    private timerHubService: ActivityTimerHubService,
    private formHelperService: ReactiveFormHelperService
  ) {
    this.dataForm = this.fb.group({
      reason: [''],
      period: ['']
    });

    this.reason.valueChanges.subscribe(() => this.onReasonChange());
  }

  private onReasonChange() {
    this.showDndTimes = this.reason.value == null ? false : this.selectdReasonRequiredSignOut === false;
  }

  get anyActivitiesRecording(): boolean {
    return this.activityListService.recordingCount > 0;
  }

  private get selectdReasonRequiredSignOut(): boolean {
    const reasonId = +this.reason.value;
    const reason = this.model.reasonLookupData.items.find(f => f.id === reasonId);
    return reason.data.signOutRequired;
  }

  private LoadLookupData() {
    if (this.divisionId) {
      this.activityListService.divisionId = +this.divisionId;
      this.activityListService.getPauseAllLookupData().then(response => {
        this.model = response;
        this.reasonLookupData = this.formHelperService.createLookupData(this.model.reasonLookupData);
        this.periodLookupData = this.formHelperService.createLookupData(this.model.periodLookupData);
      });
    }
  }

  ngOnChanges(): void {
    this.LoadLookupData();
  }

  pauseAll() {
    const selectedPeriod = this.period.value ? +this.period.value : null;
    this.timerHubService.pauseAllTimers({ reason: this.reason.value, period: selectedPeriod });
    this.period.setValue(null);
    this.reason.setValue(null);
  }

  get canClickPauseAll(): boolean {
    return this.reason.value && (this.selectdReasonRequiredSignOut === true || this.period.value);
  }

  reloadTimerList() {
    this.activityListService.reload();
  }
}
