// Copied (with slight C# to TS conversions) from ServiceLibrary ClientContract/Messages/InboundMessageParams

export class LoginExtensionArgs
{
    /**
     * The telephone extension to log in with. Optional. If not specified, the default extension in the companion app will be used instead.
     */
    public Extension: string;
    /**
     * If true, will kick out any existing user that is logged in to the companion app with this extension.
     * If false and another user is logged on, the request will fail.
     * This mostly affects multiple web browser tabs. If the same person is already logged into the phone system in another tab, this will log them out in that
     * tab and log us in with the new tab.
     * */
    public KickExistingUser: boolean;

    /**
     *  If set, will override the default username configured in the application for the account to use to connect to the Mitel server.
     * */
    public Username: string;

    /**
     *  If Username is set, this should also be set to the appropriate password.
     * */
    public Password: string;
}

export class ModifyCallDisplayArgs
{
    /** A message to display on the physical phone. */
    public Message: string;
    /** Optional. No testing has been done around this so not totally sure how it works. */
    public CallIndex: number;
}

export class MakeCallArgs
{
    /** The phone number to call. */
    public DestinationPhoneNumber: string;
    /** Optional account code to insert into SMDR data. Can be used to later associate calls from the Mitel system output to call records created by the application. */
    public AccountCode: string;
}

export class AnnouncedTransferArgs
{
    public DestinationPhoneNumber: string;
    /** Index of the call to transfer. Believed to be mandatory. */
    public CallIndexToTransfer: number;
}

export class TransferCallArgs
{
     public TransferToHold: boolean;
     /** Optional. No testing has been done around this so not totally sure how it works. */
     public HeldCallIndex: number;
}

export class QueryRecordingDetailsArgs
{
    /**
     * If the recording details are unavailable, a non-zero value here will cause the attempt to get the recording to be repeated the specified number of times.
     * */
    public MaxRetriesIfRecordingUnavailable: number;
    /**
     * The number of milliseconds to wait between polls if MaxRetriesIfRecordingUnavailable is >0.
     * */
    public DelayBetweenPollsMs: number;
}