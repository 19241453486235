import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'concurrency-error',
  template: `
    <div *ngIf="message" class="concurrency-error-container">
      <div class="message">{{ message }}</div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" >
        <button mat-button (click)="reload()"><mat-icon>refresh</mat-icon>Reload</button>
      </div>      
    </div>
  `,
  styles: [
    `
      .concurrency-error-container {
        margin-bottom: 5px;
      }
      .message {
        background-color: #c00;
        color: #fff;
        font-size: 0.9em;
        padding: 5px 10px;
        margin-bottom: 3px;
      }
      mat-icon {
        font-size: 18px;
      }
    `
  ]
})
export class ConcurrencyErrorComponent {
  @Input() message = '';
  @Output() reloadRequested = new EventEmitter<void>();

  constructor() {}

  reload() {
    this.reloadRequested.emit();
  }
}
