import { Division } from './division';

export enum ActivityLevel {
  Client = 1,
  Product = 2,
  Case = 3,
}

export function activityLevelDisplay(activityLevel: ActivityLevel, division: Division): string {
  const activity = ActivityLevel[activityLevel];

  if (+division === Division.FinanceAndLegal) {
    switch (+activityLevel) {
      case ActivityLevel.Case:
        return 'Claim';
      case ActivityLevel.Product:
        return 'Policy';
      case ActivityLevel.Client:
        return 'Client';
    }
  }
  return activity;
}
