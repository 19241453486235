import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { ActivityEmailAttachmentListItemModel } from './activity-email-attachment-list-item.model';

export class ActivityEmailAttachmentListItemViewModel extends ActionableItemState {
  downloadButton: IIconActionButton;
  viewFileButton: IIconActionButton;
  bordereauxImportButton: IIconActionButton;
  expanded = false;

  constructor(dataModel: ActivityEmailAttachmentListItemModel) {
    super();

    this.dataModel = dataModel;

    this.downloadButton = {
      visible: true,
      enabled: true,
      actionEnabled: true,
      actionDisabledMessage: '',
      iconName: 'cloud_download',
      actionName: 'download',
      hoverText: 'Download the item',
      class: '',
      buttonText: '',
    };
    this.processActions.push(this.downloadButton);

    this.viewFileButton = {
      visible: true,
      enabled: true,
      actionEnabled: true,
      actionDisabledMessage: '',
      iconName: 'visibility',
      actionName: 'viewFile',
      hoverText: 'Open file in browser',
      class: '',
      buttonText: '',
    };
    this.processActions.push(this.viewFileButton);

    if(dataModel.showBordereauxUploadButton)
    {
      this.bordereauxImportButton = {
        visible: true,
        enabled: dataModel.fileTypeAllowedForBordereaux,
        actionEnabled: true,
        actionDisabledMessage: "Must have a CSV or XLSX file attachment in order to upload",
        iconName: 'import_export',
        actionName: 'bordereauxImport',
        hoverText: 'Upload Bordereaux',
        class: '',
        buttonText: '',
      };
      this.processActions.push(this.bordereauxImportButton);
    }

  }

}
