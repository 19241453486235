import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { CaseClaimTaskFormModel } from 'app/modules/solution-shared/case-claim/case-claim-detail/case-claim-detail-tasks/models/case-claim-task-form.model';
import { ActivityTaskListService } from 'app/modules/solution-shared/case-claim/case-claim-detail/case-claim-detail-tasks/services/case-claim-task-list.service';
import { UsersService } from 'app/modules/user-management/services/users.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { TaskOwnerType } from 'app/shared/enumeration/task-owner-type';
import { formatAsString, toFormattedDateString } from 'app/shared/helpers/date-helpers';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { ActivityTimerHubService } from 'app/shared/services/signalr.service';
import { dateGreaterThanOrEqualToControl } from 'app/shared/validation/custom-validators';


@Component({
  selector: 'case-claim-task-form',
  templateUrl: './case-claim-task-form.component.html',
  styles: [
    `
      h4 {
        margin-block-start: 0.1em;
        margin-block-end: 0.3em;
      }
    `
  ]
})

export class ActivityTaskFormComponent implements OnInit {
  public data: any;
  saveText: string;
  editMode: boolean;

  statusLookupData: any[];
  assigneeIdLookupData: any[];

  hoursLookupData: string[];

  minsLookupData: string[];

  viewModel: CaseClaimTaskFormModel;

  dataForm: FormGroup;

  startTime: Date;
  stopTime: Date;

  constructor(
    private fb: FormBuilder,
    private service: ActivityTaskListService,
    private asideService: AsideService,
    private activityHubService: ActivityTimerHubService,
    private formHelperService: ReactiveFormHelperService,
    private dialog: MatDialog,
    private usersService: UsersService
  ) {
    this.startTime = new Date();

    this.dataForm = fb.group({
      description: ['', [Validators.required, Validators.maxLength(200)]],
      startDate: ['', [Validators.required]],
      dueDate: ['', [Validators.required, dateGreaterThanOrEqualToControl('startDate')]],
      status: ['', [Validators.required]],
      assigneeId: ['', [Validators.required]],
      note: ['', [Validators.maxLength(1000)]],
      dueDateHour: ['', [Validators.required]],
      dueDateMinute: ['', [Validators.required]]
    });
  }

  // shortcuts to controls for readability
  get description(): AbstractControl {
    return this.dataForm.controls['description'];
  }
  get startDate(): AbstractControl {
    return this.dataForm.controls['startDate'];
  }
  get dueDate(): AbstractControl {
    return this.dataForm.controls['dueDate'];
  }
  get status(): AbstractControl {
    return this.dataForm.controls['status'];
  }
  get assigneeId(): AbstractControl {
    return this.dataForm.controls['assigneeId'];
  }
  get note(): AbstractControl {
    return this.dataForm.controls['note'];
  }
  get dueDateHour(): AbstractControl {
    return this.dataForm.controls['dueDateHour'];
  }
  get dueDateMinute(): AbstractControl {
    return this.dataForm.controls['dueDateMinute'];
  }

  get disableTaskOwnerControl(): boolean {
    return this.editMode && this.isUserAdmin === false;
  }

  ngOnInit() {
    this.editMode = this.data.itemId > 0;
    this.saveText = this.editMode ? 'Update' : 'Create';

    if (this.editMode) {
      this.activityHubService.startRecording(this.data.activityId);
    }

    const id: number = this.editMode ? this.data.itemId : null;

    this.service.getEditableItemDetail(id).then((response: CaseClaimTaskFormModel) => {
      this.viewModel = response;
      if (this.viewModel) {
        this.viewModel.levelParentId = this.data.levelParentId;
        this.viewModel.activityLevel = this.data.activityLevel;
        this.initialiseForm();
      }
    });
  }

  initialiseForm() {
    this.startDate.valueChanges.subscribe(value => this.onStartDateChanged());

    this.statusLookupData = this.formHelperService.createLookupData(this.viewModel.statusLookupData);
    this.assigneeIdLookupData = this.service.createGroupedLookupDataForAssignee(this.viewModel.assigneeIdLookupData);

    this.hoursLookupData = [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23'
    ];

    this.minsLookupData = ['00', '15', '30', '45'];

    this.dataForm.controls['description'].setValue(this.viewModel.description);
    this.dataForm.controls['startDate'].setValue(this.viewModel.startDate);
    this.dataForm.controls['dueDate'].setValue(this.viewModel.dueDate);
    this.dataForm.controls['status'].setValue(this.viewModel.status);
    if (this.viewModel.dueDate !== undefined) {
      const dueDate = new Date(this.viewModel.dueDate);
      const dueDateHour = ('0' + dueDate.getHours()).slice(-2);
      const dueDateMin = ('0' + dueDate.getMinutes()).slice(-2);
      this.dataForm.controls['dueDateHour'].setValue(dueDateHour);
      this.dataForm.controls['dueDateMinute'].setValue(dueDateMin);
    }

    const correctGroup = this.assigneeIdLookupData.find(groupedList =>
      this.viewModel.taskOwnerType === TaskOwnerType.Individual ? groupedList.name === 'Users' : groupedList.name === 'Roles'
    );

    const defaultAssignee = correctGroup.items.find(item => item.id === this.viewModel.assigneeId);

    this.dataForm.controls['assigneeId'].setValue(defaultAssignee);
    this.dataForm.controls['note'].setValue(this.viewModel.note);
  }

  get isUserAdmin(): boolean {
    return this.usersService.userPermissions.action.task.allowUserToUpdateTaskOwner;
  }

  onStartDateChanged() {
    this.dueDate.updateValueAndValidity();
  }

  private createUpdateModel() {
    this.stopTime = new Date();
    const dueDateAndTime = new Date(this.dueDate.value);
    dueDateAndTime.setHours(this.dueDateHour.value === '00' ? '0' : this.dueDateHour.value);
    dueDateAndTime.setMinutes(this.dueDateMinute.value === '00' ? '0' : this.dueDateMinute.value);

    return {
      description: this.description.value,
      startDate: formatAsString(this.startDate.value),
      dueDate: formatAsString(dueDateAndTime.toString(), 'YYYY-MM-DD HH:mm'),
      status: this.status.value,
      assigneeId: this.assigneeId.value.id,
      taskOwnerType: this.assigneeId.value.isUser ? TaskOwnerType.Individual : TaskOwnerType.Pool,
      note: this.note.value,
      startTime: toFormattedDateString(this.startTime, 'YYYY-MM-DD HH:mm:ss'),
      stopTime: toFormattedDateString(this.stopTime, 'YYYY-MM-DD HH:mm:ss'),
      timestamp: this.viewModel.timestamp,
      levelParentId: this.data.levelParentId,
      activityLevel: this.data.activityLevel
    };
  }

  save() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();
      if (this.editMode) {
        this.service.updateTask(this.data.itemId, updateModel).then(response => {
          if (response.success === true) {
            this.closeForm('update');
          } else {
          }
        });
      } else {
        this.service.addNewTask(updateModel).then(newRecordId => {
          this.activityHubService.requestActivityTimer(newRecordId, 'Task');
          this.closeForm('update');
        });
      }
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?'
        }
      });

      dialogRef.componentInstance.onClose.subscribe(result => {
        if (result.result) {
          this.closeForm('cancel');
        }
      });
    } else {
      this.closeForm('cancel');
    }
  }

  closeForm(message: string) {
    if (this.editMode) {
      this.activityHubService.pauseRecording(this.data.activityId);
    }
    this.asideService.close(message);
  }

  //#endregion
}
