import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { BordereauxImportListItemModel } from './bordereaux-import-list-item.model';
import { ListItemViewBaseModel } from 'app/shared/models/list-item-view-base.model';

export class BordereauxImportListItemViewModel extends ListItemViewBaseModel<BordereauxImportListItemModel> {
  authoriseButton: IIconActionButton;
  rejectButton: IIconActionButton;
  viewButton: IIconActionButton;
  downloadButton: IIconActionButton;
  remapButton: IIconActionButton;
  viewRemappedButton: IIconActionButton;
  downloadRemappedButton: IIconActionButton;
  deleteItemButton: IIconActionButton;

  constructor(dataModel: BordereauxImportListItemModel) {
    super(dataModel);  

    this.authoriseButton = { visible: true, enabled: true, actionEnabled: true, actionDisabledMessage: '', iconName: 'done', actionName: 'authorise', hoverText: 'Authorise the item', class: '', buttonText: '' };
    this.rejectButton = { visible: true, enabled: true, actionEnabled: true, actionDisabledMessage: '', iconName: 'clear', actionName: 'reject', hoverText: 'Reject the item', class: '', buttonText: '' };
    this.viewButton = { visible: true, enabled: true, actionEnabled: true, actionDisabledMessage: '', iconName: 'visibility', actionName: 'view', hoverText: 'View the item', class: 'icon-red', buttonText: '' };
    this.downloadButton = { visible: true, enabled: true, actionEnabled: true, actionDisabledMessage: '', iconName: 'cloud_download', actionName: 'download', hoverText: 'Download the item', class: 'icon-red', buttonText: '' };

    // wireframes had a standard "copy" icon (probably content_copy) as the icon for remap
    // view_column might be more appropriate as we're remapping columns?
    this.remapButton = { visible: true, enabled: true, actionEnabled: true, actionDisabledMessage: '', iconName: 'view_column', actionName: 'remap', hoverText: 'Remap', class: '', buttonText: '' };
    this.viewRemappedButton = { visible: true, enabled: true, actionEnabled: true, actionDisabledMessage: '', iconName: 'visibility', actionName: 'viewRemapped', hoverText: 'View the item', class: 'primary-color', buttonText: '' };
    this.downloadRemappedButton = { visible: true, enabled: true, actionEnabled: true, actionDisabledMessage: '', iconName: 'cloud_download', actionName: 'downloadRemapped', hoverText: 'Download the item', class: 'primary-color', buttonText: '' };

    this.deleteItemButton = { visible: true, enabled: true, actionEnabled: true, actionDisabledMessage: '', iconName: 'delete', actionName: 'deleteItem', hoverText: 'Delete the item', class: '', buttonText: '' };
  
    if(dataModel.allowAuthorise)
    {
      this.processActions.push(this.authoriseButton);
    }
    if(dataModel.allowReject)
    {
      this.processActions.push(this.rejectButton);
    }
    this.processActions.push(this.viewButton);
    this.processActions.push(this.downloadButton);
    if(dataModel.allowRemap)
    {
      this.processActions.push(this.remapButton);
    }
    if(dataModel.allowMappedFileViewAndDownload)
    {
      this.processActions.push(this.viewRemappedButton);
      this.processActions.push(this.downloadRemappedButton);
    }

    
    //this.processActions.push(this.deleteItemButton);  // Damien said to hide on 2020-02-25 - button may not be required
  
  }
}

