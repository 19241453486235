import { Observable } from "rxjs";
import { Subject } from 'rxjs';

export interface ILiteEvent<T> {
    on(handler: { (data?: T): void }): void;
    off(handler: { (data?: T): void }): void;
}

/**
 * Lightweight event implementation.
 * @see {@link https://stackoverflow.com/questions/12881212/does-typescript-support-events-on-classes}
 */
export class LiteEvent<T> implements ILiteEvent<T> {
    private handlers: { (data?: T): void; }[] = [];

    public on(handler: { (data?: T): void }): void {
        this.handlers.push(handler);
    }

    public off(handler: { (data?: T): void }): void {
        this.handlers = this.handlers.filter(h => h !== handler);
    }

    public trigger(data?: T) {
        this.handlers.slice(0).forEach(h => h(data));
    }

    public expose(): ILiteEvent<T> {
        return this;
    }

    private _observable : Subject<T> = null;

    /** Exposes the event as an observable {@link Subject}. */
    public asObservable() : Subject<T> {
        if(this._observable===null)
        {
            var s = new Subject<T>();
            this.on(data => s.next(data));
            this._observable=s;
        }
        return this._observable;
    }
}