exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".dropzone {\n  border: #999 dashed 1px;\n  background-color: #fff;\n  color: #666;\n  font-size: 15px;\n  padding-top: 10px;\n  padding-bottom: 10px; }\n\n/* .inputfile label{\r\n    color: #999;\r\n    font-size: 0.6em;\r\n    \r\n} */\n.uploader-content {\n  min-height: 150px; }\n\n.text-wrapper {\n  vertical-align: middle; }\n\n.centered {\n  font-family: sans-serif;\n  font-size: 14px;\n  font-weight: bold;\n  text-align: center; }\n\n.nv-file-over {\n  border: dotted 2px green;\n  background-color: transparent; }\n\n/* Default class applied to drop zones on over */\n.inputfile {\n  width: 0.1px;\n  height: 0.1px;\n  opacity: 0;\n  overflow: hidden;\n  position: fixed;\n  z-index: -1; }\n\n.inputfile + label {\n  font-size: 14px;\n  font-weight: 700;\n  color: black;\n  background-color: #eee;\n  display: inline-block;\n  cursor: pointer; }\n\n.inputfile:focus + label,\n.inputfile + label:hover {\n  background-color: transparent; }\n\n.inputfile + label {\n  font-size: 14px;\n  font-weight: 700;\n  color: black;\n  background-color: #eee;\n  display: inline-block;\n  cursor: pointer; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();