import { ApiEntity } from 'app/api/models/api-entity.model';

export class UserRowModel extends ApiEntity {
  constructor(
    public id: number = null,
    public firstName: string = '',
    public lastName: string = '',
    public username: string = '',
    public email: string = '',
    public phone: string = '',
    public mobileNumber: string = '',
    public roles: string = '',
    public divisions: string = '',
    public isActive: boolean = false,
    public internal: boolean
  ) {
    super();
  }
}
