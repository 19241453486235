exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".message {\n  text-align: center;\n  width: 60%;\n  background-color: #f5f5f5;\n  color: black;\n  font-size: 2rem;\n  margin-top: 50px;\n  margin-left: auto;\n  margin-right: auto;\n  padding: 20px; }\n\n.divisions {\n  height: 80px; }\n\n/* Fake (pseudo) element, that enables vertical-align */\n.divisions:before {\n  content: \"\";\n  display: inline-block;\n  vertical-align: middle;\n  height: 100%; }\n\n.welcome {\n  padding: 15px;\n  width: 250px;\n  height: 130px;\n  background-color: #f5f5f5;\n  border: 1px solid #d1d1d1;\n  margin: 10px;\n  cursor: pointer; }\n\n.welcome img {\n  width: 223px; }\n\n.welcome h3 {\n  font-size: 2rem;\n  position: absolute;\n  bottom: 0; }\n\n.division-logo {\n  display: block;\n  max-height: 110px;\n  width: auto;\n  height: auto;\n  padding-top: 15px; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();