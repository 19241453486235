import { ApiEntity } from "app/api/models/api-entity.model";
import { LookupList } from "app/shared/models/lookup-list.model";

export class RecordedTimeAmendFormModel extends ApiEntity {
  constructor(
    id: number = null,
    public timestamp: string = "",
    public originalStart: Date = new Date(),
    public originalStop: Date = new Date(),
    public newStopTime: Date = new Date(),
    public newStopTimeHours: Date = new Date(),
    public newStopTimeMinutes: Date = new Date(),
    public newStopTimeSeconds: Date = new Date(),
    public status: number = null,
    public statusLookupData: LookupList = null,
    public timeAmendReasonLookupData: LookupList = null,
    public amendStopTime: Date = new Date(),
    public amendStatus: number = null,
    public timeAmendmentReasonId: number = null,
  ) {
    super(id);
  }
}
