import { IIconActionButton } from "app/shared/interfaces/IIconActionButton.interface";

export class ActionableItemState {
  // the actions that will be rendered as icon buttons on the component
  processActions: IIconActionButton[] = [];
  itemId: number; // id of the itme being viewed
  itemSelected = false; // dataModel: any; // the data to be rendered and managed in the UI

  constructor(public dataModel: any = null) {}
}
