import { Filter } from './../../shared/models/filter.model';
import { SortOrder } from './../../shared/models/sort-order.model';

export class ListRequestModel {
  constructor(
    public paged: boolean = true,
    public pageNumber: number = 1,
    public pageSize: number = 20,
    public sort: Array<SortOrder> = [],
    public filters: Array<Filter> = []) {
  }

  getFilterByName(fieldName: string): Filter {
    return this.filters.find(f=>f.field == fieldName);
  }
}
