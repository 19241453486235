import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { toFormattedDateString } from 'app/shared/helpers/date-helpers';
import { CaseActivityOutboundCallModel } from '../models/case-activity-outbound-call.model';
import { CaseActivityOutboundCallService } from '../services/case-activity-outbound-call.service';

@Component({
  selector: 'activity-outbound-call-form',
  templateUrl: './activity-outbound-call-form.component.html',
  styles: [
    `
      .form-field {
        margin-top: 12px;
        margin-bottom: 12px;
      }
    `
  ]
})
export class ActivityOutboundCallComponent implements OnInit {
  public data: any;
  public contactLookupData: any[];
  public telephoneLookupData: any[];
  viewModel: CaseActivityOutboundCallModel;
  dataForm: FormGroup;

  constructor(
    fb: FormBuilder,
    private service: CaseActivityOutboundCallService,
    private asideService: AsideService,
    private formHelperService: ReactiveFormHelperService,
    private dialog: MatDialog
  ) {
    this.service.startTime = toFormattedDateString(new Date(), 'YYYY-MM-DD HH:mm:ss');

    this.dataForm = fb.group({
      contactId: ['', [Validators.required]],
      telephoneNumberId: ['', [Validators.required]]
    });
  }

  get contactId(): AbstractControl {
    return this.dataForm.controls['contactId'];
  }

  get telephoneNumberId(): AbstractControl {
    return this.dataForm.controls['telephoneNumberId'];
  }

  ngOnInit() {
    this.loadFormDetails();
  }

  private loadFormDetails() {
    this.service.getEditableItemDetail(null).then((response: CaseActivityOutboundCallModel) => {
      this.viewModel = response;
      if (this.viewModel) {
        this.initialiseForm();
      }
    });
  }

  initialiseForm() {
    this.contactLookupData = this.formHelperService.createLookupData(this.viewModel.contactLookupData);

    this.contactId.valueChanges.subscribe(() => this.onContactChange());
    this.contactId.setValue(this.viewModel.contactLookupData.defaultId);
    this.dataForm.markAsPristine();
  }

  onContactChange() {
    if (this.contactId !== null) {
      this.updateLookups();
    }
  }

  updateLookups() {
    const contactId: number = +this.contactId.value;

    if (!contactId) {
      return;
    }
    if (this.viewModel.contactLookupData) {
      const contactLookupData = this.viewModel.contactLookupData.items.find(f => f.id === contactId);
      if (contactLookupData != null) {
        const subList = contactLookupData.subLists.find(l => l.name === 'TelephoneNumbers');
        this.telephoneNumberId.setValue(subList.defaultId);
        this.telephoneLookupData = subList.items.map(item => {
          return {
            id: +item.id,
            text: item.name,
            number: item.data
          };
        });
      }
    }
  }

  private createUpdateModel(selectedNumber) {
    return {
      contactId: this.contactId.value,
      telephoneNumberId: this.telephoneNumberId.value,
      telephoneNumber: selectedNumber,
      startTime: this.service.startTime,
      activityLevel: this.data.activityLevel,
      levelParentId: this.data.levelParentId
    };
  }

  call() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const selectedNumber = this.telephoneLookupData.find(f => f.id === this.telephoneNumberId.value);

      const updateModel = this.createUpdateModel(selectedNumber.number);

      this.service.makeCall(updateModel, selectedNumber.number).then(() => {
        this.asideService.close('update');
      });
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?'
        }
      });

      dialogRef.componentInstance.onClose.subscribe(result => {
        if (result.result) {
          this.closeForm('cancel');
        }
      });
    } else {
      this.closeForm('cancel');
    }
  }

  closeForm(message: string) {
    this.asideService.close(message);
  }
}
