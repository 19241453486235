import { EventEmitter, Input, OnDestroy, OnInit, Output, Injector, ComponentFactoryResolver } from '@angular/core';
import { IAddListService } from 'app/shared/interfaces/IAddListService.interface';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { ComponentItem } from 'app/shared/base/aside/component-item';
import { AsideService } from 'app/layout/components/aside-container/aside.service';

// Acts as a mediator between the container and its children
// TODO: should be abstract but not sure if this is possble with Angular
// the create component will take control by passing in a negative value for itemId
export abstract class AddListComponent implements OnInit, OnDestroy {
  parentId: number; // the id of the parent of the children in the list. Should be assigned during component initiation. Could be from route or through input property

  @Input() protected isRowSelectable = false; // indicates whether rows can be selected and highlighted

  private _service: IAddListService; // a service derived from ListServiceBase
  protected get service(): IAddListService {
    return this._service;
  }
  protected set service(value: IAddListService) {
    this._service = value;
  }
  itemDeletedSubscription: Subscription;
  itemChangedSubscription: Subscription;
  listReloadedSubscription: Subscription;
  protected loadAfterInit = true; // indicates whether the list should load on init or whether that will be handled elsewhere

  itemList: any[] = null; // used for self managing list on a component. If null then the list on the srvice will be used

  @BlockUI() blockUI: NgBlockUI;

  protected selectedRowId: number;

  @Output() selectedRowChanged = new EventEmitter<any>();
  @Output() itemDeleted = new EventEmitter<number>();
  @Output() itemChanged = new EventEmitter<number>();
  @Output() listReloaded = new EventEmitter<number>();

  constructor() {}

  protected doPostLoad() {}

  private reloadList() {
    this.blockUI.start();

    this.service
      .reload()
      .then(() => {
        this.blockUI.stop();
      })
      .catch(error => {
        this.blockUI.stop();
      });
  }

  ngOnInit() {
    this.itemDeletedSubscription = this.service.itemDeleted$.subscribe(item => this.itemDeletedHandler(item));
    this.itemChangedSubscription = this.service.itemChanged$.subscribe(item => this.itemChangedHandler(item));
    this.listReloadedSubscription = this.service.listReloaded$.subscribe(listItemCount => this.listReloadedHandler(listItemCount));

    if (this.service) {
      this.service.ListRequest.sort = [];
      this.service.ListRequest.filters = [];
    }
  }

  ngAfterViewInit() {
    if (this.loadAfterInit === true) {
      this.reloadList();
    }
  }
  ngOnDestroy() {}

  selectRow(viewModel) {
    if (this.isRowSelectable === true) {
      this.selectedRowId = viewModel.dataModel.id;
      this.selectedRowChanged.emit(viewModel.dataModel);
    }
  }

  selectRowByIdNoEmit(id: number) {
    if (this.isRowSelectable === true ) {
      this.selectedRowId = id;
    }
  }

  selectRowByIndex(index) {
    if (this.isRowSelectable === true && index < this.service.itemsCount ) {
      const row = this.service.itemList[index];
      this.selectRow(row)
    }
  }

  itemDeletedHandler(item: number) {
    if (item) {
      if (item === this.selectedRowId) {
        this.selectedRowId = null;
        this.selectedRowChanged.emit(null);
      }
      this.itemDeleted.emit(item);
    }
  }
  
  itemChangedHandler(item: number) {
    this.itemChanged.emit(item);
  }

  listReloadedHandler(listItemCount) {
    this.service.selectAll(false); // deselect all items when the list reloads
    this.listReloaded.emit(listItemCount);
  }

}
