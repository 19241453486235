import { ListRequestModel } from 'app/api/models/list-request.model';
import { SortOrder } from 'app/shared/models/sort-order.model';
import { Filter } from 'app/shared/models/filter.model';

export class TimeSearchListRequestModel extends ListRequestModel {
  constructor(
    public dateFrom: Date = null,
    public dateTo: Date = null,
    public caseReference: string = '',
    public colleagueId: number = null,
    public recordedTimeItemId: number = null,
    public amendStatus: number = null,
    paged: boolean = true,
    pageNumber: number = 1,
    pageSize: number = 20,
    sort: Array<SortOrder> = [],
    filters: Array<Filter> = []
  ) {
    super(paged, pageNumber, pageSize, sort, filters);
  }
}
