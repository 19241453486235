import { Injectable } from "@angular/core";
import { ApiDependenciesService } from "app/api/services/api-dependencies.service";
import { DataService } from "./data.service";

@Injectable()
export class KeepAliveService extends DataService<any> {


  constructor(protected dependencies: ApiDependenciesService) {
    super(dependencies);
    
  }
  
  async checkAuthentication(): Promise<boolean> {
    let model: boolean;
    await this.setEndpoint('/application/check-authentication')
      .getSingle()
      .then(response => {
        model = response;
      });
    return Promise.resolve(model);
  }
}
