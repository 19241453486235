import { ActionableItemState } from "./actionable-item-state";
import { ApiEntity } from "app/api/models/api-entity.model";

/**
 * XxxListItemViewModel classes may switch to using this as a base instead of ActionableItemState in order to add better typing / intellisense on the dataModel member.
 */
// In addition to improving intellisense this is expected to greatly reduce errors / typos that were previously going undetected.
// Will initially trial with ActivityListItemViewModel as that component is used in a number of places and gives significant bang-for-buck.
export class ListItemViewBaseModel<TListItemModel extends ApiEntity> extends ActionableItemState
{
    public dataModel: TListItemModel;

    constructor(dataModel: TListItemModel = null) {
        super(dataModel);
        this.dataModel=dataModel;
    }
}