import { Component, Input, ChangeDetectionStrategy, TemplateRef, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { StringUtilities } from 'app/shared/helpers/string-helpers';

@Component({
  selector: 'kaleida-popover',
  styleUrls: ['./pop-over.component.scss'],
  templateUrl: './pop-over.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('popoverTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class KaleidaPopoverComponent {

  @Input() text = '';
  @Input() header = '';
  @Input() footer = '';
  @Input() popoverData = {ddata: 23};
  @Input() contentTemplate: TemplateRef<any>;

  constructor() {
  }


  convertString(source: string): string {
    return StringUtilities.translateCR(source);
  }

}
