exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":host {\n  display: block; }\n\n.popover-container {\n  background-color: #dee2e6;\n  padding: 0.5rem 1rem;\n  border-radius: 8px; }\n\n.popover-content {\n  color: #495057;\n  font-size: 12px; }\n\n.popover-header {\n  color: #343a40;\n  font-size: 12px;\n  font-weight: bold;\n  border-bottom: solid 1px #adb5bd;\n  margin-bottom: 5px; }\n\n.popover-footer {\n  color: #343a40;\n  font-size: 9px;\n  border-top: solid 1px #adb5bd;\n  margin-top: 5px; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();