export enum TimerControlStatus {
    Recording = 1,
    Paused = 2,
    Stopped = 3
}

export enum TimerControlEvents {
    Record = 1,
    Pause = 2,
    Stop = 3
}

