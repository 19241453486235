import { SystemRole } from './../../../api/models/system-role.model';
import { ApiEntity } from 'app/api/models/api-entity.model';

export class User extends ApiEntity {

    constructor(
        id: number,
        username: string,
        firstName: string,
        surname: string,
        email: string,
        mobileNumber: string,
        isActive: boolean,
        roles: SystemRole[]
    ) {
        super();

        this.id = id;
        this.username = username;
        this.firstName = firstName;
        this.surname = surname;
        this.email = email;
        this.mobileNumber = mobileNumber;
        this.isActive = isActive;
        this.roles = roles;
    }

  id: number;
  username: string;
  firstName: string;
  surname: string;
  email: string;
  mobileNumber: string;
  isActive: boolean;
  roles: SystemRole[];

  summaryContentVisible: boolean = false;

  toggleSummaryContent() {
    this.summaryContentVisible = !this.summaryContentVisible;
  }

  get fullname(): string {
    return this.firstName + ' ' + this.surname;
  }
}
