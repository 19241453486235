import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { ApiEntity } from 'app/api/models/api-entity.model';

import { CaseActivityModel } from 'app/modules/solution-shared/case-activity/models/case-activity.model';
import { TimerListItemModel } from '../models/timer-list-item.model';
import { TimerListItemViewModel } from '../models/timer-list-item-view.model';
import { TimerControlStatus } from 'app/shared/enumeration/timer-control-status';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TimerListService extends ListServiceBase {
  divisionId: number;
  caseClaimId: number;

  private itemStopped = new BehaviorSubject<number>(null);
  // Observable itemDeleted stream
  itemStopped$ = this.itemStopped.asObservable();

  constructor(protected dependencies: ApiDependenciesService, protected dialog: MatDialog) {
    super(dependencies, dialog);

    this.initialiseFiltersSortAndPaging();
  }

  // populate initial filter properties
  private initialiseFiltersSortAndPaging() {
    // Add intialisation here
  }

  //#region Get Endpoints

  get baseEndpoint(): string {
    return '/divisions/' + this.divisionId + '/case-activities';
  }

  baseEndpointWithId(id: number): string {
    return this.baseEndpoint + '/' + id;
  }

  // a number of endpoint getters are defined in ListServiceBase
  // not all endpoints will be required for all implementations

  // OVERRIDDEN from ListServiceBase
  protected getCommandStubEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }

  // OVERRIDDEN from ListServiceBase
  protected getListEndpoint(): string {
    return this.baseEndpoint + '/timer-list';
  }

  //#region list loading

  // OVERRIDDEN from ListServiceBase
  // Create a new ViewModel specific to this list
  // Not necessary to set the viewmodel itemId as it is set in the viewModel constructor
  createListViewModel(dataModel: ApiEntity) {
    return new TimerListItemViewModel(dataModel as TimerListItemModel);
  }

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for each list row item
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedListItemDataModel(responseItem: any): ApiEntity {
    const model: TimerListItemModel = responseItem;
    return model;
  }

  //#region get details

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for the detail item (create/update)
  // The model must be derived from ApiEntity
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedEditableItemDataModel(response: any): ApiEntity {
    const model: CaseActivityModel = response;
    return model;
  }

  //#endregion

  protected getCreateEndpoint(): string {
    return this.baseEndpoint;
  }

  protected getUpdateEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }

  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  async download(itemId: number) {
    await this.sendCommand(itemId, 'download');
  }
  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  async notes(itemId: number) {
    await this.sendCommand(itemId, 'notes');
  }
  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  async reassociate(itemId: number) {
    await this.sendCommand(itemId, 'reassociate');
  }

  // OVERRIDDEN from ListServiceBase
  protected getGetEditableItemEndpoint(itemId: number) {
    return itemId ? this.baseEndpointWithId(itemId) : this.baseEndpoint + '/blank';
  }

  protected get filterLookupDataEndpoint(): string {
    return this.baseEndpoint + '/filter-lookup-data';
  }
  protected get pauseAllLookupDataEndpoint(): string {
    return this.baseEndpoint + '/pause-all-lookup-data';
  }

  async getFilterLookupData(): Promise<any> {
    let model: any;
    await this.setEndpoint(this.filterLookupDataEndpoint)
      .getSingle()
      .then((response: any) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  async getPauseAllLookupData() {
    let model: any;
    await this.setEndpoint(this.pauseAllLookupDataEndpoint)
      .getSingle()
      .then((response: any) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  updateTimer(data: any) {

    if (data.Status === TimerControlStatus.Stopped) {
      // const vm = this.findViewModelFromItemId(data.CaseActivityId);
      // this.itemList.splice(this.itemList.indexOf(vm), 1);
      // const newArray = this.itemList.map(m => m);
      // this.itemList = [];
      // this.itemList = newArray;
      // this.reload();
    } else {
      const vm = this.findViewModelFromItemId(data.CaseActivityId);
      if (vm) {
        vm.dataModel.elapsedTime = data.ElapsedSecondsDisplay;
        vm.dataModel.timeRecordingStatus = data.Status;
        // notify that list has changed so that change detection an be invoked
        this.itemChanged.next(vm.itemId);
      }
    }
  }

  get recordingCount(): number {
    return this.itemList.filter(f => f.dataModel.timeRecordingStatus === TimerControlStatus.Recording).length;
  }
}
