import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { NotesListItemModel } from './notes-list-item.model';

export class NotesListItemViewModel extends ActionableItemState {
  editButton: IIconActionButton;

  constructor(dataModel: NotesListItemModel) {
    super();

    this.dataModel = dataModel;
  

    this.editButton = { visible: true, enabled: true, actionEnabled: true, actionDisabledMessage: '', iconName: 'edit', actionName: 'edit', hoverText: 'Edit the item', class: '', buttonText: '' };
  
    this.processActions.push(this.editButton);
  
  }
}

