import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { RouterModule } from '@angular/router';
import { KALEIDA_UK_DATE_FORMATS } from 'app/app.module';
import { AppMaterialModule } from 'app/core/app-material/app-material.module';
import { SharedModule } from 'app/shared/shared.module';
import { ExternalRaiseDefectFormComponent } from './external-raise-defect-form.component';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    // DefectRoutesModule,
    EditorModule,
    AppMaterialModule,

  ],
  declarations: [
    ExternalRaiseDefectFormComponent,
  ],
  exports: [ExternalRaiseDefectFormComponent],
  entryComponents: [
    ExternalRaiseDefectFormComponent,
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: KALEIDA_UK_DATE_FORMATS },
  ],
})
export class ExternalRaiseDefectModule {}
