
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { BordereauxImportListItemViewModel } from 'app/modules/contacts/company-detail/models/bordereaux-import-list-item-view.model';
import { BordereauxImportListItemModel } from 'app/modules/contacts/company-detail/models/bordereaux-import-list-item.model';
import { downloadFileFromExtendedBlob } from 'app/shared/helpers/file-download-helpers';
import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { ApiResponseSummary } from 'app/shared/models/api-response-summary.model';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { BordereauxImportListGroupItemViewModel } from '../models/bordereaux-import-list-groupitem-view.model';
import { BordereauxImportListGroupItemModel } from '../models/bordereaux-import-list-groupitem.model';


@Injectable()
export class BordereauxImportListService extends ListServiceBase {

  divisionId: number;  companyId: number;
  constructor(protected dependencies: ApiDependenciesService, protected dialog : MatDialog) {
    super(dependencies, dialog);

    this.initialiseFiltersSortAndPaging();
  }

  // populate initial filter properties
  private initialiseFiltersSortAndPaging() {
    // Add intialisation here
  }

  //#region Get Endpoints

  get baseEndpoint():string{
    return "/divisions/" + this.divisionId + "/companies/" + this.companyId + "/bordereaux-imports";

  }
  baseEndpointWithId(id: number):string{
    return this.baseEndpoint + '/' + id;
  }

  // a number of endpoint getters are defined in ListServiceBase
  // not all endpoints will be required for all implementations


  // OVERRIDDEN from ListServiceBase
  protected getDeleteEndpoint(itemId: number): string {
    return this.baseEndpointWithId (itemId);
  }
  // OVERRIDDEN from ListServiceBase
  protected getCommandStubEndpoint(itemId: number): string {
    return this.baseEndpointWithId (itemId);
  }


  // OVERRIDDEN from ListServiceBase
  protected getListEndpoint(): string {
    return this.baseEndpoint + '/bordereaux-import-list';
  }
  protected get exportListEndpoint(): string {
    return this.getListEndpoint() + '/export';
  }

  public get uploadEndpoint(): string {
    return this.baseEndpoint + '/upload';
  }
  


  
  //#region list loading

  // OVERRIDDEN from ListServiceBase
  // Create a new ViewModel specific to this list
  // Not necessary to set the viewmodel itemId as it is set in the viewModel constructor 
  createListViewModel(dataModel: ApiEntity) {
    var groupItem = dataModel as BordereauxImportListGroupItemModel;

    var subItemViewModels = [];
    groupItem.subItems.forEach((subItem: BordereauxImportListItemModel) => {
          const subItemViewModel: ActionableItemState = new BordereauxImportListItemViewModel(subItem);
          subItemViewModel.itemId = subItem.id;
          subItemViewModels.push(subItemViewModel);
        });
        groupItem.subItemViews=subItemViewModels;

    return new BordereauxImportListGroupItemViewModel(groupItem);
  }

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for each list row item
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedListItemDataModel(responseItem: any): ApiEntity {
    const model:BordereauxImportListItemModel = responseItem;
    return model;
  }


  /** Overridden from ListServiceBase. Searches subitems rather than group items  */
  findViewModelFromItemId(itemId: number): ActionableItemState {
    /* Original 
    const viewModel = this.itemList.find((x) => x.itemId == itemId);
    if (viewModel === null) {
      return null;
    }
    return viewModel;
    */ 

    /* 1st attempt at using groups
    const viewModel = this.itemList
    .find((x: BordereauxImportListGroupItemViewModel) => x.dataModel.subItemViews
    .find((si: BordereauxImportListItemViewModel) => si.itemId == itemId));
    */

    // 2nd attempt

    for(const item of this.itemList)
    {
      const group = item as BordereauxImportListGroupItemViewModel;
      const subItem = group.dataModel.subItemViews.find((si:BordereauxImportListItemViewModel)=>si.itemId==itemId);
      if(subItem!=null) return subItem;
    }
    return null;
  }




  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  async authorise(itemId: number) {
    await this.sendCommand(itemId, 'authorise');  
  }
  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  async reject(itemId: number) {
    await this.sendCommand(itemId, 'reject');  
  }
  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  async view(itemId: number) {
    await this.sendCommand(itemId, 'view');
  }
  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  /*
  async download(itemId: number) {
    await this.sendCommand(itemId, 'download');  
  }*/

  /**
   * Downloads a file.
   * @param uniqueIdentifier - The unique identifier of the FileStoreReference.
   */
  // Download getOfficeViewWebUrl based on what's in activity-list.service.ts
  async downloadFile(uniqueIdentifier: string) {
    await this.setEndpoint('/file-store/' + uniqueIdentifier + '/download')
      .getFileAsBlobWithPost()
      .then(response => {
        downloadFileFromExtendedBlob(response);
      });
  }

  /**
   * Gets a URL that will allow a file to be opened in the web version of Excel.
   * @param uniqueIdentifier - The unique identifier of the FileStoreReference.
   */
  async getOfficeViewWebUrl(uniqueIdentifier: string): Promise<string> {
    let model: string;
    const endpoint = '/file-store/' + uniqueIdentifier + '/weburl'
    await this.setEndpoint(endpoint)
      .getSingle()
      .then((response: string) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  protected get filterLookupDataEndpoint(): string {
    return this.baseEndpoint + '/filter-lookup-data';
  }

  async getFilterLookupData(): Promise<any> {
    let model: any;
    await this.setEndpoint(this.filterLookupDataEndpoint)
      .getSingle()
      .then((response: any) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  // Begin: Remap related
  getRemapEndpoint(itemId: number): string {
    if(itemId==null) throw "itemId is required.";
    return this.baseEndpoint + '/' + itemId + '/manage-remapping';
  }

  async getRemapModel(importId: number): Promise<ApiEntity> {
    let model: ApiEntity;
    await this.setEndpoint(this.getRemapEndpoint(importId))
      .getSingle()
      .then((response) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  async updateRemapModel(itemId: number, dataToUpdate: any): Promise<ApiResponseSummary<any>> {
    return this.updateItem(itemId, dataToUpdate, this.getRemapEndpoint(itemId));
  }
// End: Remap related

//end of class
}