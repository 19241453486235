import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Component, OnInit, Input, forwardRef, Inject } from '@angular/core';
import { FilterFieldsBaseComponent } from 'app/shared/components/list-filter/filter-fields-base.component';
import { Filter, FilterOperatorType } from 'app/shared/models/filter.model';
import { toFormattedDateString, addDays } from 'app/shared/helpers/date-helpers';

import { CaseActivityTimeSearchListListService } from 'app/modules/solution-shared/case-activity-time-search/services/case-activity-time-search-list-list.service';
import { CaseActivityTimeSearchListListFilterModel } from 'app/modules/solution-shared/case-activity-time-search/models/case-activity-time-search-list-list-filter.model';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { UsersService } from 'app/modules/user-management/services/users.service';
import { IUsersService } from 'app/modules/user-management/services/users.service.interface';
import { TimeRecorderListMode } from '../models/time-recorder-mode';

@Component({
  selector: 'case-activity-time-search-list-list-filter',
  templateUrl: './case-activity-time-search-list-list-filter.component.html',
  providers: [{ provide: FilterFieldsBaseComponent, useExisting: forwardRef(() => CaseActivityTimeSearchListListFilterComponent) }]
})
export class CaseActivityTimeSearchListListFilterComponent extends FilterFieldsBaseComponent implements OnInit {
  model: CaseActivityTimeSearchListListFilterModel = new CaseActivityTimeSearchListListFilterModel();

  @Input() listMode: TimeRecorderListMode;
  @Input() filterByUser = false;
  @Input() divisionId: number;
  colleagueIdLookupData: any[];
  amendStatusLookupData: any[];

  dataForm: FormGroup;
  get dateFrom(): AbstractControl {
    return this.dataForm.controls['dateFrom'];
  }
  get dateTo(): AbstractControl {
    return this.dataForm.controls['dateTo'];
  }
  get allDates(): AbstractControl {
    return this.dataForm.controls['allDates'];
  }
  get caseReference(): AbstractControl {
    return this.dataForm.controls['caseReference'];
  }
  get colleagueId(): AbstractControl {
    return this.dataForm.controls['colleagueId'];
  }
  get recordedTimeItemId(): AbstractControl {
    return this.dataForm.controls['recordedTimeItemId'];
  }
  get amendStatus(): AbstractControl {
    return this.dataForm.controls['amendStatus'];
  }

  constructor(
    private fb: FormBuilder,
    private service: CaseActivityTimeSearchListListService,
    private formHelperService: ReactiveFormHelperService,
    public usersService: UsersService,
  ) {
    super();

  }

  get showCaseFilter(): boolean {
    return this.listMode === TimeRecorderListMode.GlobalSearch;
  }
  ngOnInit() {
    if(this.service.divisionId===undefined)
    {
      this.service.divisionId=this.divisionId;
    }

    this.dataForm = this.fb.group({
      dateFrom: [this.model.dateFrom],
      dateTo: [this.model.dateTo],
      allDates: [false],
      caseReference: [this.model.caseReference],
      colleagueId: [this.model.colleagueId],
      recordedTimeItemId: [this.model.recordedTimeItemId],
      amendStatus: [this.model.amendStatus],
    });

    this.dataForm.controls['allDates'].valueChanges.subscribe(() => this.onAllDatesChnaged());

    this.service.getFilterLookupData().then((response: any) => {
      this.colleagueIdLookupData = this.formHelperService.createLookupData(response.colleagueIdLookupData);
      this.colleagueIdLookupData.unshift({ id: 0, text: 'All Colleagues' });

      this.amendStatusLookupData = this.formHelperService.createLookupData(response.amendStatusLookupData);
      this.amendStatusLookupData.unshift({ id: 0, text: 'All Time' });
    });

    this.dataForm.markAsPristine();
  }

  onAllDatesChnaged() {
    const allDates = this.dataForm.controls['allDates'].value;

    if (allDates === true) {
      // this.dateFrom.setValue(null);
      // this.dateTo.setValue(null);
      this.dateFrom.disable();
      this.dateTo.disable();
    } else {
      // this.dateFrom.setValue(this.model.dateFrom);
      // this.dateTo.setValue(this.model.dateTo);
      this.dateFrom.enable();
      this.dateTo.enable();
    }
  }

  ngAfterViewInit() {
    this.notifyFilterChanged();
  }

  resetToDefault() {
    this.dataForm.controls['dateFrom'].setValue(this.model.dateFrom);
    this.dataForm.controls['dateTo'].setValue(this.model.dateTo);
    this.dataForm.controls['allDates'].setValue(false);
    this.dataForm.controls['caseReference'].setValue(this.model.caseReference);
    this.dataForm.controls['colleagueId'].setValue(this.model.colleagueId);
    this.dataForm.controls['recordedTimeItemId'].setValue(this.model.recordedTimeItemId);
    this.dataForm.controls['amendStatus'].setValue(this.model.amendStatus);
  }

  getFilters(): Array<Filter> {
    const filters = new Array<Filter>();

    if (this.allDates.value === false) {
      if (this.dateFrom.value) {
        const date = toFormattedDateString(this.dateFrom.value, 'DD-MMM-YYYY');
        filters.push(new Filter('DateFrom', new FilterOperatorType().GreaterThanEqual, date));
      }

      if (this.dateTo.value) {
        const toDate = addDays(this.dateTo.value, 1);
        const date = toFormattedDateString(toDate, 'DD-MMM-YYYY');
        filters.push(new Filter('DateTo', new FilterOperatorType().LessThan, date));
      }
    }

    if (this.caseReference.value) {
      filters.push(new Filter('CaseReference', new FilterOperatorType().Contains, this.caseReference.value));
    }
    if (this.colleagueId.value && this.colleagueId.value > 0) {
      filters.push(new Filter('UserId', new FilterOperatorType().Equals, this.colleagueId.value));
    }
    if (this.recordedTimeItemId.value) {
      filters.push(new Filter('Id', new FilterOperatorType().Equals, +this.recordedTimeItemId.value));
    }
    if (this.amendStatus.value && this.amendStatus.value > 0) {
      filters.push(new Filter('AmendStatus', new FilterOperatorType().Equals, this.amendStatus.value));
    }

    return filters;
  }
}
