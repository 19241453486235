import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { CaseClaimTaskListItemModel } from './case-claim-task-list-item.model';

export class CaseClaimTaskListItemViewModel extends ActionableItemState {
  viewNotesButton: IIconActionButton;
  editButton: IIconActionButton;
  deleteItemButton: IIconActionButton;
  

  constructor(dataModel: CaseClaimTaskListItemModel) {
    super();

    this.dataModel = dataModel;

    this.editButton = {
      visible: true,
      enabled: true,
      actionEnabled: true,
      actionDisabledMessage: '',
      iconName: 'edit',
      actionName: 'edit',
      hoverText: 'Edit the task',
      class: '',
      buttonText: ''
    };
    this.processActions.push(this.editButton);

    this.viewNotesButton = {
      visible: true,
      enabled: true,
      actionEnabled: true,
      actionDisabledMessage: '',
      iconName: 'notes',
      actionName: 'viewNotes',
      hoverText: 'View Notes for the task',
      class: '',
      buttonText: ''
    };
    this.processActions.push(this.viewNotesButton);

    this.deleteItemButton = {
      visible: true,
      enabled: true,
      actionEnabled: this.dataModel.canDelete,
      actionDisabledMessage: this.dataModel.cannotDeleteReason,
      iconName: 'delete',
      actionName: 'deleteItem',
      hoverText: 'Delete the task',
      class: '',
      buttonText: ''
    };
    this.processActions.push(this.deleteItemButton);

    
  }
}
