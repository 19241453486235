import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { CaseActivityModel } from 'app/modules/solution-shared/case-activity/models/case-activity.model';
import { ActivityListService } from 'app/modules/solution-shared/case-activity/services/activity-list.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { formatAsString, toFormattedDateString } from 'app/shared/helpers/date-helpers';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { ActivityTimerHubService } from 'app/shared/services/signalr.service';
import { requiredIf } from 'app/shared/validation/custom-validators';

@Component({
  selector: 'case-activity-form',
  templateUrl: './case-activity-form.component.html'
})
export class CaseActivityFormComponent implements OnInit {
  public data: any;
  saveText: string;
  editMode: boolean;

  activityTypeIdLookupData: any[];
  allTypesLookupData: any[];

  viewModel: CaseActivityModel;
  concurrencyErrorMessage = '';
  dataForm: FormGroup;
  activityTypeName: string;
  selectType = true;
  fullDay = false;
  halfDay = false;
  any = false;
  shouldRecordTime = false;

  startTime: Date;
  stopTime: Date;

  constructor(
    private fb: FormBuilder,
    private service: ActivityListService,
    private asideService: AsideService,
    private formHelperService: ReactiveFormHelperService,
    private activityHubService: ActivityTimerHubService,
    private dialog: MatDialog
  ) {
    this.startTime = new Date();

    this.dataForm = fb.group(
      {
        activityTypeId: ['', [Validators.required]],
        summary: ['', [Validators.required, Validators.maxLength(200)]],
        detail: ['', [Validators.required, Validators.maxLength(500)]],
        bookingDate: ['', [requiredIf(this.notAllDayValidation.bind(this))]],
        morningSlot: [false],
        afternoonSlot: [false]
      },
      {
        validator: Validators.compose([this.halfDayBookingSlotValidation()])
      }
    );
  }

  // shortcuts to controls for readability
  get activityTypeId(): AbstractControl {
    return this.dataForm.controls['activityTypeId'];
  }
  get summary(): AbstractControl {
    return this.dataForm.controls['summary'];
  }
  get detail(): AbstractControl {
    return this.dataForm.controls['detail'];
  }
  get bookingDate(): AbstractControl {
    return this.dataForm.controls['bookingDate'];
  }
  get morningSlot(): AbstractControl {
    return this.dataForm.controls['morningSlot'];
  }
  get afternoonSlot(): AbstractControl {
    return this.dataForm.controls['afternoonSlot'];
  }

  ngOnInit() {
    this.editMode = this.data.itemId > 0;
    this.saveText = this.editMode ? 'Update' : 'Create';

    this.activityTypeName = this.data.activityTypeName;
    this.loadFormDetails();
  }

  private loadFormDetails() {
    const id: number = this.editMode ? this.data.itemId : null;
    this.service.getEditableItemDetail(id).then((response: CaseActivityModel) => {
      this.viewModel = response;
      if (this.viewModel) {
        this.concurrencyErrorMessage = '';
        this.initialiseForm();
      }
    });
  }

  initialiseForm() {
    this.activityTypeIdLookupData = this.formHelperService.createLookupData(this.viewModel.activityTypeIdLookupData);
    this.allTypesLookupData = this.formHelperService.createLookupData(this.viewModel.allTypesLookupData);
    this.viewModel.levelParentId = this.data.levelParentId;
    this.viewModel.activityLevel = this.data.activityLevel;

    if (this.editMode) {
      const customActivity = this.activityTypeIdLookupData.find(f => f.text === this.data.activityTypeName);
      if (customActivity) {
        this.selectType = true;
      } else {
        this.selectType = false;
      }
    } else {
      const requiredActivity = this.allTypesLookupData.find(f => f.text === this.data.activityTypeName);

      if (requiredActivity) {
        this.viewModel.activityTypeId = requiredActivity.id;
        this.selectType = false;
      } else {
        this.selectType = true;
      }
    }

    this.dataForm.controls['activityTypeId'].setValue(this.viewModel.activityTypeId);
    this.dataForm.controls['summary'].setValue(this.viewModel.summary);
    this.dataForm.controls['detail'].setValue(this.viewModel.detail);
    this.dataForm.controls['bookingDate'].setValue(this.viewModel.bookingDate);
    this.dataForm.controls['activityTypeId'].valueChanges.subscribe(() => this.getBookingOption());
    this.dataForm.controls['morningSlot'].setValue(this.viewModel.morningBooking);
    this.dataForm.controls['afternoonSlot'].setValue(this.viewModel.afternoonBooking);

    if (this.editMode) {
      this.getBookingOption();
    }

    this.shouldRecordTime = this.editMode && this.fullDay === false && this.halfDay === false;
    if (this.shouldRecordTime) {
      this.activityHubService.startRecording(this.data.itemId);
    }

    this.dataForm.markAsPristine();
  }

  getBookingOption() {
    const x = this.viewModel.activityTypeIdLookupData.items.find(f => f.id === this.activityTypeId.value);
    const option = x.data.minimumBookingOption;
    if (option === 'FullDay') {
      this.fullDay = true;
      this.any = false;
      this.halfDay = false;
      return;
    }
    if (option === 'HalfDay') {
      this.halfDay = true;
      this.fullDay = false;
      this.any = false;
      return;
    } else {
      this.any = true;
    }
    this.fullDay = false;
    this.halfDay = false;
    return;
  }

  private createUpdateModel() {
    this.stopTime = new Date();
    return {
      activityTypeId: this.selectType ? this.activityTypeId.value : this.viewModel.activityTypeId,
      summary: this.summary.value,
      detail: this.detail.value,
      bookingDate: formatAsString(this.bookingDate.value),
      timestamp: this.viewModel.timestamp,
      morningBooking: this.morningSlot.value || this.fullDay ? true : false,
      afternoonBooking: this.afternoonSlot.value || this.fullDay ? true : false,
      startTime: toFormattedDateString(this.startTime, 'YYYY-MM-DD HH:mm:ss'),
      stopTime: toFormattedDateString(this.stopTime, 'YYYY-MM-DD HH:mm:ss'),
      activityLevel: this.viewModel.activityLevel,
      levelParentId: this.viewModel.levelParentId
    };
  }

  save() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();
      if (this.editMode) {
        this.service.updateActivityItem(this.data.itemId, updateModel).then(response => {
          if (response.success === true) {
            this.closeForm('update');
          } else {
            this.concurrencyErrorMessage = response.message;
          }
        });
      } else {
        this.service.addNewActivity(updateModel).then(newRecordId => {
          this.activityHubService.requestActivityTimer(newRecordId, '');

          this.closeForm('update');
        });
      }
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?'
        }
      });

      dialogRef.componentInstance.onClose.subscribe(result => {
        if (result.result) {
          this.closeForm('cancel');
        }
      });
    } else {
      this.closeForm('cancel');
    }
  }

  closeForm(message: string) {
    if (this.shouldRecordTime) {
      this.activityHubService.pauseRecording(this.data.itemId);
    }

    this.asideService.close(message);
  }

  notAllDayValidation() {
    return this.fullDay === true || this.halfDay === true;
  }

  halfDayBookingSlotValidation(): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
      if (this.dataForm && this.halfDay) {
        if (this.morningSlot.value === true || this.afternoonSlot.value === true) {
          return null;
        } else {
          return { halfDayBookingSlotValidation: { value: true } };
        }
      }
    };
  }
}
