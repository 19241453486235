import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { TimeProviderCreateUpdateComponent } from 'app/modules/configuration/time-provider/time-provider-create-update/time-provider-create-update.component';
import { TimeModel } from 'app/modules/configuration/time/time.model';
import { TimeService } from 'app/modules/configuration/time/time.service';
import { ExternalRaiseDefectFormComponent } from 'app/modules/external-raise-defect/external-raise-defect-form.component';
import { UsersService } from 'app/modules/user-management/services/users.service';
import { ComponentItem } from 'app/shared/base/aside/component-item';
import { Division } from 'app/shared/enumeration/division';
import { environment } from 'environments/environment';

@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  divisionId: number = null;
  public timeModel: TimeModel;
  appVersion = 'unknown build number';

  public showTimeProvider = () => (this.timeModel ? this.timeModel.showTimeProvider : false);

  public currentDatetimeValue = () => (this.timeModel ? this.timeModel.currentDateTime : '');

  private refreshCurrentTime() {
    this.timeService.getCurrentTime().then((timeModel: TimeModel) => {
      this.timeModel = timeModel;
    });
  }

  showDevelopmentMenu(){
    return environment.name !== 'LIVE' && environment.name !== 'UAT';
  }
  get isFliDivision(): boolean {
    return this.divisionId == Division.FinanceAndLegal ? true : false;
  }

  get isSupportisDivision(): boolean {
    return this.divisionId == Division.Supportis ? true : false;
  }

  get contactsSavedText() {
    return this.isFliDivision ? 'Companies' : 'Contacts' ;
  }

  get divisionSelected(): boolean {
    return this.divisionId ? true : false;
  }

  get productsLink(): (string | number)[] {
    return this.divisionSelected ? ['/divisions', this.divisionId, 'configuration', 'products'] : ['/configuration', 'products'];
  }
  get divisionsLink(): (string | number)[] {
    return this.divisionSelected ? ['/divisions', this.divisionId, 'configuration', 'divisions'] : ['/configuration', 'divisions'];
  }
  get internalUsersLink(): (string | number)[] {
    return this.divisionSelected
      ? ['/divisions', this.divisionId, 'configuration', 'internal-users']
      : ['/configuration', 'internal-users'];
  }
  get externalUsersLink(): (string | number)[] {
    return this.divisionSelected
      ? ['/divisions', this.divisionId, 'configuration', 'external-users']
      : ['/configuration', 'external-users'];
  }
  get singleUseActionsLink(): (string | number)[] {
    return this.divisionSelected ? ['/divisions', this.divisionId, 'admin', 'single-use-actions'] : ['/admin', 'single-use-actions'];
  }
  get databaseAuditingLink(): (string | number)[] {
    return this.divisionSelected
      ? ['/divisions', this.divisionId, 'configuration', 'database-auditing']
      : ['/configuration', 'database-auditing'];
  }

  get productPanelHeaderNameForDivision(): string {
    return this.divisionId === Division.FinanceAndLegal ? 'Policies' : 'Products';
  }

  get productNameForDivision(): string {
    return this.divisionId === Division.FinanceAndLegal ? 'products/policies' : 'products/search';
  }

  get productDisplayNameForDivision(): string {
    return this.divisionId === Division.FinanceAndLegal ? 'Policy' : 'Products';
  }

  get caseNameForDivision(): string {
    return this.divisionId === Division.FinanceAndLegal ? 'claims' : 'cases';
  }
  get caseDisplayNameForDivision(): string {
    return this.divisionId === Division.FinanceAndLegal ? 'Claim' : 'Case';
  }

  ngOnInit(): void {
    this.appVersion = environment.buildLabel;
    this.refreshCurrentTime();

    this.usersService.themeName.subscribe(themeId => {
      this.divisionId = themeId;
      this.ref.detectChanges();
    });
  }

  setTimeProvider() {
    this.asideService.setComponent(new ComponentItem(TimeProviderCreateUpdateComponent, { itemId: 0 }));
    const that = this;
    this.asideService.asideClose.subscribe(() => {
      that.refreshCurrentTime();
    });

    this.asideService.open();
  }

  submitDefect() {
    this.asideService.setAndOpen(new ComponentItem(ExternalRaiseDefectFormComponent, {}), this.injector);
  }

  constructor(
    protected usersService: UsersService,
    protected timeService: TimeService,
    private asideService: AsideService,
    private route: ActivatedRoute,
    private injector: Injector,
    private ref: ChangeDetectorRef,
  ) {
    // route.params.subscribe(params => {
    //   this.divisionId = params['divisionId'];
    // });
  }
}
