import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { EditorModule } from '@tinymce/tinymce-angular';
import { KALEIDA_UK_DATE_FORMATS } from 'app/app.module';
import { AppMaterialModule } from 'app/core/app-material/app-material.module';
import { BordereauxImportListService } from 'app/modules/contacts/company-detail/services/bordereaux-import-list.service';
import { SharedModule } from 'app/shared/shared.module';
import { ActivityRecordedTimeFormComponent } from '../case-activity-time-search/activity-recorded-time-form/activity-recorded-time-form.component';
import { ActivityRecordedTimeListComponent } from '../case-activity-time-search/activity-recorded-time-list/activity-recorded-time-list.component';
import { RecordedTimeAmendFormComponent } from '../case-activity-time-search/activity-recorded-time-list/recorded-time-amend-form/recorded-time-amend-form.component';
import { CaseActivityTimeSearchListListFilterComponent } from '../case-activity-time-search/case-activity-time-search-list-list-filter/case-activity-time-search-list-list-filter.component';
import { CaseActivityTimeSearchListListComponent } from '../case-activity-time-search/case-activity-time-search-list-list/case-activity-time-search-list-list.component';
import { ActivityTaskListService } from '../case-claim/case-claim-detail/case-claim-detail-tasks/services/case-claim-task-list.service';
import { ActivityTaskFormComponent } from '../case-claim/case-claim-detail/case-claim-detail-tasks/task-form/case-claim-task-form.component';
import { NotesModule } from '../note/notes.module';
import { ActivityDocumentPackCreateFormComponent } from './activity-document-pack-create-form/activity-document-pack-create-form.component';
import { ActivityDocumentPackListComponent } from './activity-document-pack-list/activity-document-pack-list.component';
import { ActivityEmailAttachmentComponent } from './activity-email-attachment-list/activity-email-attachment-list.component';
import { ActivityEmailComponent } from './activity-email/activity-email.component';
import { ActivityFilePreviewComponent } from './activity-file-preview/activity-file-preview.component';
import { ActivityOutboundCallComponent } from './activity-outbound-call-form/activity-outbound-call-form.component';
import { ActivityDocumentCreateFormComponent } from './case-activity-document-create-form/case-activity-document-create-form.component';
import { ActivityDocumentUploadFormComponent } from './case-activity-document-upload-form/case-activity-document-upload-form.component';
import { CaseActivityFormComponent } from './case-activity-form/case-activity-form.component';
import { ActivityListFilterComponent } from './case-activity-list-filter/activity-list-filter.component';
import { ActivityListComponent } from './case-activity-list/activity-list.component';
import { CaseActivityPauseAllComponent } from './case-activity-pause-all/case-activity-pause-all.component';
import { CaseActivityPriorityNotesListComponent } from './case-activity-priority-note-list/case-activity-priority-note-list.component';
import { CaseActivityTimerListComponent } from './case-activity-timer-list/case-activity-timer-list.component';
import { ActivityDocumentPackService } from './services/activity-document-pack.service';
import { ActivityListService } from './services/activity-list.service';
import { ActivityDocumentCreateService } from './services/case-activity-document-create.service';
import { ActivityDocumentUploadService } from './services/case-activity-document-upload.service';
import { CaseActivityEmailService } from './services/case-activity-email.service';
import { CaseActivityOutboundCallService } from './services/case-activity-outbound-call.service';
import { CaseActivityPriorityNoteListService } from './services/case-activity-priority-note.service';
import { TimerListService } from './services/timer-list.service';
import { TimerControlComponent } from './timer-control/timer-control.component';
import { UploadBordereauxFromEmailAttatchmentFormComponent } from './upload-bordereaux-from-email-attachment-form/upload-bordereaux-from-email-attachment-form.component';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, FormsModule, ReactiveFormsModule, AppMaterialModule, NotesModule, EditorModule],
  declarations: [
    ActivityDocumentPackListComponent,
    ActivityListComponent,
    CaseActivityPriorityNotesListComponent,
    ActivityListFilterComponent,
    CaseActivityTimerListComponent,
    ActivityDocumentPackCreateFormComponent,
    ActivityEmailComponent,
    ActivityOutboundCallComponent,
    ActivityDocumentCreateFormComponent,
    ActivityDocumentUploadFormComponent,
    CaseActivityFormComponent,
    CaseActivityPauseAllComponent,
    TimerControlComponent,
    CaseActivityTimeSearchListListComponent,
    CaseActivityTimeSearchListListFilterComponent,
    ActivityRecordedTimeListComponent,
    ActivityRecordedTimeFormComponent,
    ActivityTaskFormComponent,
    RecordedTimeAmendFormComponent,
    ActivityEmailAttachmentComponent,
    ActivityFilePreviewComponent,
    UploadBordereauxFromEmailAttatchmentFormComponent,
  ],
  exports: [
    ActivityDocumentPackListComponent,
    ActivityListComponent,
    CaseActivityPriorityNotesListComponent,
    ActivityListFilterComponent,
    CaseActivityTimerListComponent,
    CaseActivityPauseAllComponent,
    CaseActivityTimeSearchListListComponent,
    CaseActivityTimeSearchListListFilterComponent,
    ActivityRecordedTimeListComponent,
    ActivityEmailAttachmentComponent,
    ActivityFilePreviewComponent
  ],
  entryComponents: [
    ActivityDocumentPackCreateFormComponent,
    ActivityEmailComponent,
    ActivityOutboundCallComponent,
    ActivityDocumentCreateFormComponent,
    ActivityDocumentUploadFormComponent,
    CaseActivityFormComponent,
    ActivityRecordedTimeFormComponent,
    RecordedTimeAmendFormComponent,
    ActivityTaskFormComponent,
    UploadBordereauxFromEmailAttatchmentFormComponent,
  ],
  providers: [
    ActivityDocumentPackService,
    ActivityListService,
    CaseActivityPriorityNoteListService,
    CaseActivityEmailService,
    CaseActivityOutboundCallService,
    ActivityDocumentCreateService,
    ActivityDocumentUploadService,
    TimerListService,
    ActivityTaskListService,
    BordereauxImportListService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: KALEIDA_UK_DATE_FORMATS }
  ]
})
export class ActivitiesModule {}
