import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'app/core/security/auth/auth.service';
import { UsersService } from 'app/modules/user-management/services/users.service';
import { SystemRole } from 'app/shared/enumeration/system-role';
import { CookieService } from 'app/shared/services/cookie.service';

@Injectable()
export class UserPermissionGuardService implements CanActivate {
  constructor(
    protected usersService: UsersService,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected cookieService: CookieService
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let canActivate = false;

    if (route.data.allowAnonymous && route.data.allowAnonymous === true) {
      return Promise.resolve(true);
    }

    const url: string = state.url;

    let redirect = 'login';
    if (route.data.redirect) {
      redirect = route.data.redirect;
    }


    // Check permissions over roles so that permissions can be kept in one place - the usersService
    if (route.data.permission) {
      canActivate = await this.usersService.hasPermission(route.data.permission);
    } else if (!route.data.roles || route.data.roles.length == 0) {
      canActivate = false;
    } else {
      canActivate = await this.checkRoles(route.data.roles);
    }

    if (!canActivate && redirect !== '') {
      debugger;
      this.router.navigate([redirect]);
    }

    return Promise.resolve(canActivate);
  }

  protected async checkRoles(allowedRoles: SystemRole[]): Promise<boolean> {
    let isInRole = false;
    await this.usersService.getUserRoles().then((userRoles: SystemRole[]) => {
      const intersect = allowedRoles.filter(value => -1 !== userRoles.indexOf(value));
      isInRole = intersect.length > 0;
    });

    return Promise.resolve(isInRole);
  }
}
