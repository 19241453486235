import { Injectable } from '@angular/core';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { DataService } from 'app/shared/services/data.service';

@Injectable()
export class GoogleMapsService extends DataService<any> {
  constructor(private apiDependencies: ApiDependenciesService) {
    super(apiDependencies);
  }

  getCreateIntegrationLogEndpoint(): string {
    return '/application/integration-log/google-maps';
  }

  async createIntegrationLogItem(item: any) {
    await this.setEndpoint(this.getCreateIntegrationLogEndpoint())
      .save(item)
      .then(() => {
      })
      .catch(() => {
      });
  }
}
