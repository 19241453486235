import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges
} from "@angular/core";

@Component({
  selector: "list-page-jumper",
  templateUrl: "./list-page-jumper.component.html",
  styles: [
    `
      .page-select {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.54);
      }
      .label {
        margin: 0 4px;
      }
      .mat-select,
      .mat-form-field {
        width: 50px !important;
      }
    `
  ]
})
export class ListPageJumperComponent implements OnChanges {
  @Input() page;
  @Input() pageNumber;
  @Input() pageCount;
  @Input() itemsCount;
  @Input() pageSize;
  @Output() pageIndexChanged = new EventEmitter<number>();

  constructor() {}

  get jumpOptions(): number[] {
    let arr = [];
    let numberOfPages = this.itemsCount / this.pageSize;
    let numberOfJumps = numberOfPages / 10;

    for (let index = 1; index < numberOfJumps; index++) {
      arr.push(index * 10);
    }

    return arr;
  }

  ngOnChanges(): void {}

  updatePage(index) {
    
    this.pageIndexChanged.emit(index);
  }
}
