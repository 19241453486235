import { Component, OnInit } from '@angular/core';
import { isDevMode } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ChildActivationEnd, ActivationEnd } from '@angular/router';
//import { SidebarService } from 'app/layout/services/sidebar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(router: Router, activatedRoute: ActivatedRoute) {
    if (isDevMode()) {
      console.log('----------DEVELOPMENT MODE-------------');
    }

  }
}
