import { ApiEntity } from 'app/api/models/api-entity.model';
export class CollectionResponse<T extends ApiEntity> {
    itemsCount: number;
    pages: number;
    page: number;
    items: T[];

    constructor(
        itemsCount: number,
        pages: number,
        page: number,
        items: T[]
    ) {
        this.itemsCount = itemsCount;
        this.pages = pages;
        this.page = page;
        this.items = items;
    }
}
