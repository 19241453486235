import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { CostPaymentRequestListItemModel } from './cost-payment-request-list-item.model';

export class CostPaymentRequestListItemViewModel extends ActionableItemState {
  authoriseButton: IIconActionButton;
  editButton: IIconActionButton;
  rejectButton: IIconActionButton;
  expanded = false;
  
  constructor(dataModel: CostPaymentRequestListItemModel) {
    super();
    this.dataModel = dataModel;

    const cannotActionDisableMessage = dataModel.cannotActionMessage;
    const editDisableMessage = !this.dataModel.canAction ? cannotActionDisableMessage : this.dataModel.cannotEditReason;
    const authoriseDisableMessage = !this.dataModel.canAction ? cannotActionDisableMessage : this.dataModel.cannotAuthoriseReason;
    const rejectDisableMessage = !this.dataModel.canAction ? cannotActionDisableMessage : this.dataModel.cannotRejectReason;

    this.authoriseButton = {
      visible: true,
      enabled: true,
      actionEnabled: dataModel.canAction && this.dataModel.canAuthorise,
      actionDisabledMessage: authoriseDisableMessage,
      iconName: 'check',
      actionName: 'authorise',
      hoverText: 'Authorise the Cost',
      class: '',
      buttonText: '',
    };

    this.editButton = {
      visible: true,
      enabled: true,
      actionEnabled: dataModel.canAction && dataModel.canEdit,
      actionDisabledMessage: editDisableMessage,
      iconName: 'edit',
      actionName: 'edit',
      hoverText: 'Edit the Cost',
      class: '',
      buttonText: '',
    };

    this.rejectButton = {
      visible: true,
      enabled: true,
      actionEnabled: dataModel.canAction  && dataModel.canReject,
      actionDisabledMessage: rejectDisableMessage,
      iconName: 'clear',
      actionName: 'reject',
      hoverText: 'Reject the Cost',
      class: '',
      buttonText: '',
    };
 
    this.processActions.push(this.authoriseButton);
    this.processActions.push(this.rejectButton);
    this.processActions.push(this.editButton);
  }
}
