exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".note-meta {\n  font-size: 12px;\n  color: #868e96; }\n\n.note-content {\n  font-size: 16px; }\n\n.note-container {\n  background-color: #e9ecef;\n  margin-bottom: 5px;\n  border-radius: 7px;\n  padding: 5px 10px; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();