import { Injectable } from '@angular/core';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { DataService } from 'app/shared/services/data.service';
import { PostcodeLookupResponseModel } from '../models/postcode-lookup.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable()
export class PostcodeLookupService extends DataService<any> {

    @BlockUI() blockUI: NgBlockUI;
    constructor(private apiDepenencies: ApiDependenciesService) {
        super(apiDepenencies);
    }

    getPostcodeLookupEndpoint(postcode: string): string {
        return '/application/post-code-lookup/' + postcode;
        }

    getMappedEditableItemDataModel(response: any) {
        let model: PostcodeLookupResponseModel = response;
        return model;
        }

    async getPostcodeLookupResponse(postcode: string): Promise<PostcodeLookupResponseModel> {	
        this.startBlockUI();
        postcode = postcode.replace(/\s/g, '');
        let model: PostcodeLookupResponseModel;
        await this
            .setEndpoint(this.getPostcodeLookupEndpoint(postcode))
            .getSingle().then((response) => {
                model = this.getMappedEditableItemDataModel(response);
                this.stopBlockUI();
            })
            .catch(() => {
                this.stopBlockUI();
            });
        return Promise.resolve(model);
    }
    
    stopBlockUI() {
        this.blockUI.stop();
    }

    startBlockUI() {
        this.blockUI.start();
    }

}
