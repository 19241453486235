import { Component, OnInit, Output, EventEmitter, NgZone, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CookieService } from 'app/shared/services/cookie.service';
import { Router, NavigationExtras } from '@angular/router';
import { Constants } from 'app/core/models/constants';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { AuthService } from 'app/core/security/auth/auth.service';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ComponentItem } from 'app/shared/base/aside/component-item';
import { UserResetPasswordComponent } from 'app/layout/components/user-reset-password/user-reset-password.component';
import { UsersService } from 'app/modules/user-management/services/users.service';
import { MessageFromServer, TaskHubService, ActivityTimerHubService } from 'app/shared/services/signalr.service';
import { AsideActivityTimerService } from '../components/aside-activity-timers/aside-activity-timer.service';
import { SnackBarService } from 'app/shared/services/snack-bar.service';
import { TelephonyService } from 'app/shared/services/telephony.service';
import { CallDetails, CallDirections } from 'app/shared/kaleida.telephony/Contract_Types';
import { HeaderService } from 'app/layout/services/header.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  loggedInUserName: string;
  loggedInFullName: string;
  loggedInUserIsInternal: boolean;
  loggedInExtension: string;

  isOnline: boolean;

  taskRedCount = 0;
  taskAmberCount = 0;
  taskGreenCount = 0;

  divisionId: number;
  logoImageSource = '/assets/images/logo.png';

  incomingCli;
  incomingCall: boolean;
  incomingCallId: string = null;
  incomingCallRinging: boolean;
  incomingCallNumber: string;
  incomingCallTaskId: number = null;
  incomingCallRecordingId: number = null;
  groupCallItemId: number = null;

  @Output() toggleMenuEvent = new EventEmitter();

  taskhubSubscription: Subscription;
  activityHubMessageRecievedSubscription: Subscription;
  activityHubConnectionChangedSubscription: Subscription;
  telephonyPhoneRingingSubscription: Subscription;
  telephonyCallAnsweredSubscription: Subscription;
  telephonyLoggedInSubscription: Subscription;
  telephonyCallClearedSubscription: Subscription;

  connectionStatus = 'waiting...';
  connectionStatusClass = 'timerConnected';
  activityHubConnected = false;

  constructor(
    protected usersService: UsersService,
    private cookieService: CookieService,
    private router: Router,
    private apiDependencies: ApiDependenciesService,
    private auth: AuthService,
    private asideService: AsideService,
    private asideActivityTimerService: AsideActivityTimerService,
    taskHubService: TaskHubService,
    private snackBar: SnackBarService,
    private activityTimerHubService: ActivityTimerHubService,
    private zone: NgZone,
    private telephonyService: TelephonyService,
    private headerService: HeaderService,
    private ref: ChangeDetectorRef,
  ) {
    this.taskhubSubscription = taskHubService.onMessageReceivedFromServer$.subscribe((message: MessageFromServer) => {
      if (message.method === 'StatusUpdate' && message.data === 'RAG Change') {
        this.UpdateTaskStatusSummary();
      }

      console.debug(message);
    });

    this.activityHubMessageRecievedSubscription = activityTimerHubService.onMessageReceivedFromServer$.subscribe((message: MessageFromServer) => {
      this.processActivityHubMessage(message);
    });

    // initialise the activity hub management properties and subscibe to chnages
    this.activityHubConnected = this.activityTimerHubService.isConnected;
    this.connectionStatusClass = this.activityHubConnected ? 'timerConnected' : 'timerDisconnected';

    this.activityHubConnectionChangedSubscription = activityTimerHubService.onConnectionStatusChanged$.subscribe((message: any) => {
      this.connectionStatus = message.message;
      switch (message.message) {
        case 'connecting':
        case 'reconnecting':
          this.activityHubConnected = false;
          this.connectionStatusClass = 'timerConnecting';
          break;
        case 'connected':
          this.activityHubConnected = true;
          this.connectionStatusClass = 'timerConnected';
          this.activityTimerHubService.requestActivityStatus();
          break;
        case 'disconnected':
          this.activityHubConnected = false;
          this.connectionStatusClass = 'timerDisconnected';
          break;
        default:
          this.activityHubConnected = false;
          break;
      }
    });

    // Telephony Integration
    // This may need to change to support transfers, as with transfers we may get multiple ringing / answered events relating to the different calls involved in the transfer

    this.telephonyPhoneRingingSubscription = telephonyService.onPhoneRinging$.subscribe((callDetails: CallDetails) => {
      if (this.divisionLoaded) {
        if (callDetails.CallDirection === CallDirections.Inbound) {
          this.incomingCall = true;
          this.incomingCallRinging = true;
          this.incomingCallNumber = callDetails.CLI;
        }
      }
    });

    this.telephonyCallAnsweredSubscription = telephonyService.onCallAnswered$.subscribe((callDetails: CallDetails) => {
      if (this.divisionLoaded && callDetails.CallDirection === CallDirections.Inbound) {
        console.log('Creating group call on Call Answered');

        this.incomingCallRecordingId = null;
        this.incomingCallId = callDetails.CallId;
        this.telephonyService.getRecordingDetails().then((recording) => {
          if (this.incomingCallId == recording.CallId) {
            console.log('Obtained recording ID ' + recording.RecId + ' for call ' + recording.CallId);
            this.incomingCallRecordingId = recording.RecId;
          }
        });

        this.createGroupCallItem(callDetails, true).then(() => {
          this.incomingCallRinging = false;
          this.screenPopCall(callDetails);
        });
      }
    });

    this.telephonyLoggedInSubscription = telephonyService.onLoggedIn$.subscribe(() => {
      this.activityTimerHubService.requestDndStatus();
    });

    this.telephonyCallClearedSubscription = telephonyService.onCallCleared$.subscribe((callDetails: CallDetails) => {
      if (this.divisionLoaded && callDetails.CallDirection === CallDirections.Inbound) {
        if (this.groupCallItemId === null || this.groupCallItemId == undefined) {
          // missed call
          console.log('Creating group call on Call Cleared (for missed calls)');
          this.createGroupCallItem(callDetails, false);
        } else {
          console.log('Updating call on Call Cleared (updating call length). Group Call Item Id: ' + this.groupCallItemId);
          this.updateGroupCallItem(this.groupCallItemId, callDetails);
        }
        this.incomingCallNumber = null;
        this.incomingCall = false;
      } else {
        console.debug('header.component.ts ignored call cleared event. DivisionLoaded==' + this.divisionLoaded + ', callDirection==' + callDetails.CallDirection);
      }
    });
  }

  ngOnDestroy() {
    this.taskhubSubscription.unsubscribe();

    this.activityHubMessageRecievedSubscription.unsubscribe();
    this.activityHubConnectionChangedSubscription.unsubscribe();

    this.telephonyPhoneRingingSubscription.unsubscribe();
    this.telephonyCallAnsweredSubscription.unsubscribe();
    this.telephonyLoggedInSubscription.unsubscribe();
    this.telephonyCallClearedSubscription.unsubscribe();
  }

  private screenPopCall(callDetails: CallDetails) {
    if (this.groupCallItemId !== null) {
      const navigationExtras: NavigationExtras = {
        queryParams: { incomingCli: callDetails.CLI, groupCallItemId: this.groupCallItemId, taskId: this.incomingCallTaskId },
      };
      this.router.navigate(['/divisions', this.divisionId, 'products', 'screen-pop', 'pop'], navigationExtras);
    }
  }

  private createUpdateModel(callDetails: CallDetails, answered: boolean) {
    return {
      divisionId: this.divisionId,
      phoneNumber: callDetails.CLI,
      extension: this.cookieService.getLoggedInExtension(),
      answered: answered,
      talkTimeSeconds: callDetails.TalkTimeSeconds,
      recordingId: this.incomingCallRecordingId,
    };
  }

  private async createGroupCallItem(callDetails: CallDetails, answered: boolean) {
    const model = this.createUpdateModel(callDetails, answered);

    await this.headerService.addNewGroupCallItem(model).then((e) => {
      if (answered) {
        this.groupCallItemId = e.groupCallItemId;
        this.incomingCallTaskId = e.workflowTaskId;
      }
    });
  }

  private async updateGroupCallItem(id: number, callDetails: CallDetails) {
    const model = this.createUpdateModel(callDetails, true);

    console.debug('updateGroupCallItem() - ID ' + id + ', recording ID: ' + this.incomingCallRecordingId);

    await this.headerService.updateGroupCallItem(id, model).then((e) => {
      this.groupCallItemId = e.groupCallItemId;
      this.incomingCallTaskId = e.workflowTaskId;
    });
  }

  get divisionLoaded(): boolean {
    return this.divisionId !== null && this.divisionId !== undefined;
  }

  processActivityHubMessage(message: MessageFromServer) {
    switch (message.method) {
      case 'PauseAllComplete':
        if (message.data.RequiresSignOut === true) {
          // use zone as this is being handled in response to signalr message which isn't related to angular code.
          // handles "Navigation triggered outside Angular zone" error
          this.zone.run(() => this.logout(false));
        }
        break;
      case 'DndElapsed':
        this.zone.run(() => this.turnOffDnd());
        break;
      case 'UserDndStatus':
        this.zone.run(() => this.checkUserDndStatus(message.data));
        break;
      default:
        console.log('Message not processed: ' + message.method);
        break;
    }
  }

  checkUserDndStatus(dndSet: boolean) {
    if (dndSet === true) {
      this.turnOnDnd();
    }
  }

  turnOffDnd() {
    console.debug('Turn off Dnd');
    this.activityTimerHubService.notifyDndTurnedOff();

    if (this.telephonyService.isLoggedIn) {
      this.telephonyService.setDnd(false);
    } else {
      // this.snackBar.info('Telephony (logged out): Set DND Off');
    }
  }

  turnOnDnd() {
    console.debug('Turn on Dnd');
    if (this.telephonyService.isLoggedIn) {
      this.telephonyService.setDnd(true);
    } else {
      // this.snackBar.info('Telephony (logged out): Set DND On');
    }
  }

  ngOnInit() {
    this.loggedInUserName = this.cookieService.getLoggedInUserName();
    this.loggedInFullName = this.cookieService.getLoggedInFullName();
    this.loggedInUserIsInternal = this.cookieService.getLoggedInUserIsInternal();
    this.loggedInExtension = this.cookieService.getLoggedInExtension();

    this.usersService.themeName.subscribe((themeId) => {
      this.divisionId = themeId;
      if (themeId) {
        this.logoImageSource = '/assets/images/' + themeId + '/logo.png';
      } else {
        this.logoImageSource = '/assets/images/logo.png';
      }
      this.ref.detectChanges();
      this.UpdateTaskStatusSummary();
    });

    this.UpdateTaskStatusSummary();
  }

  UpdateTaskStatusSummary() {
    if (this.divisionLoaded) {
      this.headerService.getTaskStatusSummary(this.divisionId).then((response: any) => {
        if (response !== null) {
          this.taskRedCount = response.redCount;
          this.taskAmberCount = response.amberCount;
          this.taskGreenCount = response.greenCount;
        }
      });
    }
  }

  // pause timers if called from menu option
  logout(pauseTimers: boolean = true): void {
    if (pauseTimers === true) {
      this.activityTimerHubService.pauseAllTimers({ reason: null, period: null });
    }

    localStorage.removeItem(Constants.LocalStorage.KeyAccessToken);
    localStorage.removeItem(Constants.LocalStorage.KeyRefreshToken);
    this.cookieService.delete(Constants.Cookies.KeyLoggedInUserId);
    this.cookieService.delete(Constants.Cookies.KeyLoggedInUserName);
    this.cookieService.delete(Constants.Cookies.KeyLoggedInFullName);
    this.cookieService.delete(Constants.Cookies.KeyDivisionTheme);
    this.cookieService.delete(Constants.Cookies.KeyLoggedInUserExtension);
    this.cookieService.delete(Constants.Cookies.KeyLoggedInUserIsInternal);

    // TODO: [Review][DPB][2019-11-21] auth.logout logs out of phone system so is DND functionality above redundant?
    if (this.telephonyService.isLoggedIn) {
      this.telephonyService.setDnd(true);
    } else {
      // this.snackBar.info('Telephony: Set DND On');
    }

    this.auth.logout();

    this.router.navigateByUrl('/login');
  }

  resetPassword(): void {
    const cookieValue = this.apiDependencies.cookieService.get(Constants.Cookies.KeyLoggedInUserId);
    const loggedInUserId = +cookieValue;
    const userId: number = this.auth.loggedInUserId && this.auth.loggedInUserId !== -1 ? this.auth.loggedInUserId : loggedInUserId;

    this.asideService.setComponent(new ComponentItem(UserResetPasswordComponent, { itemId: userId, resetOwnPassword: true }));
    this.asideService.open();
  }

  navigateToUserManagement() {
    this.router.navigate(['configuration/users']);
  }

  toggleMenu() {
    this.toggleMenuEvent.emit();
  }

  toggleTimer() {
    if (this.activityHubConnected === false) {
      this.activityTimerHubService.reconnect().then(() => {});
    } else {
      this.asideActivityTimerService.toggle();
    }
  }

  public answerCall() {
    this.asideActivityTimerService.close();
    this.telephonyService.answerCall();
  }
}
