exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".officeFilePreviewExternalContent {\n  width: 100%;\n  height: 300px;\n  overflow: auto; }\n\n.officePreview {\n  border: none; }\n\n.borderless {\n  border: none; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();