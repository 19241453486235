import { Injectable, EventEmitter } from '@angular/core';
import { ComponentItem } from 'app/shared/base/aside/component-item';

@Injectable()
export class AsideActivityTimerService {

  asideOpen = new EventEmitter<void>();
  asideClose = new EventEmitter<void>();
  public isOpen = false;

  constructor() { }

  public open(): void {
    this.isOpen = true;
    this.asideOpen.emit();
  }

  public close(): void {
    this.isOpen = false;
    this.asideClose.emit();
  }

  public toggle(): void {
    this.isOpen === true ? this.close() : this.open();
  }
}
