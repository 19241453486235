

export class CaseActivityTimeSearchListListFilterModel  {
  constructor(    public dateFrom: Date = new Date(), 
    public dateTo: Date = new Date(), 
    public caseReference: string = '', 
    public colleagueId: number = 0, 
    public recordedTimeItemId: number = null, 
    public amendStatus: number = 0, 
  ) {      
  
  }
}
