import { ComponentRef, Directive, ElementRef, HostListener, Input, OnInit, TemplateRef } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { KaleidaPopoverComponent } from './pop-over.component';

// https://indepth.dev/tooltip-with-angular-cdk/
// https://www.youtube.com/watch?reload=9&v=2SnVxPeJdwE
// https://stackblitz.com/edit/ngtemplateoutletcontext?file=src%2Fapp%2Fclient-2%2Fclient-2.component.html
// https://netbasal.com/creating-powerful-components-with-angular-cdk-2cef53d81cea




@Directive({ selector: '[kaleidaPopover]' })
export class KaleidaPopoverDirective implements OnInit {
  @Input('kaleidaPopover') text = '';
  @Input() headerText = '';
  @Input() footerText = '';
  @Input() popoverData = null;
  @Input() contentTemplate: TemplateRef<any>;
  @Input() delay = 1000;

  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay, private overlayPositionBuilder: OverlayPositionBuilder, private elementRef: ElementRef) {}

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: -8,
      },
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: 8,
      },
    ]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseenter')
  show() {
    const popoverRef: ComponentRef<KaleidaPopoverComponent> = this.overlayRef.attach(new ComponentPortal(KaleidaPopoverComponent));
    popoverRef.instance.text = this.text;
    popoverRef.instance.header = this.headerText;
    popoverRef.instance.footer = this.footerText;
    popoverRef.instance.popoverData = this.popoverData;
    popoverRef.instance.contentTemplate = this.contentTemplate;
  }

  @HostListener('click')
  //@HostListener('mouseout')
  @HostListener('mouseleave')
  hide() {
    this.overlayRef.detach();
  }

  
}

