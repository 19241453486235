import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'urlLink'})
export class UrlLinkPipe implements PipeTransform {
    transform(value: string, target: string = '') {
        if (target) {
            return '<a href="' + value + '" target="' + target + '">' + value + '</a>';
        } else {
            return '<a href="' + value + '">' + value + '</a>';
        }
    }
}
