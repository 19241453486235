import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { ActivityListItemModel } from './activity-list-item.model';
import { CaseActivityListMode } from './CaseActivityListMode';
import { SystemActivityType } from 'app/shared/enumeration/system-activity-types';
import { ListItemViewBaseModel } from 'app/shared/models/list-item-view-base.model';

export class ActivityListItemViewModel extends ListItemViewBaseModel<ActivityListItemModel> {
  expanded = false;
  downloadButton: IIconActionButton;
  viewFileButton: IIconActionButton;
  editButton: IIconActionButton;
  notesButton: IIconActionButton;
  reAssociateButton: IIconActionButton;
  emailReplyButton: IIconActionButton;
  emailButton: IIconActionButton;
  emailDocumentButton: IIconActionButton;
  emailUploadedDocumentButton: IIconActionButton;
  removeFromTribunalPackButton: IIconActionButton;
  addTimeButton: IIconActionButton;
  playButton: IIconActionButton;
  emailDocumentPackButton: IIconActionButton;
  togglePublishToPortalButton: IIconActionButton;
  createBatchedCostsButton: IIconActionButton;

  constructor(dataModel: ActivityListItemModel, mode: CaseActivityListMode) {
    super();

    this.dataModel = dataModel;

    const downloadPossible = dataModel.systemActivityType === SystemActivityType.DocumentUpload || dataModel.systemActivityType === SystemActivityType.DocumentCreate;
    const notesViewable = dataModel.systemActivityType === SystemActivityType.Task;
    const activityTypeIsOutboundEmail = dataModel.systemActivityType === SystemActivityType.EmailOutbound;
    const activityTypeIsInboundEmail = dataModel.systemActivityType === SystemActivityType.EmailInbound;
    const activityTypeIsInboundCall = dataModel.systemActivityType === SystemActivityType.PhoneCallInbound;

    const canEdit = mode === CaseActivityListMode.History; // && !activityTypeIsInboundEmail && !activityTypeIsOutboundEmail;
    const canAddTime = mode === CaseActivityListMode.History;
    const canViewNotes = mode === CaseActivityListMode.History && notesViewable;
    const canReplyToEmail = mode === CaseActivityListMode.History && activityTypeIsInboundEmail;
    const canReassociate = mode === CaseActivityListMode.History && (activityTypeIsInboundEmail || activityTypeIsInboundCall);

    const canTogglePublishToPortal =
      mode === CaseActivityListMode.History &&
      (dataModel.systemActivityType === SystemActivityType.DocumentUpload ||
        dataModel.systemActivityType === SystemActivityType.DocumentCreate ||
        dataModel.systemActivityType === SystemActivityType.Note ||
        dataModel.systemActivityType === SystemActivityType.DocumentPackCreate ||
        activityTypeIsInboundEmail ||
        activityTypeIsOutboundEmail);

    const canEmail = activityTypeIsOutboundEmail;
    const canRemoveFromTribunal = mode === CaseActivityListMode.TribunalPackContents;
    const canDownload = mode !== CaseActivityListMode.TribunalPackSelection && downloadPossible;
    const canPlayback = dataModel.recordingId != null && !isNaN(dataModel.recordingId);

    const canCreateBatchedCosts = dataModel.allowBatchedCostCreation;

    if (canCreateBatchedCosts) {
      this.createBatchedCostsButton = {
        visible: true,
        enabled: true,
        actionEnabled: true,
        actionDisabledMessage: '',
        iconName: 'file_present',
        actionName: 'createBatchedCosts',
        hoverText: 'Create Batched Costs',
        class: '',
        buttonText: '',
      };
      this.processActions.push(this.createBatchedCostsButton);
    }

    if (canEdit) {
      this.editButton = {
        visible: true,
        enabled: true,
        actionEnabled: this.dataModel.canEdit,
        actionDisabledMessage: this.dataModel.cannotEditReason,
        iconName: 'edit',
        actionName: 'edit',
        hoverText: 'Edit the item',
        class: '',
        buttonText: '',
      };
      this.processActions.push(this.editButton);
    }

    if (canAddTime) {
      this.addTimeButton = {
        visible: true,
        enabled: true,
        actionEnabled: true,
        actionDisabledMessage: '',
        iconName: 'timer',
        actionName: 'addRecordedTime',
        hoverText: 'Add recorded time to the activity',
        class: '',
        buttonText: '',
      };
      this.processActions.push(this.addTimeButton);
    }

    if (canViewNotes) {
      this.notesButton = {
        visible: true,
        enabled: true,
        actionEnabled: true,
        actionDisabledMessage: '',
        iconName: 'note',
        actionName: 'notes',
        hoverText: 'Notes the item',
        class: '',
        buttonText: '',
      };
      this.processActions.push(this.notesButton);
    }

    if (canReassociate) {
      this.reAssociateButton = {
        visible: true,
        enabled: true,
        actionEnabled: true,
        actionDisabledMessage: '',
        iconName: 'forward',
        actionName: 'reassociate',
        hoverText: 'Reassociate the item',
        class: '',
        buttonText: '',
      };
      this.processActions.push(this.reAssociateButton);
    }

    if (canReplyToEmail) {
      this.emailReplyButton = {
        visible: true,
        enabled: true,
        actionEnabled: true,
        actionDisabledMessage: this.dataModel.cannotEditReason,
        iconName: 'reply',
        actionName: 'emailReplyItem',
        hoverText: 'Reply to email',
        class: '',
        buttonText: '',
      };
      this.processActions.push(this.emailReplyButton);
    }


    if (canRemoveFromTribunal) {
      this.removeFromTribunalPackButton = {
        visible: true,
        enabled: true,
        actionEnabled: true,
        actionDisabledMessage: '',
        iconName: 'delete',
        actionName: 'removeFromTribunalPack',
        hoverText: 'Remove item from pack',
        class: '',
        buttonText: '',
      };
      this.processActions.push(this.removeFromTribunalPackButton);
    }

    if (canDownload) {
      this.downloadButton = {
        visible: true,
        enabled: true,
        actionEnabled: true,
        actionDisabledMessage: '',
        iconName: 'cloud_download',
        actionName: 'download',
        hoverText: 'Download the item',
        class: '',
        buttonText: '',
      };
      this.processActions.push(this.downloadButton);

      this.viewFileButton = {
        visible: true,
        enabled: true,
        actionEnabled: true,
        actionDisabledMessage: '',
        iconName: 'visibility',
        actionName: 'viewFile',
        hoverText: 'Open file in browser',
        class: '',
        buttonText: '',
      };
      this.processActions.push(this.viewFileButton);
    }

    if (canPlayback) {
      this.playButton = {
        visible: true,
        enabled: true,
        actionEnabled: true,
        actionDisabledMessage: '',
        iconName: 'headset',
        actionName: 'listenToCall',
        hoverText: 'Listen to call',
        class: '',
        buttonText: '',
      };
      this.processActions.push(this.playButton);
    }

    if (dataModel.systemActivityType === SystemActivityType.DocumentCreate) {
      this.emailDocumentButton = {
        visible: true,
        enabled: true,
        actionEnabled: true,
        actionDisabledMessage: this.dataModel.cannotEditReason,
        iconName: 'email',
        actionName: 'emailDocumentItem',
        hoverText: 'Send Document',
        class: '',
        buttonText: '',
      };
      this.processActions.push(this.emailDocumentButton);
    }

    if (dataModel.systemActivityType === SystemActivityType.DocumentUpload) {
      this.emailUploadedDocumentButton = {
        visible: true,
        enabled: true,
        actionEnabled: true,
        actionDisabledMessage: this.dataModel.cannotEditReason,
        iconName: 'email',
        actionName: 'emailUploadedDocumentItem',
        hoverText: 'Send Uploaded Document',
        class: '',
        buttonText: '',
      };
      this.processActions.push(this.emailUploadedDocumentButton);
    }

    if (dataModel.systemActivityType === SystemActivityType.DocumentPackCreate) {
      this.emailDocumentPackButton = {
        visible: true,
        enabled: true,
        actionEnabled: true,
        actionDisabledMessage: this.dataModel.cannotEditReason,
        iconName: 'email',
        actionName: 'emailDocumentPackItem',
        hoverText: this.dataModel.isQuotePack ? 'Send/Resend Quote' : 'Send Document Pack',
        class: '',
        buttonText: '',
      };
      this.processActions.push(this.emailDocumentPackButton);
    }

    if (canTogglePublishToPortal) {
      this.togglePublishToPortalButton = {
        visible: true,
        enabled: true,
        actionEnabled: true,
        actionDisabledMessage: this.dataModel.cannotEditReason,
        iconName: 'publish',
        actionName: 'togglePublishToPortal',
        hoverText: this.dataModel.publishToPortal ? 'Unpublish from Portal' : 'Publish to Portal',
        class: this.dataModel.publishToPortal ? 'primary-color' : '',
        buttonText: '',
      };
      this.processActions.push(this.togglePublishToPortalButton);
    }
  }
}
