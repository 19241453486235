import { Injectable } from '@angular/core';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { DataService } from 'app/shared/services/data.service';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CompanyCreditLookupResponseModel } from '../models/company-credit-lookup.model';

@Injectable()
export class CompanyCreditLookupService extends DataService<any> {
  @BlockUI() blockUI: NgBlockUI;
  constructor(private apiDepenencies: ApiDependenciesService) {
    super(apiDepenencies);
  }

  getCompanyCreditLookupEndpoint(companyId: string): string {
    return '/application/company-credit-lookup/' + companyId;
  }

  getMappedEditableItemDataModel(response: any) {
    const model: CompanyCreditLookupResponseModel = response;
    return model;
  }

  async getCompanyCreditLookupResponse(companyId: string): Promise<CompanyCreditLookupResponseModel> {
    this.startBlockUI();

    let model: CompanyCreditLookupResponseModel;
    await this.setEndpoint(this.getCompanyCreditLookupEndpoint(companyId))
      .getSingle()
      .then(response => {
        model = this.getMappedEditableItemDataModel(response);
        this.stopBlockUI();
      })
      .catch(() => {
        this.stopBlockUI();
      });
    return Promise.resolve(model);
  }

  stopBlockUI() {
    this.blockUI.stop();
  }

  startBlockUI() {
    this.blockUI.start();
  }
}
