import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { CookieService } from "app/shared/services/cookie.service";
import { HttpClient } from "@angular/common/http";
import { SnackBarService } from "app/shared/services/snack-bar.service";

@Injectable()
export class ApiDependenciesService {
  constructor(public http: HttpClient, public router: Router, public snackBar: SnackBarService, public cookieService: CookieService) {}
}
