import {Component, Input} from '@angular/core';
import {DynamicComponent} from "app/shared/base/aside/dynamic-component";

@Component({
  selector: 'default-aside',
  template: `
    <h4>Loading...</h4>
  `
})
export class DefaultAsideComponent implements DynamicComponent {
  constructor() { }
  @Input() data: any;
}
