import { Injectable } from "@angular/core";
import { ApiDependenciesService } from "app/api/services/api-dependencies.service";
import { DataService } from "app/shared/services/data.service";
import { TimeModel } from "./time.model";
import { TimeProviderDetailModel } from "../time-provider/time-provider-detail.model";

@Injectable()
export class TimeService extends DataService<any> {
  constructor(protected dependencies: ApiDependenciesService) {
    super(dependencies);

  }

  async getCurrentTime(): Promise<TimeModel> {
    let model: TimeModel;
    await this.setEndpoint("/timeprovider/now")
      .getSingle()
      .then(response => {
        model = new TimeModel(null, response.currentDateTime, response.showTimeProvider);
      });
    return Promise.resolve(model);
  }  

  async getEditableItemDetail(): Promise<TimeProviderDetailModel>{
    let model: TimeProviderDetailModel;
    await this.setEndpoint("/timeprovider/detail")
      .getSingle()
      .then(response => {
        model = new TimeProviderDetailModel(null, response.now,  response.realTime,  response.fixedTime,  response.baseDate,  response.incrementDays,  response.incrementTime );
      });
    return Promise.resolve(model);
  }

  public async updateItem(formData: any) {
    return this.setEndpoint('/timeprovider/detail').update(formData);
  }
}
