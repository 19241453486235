import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from 'app/core/app-material/app-material.module';
import { LayoutRoutesModule } from 'app/layout/layout-routes/layout-routes.module';
import { TimeProviderCreateUpdateComponent } from 'app/modules/configuration/time-provider/time-provider-create-update/time-provider-create-update.component';
import { BlockUIModule } from 'ng-block-ui';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [TimeProviderCreateUpdateComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule,
    SharedModule,
    AppMaterialModule,
    LayoutRoutesModule // this must be last
  ],
  exports: [],
  entryComponents: [TimeProviderCreateUpdateComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutTransferModule {}
