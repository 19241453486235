exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "table {\n  width: 100%;\n  table-layout: fixed; }\n\n.table-container {\n  overflow: auto;\n  width: 100%;\n  height: calc(100% - 133px); }\n\nth.mat-header-cell,\ntd.mat-cell {\n  padding-left: 10px; }\n\ntr.mat-row:nth-child(odd) {\n  background-color: #efefef; }\n\n.mat-column-timerControls {\n  width: 60px; }\n\n.mat-column-actions {\n  width: 160px;\n  display: table-cell; }\n\n.mat-column-activityTypeName {\n  width: 25px; }\n\n.mat-column-activityDate {\n  width: 150px; }\n\n.mat-column-caseReference {\n  width: 200px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis; }\n\n.mat-column-elapsedTime {\n  width: 100px; }\n\n.mat-column-summary {\n  width: 300px; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();