export class Filter {
    constructor(
        public field: string = '',
        public operator: string = '',
        public value: any = null
    ) {
    }
}
export class FilterOperatorType {
    StartsWith = 'StartsWith';
    EndsWith = 'EndsWith';
    Contains = 'Contains';
    Equals = 'Equals';
    NotEquals = 'NotEquals';
    GreaterThan = 'GreaterThan';
    GreaterThanEqual = 'GreaterThanEqual';
    LessThan = 'LessThan';
    LessThanEqual = 'LessThanEqual';
    DoesNotContain = 'DoesNotContain';
}
