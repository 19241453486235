import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'warning-box',
  template: `
    <div class="warning-box" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
      <div fxFlex="10"><mat-icon>warning</mat-icon></div>
      <div fxFlex="90">
        {{ message }}
      </div>
    </div>
  `,
  styles: [
    `
      .warning-box {
        font-weight: bold;
        color: #ffffff;
        background-color: rgb(199, 119, 0);
        padding: 5px 10px;
        margin-bottom: 3px;
        margin-top: 10px;
      }
    `,
  ],
})
export class WarningBoxComponent implements OnInit {
  @Input() message = '';
  constructor() {}

  ngOnInit() {}
}
