import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MainMenuService {

  public menuVisible = true;

  private visibilityChanged = new BehaviorSubject<boolean>(null);
  // Observable visibilityChanged stream
  visibilityChanged$ = this.visibilityChanged.asObservable();

  constructor() { }

   toggleMenu() {
    this.menuVisible = !this.menuVisible;
    this.visibilityChanged.next(this.menuVisible);
  }

  closeMenu() {
    this.menuVisible = false;
    this.visibilityChanged.next(this.menuVisible);
  }

  openMenu() {
    this.menuVisible = true;
    this.visibilityChanged.next(this.menuVisible);
  }

}
