import moment from "moment";
import { CookieService } from "../services/cookie.service";
import { formatAsString } from "./date-helpers";
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { InvoiceRule} from "../enumeration/invoice-rule"
/**
 * Pad the beginning of a string with a given character until it reaches the length provided
 * @param {string} string - The string to pad
 * @param {number} length - The length of the string required after padding
 * @param {string} padWith - What to pad the string with
 * @example padStart('1', 3, '0') // returns '001'
 */
export function padStart(string: string, length: number, padWith: string) {
    const pad = new Array(1 + length).join(padWith);
    return (pad + string).slice(-pad.length);
}

export function integerValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value != null && (isNaN(value) || !Number.isInteger(+value))) {
        return { notInteger: true };
      }
      return null;
    };
  }

  export function invoiceRuleChecker(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = Number(control.value); 
        const numericEnumValues = Object.values(InvoiceRule).filter(v => typeof v === 'number');

        if (!isNaN(value)) {
            if (!numericEnumValues.includes(value)) {
                return { notInvoiceRule: true }; 
            }
        }
        return null;
    };
}

export class StringUtilities {

    public static generateNewGuid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    public static translateCR(source: string) {
        return source.replace(/(?:\r\n|\r|\n)/g, '<br />')
        
    }

    public static getUserDateTime(date) {
        const cs = new CookieService();
        
        const dateString = formatAsString(moment(date), 'YYYY-MM-DD @ HH:mm:SS')
        //return '<strong>[<time datetime="' + date.toString() + '">' + date.toDateString() + '</time>]</strong>';
        return '<i><strong>[' + cs.getLoggedInFullName() + ': ' + dateString + ']</strong></i>';
      };
      
    public static replaceCidImages() {
        const pattern = /cid:[\w.-]+/g;
        const replacement = 'data:image/gif;base64,R0lGODlhAQABAAAAADs=';
        const images = document.querySelectorAll('img');
    
        images.forEach(function(img) {
          if (pattern.test(img.src)) {
             img.src = replacement;
          }
        });
    }
}
