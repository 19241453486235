/**
 * Represents a type of instruction to send from the client to the server.
 * Copied (with slight C# to TS conversions) from ServiceLibrary ClientContract/Messages/MessagesCore
 */
export enum InboundMessageType {
    /** Does nothing, for testing communications only. */
    DoNothing = 0,
    /** Logs in for a specific phone extension. If extension is already logged in we'll reassocate it with the socket this request came in on. Data is the extension to use.*/
    LoginExtension = 1,
    /** Logs a client out. */
    Logout = 2,
    /** Retrieves the current version number of the Kaleida companion app. */
    GetCompanionVersion = 5,
    /** Sets "do not disturb" mode - data should be a {@link DndInfo}. */
    SetDnd = 100,
    /** Answers a call. Data can optionally provide an index of a call to answer. */
    AnswerCall = 101,
    /** Places a call on holds. Data can optionally provide an index of a call to answer. */
    HoldCall = 102,
    /** Retrieves a call from hold. Data can optionally provide an index of a call to answer. */
    RetrieveCall = 103,
    /** Clears a call (hangs up). Data can optionally provide an index of the specific call. */
    ClearCall = 104,
    /** Starts an announced transfer? (experimental - not extensively tested). */
    AnnouncedTransfer = 105,
    /** Completes an announced transfer? (experimental - not extensively tested). */
    TransferCall = 106,
    /** Initiates an outbound call. Data is a {@link MakeCallArgs} object.*/
    MakeCall = 110,

    /** Displays a message on the phone. Data is a ModifiyCallDisplayArgs object.*/
    ModifyCallDisplay = 130,

    /** Gets a DndInfo for the current state.*/
    GetDnd = 200,

    /** Attempts to obtain the RecID for the current call. There may be a delay between the call event and this being available. Returns a {@link CallRecordingDetails} object if successful.*/
    QueryRecordingDetails = 300,
    /** Takes a RecID (previously obtained via {@link QueryRecordingDetails}) and fetches the URL to redirect the user to that contains a player to play the recording.*/
    GetRecordingPlayerUrl = 301, 
}

/**
 * Represents a type of instruction from the server to the client.
 */
export enum OutboundMessageType {
    /** Does nothing, for testing communications only. */
    DoNothing = 0,
    /** Indicates the message is a response to a previous inbound (client to server) message. The Data element will contain an {@link InboundMessageResponse}. */
    InboundInstructionResponse = 1,
    /** Sent to the client if we've had to log them out of the extension (possibly as it was acquired by another socket). Data will contain a string explaining why. */
    ForceLogout = 2,
    /** Sent to notify the client when a "CallEvent" type of event is raised, such as a ringing phone. AdditionalData is a JSON representation of a {@link CallEvent}. */
    CallEvent = 10,
}

// Note that below, "inbound" refers to client-to-server whilst "outbound" refers to server-to-client.

/**
 * Represents a message sent from the web browser to the service, usually informing it to do something such as log on, or pick up the phone.
 * */
export class InboundMessage {
    /** A unique identifier that can be passed down. The response will contain the same number. */
    RequestId: number;
    /** The instruction (command) type. */
    Type: InboundMessageType
    /** Contents of Data is dependent upon Type and may be a JSON representation of another object. */
    Data: string
}

/**
 * Represents a response sent back to the web browser after an {@link InboundMessage} indicating the result of the operation.
 * These will be wrapped up inside an {@link OutboundMessage} of type {@link OutboundMessageType#InboundInstructionResponse}.
 * */
export class InboundMessageResponse {
    /** The [RequestId]{@link InboundMessage#RequestId} that this response relates to. Note that the TelephonyServer base class will automatically set this. */
    RequestId: number;
    Successful: boolean;
    /** A string containing additional information (such as an error message if not successful). This may also include JSON data depending on the {@link InboundMessageType} of the original request. */
    AdditionalInformation: string;
}

/**
 * This can represents some kind of event that we want to notify the client (web browser) of. These can be sent at any time, not necessarily in response to an InboundMessage.
 * An example could be to notify the client that the phone is ringing.
 * */
export class OutboundMessage {
    Type: OutboundMessageType;
    /** Contents of Data is dependent upon {@link Type}. */
    Data: string;
}

export class DotNetVersionInfo{
    Major: number;
    Minor: number;
    Build: number;
    Revision: number;
}