import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ContentChild } from '@angular/core';
import { Filter } from 'app/shared/models/filter.model';
import { FilterFieldsBaseComponent } from 'app/shared/components/list-filter/filter-fields-base.component';
import { ListFilterComponent } from 'app/shared/components/list-filter/list-filter.component';

@Component({
  selector: 'list-filter-inline',
  templateUrl: './list-filter-inline.component.html',
  styles: [`
  button{
    width: 30px;
  }
  form{
    width:100%;
  }
  `],
})

// extends ListFilterComponent withiut any additional behaviour
// just uses a different template
export class ListFilterInlineComponent extends ListFilterComponent {

}
