import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CompanyCreditLookupResponseModel } from './models/company-credit-lookup.model';
import { CompanyCreditLookupService } from './services/company-credit-lookup.service';

@Component({
  selector: 'company-credit-lookup',
  templateUrl: './company-credit-lookup.component.html'
})
export class CompanyCreditLookupComponent {
  dataForm: FormGroup;
  responseModel: CompanyCreditLookupResponseModel = null;
  result: string;
  @Input() companyId: string;
  @Output() companyCreditLookupResultEvent = new EventEmitter<CompanyCreditLookupResponseModel>();

  constructor(private service: CompanyCreditLookupService) {}

  enableLookupButton = () => {
    return this.companyId !== '';
  };

  lookup() {
    this.service.getCompanyCreditLookupResponse(this.companyId).then((response: CompanyCreditLookupResponseModel) => {
      this.responseModel = response;
      this.companyCreditLookupResultEvent.emit(response);
    });
  }
}
