import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { TimerListItemModel } from './timer-list-item.model';

export class TimerListItemViewModel extends ActionableItemState {
  downloadButton: IIconActionButton;
  //editButton: IIconActionButton;  //D14845 - see extended comment in constructor
  notesButton: IIconActionButton;
  reassociateButton: IIconActionButton;

  constructor(dataModel: TimerListItemModel) {
    super();

    this.dataModel = dataModel;
  

    const downloadVisible = dataModel.activityTypeName === 'Upload Document' || dataModel.activityTypeName === 'Create Document';
    const notesVisible = dataModel.activityTypeName === 'Task';
    const reassociateVisible = dataModel.activityTypeName === 'Email';

    /*
    // DP: See D14845. Simon asked me to remove the edit button. For now I've just commented out the actual button here and left the implementation
    // (in case-activity-timer-list.component.ts and dependencies) in to avoid risk of side effects and in case it later needs to be brought back
    // for some activity types.
    this.editButton = { visible: true, enabled: true, actionEnabled: true, actionDisabledMessage: '', iconName: 'edit', actionName: 'edit', hoverText: 'Edit the item', class: '', buttonText: '' };
    this.processActions.push(this.editButton);
    */
  
    // TODO: [Technical Debt][DPB][2020-01-08] Remove following confirmation that the actions won't be available in the timer list
    // if (downloadVisible) {
    //   this.downloadButton = { visible: true, enabled: true, actionEnabled: true, actionDisabledMessage: '', iconName: 'cloud_download', actionName: 'download', hoverText: 'Download the item', class: '', buttonText: '' };
    //   this.processActions.push(this.downloadButton);
    // }

    // if (notesVisible) {

    //   this.notesButton = { visible: true, enabled: true, actionEnabled: true, actionDisabledMessage: '', iconName: 'note', actionName: 'notes', hoverText: 'Notes the item', class: '', buttonText: '' };
    //   this.processActions.push(this.notesButton);
    // }

    // if (reassociateVisible) {
    //   this.reassociateButton = { visible: true, enabled: true, actionEnabled: true, actionDisabledMessage: '', iconName: 'forward', actionName: 'reassociate', hoverText: 'Reassociate the item', class: '', buttonText: '' };
    //   this.processActions.push(this.reassociateButton);

    // }
  
  }
}

