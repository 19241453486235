exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* For dealing with expand/collapse - see https://kaleida.atlassian.net/wiki/spaces/DFG/pages/1101168675/Expandable+rows */\n/* override the height of the detial row so that it can be zero height */\ntr.detail-row {\n  height: 0 !important; }\n\n.expanded-detail {\n  overflow: hidden; }\n\n/* remove the border from the bottom of the main data row  */\n.table-data-row td {\n  border-bottom-width: 0; }\n\n.mat-row:nth-child(odd) {\n  background-color: #ffffff; }\n\n.mat-row:nth-child(4n+1) {\n  background-color: #efefef; }\n\n/* ensure the document pack contents and inline previews use the full width */\n.mat-table {\n  width: 100%; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();