import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { RegexValidationStrings } from 'app/shared/validation/reg-ex-validation-constants';
import { ExternalRaiseDefectService } from './services/external-raise-defect.service';
import { InternalFileUploaderComponent } from 'app/shared/components/file-uploader/file-uploader.component';
import { ExternalRaiseDefectModel } from './models/external-raise-defect.model';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { CookieService } from 'app/shared/services/cookie.service';
import { SnackBarService } from 'app/shared/services/snack-bar.service';
import { Constants } from 'app/core/models/constants';

@Component({
  selector: 'external-raise-defect-form',
  templateUrl: './external-raise-defect-form.component.html',
})
export class ExternalRaiseDefectFormComponent implements OnInit {
  public data: any;
  uploadEndpoint = '';
  baseEndpointOverride = '';
  allowedTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/png', 'image/jpeg', 'application/pdf'];
  tinyMceAPIKey = environment.tinyMceAPIKey;

  softwareFeatureIdLookupData: any[];

  viewModel: ExternalRaiseDefectModel;

  dataForm: FormGroup;
  tinyMceSettings = Constants.EditorSettings.FullSettings;
  @ViewChild(InternalFileUploaderComponent, { static: false }) fileUploaderComponent: InternalFileUploaderComponent;

  // shortcuts to controls for readability
  get description(): AbstractControl {
    return this.dataForm.controls['description'];
  }
  get softwareFeatureId(): AbstractControl {
    return this.dataForm.controls['softwareFeatureId'];
  }
  get details(): AbstractControl {
    return this.dataForm.controls['details'];
  }
  get reproductionSteps(): AbstractControl {
    return this.dataForm.controls['reproductionSteps'];
  }

  constructor(
    private fb: FormBuilder,
    private service: ExternalRaiseDefectService,
    private asideService: AsideService,
    private router: Router,
    private cookieService: CookieService,
    private snackBar: SnackBarService,
    private formHelperService: ReactiveFormHelperService,
    private dialog: MatDialog
  ) {
    this.dataForm = fb.group({
      description: ['', [Validators.required, Validators.maxLength(200)]],
      softwareFeatureId: ['', [Validators.required]],
      details: ['', [Validators.required]],
      reproductionSteps: [''],
    });
  }

  ngOnInit() {
    this.loadFormDetails();
    this.uploadEndpoint = this.service.getCreateWithAttachmentsEndpoint();
    this.baseEndpointOverride = `${environment.baseUrlForRaisingDefect}${environment.apiPrefixForRaisingDefect}`;
  }

  private uploadDocument(updateModel) {
    this.fileUploaderComponent.headers.push({ name: 'X-JsonData', value: JSON.stringify(updateModel) });
    this.fileUploaderComponent.onApply();
  }

  fileUploadComplete(): void {
    this.fileUploaderComponent.clearQueue();
    this.asideService.close('external-defect-raised');
    this.snackBar.info('The Defect has been submitted to Kaleida');
  }

  private loadFormDetails() {
    this.service.getLookupDataForDefect().then((response: ExternalRaiseDefectModel) => {
      this.viewModel = response;
      this.initialiseForm();
    });
  }
  initialiseForm() {
    this.softwareFeatureIdLookupData = this.viewModel.softwareFeatureIdLookupData.items.map((item) => {
      return {
        id: item.data,
        text: item.name,
      };
    });

    this.dataForm.markAsPristine();
  }

  private createUpdateModel() {
    return {
      description: this.description.value,
      featureKey: this.softwareFeatureId.value === 0 ? null : this.softwareFeatureId.value,
      details: this.details.value,
      reproductionSteps: this.reproductionSteps.value,
      buildNumber: environment.buildLabel,
      language: window.navigator.language,
      userAgent: window.navigator.userAgent,
      route: window.location.href,
      userId: this.cookieService.getLoggedInUserId(),
      username: this.cookieService.getLoggedInFullName(),
    };
  }

  save() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();
      if (this.fileUploaderComponent.uploader.queue.length > 0) {
        this.uploadDocument(updateModel);
      } else {
        this.service.addNew(updateModel).then(() => {
          this.asideService.close('external-defect-raised');
          this.snackBar.info('The Defect has been submitted to Kaleida');
        });
      }
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm) || this.fileUploaderComponent.uploader.queue.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?',
        },
      });

      dialogRef.componentInstance.onClose.subscribe((result) => {
        if (result.result) {
          this.asideService.close('cancel');
        }
      });
    } else {
      this.asideService.close('cancel');
    }
  }
}
