import { Injectable } from '@angular/core';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ActivityDocumentPackCreateFormModel } from '../models/activity-document-pack-create-form.model';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { MatDialog } from '@angular/material/dialog';
import { SystemActivityType } from 'app/shared/enumeration/system-activity-types';
import { ActivityLevel } from 'app/shared/enumeration/activity-level';

@Injectable()
export class ActivityDocumentPackService extends ListServiceBase {
  @BlockUI() blockUI: NgBlockUI;
  levelParentId: number;
  activityLevel: ActivityLevel;
  divisionId: number;
  companyId: number;
  activityType: SystemActivityType;

  constructor(private apiDependencies: ApiDependenciesService, protected dialog: MatDialog) {
    super(apiDependencies, dialog);
  }

  get baseEndpoint(): string {
      return `/divisions/${this.divisionId}/activities/${this.activityLevel}/${this.levelParentId}/document-packs`;
  }

  protected getCreateEndpoint(): string {
    return this.baseEndpoint;
  }

  protected getGetEditableItemEndpoint(itemId: number) {
    return itemId ? `${this.baseEndpoint}/${itemId}` : `${this.baseEndpoint}/blank`;
  }

    // OVERRIDDEN from ListServiceBase
    protected getListEndpoint(): string {

      let levelName = this.activityLevel === ActivityLevel.Case ? 'case-claims' : ActivityLevel.Client ? 'companies' : 'products';
      let activities = this.activityLevel === ActivityLevel.Case ? 'case-activities' : 'activities';

      return `/divisions/${this.divisionId}/${levelName}/${this.levelParentId}/${activities}` + (this.activityLevel === ActivityLevel.Case ? '/case-activity-list' : '');
    }

  getMappedEditableItemDataModel(response: any): ApiEntity {
    const model: ActivityDocumentPackCreateFormModel = response;
    return model;
  }
}
