import { CollectionResponse } from 'app/shared/models/collectionResponse.model';
import { DataService } from 'app/shared/services/data.service';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { Injectable } from '@angular/core';
import { IAddListService } from 'app/shared/interfaces/IAddListService.interface';
import { ListRequestModel } from 'app/api/models/list-request.model';
import { Filter } from 'app/shared/models/filter.model';
import { SortOrder } from 'app/shared/models/sort-order.model';
import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { BehaviorSubject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { ApiResponseSummary } from '../models/api-response-summary.model';
import { downloadFileFromExtendedBlob } from '../helpers/file-download-helpers';
import { Group } from '../models/group.model';
// Base class for all services injected into componets using the AddList component
// Provides the message hub for the mediator pattern
// Provides methods to be used to publish messages to the hub for distribution to subscribers
// The base service will be responsible for maintaining the state of the children in the list and interfacing with the API
@Injectable()
export abstract class ListServiceBase extends DataService<any> implements IAddListService {
  @BlockUI() blockUI: NgBlockUI;
  // the items to be rendered in the list
  itemList: any[] = [];
  groupedData: any[] = [];
  reducedGroups = [];
  groupBy = '';
  groupTitleColumn = '';
  defaultGroupSorting = true;
  selection = new SelectionModel<any>(true, []); // used for multi select
  allowReload = true;
  expandedRowIds: number[] = [];
  protected listRequest: ListRequestModel = new ListRequestModel();

  private itemDeleted = new BehaviorSubject<number>(null);
  // Observable itemDeleted stream
  itemDeleted$ = this.itemDeleted.asObservable();

  protected listReloaded = new BehaviorSubject<number>(null);
  // Observable listReloaded stream
  listReloaded$ = this.listReloaded.asObservable();

  protected itemChanged = new BehaviorSubject<number>(null);
  // Observable listReloaded stream
  itemChanged$ = this.itemChanged.asObservable();

  protected itemAdded = new BehaviorSubject<number>(null);
  // Observable listReloaded stream
  itemAdded$ = this.itemAdded.asObservable();

  get ListRequest() {
    return this.listRequest as ListRequestModel;
  }
  protected showPagingEllipses = false;

  itemsCount: number;
  protected pageCount: number;

  constructor(protected dependencies: ApiDependenciesService, protected dialog: MatDialog) {
    super(dependencies);
  }

  get paginatorIndex(): number {
    return this.listRequest.pageNumber - 1;
  }

  //#region endpoints

  // not all endpoints will be required for all implementations

  // OVERRIDE in derived class as required
  protected getListEndpoint(): string {
    return '/unknown-endpoint';
  }

  // OVERRIDE in derived class as required
  protected getCreateEndpoint(): string {
    return '/unknown-endpoint';
  }

  // OVERRIDE in derived class as required
  protected getUpdateEndpoint(itemId: number): string {
    return '/unknown-endpoint';
  }

  // OVERRIDE in derived class as required
  protected getCommandStubEndpoint(itemId: number): string {
    return '/unknown-endpoint';
  }

  // OVERRIDE in derived class as required
  protected getDeleteEndpoint(itemId: number): string {
    return '/unknown-endpoint';
  }

  // OVERRIDE in derived class as required
  protected getGetEditableItemEndpoint(itemId: number): string {
    return '/unknown-endpoint';
  }

  protected get exportListEndpoint(): string {
    return '/unknown-endpoint';
  }

  //#endregion

  //#region list building

  // OVERRIDE in derived class
  createListViewModel(dataModel: ApiEntity) {
    return new ActionableItemState();
  }

  /**
   * Reloads the list using overridden methods for the implementation specific details.
   * The {@link itemList} list is populated with new view models, which are created from the mapped ApiEntity data models from the server using {@link createListViewModel}).
   */
  async reload() {
    if (this.allowReload === false) {
      return;
    }
    this.startBlockUI();
    const selectedIds = this.getSelectedItemIds();
    await this.getListItems()
      .then((response: ApiEntity[]) => {
        this.itemList = [];
        response.forEach((dataModel: ApiEntity) => {
          const model: ActionableItemState = this.createListViewModel(dataModel);
          model.itemId = dataModel.id;
          this.itemList.push(model);
        });

        this.selectItemsFromIds(selectedIds);
        this.applyGrouping();
        this.listReloaded.next(this.itemsCount);

        this.stopBlockUI();
      })
      .catch(() => {
        this.stopBlockUI();
      });
  }

  private applyGrouping() {
    const groupFields = this.getGroupFields();
    if (groupFields.length > 0) {
      if (this.defaultGroupSorting) {
        // sort the item list by the first group by value and then by the sorted column
        const sortByColumn = groupFields[0];
        const listRequestSort = this.listRequest.sort.length === 0 ? null : this.listRequest.sort[0];

        this.itemList = this.itemList.sort(
          (a, b) => this.sortFn(a.dataModel[sortByColumn], b.dataModel[sortByColumn]) || (listRequestSort ? this.sortFn(a.dataModel[listRequestSort.field], b.dataModel[listRequestSort.field], listRequestSort.direction === 'asc') : 0)
        );
      }
      this.groupedData = this.addGroups(this.itemList, groupFields);
    } else {
      this.groupedData = this.itemList;
    }
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();

    return this.getSubLevel(data, 0, groupByColumns, rootGroup);
  }

  getSubLevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    // Recursive function, stop when there are no more levels.
    if (level >= groupByColumns.length) {
      return data;
    }

    const groups = this.uniqueBy(
      data.map((row) => {
        const result = new Group();
        result.level = level + 1;
        result.parent = parent;
        for (let i = 0; i <= level; i++) {
          result[groupByColumns[i]] = row.dataModel[groupByColumns[i]];
        }
        return result;
      }),
      JSON.stringify
    );

    const currentColumn = groupByColumns[level];

    let subGroups = [];
    groups.forEach((group) => {
      let rowsInGroup = data.filter((row) => group[currentColumn] === row.dataModel[currentColumn]);
      group.count = rowsInGroup.length;
      if (this.groupTitleColumn) {
        group.title = rowsInGroup[0].dataModel[this.groupTitleColumn];
      }

      const groupReduced = this.reducedGroups.some((g) => JSON.stringify(g) === JSON.stringify(group));

      if (groupReduced) {
        rowsInGroup = [];
        group.reduced = true;
      }
      const subGroup = this.getSubLevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);

      subGroups = subGroups.concat(subGroup);
    });

    return subGroups;
  }

  groupHeaderClick(row) {
    if (!row.reduced) {
      this.reducedGroups.push(row);
    } else {
      const sortByColumn = this.getGroupFields()[0];
      this.reducedGroups = this.reducedGroups.filter((obj) => obj[sortByColumn] !== row[sortByColumn]);
      row.reduced = false;
    }

    this.applyGrouping();
  }

  getGroupFields(): string[] {
    if (this.groupBy) {
      return [this.groupBy];
    } else {
      return [];
    }
  }
  uniqueBy(a, key) {
    const seen = {};
    return a.filter(function (item) {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  private sortFn(a, b, acs: boolean = true): number {
    if (acs) {
      return a === b ? 0 : a < b ? -1 : 1;
    } else {
      return a === b ? 0 : a > b ? -1 : 1;
    }
  }

  /**
   * Unblocks the user interface, after a previous call to {@link startBlockUI}.
   */
  stopBlockUI() {
    this.blockUI.stop();
  }

  /**
   * Blocks the user interface, preventing the user from clicking buttons, etc, during an asynchronous operation. Use {@link stopBlockUI} to unblock.
   */
  startBlockUI() {
    this.blockUI.start();
  }

  clearListRequest() {
    this.listRequest = new ListRequestModel();
  }

  async getListItems(): Promise<ApiEntity[]> {
    let model = new Array<ApiEntity>();
    const url = this.getListEndpoint();

    if (url) {
      await this.setEndpoint(url)
        .queryCollection(this.listRequest)
        .then((response: CollectionResponse<any>) => {
          model = this.mapList(response);
        });
    }

    return Promise.resolve(model);
  }

  mapList(responseList: CollectionResponse<any>): ApiEntity[] {
    const result: ApiEntity[] = [];
    if (responseList != null) {
      this.itemsCount = responseList.itemsCount;
      this.pageCount = Math.ceil(this.itemsCount / this.listRequest.pageSize);
      this.listRequest.pageNumber = responseList.page;
      // this.showPagingEllipses = Math.ceil(this.itemsCount/this.listRequest.pageSize) > 6;
      responseList.items.forEach((responseItem) => {
        const mappedModel = this.getMappedListItemDataModel(responseItem);
        result.push(mappedModel);
      });
    }

    return result;
  }

  // OVERRIDE in derived class
  getMappedListItemDataModel(responseItem: any): ApiEntity {
    return null;
  }

  //#endregion

  //#region paging, sporting and filtering

  get currentPageItemCount(): number {
    return this.itemList ? this.itemList.length : 0;
  }

  pageSummary() {
    const start = Math.min(this.listRequest.pageSize * (this.listRequest.pageNumber - 1) + 1, this.itemsCount);
    const end = Math.min(start + this.listRequest.pageSize - 1, this.itemsCount);
    return 'Showing ' + start + ' to ' + end + ' of ' + this.itemsCount + ' items';
  }

  getSortIcon(sortColumn): string {
    const existingSort: SortOrder = this.listRequest.sort.length == 0 ? null : this.listRequest.sort[0];
    if (existingSort) {
      if (existingSort.field === sortColumn) {
        return existingSort.direction === 'asc' ? 'zmdi-caret-up' : 'zmdi-caret-down';
      }
    }
    return '';
  }

  setSort(sortColumn, sortDirection) {
    this.listRequest.sort = [new SortOrder(sortColumn, sortDirection)];
    this.listRequest.pageNumber = 1;
    this.reload();
  }

  setFilters(filters: Array<Filter>) {
    this.listRequest.filters = filters;
    this.listRequest.pageNumber = 1;
    this.reload();
  }
  //#endregion

  //#region multi select

  // update the itemSelected Property of all visible items in the list
  selectAll(selectValue: boolean) {
    for (let i = 0, len = this.itemList.length; i < len; i++) {
      if (!selectValue || this.isItemSelectable(this.itemList[i].dataModel)) {
        this.itemList[i].itemSelected = selectValue;
      }
    }
  }

  /** Selects all items unless they are already all selected. If they are all selected already, this will deselect all items. */
  toggleSelectAll() {
    this.selectAll(!this.allSelected);
  }

  get selectableListLength(): number {
    return this.itemList.filter((item) => this.isItemSelectable(item.dataModel)).length;
  }

  get allSelected(): boolean {
    const count = this.itemList.filter((item) => item.itemSelected === true).length;
    return count === this.selectableListLength;
  }

  get noneSelected(): boolean {
    const count = this.itemList.filter((item) => item.itemSelected === true).length;
    return count === 0;
  }

  get anySelected(): boolean {
    const count = this.itemList.filter((item) => item.itemSelected === true).length;
    return count > 0;
  }

  /**
   * Gets an array of the IDs for the selected items.
   */
  getSelectedItemIds(): number[] {
    // populate number array with the id of visible selected items in the list
    if (this.itemList == null) {
      return [];
    }

    const selectedIds: number[] = [];
    for (let i = 0, len = this.itemList.length; i < len; i++) {
      const model = this.itemList[i];
      if (model.itemSelected === true) {
        selectedIds.push(model.itemId);
      }
    }
    return selectedIds;
  }

  private selectItemsFromIds(selectedIds: number[]) {
    selectedIds.forEach((id) => {
      const model = this.findViewModelFromItemId(id);
      if (model != null && this.isItemSelectable(model.dataModel)) {
        model.itemSelected = true;
      }
    });
  }

  /** Returns whether an item can be selected. May be overridden to exclude an item form being reselected. */
  protected isItemSelectable(datamodel: any): boolean {
    return true;
  }
  //#endregion

  //#region item processing

  findViewModelFromItemId(itemId: number): ActionableItemState {
    const viewModel = this.itemList.find((x) => x.itemId == itemId);
    if (viewModel === null) {
      return null;
    }

    return viewModel;
  }

  async sendCommand(itemId: number, command: string, data: any = null) {
    const viewModel = this.findViewModelFromItemId(itemId);
    if (viewModel != null) {
      this.blockUI.start();
      await this.setEndpoint(this.getCommandStubEndpoint(itemId) + '/' + command)
        .command(data)
        .then(() => {
          this.blockUI.stop();
        })
        .catch(() => {
          this.blockUI.stop();
        });
    }
  }

  sendCommandFollowingConfirmation(itemId: number, command: string, data: any = null, title: string = 'Confirm Action', message: string = 'Are you sure you want to process this item?') {
    const viewModel = this.findViewModelFromItemId(itemId);
    if (viewModel != null) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        data: { title: title, message: message },
      });

      dialogRef.componentInstance.onClose.subscribe((result) => {
        if (result.result) {
          this.setEndpoint(this.getCommandStubEndpoint(itemId) + '/' + command)
            .command(data)
            .then(() => {});
        }
      });
    }
  }

  async addNew(item: any, endpoint: string = null): Promise<number> {
    let newRecordId: number;
    this.blockUI.start();

    if (endpoint == null) endpoint = this.getCreateEndpoint();

    await this.setEndpoint(endpoint)
      .save(item)
      .then((response) => {
        newRecordId = response.newRecordId;
        this.itemAdded.next(response.newRecordId);

        this.blockUI.stop();
        this.reload();
      })
      .catch(() => {
        this.blockUI.stop();
      });
      
    return Promise.resolve(newRecordId);
  }

  // This is the old updateItem. As it calls the old update but doesn't care about the response returned we can safely deprectae it in favour of the new one
  async updateItemWithoutResponseSummary(itemId: number, dataToUpdate: any, endpoint: string = null) {
    this.blockUI.start();

    const endpointToUse = endpoint == null ? this.getUpdateEndpoint(itemId) : endpoint;
    await this.setEndpoint(endpointToUse)
      .update(dataToUpdate)
      .then(() => {
        this.reload();
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });
  }

  /**
   * Updates the item on the server and reloads the full list if the update was successful.
   * The UI will be blocked whilst the update is being performed.
   * @param {number} itemId - The ID of the item to update.
   * @param dataToUpdate - The new model data for the item.
   * @param {string} [endpoint] - The endpoint to call. If null, defaults to getUpdateEndpoint().
   * @returns {Promise<ApiResponseSummary<any>>} Promise object represents the response from the server.
   */
  async updateItem(itemId: number, dataToUpdate: any, endpoint: string = null): Promise<ApiResponseSummary<any>> {
    let result: ApiResponseSummary<any>;

    this.blockUI.start();

    const endpointToUse = endpoint == null ? this.getUpdateEndpoint(itemId) : endpoint;
    await this.setEndpoint(endpointToUse)
      .update(dataToUpdate)
      .then((response) => {
        result = response;
        if (result.success === true) {
          this.reload();
        }
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });
    return Promise.resolve(result);
  }

  /**
   * Updates the item on the server. No reloading will take place automatically client-side.
   * The UI will be blocked whilst the update is being performed.
   * @param {number} itemId - The ID of the item to update.
   * @param dataToUpdate - The new model data for the item.
   * @param {string} [endpoint] - The endpoint to call. If null, defaults to getUpdateEndpoint().
   * @returns {Promise<ApiResponseSummary<any>>} Promise object represents the response from the server.
   */
  async updateItemNoReload(itemId: number, dataToUpdate: any, endpoint: string = null): Promise<ApiResponseSummary<any>> {
    let result: ApiResponseSummary<any>;

    this.blockUI.start();

    const endpointToUse = endpoint == null ? this.getUpdateEndpoint(itemId) : endpoint;
    await this.setEndpoint(endpointToUse)
      .update(dataToUpdate)
      .then((response) => {
        result = response;
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });
    return Promise.resolve(result);
  }

  async saveItem(itemId: number, dataToUpdate: any, endpoint: string): Promise<number> {
    let result: number;
    this.blockUI.start();
    // find in list and process
    await this.setEndpoint(endpoint)
      .save(dataToUpdate)
      .then((response) => {
        result = response.newRecordId;
        this.reload();
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });
    return Promise.resolve(result);
  }

  async saveItemNoReload(itemId: number, dataToUpdate: any, endpoint: string): Promise<number> {
    let result: number;
    this.blockUI.start();
    // find in list and process
    await this.setEndpoint(endpoint)
      .save(dataToUpdate)
      .then((response) => {
        result = response.newRecordId;
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });

    return Promise.resolve(result);
  }

  /**
   * Deletes the specified record on the server, after first asking the user for confirmation.
   * @param {number} itemId - The ID of the item to delete.
   * @param {string} [title] - The title of the confirmation prompt.
   * @param {string} [message] - The text inside the confirmation prompt.
   */
  async deleteItem(itemId: number, title: string = 'Confirm Delete', message: string = 'Are you sure you want to delete this item?') {
    const viewModel = this.findViewModelFromItemId(itemId);
    if (viewModel != null) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        data: { title: title, message: message },
      });

      dialogRef.componentInstance.onClose.subscribe((result) => {
        if (result.result) {
          this.blockUI.start();
          this.setEndpoint(this.getDeleteEndpoint(viewModel.itemId))
            .delete()
            .then(() => {
              this.blockUI.stop();
              this.itemDeleted.next(viewModel.itemId);
              this.reload();
            })
            .catch(() => {
              this.blockUI.stop();
            });
        }
      });
    }
  }

  // OVERRIDE in derived class
  protected getMappedEditableItemDataModel(response: any): ApiEntity {
    return null;
  }

  async getEditableItemDetail(itemId: number): Promise<ApiEntity> {
    this.blockUI.start();
    let model: ApiEntity;

    await this.setEndpoint(this.getGetEditableItemEndpoint(itemId))
      .getSingle()
      .then((response) => {
        model = this.getMappedEditableItemDataModel(response);
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });

    return Promise.resolve(model);
  }

  //#endregion

  async exportToExcel() {
    await this.setEndpoint(this.exportListEndpoint)
      .getFileAsBlobWithPost(this.listRequest)
      .then((response) => {
        downloadFileFromExtendedBlob(response);
      });
  }
}
