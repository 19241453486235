import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { CaseNoteFormModel } from '../models/notes-form.model';
import { NotesListService } from '../services/notes-list.service';
import { ActivityTimerHubService } from 'app/shared/services/signalr.service';
import { toFormattedDateString } from 'app/shared/helpers/date-helpers';
import { ActivityLevel } from 'app/shared/enumeration/activity-level';

@Component({
  selector: 'case-note-form',
  templateUrl: './case-note-form.component.html',
})
export class ActivityNoteFormComponent implements OnInit {
  public data: any;
  saveText: string;
  editMode: boolean;

  viewModel = new CaseNoteFormModel();
  concurrencyErrorMessage = '';
  dataForm: FormGroup;
  showIsPriority = false;

  startTime: Date;
  stopTime: Date;

  divisionId: number;

  constructor(fb: FormBuilder, private service: NotesListService, private asideService: AsideService, private activityHubService: ActivityTimerHubService, private formHelperService: ReactiveFormHelperService, private dialog: MatDialog) {
    this.startTime = new Date();

    this.dataForm = fb.group({
      description: ['', [Validators.required, Validators.maxLength(200)]],
      content: ['', [Validators.required]],
      isPriority: [],
    });
  }

  // shortcuts to controls for readability
  get description(): AbstractControl {
    return this.dataForm.controls['description'];
  }
  get content(): AbstractControl {
    return this.dataForm.controls['content'];
  }

  get isPriority(): AbstractControl {
    return this.dataForm.controls['isPriority'];
  }

  ngOnInit() {
    this.editMode = this.data.itemId > 0;
    this.saveText = this.editMode ? 'Update' : 'Create';
    this.viewModel.levelParentId = this.data.levelParentId;
    this.viewModel.activityLevel = this.data.activityLevel;
    this.viewModel.isPriority = this.data.isPriority;
    this.divisionId = this.data.divisionId;

    this.showIsPriority = (this.viewModel.activityLevel === ActivityLevel.Case) || (this.divisionId==2) ;
        
    if (this.editMode) {
      this.activityHubService.startRecording(this.data.activityId);
    }

    this.loadFormDetails();
  }

 

  private loadFormDetails() {
    const id: number = this.editMode ? this.data.itemId : null;

    if (this.editMode) {
      this.service.getEditableItemDetail(id).then((response: CaseNoteFormModel) => {
        this.viewModel = response;
        if (this.viewModel) {
          this.concurrencyErrorMessage = '';
          this.initialiseForm();
        }
      });
    }
  }

  initialiseForm() {
    this.dataForm.controls['description'].setValue(this.viewModel.description);
    this.dataForm.controls['content'].setValue(this.viewModel.content);
    this.dataForm.controls['isPriority'].setValue(this.viewModel.isPriority);
    this.dataForm.markAsPristine();
  }

  private createUpdateModel() {
    this.stopTime = new Date();
    return {
      description: this.description.value,
      content: this.content.value,
      isPriority: this.isPriority.value,
      startTime: toFormattedDateString(this.startTime, 'YYYY-MM-DD HH:mm:ss'),
      stopTime: toFormattedDateString(this.stopTime, 'YYYY-MM-DD HH:mm:ss'),
      timestamp: this.viewModel.timestamp,
      activityLevel: this.viewModel.activityLevel,
      levelParentId: this.viewModel.levelParentId,
    };
  }

  save() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();
      if (this.editMode) {
        this.service.updateActivityNote(this.data.itemId, updateModel).then((response) => {
          if (response.success === true) {
            this.closeForm('update');
          } else {
            this.concurrencyErrorMessage = response.message;
          }
        });
      } else {
        this.service.createActivityNote(updateModel).then((newRecordId) => {
          this.activityHubService.requestActivityTimer(newRecordId, 'Note');

          this.closeForm('update');
        });
      }
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?',
        },
      });

      dialogRef.componentInstance.onClose.subscribe((result) => {
        if (result.result) {
          this.closeForm('cancel');
        }
      });
    } else {
      this.closeForm('cancel');
    }
  }

  closeForm(message: string) {
    if (this.editMode) {
      this.activityHubService.pauseRecording(this.data.activityId);
    }

    this.asideService.close(message);
  }
}
