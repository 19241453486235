import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppMaterialModule } from 'app/core/app-material/app-material.module';
import { ActivityNoteFormComponent } from 'app/modules/solution-shared/note/case-note-form/case-note-form.component';
import { BlockUIModule } from 'ng-block-ui';
import { NotesListComponent } from 'app/modules/solution-shared/note/notes-list/notes-list.component';
import { NotesListService } from './services/notes-list.service';
import { NotesFormComponent } from './notes-form/notes-form.component';
import { SharedModule } from 'app/shared/shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    ActivityNoteFormComponent,
    NotesFormComponent,
    NotesListComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule,
    SharedModule,
    AppMaterialModule,
  ],
  exports: [
    ActivityNoteFormComponent,
    NotesFormComponent,
    NotesListComponent,
  ],
  entryComponents: [
    NotesFormComponent,
    ActivityNoteFormComponent,
  ],
  providers: [
    NotesListService
  ],
})
export class NotesModule {}
