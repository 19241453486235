exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "table {\n  width: 100%;\n  table-layout: fixed; }\n\n.table-container {\n  overflow: auto;\n  width: 100%;\n  height: calc(100% - 68px); }\n\nth.mat-header-cell,\ntd.mat-cell {\n  padding-left: 10px;\n  padding-right: 10px; }\n\ntr.mat-row:nth-child(odd) {\n  background-color: #efefef; }\n\n.mat-column-actions {\n  width: 160px;\n  display: table-cell; }\n\n.mat-column-startTime {\n  width: 105px; }\n\n.mat-column-stopTime {\n  width: 65px; }\n\n.mat-column-id {\n  width: 150px; }\n\n.mat-column-duration {\n  width: 80px; }\n\n.mat-column-durationSort {\n  width: 80px; }\n\n.mat-column-userName {\n  width: 100px; }\n\n.mat-column-activitySummary {\n  width: 150px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis; }\n\n.mat-column-activityType {\n  width: 25px; }\n\n.mat-column-amendStatus {\n  width: 40px; }\n\nmat-icon.approved {\n  color: #008800; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();