import { NgModule } from '@angular/core';
import { CookieService } from 'ng2-cookies';
import { CookieService as KaleidaCookieService } from 'app/shared/services/cookie.service';

import { ApplicationService } from './services/application.service';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { AppMaterialModule } from 'app/core/app-material/app-material.module';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { SnackBarService } from './services/snack-bar.service';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SafeHtmlPipe } from './pipes/safe-html.pipe';


@NgModule({
  imports: [
    // CommonModule,
    // BlockUIModule,
    // FormsModule,
    // ReactiveFormsModule,
    // SharedRoutesModule,
    // OverlayModule,
    // RouterModule,
    // FileUploadModule,
    FlexLayoutModule,
    AppMaterialModule,
    // AgmCoreModule.forRoot({ apiKey: environment.googleMapsAPIKey })
  ],
  declarations: [
    ConfirmationModalComponent,
    // IconActionButtonComponent,
    // IconActionButtonListComponent,
    // ListFilterComponent,
    // ListFilterInlineComponent,
    // PageTitleComponent,
    SnackBarComponent,
    // ListPageJumperComponent,
    // SimpleFileUploadComponent,
    // InternalFileUploaderComponent,
    // ActiveInactivePipe,
    // YesNoPipe,
    // YesBlankPipe,
    // IsSystemPipe,
    // UrlLinkPipe,
    // EmailLinkPipe,
    SafeHtmlPipe,
    // NoResultsComponent,
    // AlertBoxComponent,
    // ToBeImplementedComponent,
    // PostcodeLookupComponent,
    // CompanyCreditLookupComponent,
    // CompanyLookupComponent,
    // CompanyCreditLookupComponent,
    // GoogleMapComponent,
    // BreadcrumbComponent,
    // KaleidaPopoverDirective,
    // KaleidaPopoverComponent,
    // ConcurrencyErrorComponent,
    // SagePayComponent,
    // DateTimeComponent
  ],
  entryComponents: [
    ConfirmationModalComponent, 
    SnackBarComponent, 
    //KaleidaPopoverComponent
  ],
  exports: [
    // BlockUIModule,
    ConfirmationModalComponent,
    // IconActionButtonComponent,
    // IconActionButtonListComponent,
    // ListFilterComponent,
    // ListFilterInlineComponent,
    // PageTitleComponent,
    SnackBarComponent,
    // ListPageJumperComponent,
    // SimpleFileUploadComponent,
    // InternalFileUploaderComponent,
    FlexLayoutModule,
    // ActiveInactivePipe,
    // YesNoPipe,
    // YesBlankPipe,
    // IsSystemPipe,
    // UrlLinkPipe,
    // EmailLinkPipe,
    SafeHtmlPipe,
    // NoResultsComponent,
    // AlertBoxComponent,
    // ToBeImplementedComponent,
    // PostcodeLookupComponent,
    // CompanyCreditLookupComponent,
    // CompanyLookupComponent,
    // CompanyCreditLookupComponent,
    // GoogleMapComponent,
    // BreadcrumbComponent,
    // KaleidaPopoverDirective,
    // ConcurrencyErrorComponent,
    // SagePayComponent,    
    // DateTimeComponent
  ],
  providers: [
    SnackBarService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { verticalPosition: 'top', horizontalPosition: 'right' }
    },
    KaleidaCookieService,
    CookieService,
    // Providers needed for asideService.setComponent() calls! These should only really be needed in the offline module.
    ApplicationService,
    // { provide: 'windowObject', useValue: window },
    // { provide: 'navigatorObject', useValue: navigator },
    // {
    //   provide: DateAdapter,
    //   useClass: AppDateAdapter
    // },
    // {
    //   provide: MAT_DATE_FORMATS,
    //   useValue: APP_DATE_FORMATS
    // },
    ReactiveFormHelperService,
    // LoggingService,
    // LogDataService,
    // PostcodeLookupService,
    // CompanyCreditLookupService,
    // CompanyLookupService,
    // CompanyCreditLookupService,
    // GoogleMapsAPIWrapper,
    // GoogleMapsService,
    // SagePayService
  ]
})
export class CoreSharedModule {}
