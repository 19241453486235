import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { CostListItemModel } from './cost-list-item.model';
import { CostListMode } from './cost-list-mode';

export class CostListItemViewModel extends ActionableItemState {
  // authoriseButton: IIconActionButton;
  downloadButton: IIconActionButton;
  editButton: IIconActionButton;
  deleteItemButton: IIconActionButton;
  addPaymentButton: IIconActionButton;
  expanded = false;

  constructor(dataModel: CostListItemModel, listMode: CostListMode) {
    super();
    this.dataModel = dataModel;

    const cannotActionDisableMessage = this.dataModel.cannotActionMessage;
    const deleteDisableMessage = !this.dataModel.canAction ? cannotActionDisableMessage : this.dataModel.cannotDeleteReason;
    
    this.downloadButton = {
      visible: true, 
      enabled: this.dataModel.hasEvidenceFile,
      actionEnabled: this.dataModel.hasEvidenceFile,
      actionDisabledMessage: 'No Evidence has been uploaded for this Cost.',
      iconName: 'cloud_download',
      actionName: 'download',
      hoverText: 'Download evidence file',
      class: '',
      buttonText: '',
    };
    this.editButton = {
      visible: true,
      enabled: true,
      actionEnabled: this.dataModel.canAction,
      actionDisabledMessage: cannotActionDisableMessage,
      iconName: 'edit',
      actionName: 'edit',
      hoverText: 'Edit the Cost',
      class: '',
      buttonText: '',
    };

    this.deleteItemButton = {
      visible: true,
      enabled: true,
      actionEnabled: this.dataModel.canDelete && this.dataModel.canAction,
      actionDisabledMessage: deleteDisableMessage,
      iconName: 'delete',
      actionName: 'deleteItem',
      hoverText: 'Delete the Cost',
      class: '',
      buttonText: '',
    };

    this.addPaymentButton = {
      visible: true,
      enabled: true,
      actionEnabled: this.dataModel.canAction,
      actionDisabledMessage: cannotActionDisableMessage,
      iconName: 'add_circle',
      actionName: 'addPayment',
      hoverText: 'Add Payment Request',
      class: '',
      buttonText: '',
    };

    if (listMode === CostListMode.ClaimDetail) {
      this.processActions.push(this.addPaymentButton);
    }
    this.processActions.push(this.downloadButton);
    this.processActions.push(this.editButton);
    this.processActions.push(this.deleteItemButton);
  }
}
