exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "table {\n  width: 400px;\n  table-layout: fixed; }\n\n.table-container {\n  overflow: auto;\n  width: 400px;\n  height: calc(100% - 90px); }\n\nth.mat-header-cell,\ntd.mat-cell {\n  padding-left: 10px; }\n\n.mat-column-actions {\n  width: 45px;\n  display: table-cell; }\n\n.mat-column-activityTypeName {\n  width: 45px; }\n\n.mat-column-summary {\n  width: 250px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis; }\n\n.element-row.expanded {\n  border-bottom-color: transparent; }\n\ntr.detail-row {\n  height: 0 !important; }\n\n.expanded-detail {\n  overflow: hidden; }\n\n.expanded-detail-content {\n  border-top: 1px solid #efefef; }\n\n.mat-row:nth-child(odd) {\n  background-color: #ffffff; }\n\n.mat-row:nth-child(4n + 1) {\n  background-color: #efefef; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();