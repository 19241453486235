export enum SystemRole {
    DivisionAdmin = 1,
    FinanceTeam = 2,
    ITAdmin = 3,
    TeamLeader = 4,
    User = 5,
    ExternalDivisionAdmin = 6,
    ExternalFinanceTeam = 7,
    ExternalITAdmin = 8,
    ExternalTeamLeader = 9,
    ExternalUser = 10,
    ClientRelations = 11,
    ExternalClientRelations = 12,
    Management = 13,
    ExternalManagement = 14,
}

