import { MainMenuService } from './../services/main-menu.service';
import { DefaultAsideComponent } from './../components/aside-container/default-aside.component';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ResponsiveService } from './../services/responsive.service';
import { AfterViewInit, Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ComponentItem } from '../../shared/base/aside/component-item';
import { Subscription } from 'rxjs';
import { Division } from 'app/shared/enumeration/division';
import { UsersService } from 'app/modules/user-management/services/users.service';
import { IUsersService } from 'app/modules/user-management/services/users.service.interface';
import { Router, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET } from '@angular/router';
import { AsideActivityTimerService } from '../components/aside-activity-timers/aside-activity-timer.service';
import { CaseActivityTimerListComponent } from 'app/modules/solution-shared/case-activity/case-activity-timer-list/case-activity-timer-list.component';
import { KeepAliveService } from 'app/shared/services/keep-alive.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {
  selectedTheme: string;
  divisions = Division;

  permissionsLoadedSubscription: Subscription;

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild('sidenav2', { static: true }) sidenav2: MatSidenav;

  constructor(
    protected usersService: UsersService,
    private router: Router,
    public mainMenuService: MainMenuService,
    public asideService: AsideService,
    public asideActivityTimerService: AsideActivityTimerService,
    public responsiveService: ResponsiveService,
    public keepAliveService: KeepAliveService
  ) {}

  ngOnInit() {

    this.permissionsLoadedSubscription = this.usersService.permissionsLoaded$.subscribe(() => this.permissionsLoadedHandler());
    window.addEventListener('focus', this.focus.bind(this)); // bind(this) ensures this is available as the service in the focus method
    
    // move to ngAfterViewInit so that the side menu can respond to the theme change
    // TODO: [Technical Debt][DPB][2020-03-24] not a very elegant solution and we should look for a better solution in the future
    // this.usersService.ensurePermissions();

    this.asideService.asideOpen.subscribe(() => {
      this.sidenav.open();
    });
    this.asideService.asideClose.subscribe(() => {
      this.sidenav.close();
    });

    this.asideActivityTimerService.asideOpen.subscribe(() => {
      this.sidenav2.open();
    });
    this.asideActivityTimerService.asideClose.subscribe(() => {
      this.sidenav2.close();
    });

    this.sidenav2.openedChange.subscribe((isOpen: boolean) => {
      this.asideActivityTimerService.isOpen = isOpen;
    });

    this.responsiveService.resize.subscribe(() => {
      if (this.responsiveService.innerWidth <= 1023) {
        this.mainMenuService.menuVisible = false;
      } else {
        this.mainMenuService.menuVisible = true;
      }
    });
  }

  ngAfterViewInit() {
    this.asideService.setComponent(new ComponentItem(DefaultAsideComponent, {}));
    this.usersService.ensurePermissions();

  }

  focus(){
    this.keepAliveService.checkAuthentication()
  }

  // once permissions have loaded subscribe
  permissionsLoadedHandler() {
    this.usersService.themeName.subscribe(theme => {
      // settimeout is used here to prevent ExpressionChangedAfterItHasBeenCheckedError on ngClass
      // when setting the selected theme back to null on the welcome screen
      setTimeout(() => {
        this.selectedTheme = theme ? this.divisions[theme] : '';
      }, 0);
    });

    this.checkRouteForDivision();
  }

  checkRouteForDivision() {
    if (!this.selectedTheme) {
      const divisionId = this.divisionIdFromRoute;
      if (divisionId) {
        this.usersService.setTheme(divisionId);
      }
    }
  }

  // we don't have access to the activated route so we need to parse the route and acces the segments independently
  get divisionIdFromRoute(): number {
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const group: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const segments: UrlSegment[] = group.segments;

    return segments[0].path === 'divisions' ? +segments[1].path : null;
  }

  toggleMenu() {
    this.mainMenuService.toggleMenu();
  }
}
