import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/core/security/auth/auth.service';
import { UsersService } from 'app/modules/user-management/services/users.service';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { ApiInfo } from 'app/shared/models/api-info.model';
import { ApplicationService } from 'app/shared/services/application.service';
import { CookieService } from 'app/shared/services/cookie.service';
import { environment } from 'environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '../../../../../node_modules/@angular/forms';
import { AuthResponse } from '../api/auth-response.model';
import { Constants } from 'app/core/models/constants';

// TODO move to shared
@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @BlockUI('login') blockUI: NgBlockUI;
  isError: boolean;
  serverErrorMessage: string;
  loginInfo: string;
  appVersion = 'unknown build number';
  apiInfo: ApiInfo;
  dataForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private appService: ApplicationService,
    private cookieService: CookieService,
    private formHelperService: ReactiveFormHelperService,
    private vcr: ViewContainerRef
  ) {
    this.dataForm = fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.appVersion = environment.buildLabel;

    this.appService.getBuildVersion().then((apiInfo: ApiInfo) => {
      this.apiInfo = apiInfo;
    });

    let reason: number;
    this.route.queryParams.subscribe((params) => {
      reason = +params['r'];
      if (reason === 1) {
        this.loginInfo = 'Your session has expired or you are not authorised to complete the last action. Please sign in again';
      }
    });

    this.usersService.userPermissions.isInitialised = false;
  }

  get username(): AbstractControl {
    return this.dataForm.controls['username'];
  }

  get password(): AbstractControl {
    return this.dataForm.controls['password'];
  }

  onSubmit(): void {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      this.blockUI.start();
      this.auth
        .login(this.dataForm.controls['username'].value, this.dataForm.controls['password'].value)
        .then((response: AuthResponse) => {
          this.blockUI.stop();
          if (response.isError) {
            this.handleLoginError(response);
          } else {
            this.reset();
            this.handleLoginSuccess(response);
          }
        })
        .catch((error) => {
          console.error('Error in login.onSubmit(): ' + error);
          this.blockUI.stop();
          this.isError = true;
          this.setErrorMessage('An unknown error has occurred.');
        });
    }
  }

  private handleLoginError(response: AuthResponse) {
    this.isError = response.isError;
    this.setErrorMessage(response.errorMessage);
  }

  private setErrorMessage(errorMessage: string) {
    this.serverErrorMessage = errorMessage;
  }

  private handleLoginSuccess(response: AuthResponse) {
    const redirectToPortal = response.data['canAccessMainSystem'] == 'false';
    const portalUrl = response.data['portalUrl'];
    console.log('User can access main site: ' + redirectToPortal);

    if (redirectToPortal) {
      console.log('Invalid user role. Navigating to ' + portalUrl);
      window.location.href = portalUrl;
    } else {
      this.auth.loggedInUserId = +response.data['userId'];
      this.auth.loggedInUserName = response.data['userName'];
      this.auth.loggedInFullName = response.data['fullName'];
      this.auth.loggedInUserIsInternal = response.data['isInternal'];
      this.auth.loggedInUserExtension = response.data['extension'];
      this.auth.loggedInMitelUserName = response.data['mitelUsername'];
      this.auth.loggedInMitelPassword = response.data['mitelPassword'];

      this.usersService.setTheme(null);
      this.usersService.userDivisions = [];

      let redirectUrl = localStorage.getItem(Constants.LocalStorage.RedirectUrl);

      if (!redirectUrl || redirectUrl.length === 0) {
        redirectUrl = this.auth.getRedirectUrl();
      }

      localStorage.setItem(Constants.LocalStorage.RedirectUrl, '');

      this.router.navigateByUrl(redirectUrl);

      this.cookieService.setLoggedInUserId(this.auth.loggedInUserId);
      this.cookieService.setLoggedInUserName(this.auth.loggedInUserName);
      this.cookieService.setLoggedInFullName(this.auth.loggedInFullName);
      this.cookieService.setLoggedInUserIsInternal(this.auth.loggedInUserIsInternal);
      this.cookieService.setLoggedInExtension(this.auth.loggedInUserExtension);
      this.cookieService.setLoggedInMitelUserName(this.auth.loggedInMitelUserName);
      this.cookieService.setLoggedInMitelPassword(this.auth.loggedInMitelPassword);

      this.cookieService.setTheme(null);
    }
  }

  private reset() {
    this.isError = false;
    this.serverErrorMessage = '';
  }
}
