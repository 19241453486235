import { SystemActivityType } from 'app/shared/enumeration/system-activity-types';

export default class CaseActivityHelper {

  static iconFromActivityType(systemActivityType: SystemActivityType): string {
    switch (systemActivityType) {
      case SystemActivityType.EmailInbound:
        return 'email';
      case SystemActivityType.EmailOutbound:
        return 'send';
      case SystemActivityType.DocumentUpload:
        return 'cloud_upload';
      case SystemActivityType.DocumentCreate:
        return 'attach_file';
      case SystemActivityType.Note:
        return 'note';
      case SystemActivityType.PhoneCallInbound:
      case SystemActivityType.PhoneCallOutbound:
        return 'phone';
      case SystemActivityType.Task:
        return 'query_builder';
      case SystemActivityType.DocumentPackCreate:
        return 'filter_none';
      default:
        return 'help_outline';
    }
  }

  static canControlTimer(systemActivityType: SystemActivityType): boolean {
    switch (systemActivityType) {
      case SystemActivityType.PhoneCallInbound:
      case SystemActivityType.PhoneCallOutbound:
        return false;
      case SystemActivityType.EmailInbound:
      case SystemActivityType.EmailOutbound:
      case SystemActivityType.DocumentUpload:
      case SystemActivityType.DocumentCreate:
      case SystemActivityType.Note:
      case SystemActivityType.Task:
      case SystemActivityType.DocumentPackCreate:
        return true;
      default:
        return true;
    }
  }

}
