import { Injectable, NgZone } from '@angular/core';
import { ApiAuthService } from 'app/core/security/api/api-auth.service';
import { AuthResponse } from '../api/auth-response.model';
import { TelephonyService } from 'app/shared/services/telephony.service';
import { ActivityTimerHubService, MessageFromServer } from 'app/shared/services/signalr.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { LoginExtensionArgs } from 'app/shared/kaleida.telephony/Contract_InboundMessageParams';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Constants } from 'app/core/models/constants';

@Injectable()
export class AuthService implements HttpInterceptor {
  // store the URL so we can redirect after logging in
  private redirectUrl = '';
  public loggedInUserId = -1;
  public loggedInUserName = '';
  public loggedInFullName = '';
  public loggedInUserIsInternal = '';
  public loggedInUserExtension = '';
  public loggedInMitelUserName = '';
  public loggedInMitelPassword = '';

  private phoneLoggedIn = new BehaviorSubject<void>(null);
  // Observable itemDeleted stream
  phoneLoggedIn$ = this.phoneLoggedIn.asObservable();

  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private apiAuth: ApiAuthService, private telephonyService: TelephonyService, protected dependencies: ApiDependenciesService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).catch(error => {
      
      // We don't want to refresh token for some requests like login or refresh token itself
      // So we verify url and we throw an error if it's the case
      if (request.url.includes('refreshtoken') || request.url.includes('login')) {
        // We do another check to see if refresh token failed
        // In this case we want to logout user and to redirect it to login page
        if (request.url.includes('refreshtoken')) {
          this.logout();
          this.router.navigateByUrl('/login?r=1');
        }
        // return throwError(error);
      }

      // If error status is different than 401 we want to skip refresh token
      // So we check that and throw the error if it's the case
      if (error.status !== 401) {
        return throwError(error);
      }

      if (this.refreshTokenInProgress) {
        // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
        // – which means the new token is ready and we can retry the request again
        return this.refreshTokenSubject
          .filter(result => result !== null)
          .take(1)
          .switchMap(() => next.handle(this.addAuthenticationToken(request)));
      } else {
        this.refreshTokenInProgress = true;

        // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
        this.refreshTokenSubject.next(null);

        // Call auth.refreshAccessToken(this is an Observable that will be returned)
        return this.apiAuth
          .refreshAccessToken()
          .switchMap((token: any) => {
            console.log('refreshAccessToken returned token');
            console.log(token);
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(token);
            return next.handle(this.addAuthenticationToken(request));
          })
          .catch((err: any) => {
            this.refreshTokenInProgress = false;
            // this.snackBar.error('Your session has expired or you are not authorised to complete this action. Please sign in again.');
            if (error.status === 401 || error.status === 400 ) {
              console.log(err)
              this.refreshTokenSubject.next(null);
              this.logout();
              this.router.navigateByUrl('/login?r=1');
              return Observable.empty();
            } else {
               return Observable.throw(err);
             }
          })
          
      }
    });
  }

  addAuthenticationToken(request) {
    const accessToken = this.apiAuth.getAccessToken();

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + accessToken
      }
    });
  }

  isLoggedIn(): boolean {
    return this.apiAuth.isAuthenticated();
  }

  login(username: string, password: string): Promise<AuthResponse> {
    return this.apiAuth.authenticate(username, password).then(response => {
      if (response.data != null && response.data.extension != null && response.data.extension !== '') {
        // Log in to phone system
        const loginExtensionArgs = new LoginExtensionArgs();
        loginExtensionArgs.Extension = response.data.extension;
        loginExtensionArgs.Username = response.data.mitelUsername;
        loginExtensionArgs.Password = response.data.mitelPassword;
        loginExtensionArgs.KickExistingUser = true;

        this.telephonyService.logon(loginExtensionArgs).then(() => {
          console.log('telephony Service logon during app logon complete');
          // note to Damien: There are a few other places where login can take place (F5 refresh, connection re-establish after failure, etc).
          // Depending on what you're doing, you may want to watch the onLoggedIn / onLoggedOut observables on TelephonyService or query the isLoggedIn property.
          this.phoneLoggedIn.next(null);
        })
        .catch((err: Error)=>{
          this.dependencies.snackBar.error('Unable to sign-in to phone system.');
          throw err;
        });
      } else {
        console.log('No extension found for logged in user. Will not connect to telephony service.');
      }
      return response;
    });
  }

  logout(): Promise<boolean> {
    this.telephonyService.logoutAndDisconnect();
    localStorage.removeItem(Constants.LocalStorage.KeyAccessToken);
    localStorage.removeItem(Constants.LocalStorage.KeyRefreshToken);
    Cookie.delete(Constants.Cookies.KeyLoggedInUserId);
    Cookie.delete(Constants.Cookies.KeyLoggedInUserName);
    Cookie.delete(Constants.Cookies.KeyLoggedInFullName);
    Cookie.delete(Constants.Cookies.KeyDivisionTheme);
    Cookie.delete(Constants.Cookies.KeyLoggedInUserExtension);
    Cookie.delete(Constants.Cookies.KeyLoggedInUserIsInternal);
    return this.apiAuth.deauthenticate();
  }

  setRedirectUrl(url: string): string {
    this.redirectUrl = url;
    return this.redirectUrl;
  }

  getRedirectUrl(): string {
    return this.redirectUrl.length > 0 ? this.redirectUrl : '/';
  }

  getAccessToken(): string {
    console.log('getAccessToken entered');
    return this.apiAuth.getAccessToken();
  }
}
