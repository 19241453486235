import { Injectable } from '@angular/core';
import * as localforage from 'localforage';
import { LogDataService } from 'app/shared/services/log.data.service';
import { Constants } from 'app/core/models/constants';
import { toFormattedDateString } from 'app/shared/helpers/date-helpers';
import { AuditEntryContainer, AuditEntry } from 'app/shared/models/audit.model';
import { StringUtilities } from 'app/shared/helpers/string-helpers';
import { CookieService } from 'app/shared/services/cookie.service';

@Injectable()
export class LoggingService {

  private padTo = 40;

  constructor(private dataService: LogDataService, private cookieService: CookieService) {
  }

  public log(source: string, message: string) {
    const msg = this.getMessage(source, message);
    console.log(msg);
    this.saveMessage('log', source, message).then();
  }

  public debug(source: string, message: string) {
    const msg = this.getMessage(source, message);
    console.debug(msg);
    this.saveMessage('debug', source, message).then();
  }

  public error(source: string, message: string) {
    const msg = this.getMessage(source, message);
    console.error(msg);
    this.saveMessage('error', source, message).then();
  }

  public info(source: string, message: string) {
    const msg = this.getMessage(source, message);
    console.info(msg);
    this.saveMessage('info', source, message).then();
  }

  public warn(source: string, message: string) {
    const msg = this.getMessage(source, message);
    console.warn(msg);
    this.saveMessage('warn', source, message).then();
  }

  public trace(source: string, message: string) {
    const msg = this.getMessage(source, message);
    console.trace(msg);
    this.saveMessage('trace', source, message).then();
  }

  public async purge(): Promise<void> {

    try {
      this.prepareStorage();
      const currentKey = Constants.Audit.KeyAuditEntryPrefix + toFormattedDateString(new Date(), Constants.Audit.KeyAuditEntryFrequency);
      const keys = await localforage.keys();

      for (const key of keys) {
        if (key.startsWith(Constants.Audit.KeyAuditEntryPrefix)) {

          if (key !== currentKey) {

            let canPurge = true;
            const container = await this.getValue<AuditEntryContainer>(key);

            if (container != null) {
              for (const auditEntry of container.entries) {
                const isOK = await this.dataService.submitAudit(auditEntry);
                if (!isOK) {
                  canPurge = false;
                  break;
                }
              }
            }

            if (canPurge) {
              await this.removeItem(key);
              break;
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
      // Quench
    }
  }

  private async saveMessage(severity: string, source: string, message: string): Promise<void> {

    // todo(WH) lock this method for concurrent use
    if (severity === 'log' || severity === 'debug' || severity === 'trace') {
       return Promise.resolve(null);
    }

    try {
      const key = Constants.Audit.KeyAuditEntryPrefix + toFormattedDateString(new Date(), Constants.Audit.KeyAuditEntryFrequency);
      let v = await this.getValue<AuditEntryContainer>(key);

      if (v == null) {
        v = new AuditEntryContainer();
      }

      const entry = new AuditEntry();
      entry.guid = StringUtilities.generateNewGuid();
      entry.whenAudited = toFormattedDateString(new Date(), 'DD-MMM-YYYY HH:mm:ss.SSS');
      entry.deviceId = this.cookieService.getDeviceId();
      entry.severity = severity;
      entry.message = source + ':' + message;
      v.entries.push(entry);

      await this.setValue(key, v);

    } catch (error) {
      console.error(error);
      // Quench
    }

    return Promise.resolve();
  }

  private getMessage(source: string, message: string): string {
    return this.format(source) + ':' + message;
  }

  private format(s: string): string {

    s = s || '';

    if (s.length >= this.padTo) {
      return s;
    }

    let result = s;

    for (let i = s.length; i <= this.padTo; i++) {
      result = result + ' ';
    }
    return result;
  }

  private async getValue<T>(key: string): Promise<T> {

    this.prepareStorage();

    let v = await localforage.getItem<T>(key);
    if (!v) {
      v = null;
    }

    return Promise.resolve(v);
  }

  private async setValue<T>(key: string, val: T): Promise<void> {

    this.prepareStorage();

    await localforage.setItem(key, val);
    return Promise.resolve();
  }

  private async removeItem(key: string): Promise<void> {

    this.prepareStorage();

    await localforage.removeItem(key);
    return Promise.resolve();
  }

  // Important : Ensure that this is called before any call to localforage
  private prepareStorage(): void {
    localforage.config({
      driver: localforage.INDEXEDDB,
      name: Constants.LocalStorage.DatabaseName,
      version: Constants.LocalStorage.Version,
      description: Constants.LocalStorage.Description,
      size: Constants.LocalStorage.Size
    });
  }
}
