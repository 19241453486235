import { Injectable, EventEmitter, Injector, ComponentFactoryResolver } from '@angular/core';
import { ComponentItem } from 'app/shared/base/aside/component-item';

@Injectable()
export class AsideService {

  testVal: number;
  asideOpen = new EventEmitter<void>();
  asideClose = new EventEmitter<any>();
  componentProvided: EventEmitter<ComponentItem> = new EventEmitter();
  componentUnset: EventEmitter<boolean> = new EventEmitter();
  public isOpen = false;

  constructor() { }

  public open(): void {
    this.isOpen = true;
    this.asideOpen.emit();
  }

  public close(data?: any): void {
    this.isOpen = false;
    this.unsetComponent();
    this.asideClose.emit(data);
  }

  setComponent(componentItem: ComponentItem) {
    this.componentProvided.emit(componentItem);
  }

  
  unsetComponent() {
    this.componentUnset.emit(true);
  }

  // https://www.reddit.com/r/Angular2/comments/6dij2p/passing_injector_to_createcomponent/
  setAndOpen(componentItem: ComponentItem, injector: Injector) {
    const resolver = injector.get(ComponentFactoryResolver)
    componentItem.resolver = resolver;
    this.setComponent(componentItem);
    this.open();
  }

}
