import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'app/core/security/auth/auth.service';
import { UsersService } from 'app/modules/user-management/services/users.service';
import { Division } from 'app/shared/enumeration/division';
import { RestrictedClientPageType } from 'app/shared/enumeration/restricted-client-page-type';
import { SystemRole } from 'app/shared/enumeration/system-role';
import { CookieService } from 'app/shared/services/cookie.service';

@Injectable()
export class RestrictedClientGuardService implements CanActivate {
  constructor(
    protected usersService: UsersService,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected cookieService: CookieService
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let canActivate = true;

    let canAccessRestrictedClient = await this.usersService.hasPermission('action.allowUserToAccessRestrictedClientPages');
    let divisionId = +route.params['divisionId'];

    if (canAccessRestrictedClient || divisionId !== +Division.Supportis) return Promise.resolve(true);

    let restrictedPageType: RestrictedClientPageType = route.data.restrictedClientPageType;
    let entityId: number = 0;

    switch (restrictedPageType) {
      case RestrictedClientPageType.SupportisServiceAgreementDetailPage:
      case RestrictedClientPageType.CompanyDetailPage: {
        entityId = +route.params['companyId'];
        break;
      }
      case RestrictedClientPageType.ContactDetailPage: {
        entityId = +route.params['contactId'];
        break;
      }
      case RestrictedClientPageType.ProductDetailPage: {
        entityId = +route.params['productId'];
        break;
      }
      case RestrictedClientPageType.CaseClaimDetailPage: {
        entityId = +route.params['caseClaimId'];
        break;
      }
      default: {
        break;
      }
    }

    let redirect = 'login';
    if (route.data.redirect) {
      redirect = route.data.redirect;
    }

      let isRestrictedClientPage = await this.usersService.isPageForRestrictedClient(restrictedPageType, entityId);
      canActivate = !isRestrictedClientPage;

    if (!canActivate && redirect !== '') {
      this.router.navigate([redirect]);
    }

    return Promise.resolve(canActivate);
  }


}
