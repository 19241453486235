exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".summary-item {\n  color: #FFFFFF;\n  margin-left: 2px;\n  margin-right: 2px;\n  padding: 2px 3px 2px 2px;\n  width: 26px;\n  height: 26px;\n  font-size: 0.8rem;\n  text-align: center;\n  border-radius: 7px; }\n\n.summary-item-red {\n  background-color: #aa0000; }\n\n.summary-item-amber {\n  background-color: #ff8800; }\n\n.summary-item-green {\n  background-color: #008800; }\n\na {\n  color: #FFFFFF;\n  text-decoration: none; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();