import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { CaseActivityModel } from 'app/modules/solution-shared/case-activity/models/case-activity.model';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { SnackBarService } from 'app/shared/services/snack-bar.service';
import { CaseActivityPriorityNoteListItemViewModel } from '../models/case-activity-priority-note-list-item-view.model';
import { CaseActivityPriorityNoteListItemModel } from '../models/case-activity-priority-note-list-item.model';
import { CaseActivityListMode } from '../models/CaseActivityListMode';

@Injectable()
export class CaseActivityPriorityNoteListService extends ListServiceBase {
  listMode = CaseActivityListMode.History;

  divisionId: number;
  levelParentId: number;
  activityLevel: number;

  constructor(protected dependencies: ApiDependenciesService, protected dialog: MatDialog, private snackBar: SnackBarService) {
    super(dependencies, dialog);

    this.initialiseFiltersSortAndPaging();
  }

  // populate initial filter properties
  private initialiseFiltersSortAndPaging() {
    // Add intialisation here
  }

  //#region Get Endpoints

  get baseEndpoint(): string {
    return '/divisions/' + this.divisionId + '/case-claims/' + this.levelParentId + '/case-activities';
  }
  baseEndpointWithId(id: number): string {
    if (id === undefined) debugger;
    return this.baseEndpoint + '/' + id;
  }

  // a number of endpoint getters are defined in ListServiceBase
  // not all endpoints will be required for all implementations

  // OVERRIDDEN from ListServiceBase
  protected getCommandStubEndpoint(itemId: number): string {
    if (itemId === undefined) debugger;
    return this.baseEndpointWithId(itemId);
  }

  // OVERRIDDEN from ListServiceBase
  protected getListEndpoint(): string {
    const url= '/divisions/' + this.divisionId + '/activityLevels/' + this.activityLevel + '/' + this.levelParentId + '/priority-notes';
    return url;
  }

  //#region list loading

  // OVERRIDDEN from ListServiceBase
  // Create a new ViewModel specific to this list
  // Not necessary to set the viewmodel itemId as it is set in the viewModel constructor
  createListViewModel(dataModel: ApiEntity) {
    return new CaseActivityPriorityNoteListItemViewModel(dataModel as CaseActivityPriorityNoteListItemModel, this.listMode);
  }

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for each list row item
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedListItemDataModel(responseItem: any): ApiEntity {
    const model: CaseActivityPriorityNoteListItemModel = responseItem;
    return model;
  }

  //#region get details

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for the detail item (create/update)
  // The model must be derived from ApiEntity
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedEditableItemDataModel(response: any): ApiEntity {
    const model: CaseActivityModel = response;
    return model;
  }

  //#endregion

  protected getCreateEndpoint(): string {
    return this.baseEndpoint;
  }

  protected getUpdateEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }
}
