exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mat-icon {\n  color: #bbb; }\n\n.breadcrumb-container {\n  margin-top: 10px;\n  margin-bottom: 10px; }\n\na {\n  text-decoration: none; }\n\na:hover {\n  text-decoration: underline; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();