exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mat-list-item {\n  height: 25px !important;\n  font-size: 14px !important;\n  padding: 7px 0px !important; }\n\n.app-version {\n  font-size: 12px;\n  position: fixed;\n  bottom: 0px; }\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();