export enum SystemActivityType {
    DocumentUpload = 1,
    DocumentCreate = 2,
    EmailInbound = 3,
    EmailOutbound = 4,
    Note = 5,
    PhoneCallInbound = 6,
    PhoneCallOutbound = 7,
    Task = 8,
    DocumentPackCreate = 9
}
