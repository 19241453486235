import { Component, Input } from '@angular/core';

@Component({
  selector: 'task-status-summary',
  templateUrl: './task-status-summary.component.html',
  styleUrls: ['./task-status-summary.component.scss']
})
export class TaskStatusSummaryComponent {

  @Input() itemsRed = 0;
  @Input() itemsAmber = 0;
  @Input() itemsGreen = 0;

  @Input() divisionId;
  
  constructor() {
  }
}
