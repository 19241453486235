import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { Filter } from 'app/shared/models/filter.model';
import { ApiEntity } from 'app/api/models/api-entity.model';

import { ActivityRecordedTimeListItemModel } from 'app/modules/solution-shared/case-activity-time-search/models/activity-recorded-time-list-item.model';
import { ActivityRecordedTimeListItemViewModel } from 'app/modules/solution-shared/case-activity-time-search/models/activity-recorded-time-list-item-view.model';
import { TimeSearchListRequestModel } from '../models/time-search-request.model';
import { ApiResponseSummary } from 'app/shared/models/api-response-summary.model';
import { ActivityRecordedTimeModel } from '../models/activity-recorded-time.model';
import { TimeRecorderListMode } from '../models/time-recorder-mode';


@Injectable()
export class ActivityRecordedTimeListService extends ListServiceBase {
  private listMode: TimeRecorderListMode;
  divisionId: number;
  caseActivityId: number;
  caseClaimId: number;
  recordedTimeItemId: number;
  constructor(protected dependencies: ApiDependenciesService, protected dialog: MatDialog) {
    super(dependencies, dialog);
  }

  setListMode(mode: TimeRecorderListMode) {
    this.listMode = mode;
    if (mode === TimeRecorderListMode.GlobalSearch) {
      this.listRequest.paged = false;
      this.caseClaimId = null;
    } else {
      this.listRequest.paged = true;
      this.caseActivityId = null;
    }
  }

  //#region Get Endpoints
  get baseEndpointForAmendments(): string {
    return '/divisions/' + this.divisionId + '/recorded-time/' + this.recordedTimeItemId + '/amendments/';
  }

  get baseEndpoint(): string {
    if (!this.listMode) {
      throw new Error('List mode Required');
    }

    if (this.listMode === TimeRecorderListMode.GlobalSearch) {
      return '/divisions/' + this.divisionId + '/case-activities/' + this.caseActivityId + '/recorded-time';
    } else {
      return '/divisions/' + this.divisionId + '/case-claims/' + this.caseClaimId + '/recorded-time';
    }
  }

  baseEndpointWithId(id: number): string {
    return this.baseEndpoint + '/' + id;
  }


  protected getCreateEndpoint(): string {
    return this.caseActivityEndpoint;
  }


  private get caseActivityEndpoint(): string {
    return '/divisions/' + this.divisionId + '/case-activities/' + this.caseActivityId + '/recorded-time';
  }

  getUpdateEndpointForTimeAmend(itemId: number) {
    return '/divisions/' + this.divisionId + '/recorded-time/' + this.recordedTimeItemId + '/amendments/' + itemId;
  }
  getCreateEndpointForTimeAmend(): string {
    return this.baseEndpointForAmendments;
  }
  blankEndpointForManualTimeAmend() {
    return '/divisions/' + this.divisionId + '/recorded-time/' + 'blank';
  }

  // a number of endpoint getters are defined in ListServiceBase
  // not all endpoints will be required for all implementations

  // OVERRIDDEN from ListServiceBase
  protected getCommandStubEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }

  // OVERRIDDEN from ListServiceBase
  protected getListEndpoint(): string {
    return this.baseEndpoint + '/list';
  }

  //#region list loading

  // OVERRIDDEN from ListServiceBase
  // Create a new ViewModel specific to this list
  // Not necessary to set the viewmodel itemId as it is set in the viewModel constructor
  createListViewModel(dataModel: ApiEntity) {
    return new ActivityRecordedTimeListItemViewModel(dataModel as ActivityRecordedTimeListItemModel);
  }

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for each list row item
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedListItemDataModel(responseItem: any): ApiEntity {
    const model: ActivityRecordedTimeListItemModel = responseItem;
    return model;
  }

  // OVERRIDDEN from ListServiceBase
  protected getAdjustmentEditableItemEndpoint(itemId: number) {
    return itemId ? this.baseEndpointForAmendments + itemId : this.baseEndpointForAmendments + '/blank';
  }

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for the detail item (create/update)
  // The model must be derived from ApiEntity
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedEditableItemDataModel(responseItem: any): ApiEntity {
    const model: ActivityRecordedTimeModel = responseItem;
    return model;
  }

  async getAdjustmentEditableItemDetail(itemId: number): Promise<ApiEntity> {
    this.blockUI.start();
    let model: ApiEntity;
    await this.setEndpoint(this.getAdjustmentEditableItemEndpoint(itemId))
      .getSingle()
      .then(response => {
        model = this.getMappedEditableItemDataModel(response);
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });
    return Promise.resolve(model);
  }


  async getEditableItemDetail(itemId: number): Promise<ApiEntity> {
    this.blockUI.start();
    let model: ApiEntity;
    await this.setEndpoint(this.blankEndpointForManualTimeAmend())
      .getSingle()
      .then(response => {
        model = this.getMappedEditableItemDataModel(response);
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });

    return Promise.resolve(model);
  }


  async addNewTimeAmend(item: any): Promise<number> {
    let newRecordId: number;
    this.blockUI.start();
    await this.setEndpoint(this.getCreateEndpointForTimeAmend())
      .save(item)
      .then(response => {
        newRecordId = response.newRecordId;
        this.itemAdded.next(response.newRecordId);

        this.blockUI.stop();
        this.reload();
      })
      .catch(() => {
        this.blockUI.stop();
      });

    return Promise.resolve(newRecordId);
  }

  async updateTimeAmend(itemId: number, dataToUpdate: any): Promise<ApiResponseSummary<any>> {
    let result: ApiResponseSummary<any>;
    this.blockUI.start();
    const endpointToUse = this.getUpdateEndpointForTimeAmend(itemId);
    await this.setEndpoint(endpointToUse)
      .update(dataToUpdate)
      .then(response => {
        result = response;
        if (result.success === true) {
          this.reload();
        }
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });
    return Promise.resolve(result);
  }

  async isTimeRecordingOverlapping(id: number, originalStart: Date, originalStop: Date): Promise<boolean> {
    let result = true;

    const model = {
      id: id,
      startTime: originalStart,
      stopTime: originalStop
    }

    const endpoint = this.caseActivityEndpoint + '/validate-overlap';

    await this.setEndpoint(endpoint)
      .save(model)
      .then((response: boolean) => {
        result = response;
      });

    return Promise.resolve(result);
  }

  setFilters(filters: Array<Filter>) {
    const request = this.listRequest as TimeSearchListRequestModel;

    const amendStatusFilter = filters.find(f => f.field === 'AmendStatus');
    request.amendStatus = amendStatusFilter ? amendStatusFilter.value : '';

    const dateFromFilter = filters.find(f => f.field === 'DateFrom');
    if (dateFromFilter) {
      dateFromFilter.field = 'StartTime';
    }
    const dateToFilter = filters.find(f => f.field === 'DateTo');
    if (dateToFilter) {
      dateToFilter.field = 'StartTime';
    }

    this.listRequest.filters = filters.filter(item => item.field !== 'AmendStatus' && item.field !== 'CaseReference');
    this.listRequest.pageNumber = 1;
    this.reload();
  }

}
