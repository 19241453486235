import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { ActivityLevel } from 'app/shared/enumeration/activity-level';
import { SystemActivityType } from 'app/shared/enumeration/system-activity-types';
import { LookupList } from 'app/shared/models/lookup-list.model';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CaseActivityEmailModel } from '../models/case-activity-email.model';

@Injectable()
export class CaseActivityEmailService extends ListServiceBase {
  @BlockUI() blockUI: NgBlockUI;
  levelParentId: number;
  activityLevel: ActivityLevel;
  divisionId: number;
  companyId: number;
  activityType: SystemActivityType;

  constructor(private apiDependencies: ApiDependenciesService, protected dialog: MatDialog) {
    super(apiDependencies, dialog);
  }

  get baseEndpoint(): string {
    if (
      this.activityType === SystemActivityType.DocumentCreate ||
      this.activityType === SystemActivityType.DocumentUpload ||
      this.activityType === SystemActivityType.DocumentPackCreate
    ) {
      return `/divisions/${this.divisionId}/activities/${this.activityLevel}/${this.levelParentId}/`;
    } else {
      return `/divisions/${this.divisionId}/activities/${this.activityLevel}/${this.levelParentId}/outbound-emails/`;
    }
  }

  public baseEndpointWithId(itemId: number): string {
    if (
      this.activityType === SystemActivityType.DocumentCreate ||
      this.activityType === SystemActivityType.DocumentUpload ||
      this.activityType === SystemActivityType.DocumentPackCreate
    ) {
      return `${this.baseEndpoint}/email-document/${itemId}/blank`;
    }

    return `${this.baseEndpoint}/${itemId}`;
  }

  protected getCreateEndpoint(): string {
    return '/divisions/' + this.divisionId + '/case-claims/' + this.levelParentId + '/case-activities/email';
  }

  protected getListEndpoint(): string {
    return '/divisions/' + this.divisionId + '/case-claims/' + this.levelParentId + '/tasks/list';
  }

  protected getUpdateEndpoint(itemId: number): string {
    return '/divisions/' + this.divisionId + '/case-claims/' + this.levelParentId + '/case-activities/email/' + itemId;
  }

  protected getGetEditableItemEndpoint(itemId: number) {
    return itemId ? this.baseEndpointWithId(itemId) : `${this.baseEndpoint}/blank`;
  }

  getMappedEditableItemDataModel(response: any): ApiEntity {
    const model: CaseActivityEmailModel = response;
    return model;
  }

  async sendEmail(dataToUpdate: any) {
    let result: number;
    const endpoint = `/divisions/${this.divisionId}/activities/email/send`;
    console.log('Send Email endpoint ' + endpoint);
    await this.saveItemNoReload(0, dataToUpdate, endpoint).then((newRecordId) => {
      result = newRecordId;
    });
    return Promise.resolve(result);   
  }

  createGroupedLookupDataForRecipient(list: LookupList): any[] {
    let model: GroupedContactList[] = [];

    let clientContacts = {} as GroupedContactList;
    clientContacts.name = 'Client Contacts';
    clientContacts.items = list.items.filter(item => item.data !== null && item.data.clientContacts === true).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(item => {
      return {
        id: item.id,
        text: item.name
      }
    });

    let supplierContacts = {} as GroupedContactList;
    supplierContacts.name = 'Supplier Contacts';
    supplierContacts.items = list.items.filter(item => item.data !== null && item.data.supplierContacts === true).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(item => {
      return {
        id: item.id,
        text: item.name
      }
    });

    model.push(clientContacts);
    model.push(supplierContacts);

    return model;
  }

}

export class GroupedContactList {
  constructor(
    public name: string = '',
    public items: any[] = null,
  ) {
  }
}