import { Injectable } from '@angular/core';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { DataService } from 'app/shared/services/data.service';
import { MerchantSessionKeyModel } from '../models/merchant-session-key.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CreatePaymentRequest } from '../models/create-payment-request.model';
import { ApiResponseSummary } from 'app/shared/models/api-response-summary.model';

@Injectable()
export class SagePayService extends DataService<any> {

    @BlockUI() blockUI: NgBlockUI;
    constructor(private apiDepenencies: ApiDependenciesService) {
        super(apiDepenencies);
    }

    get baseEndpoint(): string {
        return '/sage-pay';
    
      }

    getNewMerchantSessionKeyEndpoint(): string {
        return `${this.baseEndpoint}/new-merchant-session-key`;
        }

    getMappedEditableItemDataModel(response: any) {
        let model: MerchantSessionKeyModel = response;
        return model;
        }

    async getNewMerchantSessionKeyResponse(): Promise<MerchantSessionKeyModel> {	
        this.startBlockUI();
        let model: MerchantSessionKeyModel;
        await this
            .setEndpoint(this.getNewMerchantSessionKeyEndpoint())
            .getSingle().then((response) => {
                model = this.getMappedEditableItemDataModel(response);
                this.stopBlockUI();
            })
            .catch(() => {
                this.stopBlockUI();
            });
        return Promise.resolve(model);
    }

    async createPayment(data: CreatePaymentRequest): Promise<ApiResponseSummary<any>> {
        let result: ApiResponseSummary<any>;
    
        const endpointToUse = this.baseEndpoint;
        await this.setEndpoint(endpointToUse)
          .save(data)
          .then(response => {
            result = response;
          });
        this.blockUI.stop();
        return Promise.resolve(result);
      }
    
    stopBlockUI() {
        this.blockUI.stop();
    }

    startBlockUI() {
        this.blockUI.start();
    }

}
