import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout-component/layout.component';
import { AppAuthGuardService } from 'app/core/security/guards/app-auth-guard.service';
import { WelcomeComponent } from '../components/welcome/welcome.component';
import { CommonModule } from '@angular/common';
import { RestrictedClientGuardService } from 'app/core/security/guards/restricted-client-guard.service';
import { RestrictedClientPageType } from 'app/shared/enumeration/restricted-client-page-type';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/welcome',
    pathMatch: 'full'
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    component: LayoutComponent,
    canActivate: [AppAuthGuardService],
    children: [
      {
        path: 'welcome',
        component: WelcomeComponent
      },
      {
        path: 'configuration',
        loadChildren: 'app/modules/configuration/configuration.module#ConfigurationModule' // async routing syntax
      },
      {
        path: 'divisions/:divisionId/configuration',
        loadChildren: 'app/modules/configuration/division-configuration.module#DivisionConfigurationModule' // async routing syntax
      },
      {
        path: 'division/:divisionId/welcome',
        component: WelcomeComponent
      },
      {
        path: 'divisions/:divisionId/products',
        loadChildren: 'app/modules/products/products.module#ProductsModule',
      },
      {
        path: 'divisions/:divisionId/companies/:companyId/products',
        loadChildren: 'app/modules/products/products.module#ProductsModule'
      },
      {
        path: 'divisions/:divisionId/contacts',
        loadChildren: 'app/modules/contacts/contacts.module#ContactsModule'
      },
      {
        path: 'divisions/:divisionId/tasks',
        loadChildren: 'app/modules/tasks/tasks.module#TasksModule'
      },
      {
        path: 'sandbox',
        loadChildren: 'app/modules/sandbox/sandbox.module#SandboxModule'
      },
      {
        path: 'divisions/:divisionId/inbox',
        loadChildren: 'app/modules/group-inbox/group-inbox.module#GroupInboxModule'
      },
      {
        path: 'divisions/:divisionId/calls',
        loadChildren: 'app/modules/group-calls/group-calls.module#GroupCallsModule'
      }
      // {
      //   path: '',
      //   loadChildren: 'app/modules/division-bank-account-authorisation/division-bank-account-authorisation.module#DivisionBankAccountAuthorisationModule'
      // }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  declarations: [],
  exports: [RouterModule]
})
export class LayoutRoutesModule {}
