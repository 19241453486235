import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';

@Injectable()
export class SnackBarService {
  constructor(protected snackBar: MatSnackBar) {}

  success(message: string, title?: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 12000,
      panelClass: 'success-snackbar',
      data: { message: message, title: title, icon: 'done' }
    });
  }

  warning(message: string, title?: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 12000,
      panelClass: 'warning-snackbar',
      data: { message: message, title: title, icon: 'warning' }
    });
  }

  error(message: string, title?: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 12000,
      panelClass: 'error-snackbar',
      data: { message: message, title: title, icon: 'error' }
    });
  }

  info(message: string, title?: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 12000,
      panelClass: 'info-snackbar',
      data: { message: message, title: title, icon: 'info' }
    });
  }

  dismiss() {
    this.snackBar.dismiss();
  }
}
