import { DataService } from 'app/shared/services/data.service';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { Injectable } from '@angular/core';
import { AuditEntry } from 'app/shared/models/audit.model';

@Injectable()
export class LogDataService extends DataService<any> {

  constructor(protected dependencies: ApiDependenciesService) {
    super(dependencies);
  }

  public async submitAudit(audit: AuditEntry): Promise<boolean> {

    let result = false;

    await this
      .setEndpoint('/audit/submit')
      .save(audit)
      .then(() => {
        result = true;
      })
  
      return Promise.resolve(result);
  }
}
