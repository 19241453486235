import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'no-results',
  template: `
    <div class="table-no-results">
      {{message}}
    </div>
  `,
  styles: [`
  .table-no-results {
    text-align: center;
    font-weight: bold;
    color: #bbb;
  
  }`
  ]
})
export class NoResultsComponent implements OnInit {

    @Input() message = 'No Results'
    constructor() { }
  
    ngOnInit() {
    }  
}
