import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { FilterFieldsBaseComponent } from 'app/shared/components/list-filter/filter-fields-base.component';
import { Filter, FilterOperatorType } from 'app/shared/models/filter.model';
import { toFormattedDateString, addDays } from 'app/shared/helpers/date-helpers';
import { CaseActivityListFilterModel } from 'app/modules/solution-shared/case-activity/models/case-activity-list-filter.model';
import { ActivityListService } from '../services/activity-list.service';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { CaseActivityListMode } from 'app/modules/solution-shared/case-activity/models/CaseActivityListMode';

@Component({
  selector: 'activity-list-filter',
  templateUrl: './activity-list-filter.component.html',
  providers: [{ provide: FilterFieldsBaseComponent, useExisting: forwardRef(() => ActivityListFilterComponent) }],
})
export class ActivityListFilterComponent extends FilterFieldsBaseComponent implements OnInit {
  @Input() divisionId: number;
  @Input() caseActivityListMode: CaseActivityListMode = CaseActivityListMode.History;
  @Input() activityLevel: number;
  @Input() levelParentId: number;

  model: CaseActivityListFilterModel = new CaseActivityListFilterModel();

  activityTypeLookupData: any[];

  dataForm: FormGroup;
  get summary(): AbstractControl {
    return this.dataForm.controls['summary'];
  }
  get dateFrom(): AbstractControl {
    return this.dataForm.controls['dateFrom'];
  }
  get dateTo(): AbstractControl {
    return this.dataForm.controls['dateTo'];
  }
  get activityType(): AbstractControl {
    return this.dataForm.controls['activityType'];
  }

  constructor(private fb: FormBuilder, private service: ActivityListService, private formHelperService: ReactiveFormHelperService) {
    super();
  }

  ngOnInit() {
    this.dataForm = this.fb.group({
      summary: [this.model.summary],
      dateFrom: [this.model.dateFrom],
      dateTo: [this.model.dateTo],
      activityType: [this.model.activityType],
    });

    if(this.service.divisionId===undefined) this.service.divisionId = this.divisionId;
    if(this.service.activityLevel===undefined) this.service.activityLevel = this.activityLevel;
    if(this.service.levelParentId===undefined) this.service.levelParentId = this.levelParentId;

    if (this.caseActivityListMode === CaseActivityListMode.History) {
      this.service.getFilterLookupData().then((response) => {
        this.activityTypeLookupData = this.formHelperService.createLookupData(response.activityTypeLookupData);
        this.activityTypeLookupData.unshift({ id: 0, text: 'All Types' });
      });
    } else {
      this.service.getTribunalFilterLookupData().then((response) => {
        this.activityTypeLookupData = this.formHelperService.createLookupData(response.activityTypeLookupData);
        this.activityTypeLookupData.unshift({ id: 0, text: 'All Documents & Emails' });
      });
    }

    this.dataForm.markAsPristine();
  }

  ngAfterViewInit() {
    this.notifyFilterChanged();
  }

  resetToDefault() {
    this.dataForm.controls['summary'].setValue(this.model.summary);
    this.dataForm.controls['dateFrom'].setValue(this.model.dateFrom);
    this.dataForm.controls['dateTo'].setValue(this.model.dateTo);
    this.dataForm.controls['activityType'].setValue(this.model.activityType);
  }

  getFilters(): Array<Filter> {
    const filters = new Array<Filter>();

    if (this.summary.value) {
      filters.push(new Filter('Summary', new FilterOperatorType().Contains, this.summary.value));
    }

    if (this.dateFrom.value) {
      const date = toFormattedDateString(this.dateFrom.value, 'DD-MMM-YYYY');
      filters.push(new Filter('ActivityDate', new FilterOperatorType().GreaterThanEqual, date));
    }

    if (this.dateTo.value) {
      const toDate = addDays(this.dateTo.value, 1);
      const date = toFormattedDateString(toDate, 'DD-MMM-YYYY');
      filters.push(new Filter('ActivityDate', new FilterOperatorType().LessThan, date));
    }

    if (this.activityType.value && this.activityType.value > 0) {
      filters.push(new Filter('ActivityTypeId', new FilterOperatorType().Equals, this.activityType.value));
    }

    return filters;
  }
}
