import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from 'app/core/app-material/app-material.module';
import { UserResetPasswordComponent } from 'app/layout/components/user-reset-password/user-reset-password.component';
import { HeaderComponent } from 'app/layout/header/header.component';
import { LayoutComponent } from 'app/layout/layout-component/layout.component';
import { LayoutRoutesModule } from 'app/layout/layout-routes/layout-routes.module';
import { HeaderService } from 'app/layout/services/header.service';
import { TimeService } from 'app/modules/configuration/time/time.service';
import { UserListContainerService } from 'app/modules/configuration/user-list-page/user-list-container/user-list-container.service';
import { TelephonyService } from 'app/shared/services/telephony.service';
import { BlockUIModule } from 'ng-block-ui';
import { AsideContainerComponent } from './components/aside-container/aside-container.component';
import { DefaultAsideComponent } from './components/aside-container/default-aside.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { AsideContentDirective } from './directives/aside-content.directive';
import { ProfileService } from './profile/services/profile.service';
import { MainMenuService } from './services/main-menu.service';
import { ResponsiveService } from './services/responsive.service';
import { SideMenuComponent } from './side-menu/side-menu.component/side-menu.component';
import { CoreSharedModule } from 'app/shared/core-shared.module';
import { LayoutTransferModule } from './layout-transfer.module';
import { CommonModule } from '@angular/common';
import { ActivitiesModule } from 'app/modules/solution-shared/case-activity/activites.module';
import { TaskStatusSummaryComponent } from 'app/modules/tasks/task-status-summary/task-status-summary.component';
import { ExternalRaiseDefectModule } from 'app/modules/external-raise-defect/external-raise-defect.module'

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SideMenuComponent,
    DefaultAsideComponent,
    AsideContainerComponent,
    AsideContentDirective,
    WelcomeComponent,
    UserResetPasswordComponent,
    TaskStatusSummaryComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule,
    CoreSharedModule,
    AppMaterialModule,
    ActivitiesModule,
    LayoutTransferModule,
    ExternalRaiseDefectModule,
    LayoutRoutesModule // this must be last
  ],
  exports: [],
  entryComponents: [DefaultAsideComponent, UserResetPasswordComponent],
  providers: [TimeService, TelephonyService, ResponsiveService, MainMenuService, ProfileService, HeaderService, UserListContainerService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutModule {}
