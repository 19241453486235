import { ApiDependenciesService } from './../../api/services/api-dependencies.service';
import 'rxjs/add/operator/toPromise';
import { Subject } from 'rxjs/Subject';
import { Injectable, NgModule } from '@angular/core';
import { SignalRHub, SignalRError } from 'rxjs-signalr';
import { environment } from 'environments/environment';
import { Constants } from 'app/core/models/constants';
import { TimerControlStatus } from '../enumeration/timer-control-status';

export class ConnectionStatusChangedMessage {
  public message: String;
  constructor(message: String) {
    this.message = message;
  }
}

export class ErrorMessage {
  public message: String;
  constructor(message: String) {
    this.message = message;
  }
}

export class MessageFromServer {
  constructor(public method: String, public data: any) {}
}

export class ConnectionStatusChanged extends Subject<ConnectionStatusChangedMessage> {}
export class ErrorOccured extends Subject<ErrorMessage> {}
export class MessageReceivedFromServer extends Subject<MessageFromServer> {}

@Injectable()
export abstract class SignalRService {
  // extends BaseApiService {

  public onConnectionStatusChanged$ = new ConnectionStatusChanged(); // $ notation = observable
  public onErrorOccured$ = new ErrorOccured(); // $ notation = observable
  public onMessageReceivedFromServer$ = new MessageReceivedFromServer(); // $ notation = observable

  /*
  This service will need to be registered as a provider.  When this was exercised at its introduction it was registered in app.module.ts

  This service can be injected in to components and the observables above can be subscribed to as per the syntax below.
  NOTE: in your component you must use the => syntax if you want to be able to reference the 'this' object from within the subscribed method.
  Otherwise typescript will not allow for the reevaluation of the _this object when transpiled to javascript.

  signalRService.onMessageReceivedFromServer$.subscribe((message: MessageFromServer)=>{
         this.receivedMessages = message.message;
    });
  */

  protected hub: SignalRHub;
  constructor(hubName: string, url: string, protected dependencies: ApiDependenciesService) {
    this.hub = new SignalRHub(hubName, url);

    this.setQueryString();

    this.hub.state$.subscribe(state => {
      this.onConnectionStatusChanged$.next(new ConnectionStatusChangedMessage(state));
    });

    this.hub.error$.subscribe((error: SignalRError) => {
      this.onErrorOccured$.next(new ErrorMessage(error.message));
    });

    this.addServerPushMethods();

    this.hub.connection.logging = true;
    this.hub.start();
  }

  // method to add hib specific messages that will be pushed from the server
  protected abstract addServerPushMethods();

  protected setQueryString() {
    const accessTokenFromCookie = localStorage.getItem(Constants.LocalStorage.KeyAccessToken);

    this.hub.connection.qs = { access_token: accessTokenFromCookie };
  }

  public async sendMessage(data: string) {
    this.sendData('DataFromClient', data);
  }

  public async sendData(method: string, data: any) {

    if (this.isConnected) {
      this.sendDataOnConnectedHub(method, data);
    } else {
      console.debug('Cant send message. Not connected');
    }
  }

  public async reconnect() {
    this.hub.start();
  }

  public async sendDataOnConnectedHub(method: string, data: any) {
    console.debug('SignalR sending message [' + method + ']...');
    console.debug(data);
    this.hub.send(method, data);
  }

  public get isConnected(): boolean {
    return this.hub.connection.state === $.signalR.connectionState.connected;
  }
}

@Injectable()
export class TaskHubService extends SignalRService {
  constructor(dependencies: ApiDependenciesService) {
    super('TaskHub', environment.baseUrl, dependencies);
  }

  addServerPushMethods() {
    this.hub.on('StatusUpdate').subscribe((message: String) => {
      this.onMessageReceivedFromServer$.next(new MessageFromServer('StatusUpdate', message));
    });
  }
}

@Injectable()
export class ActivityTimerHubService extends SignalRService {
  constructor(dependencies: ApiDependenciesService) {
    super('ActivityTimerHub', environment.activityTimerHubUrl, dependencies);
  }

  get cookieUserId(): number {
    return +this.dependencies.cookieService.get(Constants.Cookies.KeyLoggedInUserId);
  }

  addServerPushMethods() {
    // add listener for each push method defined on the server interface

    const messages = [
      'MessageToClient',
      'ActivityTimerStatusUpdate',
      'UserActivityStatusUpdate',
      'PauseAllComplete',
      'DndElapsed',
      'ActivityListChanged',
      'UserDndStatus'
    ];

    // TODO: [refactor][DPB][2019-11-06] Can this be moved to the base class?
    messages.forEach(messageName => {
      this.hub.on(messageName).subscribe((message: any) => {
        console.debug('SignalR message received [' + messageName + ']');
        console.debug(message);
        this.onMessageReceivedFromServer$.next(new MessageFromServer(messageName, message));
      });
    });
  }

  protected setQueryString() {
    const accessTokenFromCookie = localStorage.getItem(Constants.LocalStorage.KeyAccessToken);
    this.hub.connection.qs = { access_token: accessTokenFromCookie, user_id: this.cookieUserId };
  }

  timerChangeEvent(data: any) {
    data.userId = this.cookieUserId;
    this.sendData('TimerChangeEvent', data);
  }

  startRecording(caseActivityId: number) {
    if (caseActivityId === 0) {
      throw new Error('Activity Id is 0. Message cannot be sent');
    }

    this.sendData('TimerChangeEvent', { userId: this.cookieUserId, caseActivityId: caseActivityId, event: TimerControlStatus.Recording });
  }

  pauseRecording(caseActivityId: number) {
    if (caseActivityId === 0) {
      throw new Error('Activity Id is 0. Message cannot be sent');
    }

    this.sendData('TimerChangeEvent', { userId: this.cookieUserId, caseActivityId: caseActivityId, event: TimerControlStatus.Paused });
  }

  stopRecording(caseActivityId: number) {
    if (caseActivityId === 0) {
      throw new Error('Activity Id is 0. Message cannot be sent');
    }

    this.sendData('TimerChangeEvent', { userId: this.cookieUserId, caseActivityId: caseActivityId, event: TimerControlStatus.Stopped });
  }

  pauseAllTimers(data: any) {
    data.userId = this.cookieUserId;
    this.sendData('PauseAllTimers', data);
  }

  requestActivityStatus() {
    const userId = this.cookieUserId;
    this.sendData('RequestActivityStatus', userId);
  }

  requestDndStatus() {
    const userId = this.cookieUserId;
    this.sendData('RequestDndStatus', userId);
  }

  notifyDndTurnedOff() {
    const userId = this.cookieUserId;
    this.sendData('DndTurnedOff', userId);
  }

  requestActivityTimer(activityId: number, type: string) {
    if (activityId === undefined || activityId === null || activityId === 0) {
      throw new Error(`Invalid activity ID [${activityId}]`);
    }
    this.sendData('RequestActivityTimer', { userId: this.cookieUserId, activityId: activityId, activityType: type, startRecording: false });
  }
}
