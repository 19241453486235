// TODO: [Refactor][DPB][2019-08-30] if used this can be removed and replaced by a string
export class UserPermissionsAction {
  public message: string;

  constructor(message: string) {
    this.message = message;
  }
}

export interface IMenuGroup {
  isEnabled: boolean;
  getIsEnabled(): boolean;
}

export class MenuGroup implements IMenuGroup {
  public isEnabled = false;

  public getIsEnabled(): boolean {
    return true;
  }
}

export interface IOptionGroup {
  isEnabled: boolean;
  getIsEnabled(): boolean;
}

export class ConfigurationOptionGroup implements IOptionGroup {
  public isEnabled = false;

  public manageUsers = false;
  public manageOther = false;
  public manageMultipleDivisions = false;
  public manageDivisions = false;
  public manageProducts = false;
  public manageSectors = false;
  public manageDocumentTypes = false;
  public configurationAccess = false;
  public manageAllegationParagraphs = false;
  public manageTimeAmendmentReasons = false;
  public manageDocumentTemplates = false;
  public manageDocumentPackDefinitions = false;
  public manageActivityTypes = false;
  public manageResources = false;
  public manageTaxRates = false;
  public manageRateCards = false;
  public manageDocumentPackTypes = false;
  public manageServiceAgreementFinanceDetails = false;
  public manageCancellationReasons = false;
  public manageCostTypes = false;
  public manageCoverLevels = false;
  public manageEmailTemplates = false;
  public manageSingleUseActions = false;
  public manageDatabaseAudit = false;

  public getIsEnabled(): boolean {
    return this.manageUsers;
  }
}

export class SearchOptionGroup implements IOptionGroup {
  public isEnabled = false;

  public allowActivityTimeFilteringByUser = false;

  public getIsEnabled(): boolean {
    return this.allowActivityTimeFilteringByUser;
  }
}

export class TaskOptionGroup implements IOptionGroup {
  public isEnabled = false;

  public allowUserToUpdateTaskOwner = false;

  public getIsEnabled(): boolean {
    return true;
  }
}

export class ContactOptionGroup implements IOptionGroup {
  public isEnabled = false;

  public allowUserToUpdateClientPortalAccess = false;
  public allowUserToRestrictClients = false;
  public financeAccessOnly = false;
  public financeOrAdminAccessOnly = false;

  public getIsEnabled(): boolean {
    return true;
  }
}

export class SchemeOptionGroup implements IOptionGroup {
  public isEnabled = false;

  public manageSchemes = false;

  public getIsEnabled(): boolean {
    return true;
  }  
}

export class ProductOptionGroup implements IOptionGroup {
  public isEnabled = false;

  public allowUserToUpdateProductFinance = false;
  public manageServiceAgreementPolicyOptions = false;
  public manageTerminateServiceAgreementOptions = false;
  public getIsEnabled(): boolean {
    return true;
  }
}

export class ActionOptions {
  public configuration = new ConfigurationOptionGroup();
  public search = new SearchOptionGroup();
  public menu = new MenuGroup();
  public task = new TaskOptionGroup();
  public product = new ProductOptionGroup();
  public contact = new ContactOptionGroup();
  public scheme = new SchemeOptionGroup();
  public hasDivisionSelected: boolean;
  public allowUserToAccessRestrictedClientPages: boolean;
}

export class UserPermissions {
  public isInitialised = false;
  public userName = '';
  public action = new ActionOptions();
  public isAdminUser = false;

  public markAsInitialised(userName: string) {
    this.isInitialised = true;
    this.userName = userName;
  }
}
