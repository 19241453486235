import { StringUtilities } from "app/shared/helpers/string-helpers";

export class Constants {

  public static Offline = class {

    public static KeyOfflineStatus = 'drivefurther_offline-status';
    public static IsOffline = 'offline';
    public static IsOnline = 'online';

    public static MaxFileSystemMb = 500 * 1024 * 1024;
    public static KeyInspectionPrefix = 'inspection-';

    public static KeyDevelopmentInfoPrefix = 'development-';

    public static KeyPendingInspectionPollRequests = 'pending-inspection-poll-requests';
    public static PollPauseMilliseconds = 5 * 1000;
    public static PollPauseLongerMilliseconds = 120 * 1000;

    public static KeyPendingInspectionSyncRequests = 'pending-inspection-sync-requests';
    public static SyncPauseMilliseconds = 5 * 1000;
    public static SyncPauseLongerMilliseconds = 120 * 1000;
  }

  public static LocalStorage = class {

    public static DatabaseName = 'WH-LocalStorage';
    public static Version = 1.0;
    public static Description = 'Database used by the application to store information needed for offline working';
    public static Size = 52428800;
    public static PurgeDays = 14;
    public static KeyAccessToken = 'drivefurther_accessToken';
    public static KeyRefreshToken = 'drivefurther_refreshToken';
    public static RedirectUrl = 'drivefurther_redirectUrl';
  }

  public static Audit = class {

    public static KeyAuditEntryPrefix = 'audit-';
    public static KeyAuditEntryFrequency = 'DD-MMM-YYYY-HH-mm';
  }

  public static FileSystem = class {

    public static PurgeDays = 60;

    public static FileError = class {
      public static QUOTA_EXCEEDED_ERR = 'QuotaExceededError';
      public static NOT_FOUND_ERR = 'NOT_FOUND_ERR';
      public static SECURITY_ERR = 'SECURITY_ERR';
      public static INVALID_MODIFICATION_ERR = 'INVALID_MODIFICATION_ERR';
      public static INVALID_STATE_ERR = 'INVALID_STATE_ERR';
    }

    public static FileReaderReadyState = class {
      public static EMPTY = 0;
      public static LOADING = 1;
      public static DONE = 2;
    }
  }

  public static Cookies = class {

    
    public static KeyLoggedInUserId = 'drivefurther_loggedInUserId';
    public static KeyLoggedInUserName = 'drivefurther_loggedInUserName';
    public static KeyLoggedInFullName = 'drivefurther_loggedInFullName';
    public static KeyUiState = 'drivefurther_uiState';
    public static KeyDeviceId = 'drivefurther_deviceId';
    public static KeyDivisionTheme = 'drivefurther_themeId';
    public static KeyLoggedInUserIsInternal = 'drivefurther_loggedInUserIsInternal';
    public static KeyLoggedInUserExtension = 'drivefurther_loggedInUserExtension';
    public static KeyLoggedInMitelUserName = 'drivefurther_loggedInMitelUserName';
    public static KeyLoggedInMitelPassword = 'drivefurther_loggedInMitelPassword';
    
    
    
  }

  public static Evidence = class {

    public static MaxThumbnailWidth = 200;
  }

  public static EditorSettings = class {
    public static FullSettings = {
      height: 500,
      menubar: false,
      browser_spellcheck: true,
      contextmenu: false,
      file_picker_types: 'file image media',
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        /*
          Note: In modern browsers input[type="file"] is functional without
          even adding it to the DOM, but that might not be the case in some older
          or quirky browsers like IE, so you might want to add it to the DOM
          just in case, and visually hide it. And do not forget do remove it
          once you do not need it anymore.
        */

        input.onchange = function () {
          var file = input.files[0];
          let tinymce = window['tinymce'];
          var reader = new FileReader();
          reader.onload = function () {
            /*
              Note: Now we need to register the blob in TinyMCEs image blob
              registry. In the next release this part hopefully won't be
              necessary, as we are looking to handle it internally.
            */
            var id = 'blobid' + new Date().getTime();
            var blobCache = tinymce.activeEditor.editorUpload.blobCache;
            var base64 = (<string>reader.result).split(',')[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);

            /* call the callback and populate the Title field with the file name */
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      },
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar:
        'undo redo | formatselect | image customtimestamp searchreplace | link anchor | bold italic underline strikethrough forecolor backcolor  | \
    alignleft aligncenter alignright alignjustify | \
    bullist numlist outdent indent | removeformat | help | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | code',
      insertdatetime_formats: ['%d/%m/%Y @ %H:%M:%S'],
      setup: function (editor) {
        var customUsertimeFunction = StringUtilities.getUserDateTime;

        editor.ui.registry.addButton('customtimestamp', {
          icon: 'insert-time',
          tooltip: 'Insert Timestamp',
          disabled: true,
          onAction: function (_) {
            editor.insertContent(customUsertimeFunction(new Date()));
          },
          onSetup: function (buttonApi) {
            var editorEventCallback = function (eventApi) {
              buttonApi.setDisabled(eventApi.element.nodeName.toLowerCase() === 'time');
            };
            editor.on('NodeChange', editorEventCallback);

            /* onSetup should always return the unbind handlers */
            return function (buttonApi) {
              editor.off('NodeChange', editorEventCallback);
            };
          },
        });
      },

    };
    public static BasicSettings = {
      height: 500,
      user: 'damo b',
      menubar: false,
      browser_spellcheck: true,
      contextmenu: false,
      plugins: ['advlist autolink lists charmap preview', 'searchreplace fullscreen', 'paste wordcount'],
      toolbar: 'fullscreen | undo redo searchreplace | bold italic underline strikethrough | \
          bullist numlist outdent indent | customtimestamp',
      setup: function (editor) {

        var customUsertimeFunction = StringUtilities.getUserDateTime;

        editor.ui.registry.addButton('customtimestamp', {
          icon: 'insert-time',
          tooltip: 'Insert Timestamp',
          disabled: true,
          onAction: function (_) {
            editor.insertContent(customUsertimeFunction(new Date()));
          },
          onSetup: function (buttonApi) {
            var editorEventCallback = function (eventApi) {
              buttonApi.setDisabled(eventApi.element.nodeName.toLowerCase() === 'time');
            };
            editor.on('NodeChange', editorEventCallback);

            /* onSetup should always return the unbind handlers */
            return function (buttonApi) {
              editor.off('NodeChange', editorEventCallback);
            };
          },
        });
      },
    };
  };
}
