exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".nb-error {\r\n  margin: 0 auto;\r\n  text-align: center;\r\n  max-width: 480px;\r\n  padding: 60px 30px;\r\n}\r\n\r\n.nb-error .error-code {\r\n  color: #2d353c;\r\n  font-size: 96px;\r\n  line-height: 100px;\r\n}\r\n\r\n.nb-error .error-desc {\r\n  font-size: 12px;\r\n  color: #647788;\r\n}\r\n.nb-error .input-group{\r\n  margin: 30px 0;\r\n}\r\na.btn{\r\n  color: #1ab394\r\n}\r\na.btn:hover {\r\n  color: #fff\r\n}\r\n", ""]);

// exports


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();