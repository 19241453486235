import { Component, EventEmitter, Injector, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ComponentItem } from 'app/shared/base/aside/component-item';
import { AddListComponent } from 'app/shared/components/add-list/add-list.component';
import { IconActionButtonEventMessage } from 'app/shared/components/icon-action-button-list/icon-action-button-list-message.model';
import { StringUtilities } from 'app/shared/helpers/string-helpers';
import { IAddListService } from 'app/shared/interfaces/IAddListService.interface';
import { SnackBarService } from 'app/shared/services/snack-bar.service';
import { Subject } from 'rxjs';
import { ActivityNoteFormComponent } from '../../note/case-note-form/case-note-form.component';
import { ActivityListItemViewModel } from '../models/activity-list-item-view.model';
import { CaseActivityPriorityNoteListService } from '../services/case-activity-priority-note.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'case-activity-priority-note-list',
  templateUrl: './case-activity-priority-note-list.component.html',
  styleUrls: ['./case-activity-priority-note-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CaseActivityPriorityNotesListComponent extends AddListComponent implements OnDestroy {
  @Input() divisionId: number;
  @Input() levelParentId: number;
  @Input() activityLevel: number;

  // @Input() allowManage = true;
  @Input() showActions = true;

  get displayedColumns(): string[] {
    const columns = ['activityTypeName', 'summary'];
    if (this.showActions) {
      columns.push('actions');
    }
    return columns;
  }
  private ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(private activityListService: CaseActivityPriorityNoteListService, private asideService: AsideService, public snackBar: SnackBarService, protected dialog: MatDialog, private injector: Injector) {
    super();
    this.loadAfterInit = true;
  }

  protected get service(): IAddListService {
    return this.activityListService;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    super.ngOnInit();

    this.activityListService.divisionId = +this.divisionId;
    this.activityListService.levelParentId = +this.levelParentId;
    this.activityListService.activityLevel = this.activityLevel;
  }

  convertString(source): string {
    if (!source) {
      return '';
    }
    return StringUtilities.translateCR(source);
  }

  toggleExpanded(row: ActivityListItemViewModel) {
    row.expanded = !row.expanded;
  }

  doIconButtonAction(message: IconActionButtonEventMessage) {
    const item = this.activityListService.findViewModelFromItemId(message.data);
    switch (message.action) {
      case 'edit': {
        this.asideService.setAndOpen(new ComponentItem(ActivityNoteFormComponent, { itemId: item.dataModel.activityEntityId, activityId: message.data, activityLevel: this.activityLevel }), this.injector);
        break;
      }
      default: {
        console.log('Unsupported action: ' + message.action);
        break;
      }
    }
  }
}
