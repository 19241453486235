import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'page-title',
  template: `
    <header class="content__title">
      <h2><ng-content select=".heading"></ng-content></h2>
    </header>
  `,
  styles: [`
  h2{
    margin-block-start: 0.6em;
    margin-block-end: 0.3em;
    }
  `]
})
export class PageTitleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
