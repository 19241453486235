import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators,
  ValidatorFn,
  AsyncValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { AsideService } from "app/layout/components/aside-container/aside.service";
import { ConfirmationModalComponent } from "app/shared/components/confirmation-modal/confirmation-modal.component";
import { ReactiveFormHelperService } from "app/shared/helpers/reactive-form-helper.service";
import { MatDialog } from "@angular/material/dialog";
import { RecordedTimeAmendFormModel } from "../../models/recorded-time-amend-form.model";
import { ActivityRecordedTimeListService } from "../../services/activity-recorded-time-list.service";
import { ActivatedRoute } from "@angular/router";
import { formatAsString } from "app/shared/helpers/date-helpers";
import { RegexValidationStrings } from "app/shared/validation/reg-ex-validation-constants";
import { Observable, timer } from "rxjs";
import { switchMap } from "rxjs/operators";



@Component({
  selector: "recorded-time-amend-form",
  templateUrl: "./recorded-time-amend-form.component.html"
})
export class RecordedTimeAmendFormComponent implements OnInit {

  public data: any;
  editMode: boolean;
  viewModel = new RecordedTimeAmendFormModel();
  dataForm: FormGroup;
  divisionId: number;
  amendId: number;
  caseActivityId: number;
  statusLookupData: any[];
  timeAmendReasonLookupData: any[];

  constructor(
    fb: FormBuilder,
    private service: ActivityRecordedTimeListService,
    private asideService: AsideService,
    private formHelperService: ReactiveFormHelperService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.dataForm = fb.group({
      originalStart: [""],
      originalStop: [""],
      newStopTimeHours: ['', [Validators.required, Validators.max(23), Validators.pattern(RegexValidationStrings.INTEGER)]],
      newStopTimeMinutes: ['', [Validators.required, Validators.max(59), Validators.pattern(RegexValidationStrings.INTEGER)]],
      newStopTimeSeconds: ['', [Validators.required, Validators.max(59), Validators.pattern(RegexValidationStrings.INTEGER)]],
      newStopTime: [""],
      status: [""],
      timeAmendReason: ["", [Validators.required]]
    },
      {
        validator: Validators.compose([this.compareStartAndStopTime()]),
        asyncValidator: this.timeOverlapValidator()
      }
    );
  }

  get originalStart(): AbstractControl {
    return this.dataForm.controls["originalStart"];
  }
  get originalStop(): AbstractControl {
    return this.dataForm.controls["originalStop"];
  }
  get timeAmendReason(): AbstractControl {
    return this.dataForm.controls["timeAmendReason"];
  }
  get newStopTimeHours(): AbstractControl {
    return this.dataForm.controls["newStopTimeHours"];
  }
  get newStopTimeMinutes(): AbstractControl {
    return this.dataForm.controls["newStopTimeMinutes"];
  }
  get newStopTimeSeconds(): AbstractControl {
    return this.dataForm.controls["newStopTimeSeconds"];
  }
  get newStopTime(): AbstractControl {
    return this.dataForm.controls["newStopTime"];
  }
  get status(): AbstractControl {
    return this.dataForm.controls["status"];
  }


  ngOnInit() {
    this.editMode = this.data.amendId > 0;
    this.service.divisionId = this.data.divisionId;
    this.service.recordedTimeItemId = this.data.itemId;
    this.service.caseActivityId = this.data.caseActivityId;
    this.loadFormDetails();
    this.originalStart.disable();
    this.originalStop.disable();
  }

  private loadFormDetails() {
    const id: number = this.editMode ? this.data.amendId : null;
    this.service
      .getAdjustmentEditableItemDetail(id)
      .then((response: RecordedTimeAmendFormModel) => {
        this.viewModel = response;
        if (this.viewModel) {
          this.initialiseForm();
        }
      });

  }


  initialiseForm() {
    if (this.editMode) {
      this.statusLookupData = this.formHelperService.createLookupData(this.viewModel.statusLookupData);
      this.timeAmendReasonLookupData = this.formHelperService.createLookupData(this.viewModel.timeAmendReasonLookupData);
      this.dataForm.controls["originalStart"].setValue(formatAsString(this.viewModel.originalStart.toString(), 'YYYY-MM-DD HH:mm:ss'));
      this.dataForm.controls["originalStop"].setValue(this.viewModel.originalStop ? formatAsString(this.viewModel.originalStop.toString(), 'YYYY-MM-DD HH:mm:ss') : null);
      this.dataForm.controls["timeAmendReason"].setValue(this.viewModel.timeAmendmentReasonId);
      this.dataForm.controls["newStopTime"].setValue(formatAsString(this.viewModel.newStopTime.toString(), 'YYYY-MM-DD HH:mm:ss'));
      this.readOnly()

    } else {
      this.statusLookupData = this.formHelperService.createLookupData(this.viewModel.statusLookupData);
      this.timeAmendReasonLookupData = this.formHelperService.createLookupData(this.viewModel.timeAmendReasonLookupData);
      this.dataForm.controls["originalStart"].setValue(formatAsString(this.viewModel.originalStart.toString(), 'YYYY-MM-DD HH:mm:ss'));
      this.dataForm.controls["originalStop"].setValue(this.viewModel.originalStop ? formatAsString(this.viewModel.originalStop.toString(), 'YYYY-MM-DD HH:mm:ss') : null);
      this.dataForm.controls["timeAmendReason"].setValue(this.viewModel.timeAmendmentReasonId);
      this.dataForm.controls["newStopTimeHours"].setValue(this.viewModel.newStopTimeHours);
      this.dataForm.controls["newStopTimeMinutes"].setValue(this.viewModel.newStopTimeMinutes);
      this.dataForm.controls["newStopTimeSeconds"].setValue(this.viewModel.newStopTimeSeconds);
      this.dataForm.controls["status"].setValue(this.viewModel.status);
    }
  }


  private createUpdateModel() {
    const newStopTime = this.createNewStopTime();

    return {
      recordedTimeItemId: this.data.itemId,
      newStopTimeHours: this.newStopTimeHours.value,
      newStopTimeMinutes: this.newStopTimeMinutes.value,
      newStopTimeSeconds: this.newStopTimeSeconds.value,
      newStopTime: formatAsString(newStopTime.toString(), 'YYYY-MM-DD HH:mm:ss'),
      originalStart: this.originalStart.value,
      status: this.status.value,
      timeAmendmentReasonId: this.timeAmendReason.value,
      timestamp: this.viewModel.timestamp
    };
  }

  private createNewStopTime(): Date {
    const newHours = this.newStopTimeHours.value;
    const newMinutes = this.newStopTimeMinutes.value;
    const newSeconds = this.newStopTimeSeconds.value;
    const newStopTime = new Date(this.viewModel.originalStart);
    newStopTime.setHours(newHours);
    newStopTime.setMinutes(newMinutes);
    newStopTime.setSeconds(newSeconds);
    return newStopTime;
  }

  save() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();
      if (this.editMode) {
        this.service.updateTimeAmend(this.data.amendId, updateModel).then(() => {
          this.asideService.close('request');
        });
      } else {
        this.service.addNewTimeAmend(updateModel).then(() => {
          this.asideService.close("request");
        });
      }
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: "450px",
        data: {
          title: "Confirm Lost Changes",
          message:
            "Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?"
        }
      });

      dialogRef.componentInstance.onClose.subscribe(result => {
        if (result.result) {
          this.asideService.close("cancel");
        }
      });
    } else {
      this.asideService.close("cancel");
    }
  }


  accept() {
    const updateModel = this.createUpdateModelAccept();
    this.service.updateTimeAmend(this.data.amendId, updateModel).then(() => {
      this.asideService.close("accept");
    });
  }

  reject() {
    const updateModel = this.createUpdateModelReject();
    this.service.updateTimeAmend(this.data.amendId, updateModel).then(() => {
      this.asideService.close("reject");
    });
  }

  private createUpdateModelAccept() {
    return {
      status: 2,
      timestamp: this.viewModel.timestamp
    };
  }

  private createUpdateModelReject() {
    return {
      status: 3,
      timestamp: this.viewModel.timestamp
    };
  }

  readOnly() {
    this.newStopTimeHours.disable();
    this.newStopTimeMinutes.disable();
    this.newStopTimeSeconds.disable();
    this.timeAmendReason.disable();
    this.newStopTime.disable()
  }


  compareStartAndStopTime(): ValidatorFn {

    return (group: FormGroup): { [key: string]: any } => {

      const newStopHours = group.controls['newStopTimeHours'].value;
      const newStopMinutes = group.controls['newStopTimeMinutes'].value;
      const newStopSeconds = group.controls['newStopTimeSeconds'].value;
      const startDate = group.controls['originalStart'].value;
      let newStopTime = group.controls['newStopTime'].value;

      let continueWithComparison = (newStopHours && newStopMinutes && newStopSeconds && startDate) != undefined && startDate != '';

      if (!continueWithComparison) {
        return null;
      }

      let noStopTime = (newStopHours && newStopMinutes && newStopSeconds) == '';
      newStopTime = new Date(startDate);

      if (noStopTime) {
        return newStopTime.value
      } else {
        newStopTime.setHours(newStopHours);
        newStopTime.setMinutes(newStopMinutes);
        newStopTime.setSeconds(newStopSeconds);
      }

      const expected = new Date(startDate) <= new Date(newStopTime);
      return !expected ? { 'amenddategreaterthanequaltocomparison': { value: true } } : null;
    };
  }

  timeOverlapValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      if (!this.data || !this.viewModel) {
        return null;
      }

      const proposedStart = this.viewModel.originalStart;
      const proposedStop = this.createNewStopTime();
      const id = this.data.itemId;

      const debounceTime = 250;
      return timer(debounceTime).pipe(
        switchMap(() =>
          this.service.isTimeRecordingOverlapping(id, proposedStart, proposedStop).then(retval => {
            return retval === false ? { timeoverlapping: true } : null;
          })
        )
      );
    };
  }

}
//#endregion