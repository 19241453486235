import { Injectable } from '@angular/core';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { DataService } from 'app/shared/services/data.service';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CompanyLookupResponseModel } from '../models/company-lookup.model';

@Injectable()
export class CompanyLookupService extends DataService<any> {
  @BlockUI() blockUI: NgBlockUI;
  constructor(private apiDependencies: ApiDependenciesService) {
    super(apiDependencies);
  }

  getCompanyLookupEndpoint(companyName: string = '', regNumber: string = '', postcode: string = ''): string {
    return `/application/company-lookup?companyName=${companyName}&regNumber=${regNumber}&postcode=${postcode}`;
  }

  getMappedEditableItemDataModel(response: any) {
    const model: CompanyLookupResponseModel = response;
    return model;
  }

  async getCompanyLookupResponse(companyName: string = '', regNumber: string = '', postcode: string = ''): Promise<CompanyLookupResponseModel> {
    this.startBlockUI();

    let model: CompanyLookupResponseModel;
    await this.setEndpoint(this.getCompanyLookupEndpoint(companyName, regNumber, postcode))
      .getSingle()
      .then(response => {
        model = this.getMappedEditableItemDataModel(response);
        this.stopBlockUI();
      })
      .catch(() => {
        this.stopBlockUI();
      });
    return Promise.resolve(model);
  }

  stopBlockUI() {
    this.blockUI.stop();
  }

  startBlockUI() {
    this.blockUI.start();
  }
}
