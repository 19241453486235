import moment from 'moment';

export function toFormattedDateString(when: Date, f: string): string {
  return moment(when).format(f);
}

export function toFormattedMonthString(when: number, what: string, f: string): string {
  return moment(when, what).format(f);
}

export function addDays(date: Date, days: number): Date {
  const oldDate = new Date(date); // make sure it's an actual Date and not a moment
  const newDate = new Date(date);
  newDate.setDate(oldDate.getDate() + days);
  return newDate;
}

export function formatAsString(dateValue: string | moment.Moment, format: string = 'YYYY-MM-DD'): string {
  if (dateValue == null) {
    return '';
  }

  let workingValue: moment.Moment;
  if (typeof dateValue === 'string') {
    workingValue = moment(dateValue);
  } else {
    workingValue = dateValue;
  }

  return workingValue.format(format);
}

export function getCurrentDate() {
  return new Date(Date.parse(Date()));
}
