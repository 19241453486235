import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'to-be-implemented',
  template: `
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-icon>notifications</mat-icon>
    <h3>This functionality will be in a future release</h3>
  </div>
  `,
  styles: [`
  div{
    color: #868e96;
  }
  `]
})
export class ToBeImplementedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
