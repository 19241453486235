import { Component, Input, ViewChild, NgZone, IterableDiffers, OnInit } from '@angular/core';
import { MapsAPILoader, AgmMap } from '@agm/core';
import { GoogleMapRequestItemModel } from './models/google-map-request-item.model';
import { IntegrationLogRequest } from './models/integration-log-request.model';
import { GoogleMapsService } from './services/google-map.service';
 
declare var google: any;
 
interface Marker {
  lat: number;
  lng: number;
  label?: string;
  address: string;
}

@Component({
    selector: 'google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.scss']
  })

  export class GoogleMapComponent implements OnInit {
    geocoder: any;
    public zoom = 5;
    public lat = 55.378052;
    public lng = -3.435973;

    @Input() addresses: GoogleMapRequestItemModel[] = []; 

    fitBounds: Boolean = false;

    private differ: any;

    markers: Marker[] = [];

    @ViewChild(AgmMap, { static: false }) map: AgmMap;

    constructor(public mapsApiLoader: MapsAPILoader, private zone: NgZone, private _differs: IterableDiffers, private service: GoogleMapsService) {
      this.differ = this._differs.find([]).create(null);
      this.mapsApiLoader = mapsApiLoader;
        this.zone = zone;

        this.mapsApiLoader.load().then(() => {
        this.geocoder = new google.maps.Geocoder();
        });
    }

    ngDoCheck(): void {
      const changes = this.differ.diff(this.addresses);
      if (changes) {
        this.addAddressesToMap();
      }
    }

    ngOnInit(): void {
      let model: IntegrationLogRequest = new IntegrationLogRequest;
      model.data = 'IntegrationType: [Maps JavaScript API] Map loaded on component initialization';
      this.service.createIntegrationLogItem(model);  
    }

    addAddressesToMap() {
      if (this.addresses.length > 0) {   
        this.fitBounds = true;
        this.markers = [];     
        this.addresses.forEach((value, index) => {
          let addressString = [value.organisation, value.line1, value.line2, value.line3, value.town, value.county, value.postcode].filter(Boolean).join(', ');
          this.getGeocodeForAddress(addressString, index);
        });
      } else {
        this.RemoveAllMarkers();
      }
    }

    processSuccessfulGeocodedAddress(map, index, lat, lng, address) {
      this.addMarker(map, lat, lng, (+index + 1).toString(), address); 
      let model: IntegrationLogRequest = new IntegrationLogRequest;
      model.data = 'IntegrationType: [Geocode] Status: [Success] Address Searched: [' + address + '] lat: [' + lat + '] lng: [' + lng + ']';
      this.service.createIntegrationLogItem(model);   
    }

    processFailedGeocodedAddress(address, status) {
      let model: IntegrationLogRequest = new IntegrationLogRequest;
      model.data = 'IntegrationType: [Geocode] Status: [' + status + '] Address Searched: [' + address + ']';
      this.service.createIntegrationLogItem(model);  
    }

    RemoveAllMarkers() {
      this.markers = [];
      this.fitBounds = false;
      this.map.latitude = this.lat;
      this.map.longitude = this.lng;      
      this.map.triggerResize(true); 
    }

    addMarker(map, lat, lng, label, address) {
      let marker = {} as Marker;
      marker.lat = +(lat);
      marker.lng = +(lng);
      marker.label = label;
      marker.address = address;
      this.markers.push(marker);
      map.triggerResize(true);
    }

    getGeocodeForAddress(address: string, index) {
      if (!this.geocoder) { this.geocoder = new google.maps.Geocoder() }
      let that = this;
      let map = this.map;

      this.geocoder.geocode({
        'address': address
      }, (results, status) => {        
        if (status === google.maps.GeocoderStatus.OK) {
          that.processSuccessfulGeocodedAddress(map, index, results[0].geometry.location.lat(), results[0].geometry.location.lng(), address);                
        } else {
          that.processFailedGeocodedAddress(address, status);
        }        
      });
    }
  }
