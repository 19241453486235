import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[aside-content]',
})

//A helper directive for the AsideContainer component. Required to mark a valid insertion point in the template.
export class AsideContentDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
