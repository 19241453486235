import { Injectable, OnDestroy } from '@angular/core';
import { DataService } from 'app/shared/services/data.service';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ActivityDocumentCreateModel, ParagraphLookupList, ParagraphLookupItem, GroupedParagraphList } from '../models/case-activity-document-create.model';
import { ApiResponseSummary } from '../../../../shared/models/api-response-summary.model';
import { TelephonyService } from 'app/shared/services/telephony.service';
import { CallDetails, CallDirections } from 'app/shared/kaleida.telephony/Contract_Types';
import { Subscription } from 'rxjs';
import { ActivityTimerHubService } from 'app/shared/services/signalr.service';
import { ActivityListService } from './activity-list.service';
import { ActivityRecordedTimeListService } from '../../case-activity-time-search/services/activity-recorded-time-list.service';
import { ActivityLevel } from 'app/shared/enumeration/activity-level';

@Injectable()
export class CaseActivityOutboundCallService extends DataService<any> implements OnDestroy {
  @BlockUI() blockUI: NgBlockUI;

  levelParentId: number;
  divisionId: number;
  itemId: number;
  startTime: string;
  activityLevel: ActivityLevel;

  outgoingCallRecordingId: number = null;

  telephonyCallClearedSubscription: Subscription;
  telephonyCallAnsweredSubscription: Subscription;

  constructor(
    private apiDependencies: ApiDependenciesService,
    private telephonyService: TelephonyService,
    private activityHubService: ActivityTimerHubService,
    private activityListService: ActivityListService,
    private recordedTimeListService: ActivityRecordedTimeListService
  ) {
    super(apiDependencies);

    // after the call has ended update call and activity
    this.telephonyCallClearedSubscription = telephonyService.onCallCleared$.subscribe((callDetails: CallDetails) => {
      if (callDetails.CallDirection === CallDirections.Outbound) {
        const model = {
          divisionId: this.divisionId,
          phoneNumber: callDetails.CLI,
          talkTimeSeconds: callDetails.TalkTimeSeconds,
          recordingId: this.outgoingCallRecordingId,
          startTime: this.startTime,
          levelParentId: this.levelParentId,
          activityLevel: this.activityLevel
        };
        if (this.itemId === undefined || this.itemId === null || this.itemId === 0) {
          console.debug('case-activity-outbound-call.service.ts ignored call cleared event. DivisionLoaded==' + this.divisionId + ', callDirection==' + callDetails.CallDirection);
        } else {
          this.updateItem(model);
        }
      }
    });

    this.telephonyCallAnsweredSubscription = telephonyService.onCallAnswered$.subscribe((callDetails: CallDetails) => {
      // retrieve recording ID in the background after recipient has answered the call
      if (this.outgoingCallRecordingId === null && callDetails.CallDirection === CallDirections.Outbound) {
        this.telephonyService.getRecordingDetails().then(recording => {
          console.log('Obtained recording ID ' + recording.RecId + ' for outbound call.');
          this.outgoingCallRecordingId = recording.RecId;
        });
      }
    });
  }

  get baseEndpoint(): string {
    return `/divisions/${this.divisionId}/activities/outbound-call`;
  }

  protected getCreateEndpoint(): string {
    return this.baseEndpoint;
  }

  protected getUpdateEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }

  public baseEndpointWithId(itemId: number): string {
    return `${this.baseEndpoint}/${itemId}`;
  }

  protected getGetEditableItemEndpoint(itemId: number) {
    return itemId ? this.baseEndpointWithId(itemId) : `/divisions/${this.divisionId}/activities/${this.activityLevel}/${this.levelParentId}/outbound-call/blank`;
  }

  getMappedEditableItemDataModel(response: any): ApiEntity {
    const model: ActivityDocumentCreateModel = response;
    return model;
  }

  async getEditableItemDetail(itemId: number): Promise<ApiEntity> {
    this.blockUI.start();
    let model: ApiEntity;
    await this.setEndpoint(this.getGetEditableItemEndpoint(itemId))
      .getSingle()
      .then(response => {
        model = this.getMappedEditableItemDataModel(response);
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });

    return Promise.resolve(model);
  }

  async makeCall(data: any, outboundNumber: string): Promise<number> {
    const endpoint = this.getCreateEndpoint();
    this.blockUI.start();

    // make the call
    this.outgoingCallRecordingId = null;

    if (this.telephonyService.isLoggedIn) {
      this.telephonyService.makeOutboundCall(outboundNumber);
      // save the outgoing call and create activity
      await this.setEndpoint(endpoint)
        .save(data)
        .then(response => {
          this.itemId = response.newRecordId;
        });
      this.blockUI.stop();
      return Promise.resolve(this.itemId);
    }
  }

  async updateItem(dataToUpdate: any): Promise<ApiResponseSummary<any>> {
    let result: ApiResponseSummary<any>;

    this.blockUI.start();

    const endpointToUse = this.baseEndpointWithId(this.itemId);
    await this.setEndpoint(endpointToUse)
      .update(dataToUpdate)
      .then(response => {
        result = response;
        this.activityHubService.requestActivityTimer(this.itemId, 'Phone Call Outbound');
        this.activityListService.reload();
        this.recordedTimeListService.reload();
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });
    return Promise.resolve(result);
  }

  ngOnDestroy() {
    this.telephonyCallClearedSubscription.unsubscribe();
    this.telephonyCallAnsweredSubscription.unsubscribe();
  }
}
