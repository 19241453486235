import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { Filter, FilterOperatorType } from 'app/shared/models/filter.model';
import { CheckListItem } from 'app/shared/models/check-list-item.model';
import { SortOrder } from 'app/shared/models/sort-order.model';
import { ApiEntity } from 'app/api/models/api-entity.model';

import { CaseActivityTimeSearchListListItemModel } from 'app/modules/solution-shared/case-activity-time-search/models/case-activity-time-search-list-list-item.model';
import { CaseActivityTimeSearchListListItemViewModel } from 'app/modules/solution-shared/case-activity-time-search/models/case-activity-time-search-list-list-item-view.model';
import { TimeSearchListRequestModel } from '../models/time-search-request.model';

@Injectable()
export class CaseActivityTimeSearchListListService extends ListServiceBase {
  divisionId: number;
  constructor(protected dependencies: ApiDependenciesService, protected dialog: MatDialog) {
    super(dependencies, dialog);

    this.initialiseFiltersSortAndPaging();
  }

  // populate initial filter properties
  private initialiseFiltersSortAndPaging() {
    this.listRequest = new TimeSearchListRequestModel();
  }

  //#region Get Endpoints

  get baseEndpoint(): string {
    if(this.divisionId===undefined) debugger;
    return '/divisions/' + this.divisionId + '/case-claim';
  }
  baseEndpointWithId(id: number): string {
    if(id===undefined) debugger;
    return this.baseEndpoint + '/' + id;
  }

  // a number of endpoint getters are defined in ListServiceBase
  // not all endpoints will be required for all implementations

  // OVERRIDDEN from ListServiceBase
  protected getCommandStubEndpoint(itemId: number): string {
    if(itemId===undefined) debugger;
    return this.baseEndpointWithId(itemId);
  }

  // OVERRIDDEN from ListServiceBase
  protected getListEndpoint(): string {
    return this.baseEndpoint + '/time-search';
  }

  //#region list loading

  // OVERRIDDEN from ListServiceBase
  // Create a new ViewModel specific to this list
  // Not necessary to set the viewmodel itemId as it is set in the viewModel constructor
  createListViewModel(dataModel: ApiEntity) {
    return new CaseActivityTimeSearchListListItemViewModel(dataModel as CaseActivityTimeSearchListListItemModel);
  }

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for each list row item
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedListItemDataModel(responseItem: any): ApiEntity {
    const model: CaseActivityTimeSearchListListItemModel = responseItem;
    return model;
  }

  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  async play(itemId: number) {
    await this.sendCommand(itemId, 'play');
  }

  protected get filterLookupDataEndpoint(): string {
    if(this.divisionId===undefined) debugger;
    return '/divisions/' + this.divisionId + '/case-claim/time-seach/filter-lookup=-data';
  }

  async getFilterLookupData(): Promise<any> {
    let model: any;
    await this.setEndpoint(this.filterLookupDataEndpoint)
      .getSingle()
      .then((response: any) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  // override
  setFilters(filters: Array<Filter>) {
    const request = this.listRequest as TimeSearchListRequestModel;

    const dateFromFilter = filters.find(f => f.field === 'DateFrom');
    request.dateFrom = dateFromFilter ? dateFromFilter.value : null;

    const dateToFilter = filters.find(f => f.field === 'DateTo');
    request.dateTo = dateToFilter ? dateToFilter.value : null;

    const caseReferenceFilter = filters.find(f => f.field === 'CaseReference');
    request.caseReference = caseReferenceFilter ? caseReferenceFilter.value : '';

    const colleagueIdFilter = filters.find(f => f.field === 'UserId');
    request.colleagueId = colleagueIdFilter ? colleagueIdFilter.value : '';

    const recordedTimeItemIdFilter = filters.find(f => f.field === 'Id');
    request.recordedTimeItemId = recordedTimeItemIdFilter ? recordedTimeItemIdFilter.value : '';

    const amendStatusFilter = filters.find(f => f.field === 'AmendStatus');
    request.amendStatus = amendStatusFilter ? amendStatusFilter.value : '';

    this.listRequest.pageNumber = 1;
    this.reload();
  }

  updateTimer(data: any) {
    const vm = this.findViewModelFromItemId(data.CaseActivityId);

    if (vm) {
      vm.dataModel.totalTime = data.ElapsedSecondsDisplay;
      vm.dataModel.timeRecordingStatus = data.Status;
      // notify that list has chnaged so that cheange detention an be invoked
      this.listReloaded.next(this.itemsCount);
    }
  }

  async approveForInvoice(approve: boolean, selectedItemIds: number[]) {
    const model = { approve: approve, caseActivityIds: selectedItemIds };
    const endPoint = '/divisions/' + this.divisionId + '/case-activities/approve-for-invoice';
    await this.setEndpoint(endPoint)
      .update(model)
      .then(() => {
        this.selectAll(false);
        this.reload();
      });
  }

}
