import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { NotesFormModel } from 'app/modules/solution-shared/note/models/notes-form.model';
import { NotesListService } from 'app/modules/solution-shared/note/services/notes-list.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { InternalFileUploaderComponent } from 'app/shared/components/file-uploader/file-uploader.component';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { ActivityTimerHubService } from 'app/shared/services/signalr.service';

@Component({
  selector: 'notes-form',
  templateUrl: './notes-form.component.html',
  styles: [
    `
  h4{
    margin-block-start: 0.1em;
    margin-block-end: 0.3em;
  }
    `,
  ],
})
export class NotesFormComponent implements OnInit {
  public data: any;
  saveText: string;

  activityId: number;

  uploadEndpoint = '';
  viewModel: NotesFormModel;

  dataForm: FormGroup;

  @ViewChild(InternalFileUploaderComponent, { static: false }) fileUploaderComponent: InternalFileUploaderComponent;

  startTime: Date;
  stopTime: Date;

  constructor(
    private fb: FormBuilder,
    private service: NotesListService,
    private asideService: AsideService,
    private formHelperService: ReactiveFormHelperService, 
    private activityHubService: ActivityTimerHubService,
    private dialog: MatDialog) {

      this.startTime = new Date();

    this.dataForm = fb.group({
      content: ['', [Validators.required, Validators.maxLength(1000)]],
    });
  }

  // shortcuts to controls for readability
  get content(): AbstractControl {
    return this.dataForm.controls['content'];
  }

  ngOnInit() {
    this.saveText = 'Create';

    const id: number = this.data.taskParentId;
    this.service.taskParentId = id;
    console.debug("notes-form.component.ts is assigning the task parent ID: " + id);

    this.service.getActivityIdFromEntityId(id).then((response) => {
      this.activityId = response;
      if (this.activityId && this.activityId > 0) {
        this.activityHubService.startRecording(this.activityId);
      }
    });

    this.uploadEndpoint = this.service.getCreateWithAttachmentEndpoint();
  }

  private uploadDocument(updateModel) {
    this.fileUploaderComponent.headers.push({ name: 'X-JsonData', value: JSON.stringify(updateModel) });
    this.fileUploaderComponent.onApply();
  }

  fileUploadComplete(): void {
    this.fileUploaderComponent.clearQueue();
    this.asideService.close('update');
  }

  initialiseForm() {
    this.dataForm.controls['content'].setValue(this.viewModel.content);
  }

  private createUpdateModel() {
    this.stopTime = new Date();
    return {
      content: this.content.value,
    };
  }

  save() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();

      if (this.data.allowAttachmentUpload && this.fileUploaderComponent.uploader.queue.length > 0) {
        this.uploadDocument(updateModel);
      } else {
        this.service.addNew(updateModel).then(() => {
          this.closeForm('update');
        });
      }
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?',
        },
      });

      dialogRef.componentInstance.onClose.subscribe((result) => {
        if (result.result) {
          this.closeForm('cancel');
        }
      });
    } else {
      this.closeForm('cancel');
    }
  }

  closeForm(message: string) {
    if (this.activityId) {
      this.activityHubService.pauseRecording(this.activityId);

    }
    this.asideService.close(message);
  }

  //#endregion
}
