import { ApiEntity } from 'app/api/models/api-entity.model';
import { CheckListItem } from 'app/shared/models/check-list-item.model';

export class UserDetailModel extends ApiEntity {
  constructor(
     public id: number = null,
    // public firstNames: string = '',
    // public lastName: string = '',
    // public fullName: string = '',
    // public username: string = '',
    // public receiveNotifications: boolean = true,
    public roles: CheckListItem[] = [],
    // public isActive: boolean = false
  ) {
    super();
  }
}
