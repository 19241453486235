import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  FormArray,
  ValidatorFn,
  AsyncValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { RegexValidationStrings } from 'app/shared/validation/reg-ex-validation-constants';
import { formatAsString } from 'app/shared/helpers/date-helpers';
import { ActivityRecordedTimeModel } from '../models/activity-recorded-time.model';
import { ActivityRecordedTimeListService } from '../services/activity-recorded-time-list.service';
import { ActivatedRoute } from '@angular/router';
import { TimeRecorderListMode } from '../models/time-recorder-mode';
import { Observable, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'activity-recorded-time-form',
  templateUrl: './activity-recorded-time-form.component.html'
})
export class ActivityRecordedTimeFormComponent implements OnInit {
  public data: any;
  saveText: string;
  editMode: boolean;
  divisionId: number;
  caseActivityId: number;
  concurrencyErrorMessage: string;

  userIdLookupData: any[];

  viewModel: ActivityRecordedTimeModel;

  dataForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private service: ActivityRecordedTimeListService,
    private asideService: AsideService,
    private formHelperService: ReactiveFormHelperService,
    private dialog: MatDialog
  ) {
    this.dataForm = fb.group(
      {
        //  userId: ['', [Validators.required]],
        startDate: ['', [Validators.required]],
        startHours: ['', [Validators.required, Validators.max(23), Validators.pattern(RegexValidationStrings.INTEGER)]],
        startMinutes: ['', [Validators.required, Validators.max(59), Validators.pattern(RegexValidationStrings.INTEGER)]],
        startSeconds: ['', [Validators.required, Validators.max(59), Validators.pattern(RegexValidationStrings.INTEGER)]],
        stopHours: ['', [Validators.required, Validators.max(23), Validators.pattern(RegexValidationStrings.INTEGER)]],
        stopMinutes: ['', [Validators.required, Validators.max(59), Validators.pattern(RegexValidationStrings.INTEGER)]],
        stopSeconds: ['', [Validators.required, Validators.max(59), Validators.pattern(RegexValidationStrings.INTEGER)]]
      },
      {
        validator: Validators.compose([this.compareStartAndStopTime(this.createNewStopTime, this.createNewStartTime)]),
        asyncValidator: this.timeOverlapValidator()
      }
    );
  }

  get startDate(): AbstractControl {
    return this.dataForm.controls['startDate'];
  }
  get startHours(): AbstractControl {
    return this.dataForm.controls['startHours'];
  }
  get startMinutes(): AbstractControl {
    return this.dataForm.controls['startMinutes'];
  }
  get startSeconds(): AbstractControl {
    return this.dataForm.controls['startSeconds'];
  }
  get stopHours(): AbstractControl {
    return this.dataForm.controls['stopHours'];
  }
  get stopMinutes(): AbstractControl {
    return this.dataForm.controls['stopMinutes'];
  }
  get stopSeconds(): AbstractControl {
    return this.dataForm.controls['stopSeconds'];
  }

  ngOnInit() {
    this.editMode = this.data.itemId > 0;
    this.service.divisionId = this.data.divisionId;
    this.service.caseActivityId = this.data.caseActivityId;
    this.saveText = this.editMode ? 'Update' : 'Create';
    this.loadFormDetails();
  }

  private loadFormDetails() {
    const id: number = this.editMode ? this.data.itemId : null;
    this.service.getEditableItemDetail(id).then((response: ActivityRecordedTimeModel) => {
      this.viewModel = response;
      if (this.viewModel) {
        this.initialiseForm();
      }
    });
  }

  initialiseForm() {
    //    this.dataForm.controls['userId'].setValue(this.viewModel.userId);
    this.dataForm.controls['startDate'].setValue(this.viewModel.startDate);
    this.dataForm.controls['startHours'].setValue(this.viewModel.startHours);
    this.dataForm.controls['startMinutes'].setValue(this.viewModel.startMinutes);
    this.dataForm.controls['startSeconds'].setValue(this.viewModel.startSeconds);
    this.dataForm.controls['stopHours'].setValue(this.viewModel.stopHours);
    this.dataForm.controls['stopMinutes'].setValue(this.viewModel.stopMinutes);
    this.dataForm.controls['stopSeconds'].setValue(this.viewModel.stopSeconds);
    //  this.userIdLookupData = this.formHelperService.createLookupData(this.viewModel.userIdLookupData);
    this.dataForm.markAsPristine();
  }

  private createUpdateModel() {
    const newStart = this.createNewStartTime();
    const newStop = this.createNewStopTime();

    return {
      //  userId: this.userId.value,
      startTime: formatAsString(newStart.toString(), 'YYYY-MM-DD HH:mm:ss'),
      stopTime: formatAsString(newStop.toString(), 'YYYY-MM-DD HH:mm:ss'),
      timestamp: this.viewModel.timestamp
    };
  }

  private createNewStopTime(): Date {
    const newStopHours = this.stopHours.value;
    const newStopMinutes = this.stopMinutes.value;
    const newStopSeconds = this.stopSeconds.value;
    const newStop = new Date(this.startDate.value);
    newStop.setHours(newStopHours, newStopMinutes, newStopSeconds);
    return newStop;
  }

  private createNewStartTime(): Date {
    const newStartHours = this.startHours.value;
    const newStartMinutes = this.startMinutes.value;
    const newStartSeconds = this.startSeconds.value;
    const newStart = new Date(this.startDate.value);
    newStart.setHours(newStartHours, newStartMinutes, newStartSeconds);
    return newStart;
  }

  save() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();
      this.service.addNew(updateModel).then(() => {
        this.asideService.close('update');
      });
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?'
        }
      });

      dialogRef.componentInstance.onClose.subscribe(result => {
        if (result.result) {
          this.asideService.close('cancel');
        }
      });
    } else {
      this.asideService.close('cancel');
    }
  }

  compareStartAndStopTime(newStart, newStop): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
      const newStopHours = group.controls['stopHours'].value;
      const newStopMinutes = group.controls['stopMinutes'].value;
      const newStopSeconds = group.controls['stopSeconds'].value;
      const newStartHours = group.controls['startHours'].value;
      const newStartMinutes = group.controls['startMinutes'].value;
      const newStartSeconds = group.controls['startSeconds'].value;
      const newStart = group.controls['startDate'].value;

      let continueWithComparison =
        (newStopHours && newStopMinutes && newStopSeconds && newStartHours && newStartMinutes && newStartSeconds && newStart) !=
          undefined && newStart != '';

      if (!continueWithComparison) {
        return null;
      }

      const newStopDate = new Date(newStart);
      newStopDate.setHours(newStopHours);
      newStopDate.setMinutes(newStopMinutes);
      newStopDate.setSeconds(newStopSeconds);

      const newStartDate = new Date(newStart);
      newStartDate.setHours(newStartHours);
      newStartDate.setMinutes(newStartMinutes);
      newStartDate.setSeconds(newStartSeconds);

      const expected = new Date(newStartDate) <= new Date(newStopDate);
      return !expected ? { dategreaterthanequaltocomparison: { value: true } } : null;
    };
  }

  timeOverlapValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      if (!this.data || !this.viewModel) {
        return null;
      }

      const proposedStart = this.createNewStartTime();
      const proposedStop = this.createNewStopTime();
      const id = null;

      const debounceTime = 250;
      return timer(debounceTime).pipe(
        switchMap(() =>
          this.service.isTimeRecordingOverlapping(id, proposedStart, proposedStop).then(retval => {
            return retval === false ? { timeoverlapping: true } : null;
          })
        )
      );
    };
  }
}
