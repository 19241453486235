import { AuthService } from 'app/core/security/auth/auth.service';
import { FileItem, ParsedResponseHeaders, FileUploader } from 'ng2-file-upload';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SnackBarService } from 'app/shared/services/snack-bar.service';
import { FileUploadManagerModel } from 'app/shared/models/file-upload-manager-model';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class InternalFileUploaderComponent implements OnInit {
  protected readonly baseApiUrl: string = environment.baseUrl + environment.apiPrefix;

  private _allowedMimeType: any[] = [];

  get allowedMimeType(): any[] {
    return this._allowedMimeType;
  }

  @Input()
  set allowedMimeType(val: any[]) {
    this._allowedMimeType = val;
    this.uploader.options.allowedMimeType = val;
  }

  @Input() id = 'file'; // if more than one file uploader required in a component then specify the id as an input, default will be 'file'
  @Input() fileType = 0;
  @Input() entityId = 0;
  @Input() entityType = '';
  @Input() endpointUrl: string;
  @Input() singleFile = false;
  @Input() hideApplyAndCancelButtons = false;
  @Input() compress: false;
  @Input() method = 'POST';
  @Input() baseApiUrlOverride: string;
  @Input() includeAuthenticationHeader = true;

  @Output() onEditComplete = new EventEmitter<any>();
  @Output() onCompleteAllEvent = new EventEmitter<void>();
  @Output() onSuccessItemEvent = new EventEmitter<any>();
  @Output() onQueueChanged = new EventEmitter<any>();

  @BlockUI('file-uploader') blockUI: NgBlockUI;

  uploadFile: any;
  uploadProgress = 0;
  uploadResponse: Object;
  uploading: boolean;
  url: string;
  headers: any[] = [];
  filesToUpload = 0;

  public singleFileUrl = '';
  private maxFileSizeKB = 102400;
  private maxFileSizeBytes = this.maxFileSizeKB * 1024;
  private maxFileSizeMB = this.maxFileSizeKB / 1024;
  private maxFileSizeMBFloor = Math.floor(this.maxFileSizeMB);
  public hasImage = false;
  public hasBaseDropZoneOver = false;
  public uploader: FileUploader = this.includeAuthenticationHeader ? new FileUploader({ headers: this.headers, authToken: 'Bearer ' + this.authService.getAccessToken() }) : new FileUploader({ headers: this.headers });
  private numberofFilesCompressing = 0;
  private numberOfFilesCompressed = 0;

  constructor(private authService: AuthService, private snackBar: SnackBarService) {}

  ngOnInit() {
    this.setApiUrl(this.endpointUrl);
    this.uploader.onCompleteAll = this.onCompleteAll.bind(this);
    this.uploader.onErrorItem = this.onErrorItem.bind(this);
    this.uploader.onSuccessItem = this.onSuccessItem.bind(this);
    this.uploader.onProgressAll = this.onprogress.bind(this);
    this.uploader.onBeforeUploadItem = this.onBeforeUploadItem.bind(this);
    this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);
    this.uploader.options.url = this.url;
    this.uploader.options.maxFileSize = this.maxFileSizeBytes;
    this.uploader.options.allowedMimeType = this.allowedMimeType;
    this.uploader.onWhenAddingFileFailed = this.onWhenAddingFileFailed.bind(this);
    this.headers.push({ name: 'X-ResourceType', value: this.fileType.toString() });
  }

  clearQueue(): void {
    this.uploader.clearQueue();
    this.filesToUpload = 0;
    this.numberofFilesCompressing = 0;
    this.numberOfFilesCompressed = 0;
    this.onQueueChanged.emit();
  }

  setApiUrl(endpointUrl: string) {
    if (this.baseApiUrlOverride) {
      this.url = this.baseApiUrlOverride + endpointUrl;
    } else {
      this.url = this.baseApiUrl + endpointUrl;
    }
  }

  // use this to force the url into each file uitem before applying the upload if the url is determined dynamically in the UI
  setUrlOption(endpoint: string) {
    this.setApiUrl(endpoint);
    this.uploader.options.url = this.url;
    this.uploader.queue.forEach((item) => {
      item.url = this.url;
    });
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  // added to resolve the issue where trying to add the same file a second time doesn't fire the onAfterAddingFile event
  // https://github.com/valor-software/ng2-file-upload/issues/220
  onChange(event: any): void {
    event.srcElement.value = '';
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    if (response) {
      console.log('File upload Success');

      this.onSuccessItemEvent.emit(JSON.parse(response));
    }
  }

  onAllowedMimeTypesChanged(event: any) {
    this.uploader.options.allowedMimeType = this.allowedMimeType;
  }

  onCompleteAll(): any {
    this.uploading = false;
    this.onEditComplete.emit();
    this.onCompleteAllEvent.emit();
  }

  public onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    this.snackBar.error('Upload failed.');
    this.uploading = false;
  }

  onBeforeUploadItem(data) {
    data.method = this.method;
    this.uploading = true;
  }

  onprogress(progress) {
    this.uploadProgress = progress;
  }

  onCancel() {
    this.onEditComplete.emit();
  }

  onApply() {
    if (this.uploader.queue.length > 0) {
      console.log('Calling uploadAll() of the ng2 file upload component');
      this.uploader.uploadAll();
    } else {
      this.onEditComplete.emit();
    }
  }

  getUploadFilesCount() {
    if (this.uploader && this.uploader.queue) {
      return this.uploader.queue.length;
    } else {
      return 0;
    }
  }

  onAfterAddingFile(fileItem: FileItem) {
    this.validate(fileItem);

    this.onQueueChanged.emit();
  }

  private validate(fileItem: FileItem): void {
    let validationMessage = '';

    if (fileItem.file.size > this.uploader.options.maxFileSize) {
      validationMessage = "Cannot add '" + fileItem.file.name + "'.<br>File cannot exceed " + this.maxFileSizeMBFloor + ' MB';
    }

    if (this.uploader.options.allowedMimeType.length > 0) {
      const allowedType = this.uploader.options.allowedMimeType.find((f) => f == fileItem.file.type);
      if (!allowedType) {
        validationMessage = "Cannot add '" + fileItem.file.name + "'.<br>File type '" + fileItem.file.type + "' is not allowed";
      }
    }

    if (validationMessage === '') {
      this.filesToUpload++;
      if (this.singleFile && this.uploader.queue.length > 1) {
        this.uploader.removeFromQueue(this.uploader.queue[0]);
        this.filesToUpload--;
      }
    } else {
      this.uploader.removeFromQueue(fileItem);
      this.snackBar.warning(validationMessage);
    }
  }

  setXJsonHeader(data: any) {
    console.log('Setting XJson header for file upload');
    const index = this.headers.findIndex((h) => h.name === 'X-JsonData');
    if (index > -1) {
      this.headers.splice(index, 1);
    }
    this.headers.push({ name: 'X-JsonData', value: JSON.stringify(data) });
  }

  onWhenAddingFileFailed = (item) => {};

  async getFilesAsByteArraysWorksNoAsync(): Promise<any[]> {
    const fileArray: any[] = [];
    await this.uploader.queue.forEach((item: FileItem) => {
      const reader = new FileReader();
      reader.readAsDataURL(item._file);
      reader.onloadend = function () {
        const base64data = reader.result;
        console.log(base64data);
        fileArray.push(base64data);
      };
    });

    return Promise.resolve(fileArray);
  }

  
  async getAllFilesAsBase64(): Promise<any[]> {
    var queueFiles = this.uploader.queue.map((f) => f._file);

    const arrayOfBase64 = await this.fileListToBase64(queueFiles);

    return Promise.resolve(arrayOfBase64);
  }

  // function which return resolved promise
  // with data:base64 string
  getFileAsBase64(file: File) {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = (ev) => {
        resolve(ev.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  // convert file list to an array of base64 strings
  private async fileListToBase64(fileList: File[]) {
    const promises = [];

    fileList.forEach((f) => promises.push(this.getFileAsBase64(f)));

    return await Promise.all(promises);
  }

  getUploadedFileModel(fileArray: any[], uploader: InternalFileUploaderComponent) {
    const uploadFile = fileArray[0];
    let index = uploadFile.indexOf(';base64,') + 8;
    var file = uploader?.uploader.queue[0].file;
    const fileModel = new FileUploadManagerModel(uploadFile.substr(index), file.type, file.name);
    return fileModel;
  }
}
