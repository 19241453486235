import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { ListServiceBase } from 'app/shared/services/list-base.service';
import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { Filter, FilterOperatorType } from 'app/shared/models/filter.model';
import { CheckListItem } from 'app/shared/models/check-list-item.model';
import { SortOrder } from 'app/shared/models/sort-order.model';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { CostListItemViewModel } from '../models/cost-list-item-view.model';
import { CostListItemModel } from '../models/cost-list-item.model';
import { CostModel } from '../models/cost.model';
import { CostListFilterModel } from '../models/cost-list-filter.model';
import { downloadFileFromExtendedBlob } from 'app/shared/helpers/file-download-helpers';
import { CostListMode } from '../models/cost-list-mode';
import { ApiResponseSummary } from 'app/shared/models/api-response-summary.model';
import { CostPaymentRequestListItemViewModel } from '../models/cost-payment-request-list-item-view.model';
import { CostPaymentRequestListItemModel } from '../models/cost-payment-request-list-item.model';

@Injectable()
export class CostListService extends ListServiceBase {
  listMode = CostListMode.Search;
  divisionId: number;
  companyId: number;
  policyId: number;
  claimId: number;

  constructor(protected dependencies: ApiDependenciesService, protected dialog: MatDialog) {
    super(dependencies, dialog);

    this.initialiseFiltersSortAndPaging();
  }

  // populate initial filter properties
  private initialiseFiltersSortAndPaging() {
    // Add intialisation here
  }

  //#region Get Endpoints
  get genericEndpoint(): string {
    return '/divisions/' + this.divisionId + '/costs';
  }
  get policyCostEndpoint(): string {
    return '/divisions/' + this.divisionId + '/policies/' + this.policyId + '/costs';
  }

  get baseEndpoint(): string {
    if (this.claimId) {
      return '/divisions/' + this.divisionId + '/companies/' + this.companyId + '/policies/' + this.policyId + '/claims/' + this.claimId + '/costs';
    } else {
      return this.genericEndpoint;
    }
  }

  baseEndpointWithId(id: number): string {
    return this.baseEndpoint + '/' + id;
  }

  genericEndpointWithId(id: number): string {
    return this.genericEndpoint + '/' + id;
  }
  genericEndpointWithPolicyId(id: number): string {
    return this.policyCostEndpoint + '/' + id;
  }

  // a number of endpoint getters are defined in ListServiceBase
  // not all endpoints will be required for all implementations

  // OVERRIDDEN from ListServiceBase
  protected getDeleteEndpoint(itemId: number): string {
    return this.genericEndpointWithId(itemId);
  }
  // OVERRIDDEN from ListServiceBase
  protected getCommandStubEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId);
  }

  // OVERRIDDEN from ListServiceBase
  protected getListEndpoint(): string {
    return this.baseEndpoint + '/cost-list';
  }
  protected get exportListEndpoint(): string {
    return this.getListEndpoint() + '/export';
  }

  public getUploadEndpoint(): string {
    return '/divisions/' + this.divisionId + '/claims/' + this.claimId + '/costs/upload';
  }

  public getUpdateWithUploadEndpoint(itemId: number): string {
    //return this.genericEndpointWithId(itemId) + '/upload';  // pre-KD0000983
    // [Route("~/api/divisions/{divisionId}/claims/{claimId}/costs/{id}/upload")]
    return '/divisions/' + this.divisionId + '/claims/' + this.claimId + '/costs/' + itemId + '/upload';
  }

  //#region list loading

  // OVERRIDDEN from ListServiceBase
  // Create a new ViewModel specific to this list
  // Not necessary to set the viewmodel itemId as it is set in the viewModel constructor
  createListViewModel(dataModel: ApiEntity) {
    var groupItem = dataModel as CostListItemModel;
    var subItemViewModels = [];
    if (groupItem.paymentRequests) {
      groupItem.paymentRequests.forEach((subItem: any) => {
        const subItemViewModel: ActionableItemState = new CostPaymentRequestListItemViewModel(subItem);
        subItemViewModel.itemId = subItem.id;
        subItemViewModels.push(subItemViewModel);
      });
      groupItem.paymentRequests = subItemViewModels;
    }
    return new CostListItemViewModel(groupItem as CostListItemModel, this.listMode);
  }

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for each list row item
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedListItemDataModel(responseItem: any): ApiEntity {
    const model: CostListItemModel = responseItem;
    return model;
  }

  //#region get details

  // OVERRIDDEN from ListServiceBase
  // Map to data model from the API response item for the detail item (create/update)
  // The model must be derived from ApiEntity
  // There is an assumption here that the response item directly maps to the angular model and so can just be assigned
  getMappedEditableItemDataModel(response: any): ApiEntity {
    const model: CostModel = response;
    return model;
  }

  //#endregion

  protected getCreateEndpoint(): string {
    return '/divisions/' + this.divisionId + '/companies/' + this.companyId + '/policies/' + this.policyId + '/claims/' + this.claimId + '/costs';
  }

  public getCreateEndpointForBatchedCostCreationFromInboundEmail(inboundEmailId: number): string {
    return '/divisions/' + this.divisionId + '/companies/' + this.companyId + '/policies/' + this.policyId + '/claims/' + this.claimId + '/costs/batched-email/' + inboundEmailId;
  }

  protected getUpdateEndpoint(itemId: number): string {
    //return this.genericEndpoint + '/' + itemId; // pre: S19121, changed this endpoint to make it more like the others so that the claim ID is passed through
    return '/divisions/' + this.divisionId + '/claims/' + this.claimId + '/costs/' + itemId;
  }

  protected get filterLookupDataEndpoint(): string {
    return this.genericEndpoint + '/filter-lookup-data';
  }

  protected getRejectBlankEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId) + '/reject-blank';
  }

  protected getPaymentRequestBlankEndpoint(itemId: number): string {
    return this.baseEndpointWithId(itemId) + '/payment-request/blank';
  }

  getPaymentRequestEndpoint(costId: number): string {
    return this.baseEndpointWithId(costId) + '/payment-request';
  }

  getPaymentRequestEndpointWithId(costId: number, itemId: number): string {
    if (itemId) {
      return this.baseEndpointWithId(costId) + '/payment-request/' + itemId;
    } else {
      return this.getPaymentRequestBlankEndpoint(costId);
    }
  }

  // Call base method to proces the command
  // Make sure that the getCommandStubEndpoint has been overridden
  async authorise(itemId: number) {
    await this.sendCommand(itemId, 'authorise');
  }

  async rejectCostForm(itemId: number, dataToUpdate: any): Promise<ApiResponseSummary<any>> {
    const endpoint = this.baseEndpointWithId(itemId) + '/reject';
    return this.updateItem(itemId, dataToUpdate, endpoint);
  }

  async downloadFile(uniqueIdentifier: string) {
    await this.setEndpoint('/file-store/' + uniqueIdentifier + '/download')
      .getFileAsBlobWithPost()
      .then((response) => {
        downloadFileFromExtendedBlob(response);
      });
  }

  async createStatement(selectedItemIds: number[]) {
    if (selectedItemIds.length === 0) {
      return;
    }

    const model = { paymentRequestIds: selectedItemIds };
    const endpoint = '/divisions/' + this.divisionId + '/claims/' + this.claimId + '/cost-batches/create-statement';

    await this.setEndpoint(endpoint)
      .save(model)
      .then(() => {
        this.reload();
      });
  }

  // OVERRIDDEN from ListServiceBase
  protected getGetEditableItemEndpoint(itemId: number) {
    return itemId ? this.genericEndpointWithPolicyId(itemId) : this.baseEndpoint + '/blank';
  }

  async getFilterLookupData(): Promise<any> {
    let model: any;
    await this.setEndpoint(this.filterLookupDataEndpoint)
      .getSingle()
      .then((response: any) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  async getRejectBlank(itemId: number): Promise<any> {
    let model: any;
    await this.setEndpoint(this.getRejectBlankEndpoint(itemId))
      .getSingle()
      .then((response: any) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  public isItemSelectable(datamodel: any): boolean {
    return datamodel.canBatch && this.isBatchingIdAvailable(datamodel.payeeUniqueBatchingIdentifier);
  }

  private isBatchingIdAvailable(id: number): boolean {
    for (let i = 0, len = this.itemList.length; i < len; i++) {
      const itemModel = this.itemList[i];
      if (itemModel.itemSelected === true) {
        const itemBatchingId = itemModel.dataModel.payeeUniqueBatchingIdentifier;
        console.debug('Batch availability check: ' + itemBatchingId + ' against ' + id);
        return id === itemBatchingId;
      }
    }

    return true;
  }

  async isReferenceUnique(id: number, value: any): Promise<boolean> {
    let result = true;

    const endpoint = `/divisions/${this.divisionId}/costs/${id}/is-reference-unique?reference=${value}`;

    await this.setEndpoint(endpoint)
      .getSingle()
      .then((response: boolean) => {
        result = response;
      });

    return Promise.resolve(result);
  }

  async getPaymentRequestBlank(itemId: any): Promise<any> {
    let model: any;
    await this.setEndpoint(this.getPaymentRequestBlankEndpoint(itemId))
      .getSingle()
      .then((response: any) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  async getPaymentRequest(costId: number, itemId: number): Promise<any> {
    let model: any;
    await this.setEndpoint(this.getPaymentRequestEndpointWithId(costId, itemId))
      .getSingle()
      .then((response: any) => {
        model = response;
      });
    return Promise.resolve(model);
  }

  async addNewPaymentRequest(item: any, costId: number): Promise<number> {
    let newRecordId: number;
    this.blockUI.start();

    await this.setEndpoint(this.getPaymentRequestEndpoint(costId))
      .save(item)
      .then((response) => {
        newRecordId = response.newRecordId;
        this.itemAdded.next(response.newRecordId);

        this.blockUI.stop();
        this.reload();
      })
      .catch(() => {
        this.blockUI.stop();
      });

    return Promise.resolve(newRecordId);
  }
}
