import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class ResponsiveService {

  resize: EventEmitter<any> = new EventEmitter();

  constructor() {
      this.innerHeight = (window.innerHeight);
      this.innerWidth = (window.innerWidth);
   }

  public innerHeight: number;
  public innerWidth: number;

  get innerWidthDisplay(): string {
      return this.innerWidth + 'px';
  }

  get innerHeightDisplay(): string {
      return this.innerHeight + 'px';
  }

  onResize(event) {
    this.innerHeight = (event.target.innerHeight);
    this.innerWidth = (event.target.innerWidth);
    this.resize.emit();
  }

  isMobile() {
     return window.innerWidth < 1024;
  }
}
