import { ExtendedBlob } from 'app/shared/models/extended-blob';

export function downloadFileFromExtendedBlob(extendedBlob: ExtendedBlob): void {

    if (window.navigator.msSaveOrOpenBlob) {  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        window.navigator.msSaveBlob(extendedBlob.blob, extendedBlob.metaData);
    } else {
        const objectUrl = window.URL.createObjectURL(extendedBlob.blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = extendedBlob.metaData;
        document.body.appendChild(a);

        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }
}

/**
 * Safely loads the specified URL to a new tab with the specified name. Suitable to use after promise resolution / AJAX calls.
 * @param {string} url - The URL to load.
 * @param {string} target - The target tab to load the URL into. A new tab will be opened if one does not already exist with the same name.
*/
export function loadUrlToNewTargetViaLinkClick(url: string, target: string): void {

    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = url;        
    a.target = target;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    
}
