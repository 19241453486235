import { ApiDependenciesService } from './api-dependencies.service';
import { environment } from 'environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { Constants } from 'app/core/models/constants';

export abstract class BaseApiService {
  protected readonly baseApiUrl: string = environment.baseUrl;
  protected readonly apiPrefix: string = environment.apiPrefix;
  protected _instanceEndpointCache = '';
  protected baseApiOverride: string = null;
  protected apiPrefixOverride: string = null;
  protected includeAuthorizationHeader: boolean = true; 

  protected endpoint: string = null;

  constructor(protected dependencies: ApiDependenciesService) {}

  protected buildUrl() {
    if (this.endpoint === null) {
      throw new Error('Must provide Web API endpoint for data service.');
    }

    if (this.baseApiOverride !== null && this.apiPrefixOverride !== null) {
      return this.baseApiOverride + this.apiPrefixOverride + this.endpoint;
    }

    return this.baseApiUrl + this.apiPrefix + this.endpoint;
  }

  protected buildHeaders(): HttpHeaders {
    // TODO refactor to call Cache service abstraction
    const accessTokenFromCookie = localStorage.getItem(Constants.LocalStorage.KeyAccessToken);

    if (this.includeAuthorizationHeader) {
      return new HttpHeaders({
        Accept: 'application/json',
        'X-API-Version': '1',
        Authorization: 'Bearer ' + accessTokenFromCookie,
        observe: 'response'
      });
    }

    return new HttpHeaders({
      Accept: 'application/json',
      'X-API-Version': '1',
      observe: 'response',
    });
  }

  protected handleApiError(error: any, request: any): Promise<any> {

    if (error.status === 401) {
      console.log('401- not authenticated from base-api.service');
      // Not authenticated
      // TODO [technical debt] user custom toast template
      // TODO [technical debt] toast setting to only fire 1 of the same toast instance
      // this.dependencies.toast.error(new Toast('Your session has expired or you are not authorised to complete this action. Please <a href="/login"><strong>Login</strong></a> again.'));

      this.dependencies.snackBar.error('Your session has expired or you are not authorised to complete this action. Please sign in again.');
      this.dependencies.router.navigateByUrl('/login?r=1');
    }

    let snackBarMessage: string;
    
    if (error.status === 403) {
      snackBarMessage = 'It looks like you don\'t have permission to access the resource. Please contact you system administrator.';
      this.dependencies.snackBar.error(snackBarMessage);
      this.dependencies.router.navigateByUrl('/login?r=1');
      return Promise.resolve(error);
    }

    
    // TODO refactor this error handling!!!
    if (error !== null && error.hasOwnProperty('_body')) {
      let errors: any[] = [];
      const errorResponse = JSON.parse(error._body);

      if (errorResponse !== null && errorResponse.hasOwnProperty('errors') && errorResponse.errors.length > 0) {
        errors = errorResponse.errors;
      } else if (errorResponse !== null && errorResponse.hasOwnProperty('validationErrors') && errorResponse.validationErrors.length > 0) {
        errors = errorResponse.validationErrors.map(err => err.key + ': ' + err.value);
      } else if (errorResponse !== null && errorResponse.hasOwnProperty('message')) {
        errors.push(errorResponse.message);
      }

      snackBarMessage = 'Data Service Error: ' + error.status + ' - ' + error.statusText + '. Check console for details';

      this.dependencies.snackBar.error(snackBarMessage);

      return Promise.resolve(errors);
    }

    this.resetEndpointFromCache();
    if (error !== null && error.hasOwnProperty('message')) {
      snackBarMessage = 'Data Service Error: ' + error.status + ' - ' + error.statusText + '. Check console for details';
      this.dependencies.snackBar.error(snackBarMessage);
    }

    return Promise.reject(error.message || error);
  }

  public getServerErrors(errorResponse: any): ApiDomainError[] {
    this.resetEndpointFromCache();
    const error = errorResponse.json() as ApiResponse;
    let errors: ApiDomainError[] = [];
    if (error !== null && error.hasOwnProperty('errors')) {
      errors = error.errors;
    }
    return errors;
  }

  public getServerValdiationErrors(errorResponse: any): ApiValidationError[] {
    this.resetEndpointFromCache();
    const error = errorResponse.json() as ApiResponse;
    let validationErrors: ApiValidationError[] = [];
    if (error !== null && error.hasOwnProperty('validationErrors')) {
      validationErrors = error.validationErrors;
    }
    return validationErrors;
  }

  protected setEndpoint(customEndpoint: string): any {
    if(customEndpoint.indexOf('/undefined/') >=0 )
    {
      // It seems lately that perhaps 80% of defects raised involve "undefined" IDs being passed from the SPA to the API.
      // Let's stop them before they get to the API and differentiate them from the usual 400/500 ones.
      var errMsg = "Attempt to set a URL endpoint with undefined route values aborted. Endpoint: " + customEndpoint;
      console.error(errMsg);

      debugger; // break here if debugger attached to more easily trace where the call came from
      
      // There doesn't seem to be a global error handler that catches the exception and shows something to the user, so manually show a snackBar error so there is at least some feedback.
      this.dependencies.snackBar.error("SPA error: Attempt to call API with missing parameters. Check console.");

      throw errMsg;
    }
    this._instanceEndpointCache = this.endpoint;
    this.endpoint = customEndpoint;
    return this;
  }

  protected resetEndpointFromCache() {
    if (this._instanceEndpointCache && this._instanceEndpointCache.length > 0) {
      this.endpoint = this._instanceEndpointCache;
    }
    this._instanceEndpointCache = '';
  }
}

export class ApiResponse {
  data: any;
  success: boolean;
  errors: ApiDomainError[];
  validationErrors: ApiValidationError[];
}

export class ApiValidationError {
  key: string;
  value: string;
}

export class ApiDomainError {
  key: string;
  value: string;
}
