import { DefaultAsideComponent } from './default-aside.component';
import {Component, ComponentFactoryResolver, AfterViewInit, ViewChild, Input} from '@angular/core';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { AsideContentDirective } from 'app/layout/directives/aside-content.directive';
import { ComponentItem } from 'app/shared/base/aside/component-item';
import { DynamicComponent } from 'app/shared/base/aside/dynamic-component';

@Component({
  selector: 'aside-container',
  templateUrl: './aside-container.component.html',
})
export class AsideContainerComponent implements AfterViewInit {

  @ViewChild(AsideContentDirective, { static: false }) asideContent: AsideContentDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private asideService: AsideService) { }

  ngAfterViewInit() {
    this.asideService.componentProvided.subscribe((componentItem: ComponentItem) => {
      this.loadComponent(componentItem);
    });
    this.asideService.componentUnset.subscribe(() => {
      this.loadComponent(new ComponentItem(DefaultAsideComponent, {}));
    });
  }

  loadComponent(componentItem) {
    const component = componentItem.resolver ? componentItem.resolver.resolveComponentFactory(componentItem.component) : this.componentFactoryResolver.resolveComponentFactory(componentItem.component);

    const viewContainerRef = this.asideContent.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(component);

    (<DynamicComponent>componentRef.instance).data = componentItem.data;
  }

}
