import { Injectable } from '@angular/core';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { LookupList } from 'app/shared/models/lookup-list.model';
import { DataService } from 'app/shared/services/data.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiResponseSummary } from '../../../../shared/models/api-response-summary.model';
import {
  ActivityDocumentCreateModel, GroupedParagraphList, ParagraphLookupList
} from '../models/case-activity-document-create.model';

@Injectable()
export class ActivityDocumentCreateService extends DataService<any> {
  @BlockUI() blockUI: NgBlockUI;
  levelParentId: number;
  activityLevel: number;
  divisionId: number;
  documentTemplateLookupData: LookupList;

  constructor(private apiDependencies: ApiDependenciesService) {
    super(apiDependencies);
  }

  get baseEndpoint(): string {
    if(this.divisionId===undefined) debugger;
    return `/divisions/${this.divisionId}/activities/${this.activityLevel}/${this.levelParentId}/generated-documents`;
  }

  protected getCreateEndpoint(): string {
    return this.baseEndpoint;
  }

  public baseEndpointWithId(itemId: number): string {
    return `${this.baseEndpoint}/${itemId}`;
  }

  protected getGetEditableItemEndpoint(itemId: number) {
    return itemId ? this.baseEndpointWithId(itemId) : this.baseEndpoint + '/blank';
  }

  getMappedEditableItemDataModel(response: any): ApiEntity {
    const model: ActivityDocumentCreateModel = response;
    return model;
  }

  async getEditableItemDetail(itemId: number): Promise<ApiEntity> {
    this.blockUI.start();
    let model: ApiEntity;
    await this.setEndpoint(this.getGetEditableItemEndpoint(itemId))
      .getSingle()
      .then(response => {
        model = this.getMappedEditableItemDataModel(response);
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });

    return Promise.resolve(model);
  }

  async addDocument(item: any, endpoint: string = null): Promise<number> {
    let newRecordId: number;
    this.blockUI.start();
    await this.setEndpoint(this.getCreateEndpoint())
      .save(item)
      .then(response => {
        newRecordId = response.newRecordId;
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });

    return Promise.resolve(newRecordId);
  }

  async updateDocument(itemId: number, dataToUpdate: any, endpoint: string = null): Promise<ApiResponseSummary<any>> {
    let result: ApiResponseSummary<any>;

    this.blockUI.start();

    const endpointToUse = endpoint == null ? this.baseEndpointWithId(itemId) : endpoint;
    await this.setEndpoint(endpointToUse)
      .update(dataToUpdate)
      .then(response => {
        result = response;
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });
    return Promise.resolve(result);
  }

  createParagraphLookupData(list: ParagraphLookupList): any[] {
    let model: GroupedParagraphList[] = [];

    let docParagraphs = {} as GroupedParagraphList;
    docParagraphs.name = 'Document Paragraphs';
    docParagraphs.items = list.items
      .filter(item => item.isParagraphFromDocument === true)
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      .map(item => {
        return {
          name: item.name,
          text: item.data,
          isParagraphFromDocument: item.isParagraphFromDocument
        };
      });

    let defaultParagraphs = {} as GroupedParagraphList;
    defaultParagraphs.name = 'Default Paragraphs';
    defaultParagraphs.items = list.items
      .filter(item => item.isParagraphFromDocument === false)
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      .map(item => {
        return {
          name: item.name,
          text: item.data,
          isParagraphFromDocument: item.isParagraphFromDocument
        };
      });

    model.push(docParagraphs);
    model.push(defaultParagraphs);

    return model;
  }

  async getDocumentTemplateAutoCompleteLookupList(filterCriteria: string): Promise<any> {
    let model: LookupList;

    if (!filterCriteria || typeof filterCriteria === 'object') {
      model = this.documentTemplateLookupData;
      return Promise.resolve(model);
    }

    await this.setEndpoint(this.baseEndpoint + `/document-template-lookup-data?filter=${filterCriteria}`)
      .getSingle()
      .then((response) => {
        model = response;
      });

    return Promise.resolve(model);
  }
}
