import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ActivityDocumentPackCreateFormComponent } from 'app/modules/solution-shared/case-activity/activity-document-pack-create-form/activity-document-pack-create-form.component';
import { CaseActivityFormComponent } from 'app/modules/solution-shared/case-activity/case-activity-form/case-activity-form.component';
import { ActivityListService } from 'app/modules/solution-shared/case-activity/services/activity-list.service';
import { ActivityTaskListService } from 'app/modules/solution-shared/case-claim/case-claim-detail/case-claim-detail-tasks/services/case-claim-task-list.service';
import { ComponentItem } from 'app/shared/base/aside/component-item';
import { AddListComponent } from 'app/shared/components/add-list/add-list.component';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { IconActionButtonEventMessage } from 'app/shared/components/icon-action-button-list/icon-action-button-list-message.model';
import { ActivityLevel } from 'app/shared/enumeration/activity-level';
import { Division } from 'app/shared/enumeration/division';
import { SystemActivityType } from 'app/shared/enumeration/system-activity-types';
import { loadUrlToNewTargetViaLinkClick } from 'app/shared/helpers/file-download-helpers';
import { StringUtilities } from 'app/shared/helpers/string-helpers';
import { IAddListService } from 'app/shared/interfaces/IAddListService.interface';
import { SnackBarService } from 'app/shared/services/snack-bar.service';
import { TelephonyService } from 'app/shared/services/telephony.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivityRecordedTimeFormComponent } from '../../case-activity-time-search/activity-recorded-time-form/activity-recorded-time-form.component';
import { ActivityTaskFormComponent } from '../../case-claim/case-claim-detail/case-claim-detail-tasks/task-form/case-claim-task-form.component';
import { CaseClaimEmailReplyComponent } from '../../case-claim/case-claim-email-reply/case-claim-email-reply.component';
import { CostFormComponent } from '../../claim-detail/claim-detail/cost-form/cost-form.component';
import { ActivityNoteFormComponent } from '../../note/case-note-form/case-note-form.component';
import { NotesFormComponent } from '../../note/notes-form/notes-form.component';
import { NotesListService } from '../../note/services/notes-list.service';
import { ActivityEmailComponent } from '../activity-email/activity-email.component';
import { ActivityOutboundCallComponent } from '../activity-outbound-call-form/activity-outbound-call-form.component';
import { ActivityDocumentCreateFormComponent } from '../case-activity-document-create-form/case-activity-document-create-form.component';
import { ActivityDocumentUploadFormComponent } from '../case-activity-document-upload-form/case-activity-document-upload-form.component';
import CaseActivityHelper from '../helpers/case-activity-helper';
import { ActivityListItemViewModel } from '../models/activity-list-item-view.model';
import { CaseActivityListMode } from '../models/CaseActivityListMode';
import { ReassociateActivityRequestArgs } from '../models/reassociate-activity-request-args';
import { ActivityDocumentPackService } from '../services/activity-document-pack.service';
import { ActivityDocumentCreateService } from '../services/case-activity-document-create.service';
import { ActivityDocumentUploadService } from '../services/case-activity-document-upload.service';
import { CaseActivityEmailService } from '../services/case-activity-email.service';
import { CaseActivityOutboundCallService } from '../services/case-activity-outbound-call.service';
import { CaseActivityPriorityNoteListService } from '../services/case-activity-priority-note.service';

@Component({
  selector: 'activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ActivityListComponent extends AddListComponent implements OnChanges, OnDestroy {
  @Input() listMode = CaseActivityListMode.History;
  @Input() divisionId: number;
  @Input() levelParentId: number = null;
  @Input() activityLevel: number;
  @Input() companyId: number;
  @Input() summaryExpanded: number;

  @Input() loadedInScreenPop = false;
  @Input() allowManage = true;
  @Input() showActions = true;
  @Input() caseActivityId: number;
  @Input() createBatchedCosts: boolean = true;

  @Output() modeSwitched = new EventEmitter<any>();
  @Output() listChanged = new EventEmitter<any>();

  @Output() reassociateActivityRequested = new EventEmitter<ReassociateActivityRequestArgs>();

  get downloadTribunalPackButtonTitle(): string {
    return 'Download Pack';
  }

  private sort: MatSort;
  private paginator: MatPaginator;
  matSortSubscribed;
  matPaginatorSubscribed;

  reassociateAsideLoaded = false;
  selectAll = false;
  priorityListIsExpanded = false;
  hasPriorityNotes = false;

  get allowSelect(): boolean {
    return this.listMode === CaseActivityListMode.TribunalPackContents;
  }

  get isBulkSelectable(): boolean {
    return this.listMode === CaseActivityListMode.TribunalPackSelection;
  }

  get displayedColumns(): string[] {
    let columns = ['activityTypeName', 'summary', 'activityDate'];
    
    if (+this.divisionId === Division.Supportis) {
      columns = ['activityTypeName', 'createdBy', 'summary', 'activityDate'];
    }

    if (this.activityLevel === ActivityLevel.Case) {
      columns.push('caseType');
      columns.push('caseStatus');
    }

    if (this.isBulkSelectable) {
      columns.unshift('select');
    }

    if (this.showActions) {
      columns.push('actions');
    }
    return columns;
  }
  //#region table container stylings
  get isCase() {
    return +this.activityLevel === ActivityLevel.Case && +this.divisionId === Division.Supportis;
  }
  get isProduct() {
    return +this.activityLevel === ActivityLevel.Product && +this.divisionId === Division.Supportis;
  }
  get isClient() {
    return +this.activityLevel === ActivityLevel.Client && +this.divisionId === Division.Supportis;
  }
  get isClaim() {
    return +this.activityLevel === ActivityLevel.Case && +this.divisionId === Division.FinanceAndLegal;
  }
  get isPolicy() {
    return +this.activityLevel === ActivityLevel.Product && +this.divisionId === Division.FinanceAndLegal;
  }
  get isCompany() {
    return +this.activityLevel === ActivityLevel.Client && +this.divisionId === Division.FinanceAndLegal;
  }

  get isScreenPopStyle() {
    return this.loadedInScreenPop;
  }
  get isCaseExpandedStyle() {
    return this.isCase && !this.loadedInScreenPop && this.summaryExpanded;
  }
  get isProductExpandedStyle() {
    return this.isProduct && this.summaryExpanded;
  }
  get isClientExpandedStyle() {
    return this.isClient && this.summaryExpanded;
  }
  get isCaseCollapsedStyle() {
    return this.isCase && !this.loadedInScreenPop && !this.summaryExpanded;
  }
  get isProductCollapsedStyle() {
    return this.isProduct && !this.summaryExpanded;
  }
  get isClientCollapsedStyle() {
    return this.isClient && !this.summaryExpanded;
  }

  get isClaimExpandedStyle() {
    return this.isClaim && !this.loadedInScreenPop && this.summaryExpanded;
  }
  get isPolicyExpandedStyle() {
    return this.isPolicy && this.summaryExpanded;
  }
  get isCompanyExpandedStyle() {
    return this.isCompany && this.summaryExpanded;
  }
  get isClaimCollapsedStyle() {
    return this.isClaim && !this.loadedInScreenPop && !this.summaryExpanded;
  }
  get isPolicyCollapsedStyle() {
    return this.isPolicy && !this.summaryExpanded;
  }
  get isCompanyCollapsedStyle() {
    return this.isCompany && !this.summaryExpanded;
  }
  //#endregion

  private ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private activityListService: ActivityListService,
    private caseActivityPriorityNoteListService: CaseActivityPriorityNoteListService,
    private asideService: AsideService,
    private taskListService: ActivityTaskListService,
    private noteListService: NotesListService,
    private associatedDocumentService: ActivityDocumentUploadService,
    private generatedDocumentService: ActivityDocumentCreateService,
    public snackBar: SnackBarService,
    private telephonyService: TelephonyService,
    private emailService: CaseActivityEmailService,
    private documentPackService: ActivityDocumentPackService,
    protected dialog: MatDialog,
    private outboundCallService: CaseActivityOutboundCallService,
    private injector: Injector
  ) {
    super();
    this.caseActivityPriorityNoteListService.listReloaded$.subscribe(() => this.hidePriorityList());
    this.loadAfterInit = false;
  }

  hidePriorityList() {
    this.priorityListIsExpanded = this.caseActivityPriorityNoteListService.itemsCount > 0;
    this.hasPriorityNotes = this.caseActivityPriorityNoteListService.itemsCount > 0;
  }

  iconFromActivityType(systemActivityType: SystemActivityType): string {
    return CaseActivityHelper.iconFromActivityType(systemActivityType);
  }

  get noItemsSelected(): boolean {
    return this.activityListService.getSelectedItemIds().length === 0;
  }

  switchToSelect() {
    this.modeSwitched.emit('select');
  }
  switchToContents() {
    this.modeSwitched.emit('contents');
  }

  protected get service(): IAddListService {
    return this.activityListService;
  }

  @ViewChild(MatSort, { static: false })
  set matSort(ms: MatSort) {
    this.sort = ms;
    if (this.sort) {
      if (this.matSortSubscribed) {
        this.matSortSubscribed.unsubscribe();
      }
      this.matSortSubscribed = this.sort.sortChange.subscribe((e) => {
        this.originalSort(e.active, e.direction);
      });
    }
  }

  @ViewChild(MatPaginator, { static: false })
  set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    if (this.paginator) {
      if (this.matPaginatorSubscribed) {
        this.matPaginatorSubscribed.unsubscribe();
      }

      this.matPaginatorSubscribed = this.paginator.page.subscribe((e) => {
        this.service.ListRequest.pageNumber = e.pageIndex + 1;
        this.service.ListRequest.pageSize = e.pageSize;
        this.pageChanged();
      });
    }
  }

  showPriorityNotesExpansion(): boolean {
    return this.activityLevel === ActivityLevel.Case || this.divisionId == 2;
  }

  loadPriorityNotesTable(): boolean {
    return this.levelParentId != null;
  }
  ngOnChanges(): void {
    this.selectAll = false;
    // this.listService.listMode = this.listMode;

    if (this.levelParentId) {
      console.debug('ActivityListComponent.ngOnChanges() is updating service divisionId (' + this.divisionId + '), activityLevel (' + this.activityLevel + ') and levelParentId (' + this.levelParentId + ').');

      this.activityListService.levelParentId = this.levelParentId;
      this.activityListService.divisionId = this.divisionId;
      this.activityListService.activityLevel = this.activityLevel;
      this.activityListService.reload();

      this.taskListService.caseClaimId = this.levelParentId;
      this.taskListService.divisionId = +this.divisionId;

      this.noteListService.taskParentId = this.levelParentId;
      this.noteListService.divisionId = this.divisionId;

      this.associatedDocumentService.levelParentId = +this.levelParentId;
      this.associatedDocumentService.divisionId = +this.divisionId;

      this.generatedDocumentService.levelParentId = +this.levelParentId;
      this.generatedDocumentService.activityLevel = +this.activityLevel;
      this.generatedDocumentService.divisionId = +this.divisionId;

      this.emailService.levelParentId = +this.levelParentId;
      this.emailService.activityLevel = +this.activityLevel;
      this.emailService.divisionId = +this.divisionId;

      this.documentPackService.levelParentId = +this.levelParentId;
      this.documentPackService.activityLevel = +this.activityLevel;
      this.documentPackService.divisionId = +this.divisionId;

      this.outboundCallService.levelParentId = +this.levelParentId;
      this.outboundCallService.divisionId = +this.divisionId;
      this.outboundCallService.activityLevel = +this.activityLevel;
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.asideService.asideClose.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      // TODO: [Technical Debt][DPB][2019-11-26] This shouldn't be called for cancels - need to check and
      // possibly update response messages for closing the aside
      this.activityListService.levelParentId = this.levelParentId; // WP
      //this.activityListService.activityLevel
      //this.activityLevel
      // Do we need to assign the above?

      if (this.reassociateAsideLoaded === true || data === 'update') {
        this.reassociateAsideLoaded = false;
        this.activityListService.reload();
        this.caseActivityPriorityNoteListService.reload();
        this.listChanged.emit();
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    super.ngOnInit();
    console.debug('ActivityListComponent.ngOnInit() is updating service divisionId (' + this.divisionId + '), activityLevel (' + this.activityLevel + ') and levelParentId (' + this.levelParentId + ').');
    this.activityListService.divisionId = +this.divisionId;
    this.taskListService.divisionId = +this.divisionId;
    this.noteListService.taskParentId = +this.levelParentId;
    this.noteListService.divisionId = +this.divisionId;
    this.associatedDocumentService.levelParentId = +this.levelParentId;
    this.associatedDocumentService.divisionId = +this.divisionId;
    this.generatedDocumentService.levelParentId = +this.levelParentId;
    this.generatedDocumentService.divisionId = +this.divisionId;
    this.activityListService.divisionId = +this.divisionId;
  }

  pageChanged() {
    this.service.reload();
  }

  originalSort(column: string, direction: string) {
    this.service.setSort(column, direction);
  }

  toggleSelectAll() {
    this.activityListService.selectAll(this.selectAll);
  }

  ShowDocumentPackContents(row: ActivityListItemViewModel): boolean {
    return row.dataModel.systemActivityType === SystemActivityType.DocumentPackCreate;
  }

  ShowDefaultContents(row: ActivityListItemViewModel): boolean {
    StringUtilities.replaceCidImages();
    return !(this.ShowDocumentPackContents(row) || this.ShowInboundEmailContents(row) || this.ShowFilePreview(row));
  }

  ShowInboundEmailContents(row: ActivityListItemViewModel): boolean {
    return row.dataModel.systemActivityType === SystemActivityType.EmailInbound;
  }

  ShowFilePreview(row: ActivityListItemViewModel): boolean {
    return row.dataModel.systemActivityType === SystemActivityType.DocumentCreate || row.dataModel.systemActivityType === SystemActivityType.DocumentUpload;
  }

  expandCollapseAll(collapsed: boolean) {
    this.activityListService.itemList.forEach((row) => {
      this.setRowExpanded(row, collapsed);
    });
  }

  isRowExpandable(row: ActivityListItemViewModel) {
    const hasDetail =
      row.dataModel.systemActivityType === SystemActivityType.EmailInbound ||
      row.dataModel.systemActivityType === SystemActivityType.EmailOutbound ||
      row.dataModel.systemActivityType === SystemActivityType.Note ||
      row.dataModel.systemActivityType === SystemActivityType.DocumentPackCreate ||
      row.dataModel.systemActivityType === SystemActivityType.DocumentCreate ||
      row.dataModel.systemActivityType === SystemActivityType.DocumentUpload;
    return hasDetail;
  }

  toggleExpanded(row: ActivityListItemViewModel) {
    const hasDetail = this.isRowExpandable(row);
    row.expanded = hasDetail && !row.expanded;
  }

  setRowExpanded(row: ActivityListItemViewModel, expanded: boolean) {
    const hasDetail = this.isRowExpandable(row);
    row.expanded = hasDetail && expanded;
  }

  convertString(source): string {
    if (!source) {
      return '';
    }
    return StringUtilities.translateCR(source);
  }

  includeInPack() {
    this.activityListService.updatePackContents(true, this.activityListService.getSelectedItemIds()).then(() => {
      this.switchToContents();
    });
  }

  removeFromTribunalPack(activityId: number) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { title: 'Confirm activity Removal', message: 'Are you sure you want to remove the activity from the pack?' },
    });

    dialogRef.componentInstance.onClose.subscribe((result) => {
      if (result.result) {
        this.activityListService.updatePackContents(false, [activityId]).then(() => {});
      }
    });
  }

  downloadTribunalPack() {
    this.activityListService.downloadPack().then(() => {});
  }

  get displayDownloadTribunalPack(): boolean {
    return this.listMode === CaseActivityListMode.TribunalPackContents && this.service.itemsCount > 0;
  }

  listenToCall(recordingId: number) {
    this.telephonyService.openRecordingPlayer(recordingId);
  }

  addNew(activityTypeName: string) {
    switch (activityTypeName) {
      case 'Task':
        this.asideService.setAndOpen(new ComponentItem(ActivityTaskFormComponent, { itemId: 0, levelParentId: this.levelParentId, activityLevel: this.activityLevel }), this.injector);

        break;
      case 'Note':
        this.asideService.setAndOpen(new ComponentItem(ActivityNoteFormComponent, { itemId: 0, levelParentId: this.levelParentId, activityLevel: this.activityLevel, divisionId: this.divisionId }), this.injector);
        break;
      case 'Upload Document':
        this.asideService.setAndOpen(
          new ComponentItem(ActivityDocumentUploadFormComponent, {
            itemId: 0,
            levelParentId: this.levelParentId,
            activityLevel: this.activityLevel,
          }),
          this.injector
        );
        break;
      case 'Email':
      case 'Email Inbound':
      case 'Email Outbound':
        this.asideService.setAndOpen(new ComponentItem(ActivityEmailComponent, { itemId: null, levelParentId: this.levelParentId, activityLevel: this.activityLevel }), this.injector);
        break;
      case 'Create Document':
        this.asideService.setAndOpen(
          new ComponentItem(ActivityDocumentCreateFormComponent, {
            itemId: 0,
            levelParentId: this.levelParentId,
            activityLevel: this.activityLevel,
            divisionId: this.divisionId,
          }),
          this.injector
        );
        break;
      case 'Phone Call':
      case 'Phone Call Inbound':
        this.snackBar.info('Not implemented. Unsupported Activity Type [' + activityTypeName + ']');
        break;
      case 'Phone Call Outbound':
        this.asideService.setAndOpen(
          new ComponentItem(ActivityOutboundCallComponent, {
            itemId: 0,
            levelParentId: this.levelParentId,
            activityLevel: this.activityLevel,
          }),
          this.injector
        );
        break;
      case 'Document Pack':
        this.asideService.setAndOpen(
          new ComponentItem(ActivityDocumentPackCreateFormComponent, {
            itemId: 0,
            levelParentId: this.levelParentId,
            activityLevel: this.activityLevel,
          }),
          this.injector
        );
        break;
      default:
        this.asideService.setAndOpen(
          new ComponentItem(CaseActivityFormComponent, {
            itemId: 0,
            activityTypeName: activityTypeName,
            levelParentId: this.levelParentId,
            activityLevel: this.activityLevel,
          }),
          this.injector
        );
        break;
    }
  }

  editItem(message: IconActionButtonEventMessage) {
    const item = this.activityListService.findViewModelFromItemId(message.data);
    switch (item.dataModel.systemActivityType) {
      case SystemActivityType.Task:
        this.asideService.setAndOpen(new ComponentItem(ActivityTaskFormComponent, { itemId: item.dataModel.activityEntityId, activityId: message.data }), this.injector);
        break;
      case SystemActivityType.Note:
        this.asideService.setAndOpen(new ComponentItem(ActivityNoteFormComponent, { itemId: item.dataModel.activityEntityId, activityId: message.data, activityLevel: this.activityLevel, divisionId: this.divisionId }), this.injector);
        break;
      case SystemActivityType.DocumentUpload:
        this.asideService.setAndOpen(
          new ComponentItem(ActivityDocumentUploadFormComponent, {
            itemId: item.dataModel.activityEntityId,
            activityId: message.data,
            levelParentId: this.levelParentId,
            activityLevel: this.activityLevel,
          }),
          this.injector
        );
        break;
      case SystemActivityType.EmailInbound:
        this.snackBar.info('Inbound emails cannot be edited');
        break;
      case SystemActivityType.EmailOutbound:
        const caseActivity = this.activityListService.findViewModelFromItemId(message.data);
        this.asideService.setAndOpen(
          new ComponentItem(ActivityEmailComponent, {
            itemId: item.dataModel.activityEntityId,
            levelParentId: this.levelParentId,
            activityLevel: this.activityLevel,
            activityId: item.dataModel.id,
          }),
          this.injector
        );
        break;
      case SystemActivityType.DocumentCreate:
        this.asideService.setAndOpen(
          new ComponentItem(ActivityDocumentCreateFormComponent, {
            itemId: item.dataModel.activityEntityId,
            activityId: message.data,
            levelParentId: this.levelParentId,
            activityLevel: this.activityLevel,
            divisionId: this.divisionId,
          }),
          this.injector
        );
        break;
      case SystemActivityType.PhoneCallInbound:
      case SystemActivityType.PhoneCallOutbound:
        this.snackBar.info('Phone calls cannot be edited');
        break;
      case SystemActivityType.DocumentPackCreate:
        this.snackBar.info('Document Packs cannot be edited');
        break;
      default:
        this.asideService.setAndOpen(new ComponentItem(CaseActivityFormComponent, { itemId: message.data, activityTypeName: item.dataModel.activityTypeName }), this.injector);
        break;
    }
  }

  doIconButtonAction(message: IconActionButtonEventMessage) {
    switch (message.action) {
      case 'download': {
        const caseActivity = this.activityListService.findViewModelFromItemId(message.data);
        this.activityListService.downloadFile(caseActivity.dataModel.uniqueIdentifier);
        break;
      }
      case 'viewFile': {
        const caseActivity = this.activityListService.findViewModelFromItemId(message.data);
        this.activityListService.getWebUrl(caseActivity.dataModel.uniqueIdentifier).then((url) => {
          loadUrlToNewTargetViaLinkClick(url, 'oneDriveFiles');
        });
        break;
      }
      case 'edit': {
        this.editItem(message);
        break;
      }
      case 'notes': {
        const notesActivity = this.activityListService.findViewModelFromItemId(message.data);
        this.asideService.setAndOpen(new ComponentItem(NotesFormComponent, { taskParentId: notesActivity.dataModel.activityEntityId }), this.injector);
        break;
      }
      case 'createBatchedCosts': {
        if (this.createBatchedCosts) {
          const emailActivity = this.activityListService.findViewModelFromItemId(message.data);
          const emailId = parseInt(emailActivity.dataModel.activityEntityId);
          const claimId = this.levelParentId;
          const activityDate = emailActivity.dataModel.activityDate;

          this.asideService.setAndOpen(
            new ComponentItem(CostFormComponent, {
              itemId: 0,
              divisionId: this.divisionId,
              claimId: claimId,
              emailIdForBatch: emailId,
              submissionDateOverride: activityDate,
            }),
            this.injector
          );
        } else {
          this.snackBar.info('Not allowed as the Claim or Policy is closed');
        }
        break;
      }
      case 'reassociate': {
        if (this.loadedInScreenPop === false) {
          // get list service props and reset after close
          const selectedActivity = this.activityListService.findViewModelFromItemId(message.data);

          /* KD0001139: This doesn't work here as we don't have access to GroupCallAssociateComponent / GroupInboxAssociateComponent
             Importing them results in circular references
          if (selectedActivity.dataModel.systemActivityType === SystemActivityType.PhoneCallInbound) {
            // call reassociation
            this.asideService.setAndOpen(
              new ComponentItem(GroupCallAssociateComponent, {
                divisionId: this.divisionId,
                activityLevel: this.activityLevel,
                selectedItems: [selectedActivity.dataModel.activityEntityId],
              }),
              this.injector
            );
          } else {
            // email reassociation

            this.asideService.setAndOpen(
              new ComponentItem(GroupInboxAssociateComponent, {
                divisionId: this.divisionId,
                activityLevel: this.activityLevel,
                selectedEmailItems: [selectedActivity.dataModel.activityEntityId],
              }),
              this.injector
            );
          }*/

          // KD0001139: As we can't use the required components here, we'll raise an event and let the caller handle it instead.
          var reassociateActivityRequest: ReassociateActivityRequestArgs = {
            divisionId: this.divisionId,
            activityLevelId: this.activityLevel,
            entityId: +[selectedActivity.dataModel.activityEntityId],
            systemActivityType: selectedActivity.dataModel.systemActivityType,
            reassociateAsideLoaded: false,
            asideService: this.asideService,
            injector: this.injector,
            isAssociated: true,
          };
          this.reassociateActivityRequested.emit(reassociateActivityRequest);

          this.reassociateAsideLoaded = reassociateActivityRequest.reassociateAsideLoaded;

          if (!this.reassociateAsideLoaded) {
            this.snackBar.error('SPA error: Activity reassociation not handled by the consumer of this Activity List.');
          }
        } else {
          this.snackBar.warning("An email or call can't be reassociated whilst in screen pop");
        }
        break;
      }
      case 'emailReplyItem': {
        const emailActivity = this.activityListService.findViewModelFromItemId(message.data);
        this.asideService.setAndOpen(
          new ComponentItem(CaseClaimEmailReplyComponent, {
            itemId: emailActivity.dataModel.activityEntityId,
            levelParentId: this.levelParentId,
            activityLevel: this.activityLevel,
          }),
          this.injector
        );
        break;
      }
      case 'removeFromTribunalPack': {
        this.removeFromTribunalPack(message.data);
        break;
      }
      case 'emailDocumentItem': {
        this.asideService.setAndOpen(
          new ComponentItem(ActivityEmailComponent, {
            itemId: message.data,
            activityType: SystemActivityType.DocumentCreate,
            levelParentId: this.levelParentId,
            activityLevel: this.activityLevel,
          }),
          this.injector
        );
        this.asideService.open();
        break;
      }
      case 'addRecordedTime': {
        const caseActivity = this.activityListService.findViewModelFromItemId(message.data);
        this.asideService.setAndOpen(
          new ComponentItem(ActivityRecordedTimeFormComponent, {
            itemId: 0,
            divisionId: this.divisionId,
            caseActivityId: caseActivity.dataModel.id,
          }),
          this.injector
        );
        this.asideService.open();
        break;
      }
      case 'listenToCall': {
        const caseActivity = this.activityListService.findViewModelFromItemId(message.data);
        this.listenToCall(caseActivity.dataModel.recordingId);
        break;
      }
      case 'emailUploadedDocumentItem': {
        this.asideService.setAndOpen(
          new ComponentItem(ActivityEmailComponent, {
            itemId: message.data,
            activityType: SystemActivityType.DocumentUpload,
            levelParentId: this.levelParentId,
            activityLevel: this.activityLevel,
          }),
          this.injector
        );
        break;
      }
      case 'emailDocumentPackItem': {
        this.asideService.setAndOpen(
          new ComponentItem(ActivityEmailComponent, {
            itemId: message.data,
            overridingFormTitle: 'Send Quotation Document Pack',
            activityType: SystemActivityType.DocumentPackCreate,
            levelParentId: this.levelParentId,
            activityLevel: this.activityLevel,
          }),
          this.injector
        );
        break;
      }
      case 'togglePublishToPortal': {
        this.activityListService.togglePublishToPortal(message.data).then(() => {
          this.service.reload();
        });
        break;
      }

      default: {
        console.log('Unsupported action: ' + message.action);
        break;
      }
    }
  }

  togglePriorityNotesExpanded() {
    this.priorityListIsExpanded = !this.priorityListIsExpanded;
  }

  get priorityListExpandTitle(): string {
    return this.priorityListIsExpanded ? 'Collapse Details' : 'Expand Details';
  }
}
