import { Component, Input, ViewChild, OnChanges, Injector } from '@angular/core';
import { AddListComponent } from 'app/shared/components/add-list/add-list.component';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ComponentItem } from 'app/shared/base/aside/component-item';
import { IconActionButtonEventMessage } from 'app/shared/components/icon-action-button-list/icon-action-button-list-message.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { IAddListService } from 'app/shared/interfaces/IAddListService.interface';

import { NotesListService } from 'app/modules/solution-shared/note/services/notes-list.service';
import { NotesFormComponent } from 'app/modules/solution-shared/note/notes-form/notes-form.component';
import { StringUtilities } from 'app/shared/helpers/string-helpers';
import { UsersService } from 'app/modules/user-management/services/users.service';
import { SystemRole } from 'app/shared/enumeration/system-role';

@Component({
  selector: 'notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss'],
})
export class NotesListComponent extends AddListComponent implements OnChanges {
  @Input() parentId: number;

  isUserAdmin: boolean;

  private sort: MatSort;
  private paginator: MatPaginator;
  matSortSubscribed;
  matPaginatorSubscribed;

  displayedColumns: string[] = ['description', 'content', 'noteCreateBy', 'noteCreateDate', 'actions'];

  constructor(
    private listService: NotesListService,
    private asideService: AsideService,
    private usersService: UsersService,
    private injector: Injector
  ) {
    super();
  }

  protected get service(): IAddListService {
    return this.listService;
  }

  @ViewChild(MatSort, { static: false })
  set matSort(ms: MatSort) {
    this.sort = ms;
    if (this.sort) {
      if (this.matSortSubscribed) {
        this.matSortSubscribed.unsubscribe();
      }
      this.matSortSubscribed = this.sort.sortChange.subscribe((e) => {
        this.originalSort(e.active, e.direction);
      });
    }
  }

  @ViewChild(MatPaginator, { static: false })
  set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    if (this.paginator) {
      if (this.matPaginatorSubscribed) {
        this.matPaginatorSubscribed.unsubscribe();
      }

      this.matPaginatorSubscribed = this.paginator.page.subscribe((e) => {
        this.service.ListRequest.pageNumber = e.pageIndex + 1;
        this.service.ListRequest.pageSize = e.pageSize;
        this.pageChanged();
      });
    }
  }

  get taskNotesListService() {
    return this.service as NotesListService;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnInit() {
    this.listService.taskParentId = this.parentId;

    this.usersService.getUserRoles().then((roles: SystemRole[]) => {
      this.isUserAdmin =
        roles.includes(SystemRole.ITAdmin) ||
        roles.includes(SystemRole.ExternalITAdmin) ||
        roles.includes(SystemRole.DivisionAdmin) ||
        roles.includes(SystemRole.ExternalDivisionAdmin) ||
        roles.includes(SystemRole.TeamLeader) ||
        roles.includes(SystemRole.ExternalTeamLeader)
        roles.includes(SystemRole.Management) ||
        roles.includes(SystemRole.ExternalManagement) ||
        roles.includes(SystemRole.ClientRelations) ||
        roles.includes(SystemRole.ExternalClientRelations);
    });
    super.ngOnInit();
  }

  ngOnChanges() {
    this.listService.taskParentId = this.parentId;
    this.listService.reload();
  }

  pageChanged() {
    this.service.reload();
  }

  originalSort(column, direction) {
    this.service.setSort(column, direction);
  }

  addNew() {
    this.asideService.setAndOpen(new ComponentItem(NotesFormComponent, { taskParentId: 0 }), this.injector);
  }

  downloadAttachment(id: number) {
    this.blockUI.start();

    this.listService
      .downloadNoteAttachment(id)
      .then(() => {
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });
  }

  delete(id: number) {
    this.service.deleteItem(id, 'Confirm Delete', 'Are you sure you want to delete this Note?');
  }

  doIconButtonAction(message: IconActionButtonEventMessage) {
    switch (message.action) {
      case 'edit': {
        this.asideService.setAndOpen(new ComponentItem(NotesFormComponent, { taskParentId: message.data }), this.injector);
        break;
      }
      default: {
        console.error('Unsupported action: ' + message.action);
        break;
      }
    }
  }

  convertString(source): string {
    return StringUtilities.translateCR(source);
  }
}
