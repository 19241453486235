import { ApiDependenciesService } from './../../api/services/api-dependencies.service';
import { DataService } from 'app/shared/services/data.service';
import { Injectable } from '@angular/core';
import { ApiEntity } from 'app/api/models/api-entity.model';

@Injectable()
export class HeaderService extends DataService<any> {
  
  constructor(protected dependencies: ApiDependenciesService) {
    super(dependencies);
  }

  getTaskStatusSummaryEndpoint(divisionId: number): string {
    return '/divisions' + '/' + divisionId + '/work-tasks/summary';
  }

  async getTaskStatusSummary(divisionId: number): Promise<ApiEntity> {
    let model: ApiEntity;
    await this.setEndpoint(this.getTaskStatusSummaryEndpoint(divisionId))
      .getSingle()
      .then(response => {
        model = response;
      });
    return Promise.resolve(model);
  }

  // TODO: MB move this, possibly to the telephony service?
  async addNewGroupCallItem(model: any): Promise<any> {
    let result: any;

    const endpoint = `/divisions/${model.divisionId}/group-call-items`;
    await this.setEndpoint(endpoint)
      .save(model)
      .then((response: any) => {
        result = response;
      });

    return Promise.resolve(result);
  }

  async updateGroupCallItem(id: number, model: any) {
    let result: any;

    const endpoint = `/divisions/${model.divisionId}/group-call-items/${id}`;
    await this.setEndpoint(endpoint)
      .update(model)
      .then((response: any) => {
        result = response;
      });

    return Promise.resolve(result);
  }
}
