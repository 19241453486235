export class AuditEntry {

  public guid: string;
  public whenAudited: string;
  public deviceId: string;
  public severity: string;
  public message: string;

  constructor() {
  }
}

export class AuditEntryContainer {

  public entries: Array<AuditEntry>;

  constructor() {
    this.entries = new Array<AuditEntry>();
  }
}
