import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from 'app/core/models/constants';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { ActivityLevel } from 'app/shared/enumeration/activity-level';
import { SystemActivityType } from 'app/shared/enumeration/system-activity-types';
import { toFormattedDateString } from 'app/shared/helpers/date-helpers';
import { ReactiveFormHelperService } from 'app/shared/helpers/reactive-form-helper.service';
import { ActivityTimerHubService } from 'app/shared/services/signalr.service';
import { CaseActivityEmailModel } from '../models/case-activity-email.model';
import { CaseActivityEmailService } from '../services/case-activity-email.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'activity-email',
  templateUrl: './activity-email.component.html',
  styles: [
    `
      .body {
        height: calc(100vh - 355px);
      }
    `,
  ],
})

// This component is used for outbound emails - both case level and the send document from a document activity
export class ActivityEmailComponent implements OnInit {
  public data: any;
  saveText: string;
  closeText: string;
  editMode: boolean;
  overridingFormTitle: string;
  isRecipientLookupDaataGrouped = false;

  recipientLookupData: any[];
  emailTemplateLookupData: any[];
  viewModel = new CaseActivityEmailModel();
  dataForm: FormGroup;

  tinyMceAPIKey = environment.tinyMceAPIKey;
  tinyMceSettings = Constants.EditorSettings.FullSettings;
  startTime: Date;
  stopTime: Date;

  get showEmailTemplates() {
    return this.data.activityLevel !== ActivityLevel.Client && !(this.data.itemId > 0);
  }

  constructor(
    private fb: FormBuilder,
    private service: CaseActivityEmailService,
    private asideService: AsideService,
    private activityHubService: ActivityTimerHubService,
    private formHelperService: ReactiveFormHelperService,
    private dialog: MatDialog
  ) {
    this.startTime = new Date();

    this.dataForm = fb.group({
      recipientId: ['', [Validators.required]],
      emailTemplateId: ['', []],
      subject: ['', [Validators.required, Validators.maxLength(100)]],
      body: ['', [Validators.required, Validators.maxLength(5000)]],
    });
  }

  get formTitle(): string {
    if (this.overridingFormTitle == null) return this.saveText + ' Email';
    return this.overridingFormTitle;
  }

  // short cuts to controls for readability
  get recipientId(): AbstractControl {
    return this.dataForm.controls['recipientId'];
  }
  get subject(): AbstractControl {
    return this.dataForm.controls['subject'];
  }
  get body(): AbstractControl {
    return this.dataForm.controls['body'];
  }
  get emailTemplateId(): AbstractControl {
    return this.dataForm.controls['emailTemplateId'];
  }

  ngOnInit() {
    // TODO: MB Include the new ActivityType Document Pack
    // also.. shall we post the ActivityType with the save? use this to decided which one time code to use
    this.overridingFormTitle = this.data.overridingFormTitle;

    this.editMode = this.data.itemId > 0 && this.data.activityType !== SystemActivityType.DocumentUpload && this.data.activityType !== SystemActivityType.DocumentCreate && this.data.activityType !== SystemActivityType.DocumentPackCreate;
    this.service.activityType = this.data.activityType;

    if (this.editMode) {
      console.log(`Start activity recording for ` + this.data.activityId);
      this.activityHubService.startRecording(this.data.activityId);
    }

    this.loadFormDetails();
  }

  private loadFormDetails() {
    this.saveText = this.editMode ? 'Update' : 'Create';

    this.service.getEditableItemDetail(this.data.itemId).then((response: CaseActivityEmailModel) => {
      this.viewModel = response;
      if (this.viewModel) {
        this.saveText = this.viewModel.isReadOnly ? 'View' : this.saveText;
        this.closeText = this.viewModel.isReadOnly ? 'Close' : 'Cancel';
        this.initialiseForm();
      }
    });
  }

  initialiseForm() {
    if (this.viewModel.recipientLookupData) {
      this.isRecipientLookupDaataGrouped = this.viewModel.isRecipientLookupDataGrouped;
      if (this.isRecipientLookupDaataGrouped === true) {
        this.recipientLookupData = this.service.createGroupedLookupDataForRecipient(this.viewModel.recipientLookupData);
      } else {
        this.recipientLookupData = this.formHelperService.createLookupData(this.viewModel.recipientLookupData);
      }
    }

    if (this.viewModel.emailTemplateLookupData) {
      this.emailTemplateLookupData = this.formHelperService.createLookupDataWithDataObject(this.viewModel.emailTemplateLookupData);
      this.emailTemplateLookupData.unshift({ id: 0, text: 'None', data: { subject: '', body: '' } });
      this.emailTemplateId.setValue(0);
      this.emailTemplateId.valueChanges.subscribe((value) => this.getEmailTemplate(value));
    }

    if (this.editMode) {
      this.recipientId.setValue(this.viewModel.recipientId);
      this.subject.setValue(this.viewModel.subject);
    } else {
      this.recipientId.setValue(this.viewModel.recipientLookupData.defaultId);
      if (this.viewModel.subject != null) {
        // API provided us with a default subject line
        this.subject.setValue(this.viewModel.subject);
      }
    }
    this.body.setValue(this.viewModel.body);
    this.dataForm.markAsPristine();
  }

  private createUpdateModel() {
    this.stopTime = new Date();

    return {
      id: this.data.itemId,
      activityId: this.data.activityId,
      recipientId: this.recipientId.value,
      subject: this.subject.value,
      body: this.body.value,
      startTime: toFormattedDateString(this.startTime, 'YYYY-MM-DD HH:mm:ss'),
      stopTime: toFormattedDateString(this.stopTime, 'YYYY-MM-DD HH:mm:ss'),
      fileStoreReferenceId: this.viewModel.fileStoreReferenceId,
      documentPackId: this.viewModel.documentPackId,
      activityLevel: this.data.activityLevel,
      levelParentId: this.data.levelParentId,
    };
  }

  save() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();
      if (this.editMode) {
        this.service.updateItem(this.data.itemId, updateModel).then((response) => {
          if (response.success === true) {
            this.closeForm('update');
          } else {
          }
        });
      } else {
        this.service.addNew(updateModel).then((newRecordId) => {
          this.activityHubService.requestActivityTimer(newRecordId, 'Email Outbound');
          this.closeForm('update');
        });
      }
    }
  }

  send() {
    if (this.formHelperService.isFormValid(this.dataForm)) {
      const updateModel = this.createUpdateModel();

      this.service.sendEmail(updateModel).then((newRecordId) => {
        if (!this.editMode) {
          this.activityHubService.requestActivityTimer(newRecordId, 'Email Outbound');
        }
        this.closeForm('update');
      });
    }
  }

  cancel() {
    if (this.formHelperService.isFormDirty(this.dataForm)) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '450px',
        data: {
          title: 'Confirm Lost Changes',
          message: 'Changes have been made. Closing this form will lose these changes. Are you sure you want to continue?',
        },
      });

      dialogRef.componentInstance.onClose.subscribe((result) => {
        if (result.result) {
          this.closeForm('cancel');
        }
      });
    } else {
      this.closeForm('cancel');
    }
  }

  closeForm(message: string) {
    if (this.editMode) {
      console.log(`Pause activity recording for ` + this.data.activityId);
      this.activityHubService.pauseRecording(this.data.activityId);
    }

    this.asideService.close(message);
  }

  getEmailTemplate(templateId: number) {
    const template = this.emailTemplateLookupData.find((f) => f.id === templateId);
    this.subject.setValue(template.data.subject);
    this.body.setValue(template.data.body);
  }
}
