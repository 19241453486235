import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { ListRequestModel } from 'app/api/models/list-request.model';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { Constants } from 'app/core/models/constants';
import { User } from 'app/modules/user-management/models/user.model';
import { Division } from 'app/shared/enumeration/division';
import { RestrictedClientPageType } from 'app/shared/enumeration/restricted-client-page-type';
import { SystemRole } from 'app/shared/enumeration/system-role';
import { CollectionResponse } from 'app/shared/models/collectionResponse.model';
import { DataService } from 'app/shared/services/data.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { UserPermissions, UserPermissionsAction } from '../models/userpermissions.model';
import { IUsersService } from './users.service.interface';

@Injectable({ providedIn: 'root' })
export class UsersService extends DataService<User> implements IUsersService {
  private guid;
  divisions = Division;
  private permissionsStatus = new Subject<UserPermissionsAction>();

  public loggedInUserId: number;
  /* UsersService must be a singleton service so that user roles are not fetched every time permissions are needed */
  private userRoles = new Array<SystemRole>();
  public userDivisions: Division[] = [];

  public userPermissions = new UserPermissions();

  private _themeName: Subject<number> = new Subject<number>();
  themeName = this._themeName.asObservable();

  permissionsLoaded = new Subject<void>();
  permissionsLoaded$ = this.permissionsLoaded.asObservable();

  // TODO: [Review][DPB][2019-08-30] we probably only need one observable than notifies of status: subject or permissionsLoaded
  /** Provides a way for clients to be notified of progress */
  public getMessage(): Observable<UserPermissionsAction> {
    return this.permissionsStatus.asObservable();
  }

  constructor(protected dependencies: ApiDependenciesService, protected overlayContainer: OverlayContainer) {
    super(dependencies);
    this.endpoint = '/user-Management/users';

    this.guid = (this.S4() + this.S4() + '-' + this.S4() + '-4' + this.S4().substr(0, 3) + '-' + this.S4() + '-' + this.S4() + this.S4() + this.S4()).toLowerCase();
    console.log('constructing users service: ' + this.guid);
  }

  S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  public async toggleUserActive(userId: number) {
    return this.setEndpoint('/user-Management/change-user-status/' + userId).update();
  }

  public async createNewUser(formData: any) {
    return this.setEndpoint('/user-Management/user').save(formData);
  }

  public async updateExistingUser(userId: number, formData: any) {
    formData.id = userId;
    return this.setEndpoint('/user-Management/user').update(formData);
  }

  public async isPageForRestrictedClient(pageType: RestrictedClientPageType, entityId: number): Promise<boolean> {
    let result: boolean = false;

    await this.setEndpoint(`/application/is-page-for-restricted-client/page-type/${pageType}/entity-id/${entityId}`)
      .getSingle()
      .then((response) => {
        result = response;
      });

    return Promise.resolve(result);
  }

  // TODO: [Refactor][DPB][2019-08-30] Doesn't appear to be used
  public async getUsersList(listRequest: ListRequestModel = null): Promise<User[]> {
    let result: User[] = [];

    await this.queryCollection(listRequest).then((queryResults: CollectionResponse<User>) => {
      result = this.mapUserObjects(queryResults);
    });
    return Promise.resolve(result);
  }

  public async getUserRoles(): Promise<SystemRole[]> {
    let model: SystemRole[];

    await this.setEndpoint('/current-user-roles')
      .getCollection()
      .then((response) => {
        model = <SystemRole[]>response;
      });

    return Promise.resolve(model);
  }

  public isAdmin(roles: SystemRole[]): boolean {
    return roles.includes(SystemRole.DivisionAdmin);
  }

  public async getUserDivisions(): Promise<Division[]> {
    let model: Division[];
    await this.setEndpoint('/divisions/current-user-divisions')
      .getCollection()
      .then((response) => {
        model = <Division[]>response;
      });
    return Promise.resolve(model);
  }

  public get userHasSingleDivision(): boolean {
    return this.userDivisions && this.userDivisions.length === 1;
  }

  /* Note:   As this is a singleton service, userPermissions will only be fetched once per login.
     Future: Get permissions from Web API? WebaPI should also have a role based permissions attibute rather than role based auth attributes */
  public async ensurePermissions(): Promise<UserPermissions> {
    console.log('ensuring permissions pre: ' + this.guid);
    // expect permissions to be ensured only once

    const selectedDivision = this.dependencies.cookieService.getTheme();

    if (this.userPermissions.isInitialised) {
      // send message so that the layout and side menu can respond after route guards have already loded permissions
      this.permissionsLoaded.next();
      this._themeName.next(selectedDivision);

      return Promise.resolve(this.userPermissions);
    }

    console.log('ensuring permissions not init: ' + this.guid);

    const userName = this.dependencies.cookieService.getLoggedInUserName();
    this.loggedInUserId = this.dependencies.cookieService.getLoggedInUserId();

    if (userName === '') {
      if (this.userPermissions.isInitialised) {
        this.userPermissions = new UserPermissions();
      }

      // No permissions
      this.permissionsStatus.next(new UserPermissionsAction('Current user has no permissions'));

      this.permissionsLoaded.next();

      return Promise.resolve(this.userPermissions);
    }

    if (this.userPermissions.isInitialised) {
      // Application has not changed but different user now logged in
      this.userPermissions = new UserPermissions();
    }

    // get and process divisions
    this.userDivisions = await this.getUserDivisions();

    if (!this.userDivisions || this.userDivisions.length === 0) {
      this.setTheme(null);
    } else if (this.userDivisions.length === 1) {
      this.setTheme(this.userDivisions[0]);
    }

    // get and process roles
    this.userRoles = await this.getUserRoles();

    const adminOnlyAccess = this.hasRolePermission([SystemRole.DivisionAdmin, SystemRole.ITAdmin, SystemRole.ExternalDivisionAdmin, SystemRole.ExternalITAdmin]);

    const financeTeamAccess = this.hasRolePermission([SystemRole.DivisionAdmin, SystemRole.ITAdmin, SystemRole.ExternalDivisionAdmin, SystemRole.ExternalITAdmin, SystemRole.FinanceTeam, SystemRole.ExternalFinanceTeam]);

    const managementAccess = this.hasRolePermission([SystemRole.Management, SystemRole.ExternalManagement]);

    const terminateAgreementAccess = this.hasRolePermission([SystemRole.ITAdmin, SystemRole.ExternalITAdmin, SystemRole.DivisionAdmin, SystemRole.ExternalDivisionAdmin, SystemRole.FinanceTeam, SystemRole.ExternalFinanceTeam]);

    const financeOnlyAccess = this.hasRolePermission([SystemRole.FinanceTeam, SystemRole.ExternalFinanceTeam]);
    

    const adminAndTeamLeadAccess = this.hasRolePermission([
      SystemRole.DivisionAdmin,
      SystemRole.ITAdmin,
      SystemRole.ExternalDivisionAdmin,
      SystemRole.ExternalITAdmin,
      SystemRole.TeamLeader,
      SystemRole.ExternalTeamLeader,
      SystemRole.Management,
      SystemRole.ExternalManagement,
      SystemRole.ClientRelations,
      SystemRole.ExternalClientRelations,
    ]);
    this.userPermissions.action.configuration.configurationAccess = true;

    this.userPermissions.action.configuration.manageUsers = this.hasRolePermission([SystemRole.DivisionAdmin, SystemRole.ITAdmin, SystemRole.ExternalDivisionAdmin, SystemRole.ExternalITAdmin]);
    this.userPermissions.action.configuration.manageOther = adminOnlyAccess;
    this.userPermissions.action.configuration.isEnabled = this.userPermissions.action.configuration.getIsEnabled();
    this.userPermissions.action.configuration.manageMultipleDivisions = this.hasRolePermission([SystemRole.ITAdmin]);
    this.userPermissions.action.configuration.manageDivisions = adminOnlyAccess;
    this.userPermissions.action.configuration.manageProducts = adminOnlyAccess;
    this.userPermissions.action.configuration.manageSectors = adminOnlyAccess;
    this.userPermissions.action.configuration.manageDocumentTypes = adminOnlyAccess;
    this.userPermissions.action.configuration.manageTimeAmendmentReasons = adminOnlyAccess;
    this.userPermissions.action.configuration.manageAllegationParagraphs = adminOnlyAccess;
    this.userPermissions.action.configuration.manageDocumentTemplates = adminOnlyAccess;
    this.userPermissions.action.configuration.manageDocumentPackDefinitions = adminOnlyAccess;
    this.userPermissions.action.configuration.manageActivityTypes = adminOnlyAccess;
    //this.userPermissions.action.configuration.manageResources = adminOnlyAccess;
    this.userPermissions.action.configuration.manageTaxRates = financeTeamAccess;
    this.userPermissions.action.configuration.manageRateCards = financeTeamAccess;
    this.userPermissions.action.configuration.manageDocumentPackTypes = adminOnlyAccess;
    this.userPermissions.action.configuration.manageServiceAgreementFinanceDetails = financeOnlyAccess;
    this.userPermissions.action.configuration.manageCancellationReasons = adminOnlyAccess;
    this.userPermissions.action.configuration.manageCostTypes = adminOnlyAccess;
    this.userPermissions.action.configuration.manageCoverLevels = adminOnlyAccess;
    this.userPermissions.action.configuration.manageEmailTemplates = adminOnlyAccess;
    this.userPermissions.action.configuration.manageSingleUseActions = adminOnlyAccess;
    this.userPermissions.action.configuration.manageDatabaseAudit = adminOnlyAccess;

    this.userPermissions.action.search.allowActivityTimeFilteringByUser = adminAndTeamLeadAccess;

    this.userPermissions.action.hasDivisionSelected = selectedDivision !== null;

    this.userPermissions.action.allowUserToAccessRestrictedClientPages = managementAccess;

    this.userPermissions.action.task.allowUserToUpdateTaskOwner = adminAndTeamLeadAccess;
    this.userPermissions.action.contact.allowUserToUpdateClientPortalAccess = adminAndTeamLeadAccess;
    this.userPermissions.action.contact.allowUserToRestrictClients = managementAccess;

    this.userPermissions.action.product.allowUserToUpdateProductFinance = financeTeamAccess;
    this.userPermissions.action.product.manageServiceAgreementPolicyOptions = financeTeamAccess;
    this.userPermissions.action.product.manageTerminateServiceAgreementOptions = terminateAgreementAccess;
    this.userPermissions.action.contact.financeAccessOnly = financeOnlyAccess;
    this.userPermissions.action.contact.financeOrAdminAccessOnly = financeTeamAccess;
    this.userPermissions.action.scheme.manageSchemes = financeOnlyAccess || adminOnlyAccess;

    this.userPermissions.action.task.isEnabled = this.userPermissions.action.task.getIsEnabled();

    this.userPermissions.isAdminUser = this.userHasAnyRole([SystemRole.DivisionAdmin, SystemRole.ITAdmin, SystemRole.ExternalITAdmin, SystemRole.ExternalDivisionAdmin]);
    this.userPermissions.markAsInitialised(userName);

    this.permissionsStatus.next(new UserPermissionsAction('Permissions are available'));
    this.permissionsLoaded.next();

    return Promise.resolve(this.userPermissions);
  }

  setTheme(division: number = null) {
    this._themeName.next(division);

    let classList = this.overlayContainer.getContainerElement().classList;
    var currentTheme = this.dependencies.cookieService.getTheme();

    if (division === null) {
      if (currentTheme !== null) {
        classList.remove(this.divisions[currentTheme]);
      }
      this.dependencies.cookieService.delete(Constants.Cookies.KeyDivisionTheme);
    } else {
      if (currentTheme !== null) {
        classList.remove(this.divisions[currentTheme]);
      }
      let theme = this.divisions[division];
      classList.add(theme);
      this.dependencies.cookieService.setTheme(division);
    }
  }

  public async hasPermission(permission: string): Promise<boolean> {
    if (!permission) {
      console.log('No permissions: ' + permission);
      return Promise.resolve(false);
    }
    await this.ensurePermissions();
    const val = this.recompose(this.userPermissions, permission);
    console.log('val: ' + val);
    return Promise.resolve(val);
  }

  private hasRolePermission(roles: Array<SystemRole>): boolean {
    return this.userHasAnyRole(roles);
  }

  private userHasAnyRole(roles: SystemRole[]): boolean {
    if (!this.userRoles) {
      return false;
    }

    for (const role of roles) {
      const e = this.userRoles.find((r) => r === role);

      if (e != null) {
        return true;
      }
    }

    return false;
  }

  private recompose(obj: any, s: string) {
    const parts = s.split('.');
    const newObj = obj[parts[0]];

    if (parts[1]) {
      parts.splice(0, 1);
      const newString = parts.join('.');
      return this.recompose(newObj, newString);
    }

    return newObj;
  }

  private mapUserObjects(queryResults: CollectionResponse<User>): User[] {
    const result: User[] = [];

    queryResults.items.forEach((user) => {
      result.push(new User(user.id, user.username, user.firstName, user.surname, user.email, user.mobileNumber, user.isActive, user.roles));
    });

    return result;
  }
}
