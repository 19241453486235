import { ApiEntity } from 'app/api/models/api-entity.model';
import { ActivityLevel } from 'app/shared/enumeration/activity-level';
import { LookupList } from 'app/shared/models/lookup-list.model';

export class NotesFormModel extends ApiEntity {
  constructor(id: number = null,
    public content: string = '',
    public allowAttachmentUpload: boolean = false,
  ) {
    super(id);
  }
}

export class CaseNoteFormModel extends ApiEntity {
  constructor(id: number = null,
    public description: string = '',
    public content: string = '',
    public timestamp: string = '',
    public activityLevel: ActivityLevel = null,
    public levelParentId: number = null,
    public isPriority: boolean = false
  ) {
    super(id);
  }
}
