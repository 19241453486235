import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AddListComponent } from 'app/shared/components/add-list/add-list.component';
import { IconActionButtonEventMessage } from 'app/shared/components/icon-action-button-list/icon-action-button-list-message.model';
import { AsideService } from 'app/layout/components/aside-container/aside.service';
import { ComponentItem } from 'app/shared/base/aside/component-item';
import { ActivityDocumentCreateFormComponent } from '../case-activity-document-create-form/case-activity-document-create-form.component';
import { loadUrlToNewTargetViaLinkClick } from 'app/shared/helpers/file-download-helpers';
import { ActivityListService } from 'app/modules/solution-shared/case-activity/services/activity-list.service';
import { ActionableItemState } from 'app/shared/models/actionable-item-state';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { IIconActionButton } from 'app/shared/interfaces/IIconActionButton.interface';
import { ActivityLevel } from 'app/shared/enumeration/activity-level';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { trigger, transition, state, style, animate } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'activity-document-pack-list',
  templateUrl: './activity-document-pack-list.component.html',
  styleUrls: ['./activity-document-pack-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])]
})

export class ActivityDocumentPackListComponent extends AddListComponent {
  @Input() listData: ActivityDocumentPackListItemModel[];
  @Input() levelParentId: number;
  @Input() activityLevel: ActivityLevel;
  divisionId : number;

  itemList: ActivityDocumentPackListItemViewModel[];

  get displayedColumns(): string[] {
    return ['name', 'actions'];
  }

  constructor(private route: ActivatedRoute, private asideService: AsideService, private activityListService: ActivityListService, private dialog: MatDialog) {
    super();
    // this.listService.allowReload = false;
    this.loadAfterInit = false;
    route.params.subscribe(params => {
      this.divisionId = params['divisionId'];
    });
  }

  ngOnInit() {
    this.itemList = [];
    this.listData.forEach((dataModel: ApiEntity) => {
      const model = new ActivityDocumentPackListItemViewModel(dataModel as ActivityDocumentPackListItemModel);
      model.itemId = dataModel.id;
      this.itemList.push(model);
    });
  }

  doIconButtonAction(message: IconActionButtonEventMessage) {
    switch (message.action) {
      case 'download': {
        this.activityListService.downloadFile(message.data.dataModel.uniqueIdentifier);
        break;
      }
      case 'viewFile': {
        this.activityListService.getWebUrl(message.data.dataModel.uniqueIdentifier).then(url => {
          loadUrlToNewTargetViaLinkClick(url, 'oneDriveFiles');
        });
        break;
      }
      case 'edit': {
        this.asideService.setComponent(
          new ComponentItem(ActivityDocumentCreateFormComponent, {
            itemId: message.data.dataModel.id,
            activityId: message.data.dataModel.parentActivityId,
            isInDocumentPack: true,
            divisionId: this.divisionId,
            levelParentId: this.levelParentId,
            activityLevel: this.activityLevel
          })
        );
        this.asideService.open();
        break;
      }
      case 'regenerate': {
        this.confirmAction('Regenerate Document Pack?', 'This will Regenerate the existing Document Pack, continue?', ()=> {
          this.activityListService.regenerate((<ActivityDocumentPackListItemModel>message.data.dataModel).id);
        });


      }
    }
  }

    confirmAction(title: string, message: string, action: ()=>void){
      this.confirm(title, message).then(result=>{
        if(result) action();
      })
    }

    /**
     * Asks the user for confirmation and then resolves a promise with the user's selection.
     * */
    confirm(title: string, message: string) : Promise<boolean> {
      return new Promise((resolve, reject) => {

        const dialogRef = this.dialog.open(ConfirmationModalComponent, {
          width: '450px',
          data: {
            title: title,
            message: message
          }
        });

        dialogRef.componentInstance.onClose.subscribe(result => {
          resolve(!!result.result);
        });
      })
    }

    toggleExpanded(row: ActivityDocumentPackListItemViewModel) {
      row.expanded = !row.expanded;
      console.debug("Document pack item toggleExpanded() - set to: " + row.expanded);
    }

}

export class ActivityDocumentPackListItemModel extends ApiEntity {
  constructor(
    id: number = null,
    parentActivityId: number = null,
    public name: string = '',
    public uniqueIdentifier: string = '',
    public isGeneratedDocument: boolean = false,
    public canEdit: boolean = false,
    public cannotEditReason: string = '',
    public canRegenerate: boolean = false
  ) {
    super(id);
  }
}

export class ActivityDocumentPackListItemViewModel extends ActionableItemState {
  downloadButton: IIconActionButton;
  viewFileButton: IIconActionButton;
  editButton: IIconActionButton;
  regenerateButton: IIconActionButton;
  expanded: boolean = false;

  constructor(dataModel: ActivityDocumentPackListItemModel) {
    super();

    this.dataModel = dataModel;

    if (this.dataModel.canEdit) {
      this.editButton = {
        visible: true,
        enabled: true,
        actionEnabled: true,
        actionDisabledMessage: '',
        iconName: 'edit',
        actionName: 'edit',
        hoverText: 'Edit the item',
        class: '',
        buttonText: ''
      };
      this.processActions.push(this.editButton);
    }
    
    if (this.dataModel.canRegenerate) {
      this.regenerateButton = {
        visible: true,
        enabled: true,
        actionEnabled: true,
        actionDisabledMessage: '',
        iconName: 'refresh',
        actionName: 'regenerate',
        hoverText: 'Regenerate the item',
        class: '',
        buttonText: ''
      };
      this.processActions.push(this.regenerateButton);
    }

    this.downloadButton = {
      visible: true,
      enabled: true,
      actionEnabled: true,
      actionDisabledMessage: '',
      iconName: 'cloud_download',
      actionName: 'download',
      hoverText: 'Download the item',
      class: '',
      buttonText: ''
    };
    this.processActions.push(this.downloadButton);

    this.viewFileButton = {
      visible: true,
      enabled: true,
      actionEnabled: true,
      actionDisabledMessage: '',
      iconName: 'visibility',
      actionName: 'viewFile',
      hoverText: 'Open file in browser',
      class: '',
      buttonText: ''
    };
    this.processActions.push(this.viewFileButton);
  }
}
