import { Injectable } from '@angular/core';
import { ApiEntity } from 'app/api/models/api-entity.model';
import { ApiDependenciesService } from 'app/api/services/api-dependencies.service';
import { DataService } from 'app/shared/services/data.service';
import { environment } from 'environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ExternalRaiseDefectModel } from '../models/external-raise-defect.model';
@Injectable({providedIn: 'root'})

export class ExternalRaiseDefectService extends DataService<any> {

  @BlockUI() blockUI: NgBlockUI;

  protected baseApiOverride = environment.baseUrlForRaisingDefect;
  protected apiPrefixOverride = environment.apiPrefixForRaisingDefect;
  protected includeAuthorizationHeader = false;

  constructor(apiDepenencies: ApiDependenciesService) {
    super(apiDepenencies);
  }

  baseEndpoint(): string {
    return `/software-projects/${environment.projectKeyForRaisingDefect}/defects`;
  }


  getLookupDataForDefectEndpoint(): string {
    return `${this.baseEndpoint()}/blank`;
  }

  getCreateEndpoint(): string {
    return `${this.baseEndpoint()}`;
  }

  public getCreateWithAttachmentsEndpoint(): string {
    return `${this.baseEndpoint()}/defect-with-attachments`;
  }


  async getLookupDataForDefect(): Promise<ApiEntity> {
    this.blockUI.start();  
    let model: ExternalRaiseDefectModel;

    await this
      .setEndpoint(this.getLookupDataForDefectEndpoint())
      .getSingle().then((response: any) => {
        model = response;
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });

    return Promise.resolve(model);
  }

  async addNew(item: any): Promise<any> {
    this.blockUI.start();
    let model: any;

    await this.setEndpoint(this.getCreateEndpoint())
      .save(item)
      .then((response) => {
        model = response;
        this.blockUI.stop();
      })
      .catch(() => {
        this.blockUI.stop();
      });

    return Promise.resolve(model);
  }

}
